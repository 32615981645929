<template>
    <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mt-2">
            <h3> Loading....</h3>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
    export default {
      name: "Logout",
      mounted() {
        this.logout();
      },
      methods: {
        logout(){
            localStorage.clear();
            this.$router.push({name:'Login'})
            this.$toast.success("Successfully logged out", {
            // override the global option
            position: 'top'
            })
        },
      }
    }
  </script>
  
  <style scoped>
  
  </style>