<template>
    <Topnav />

    <Sidebar />

    <div class="content-wrapper">

        <div class="content-header">

            <div class="container-fluid">

                <div class="row">

                    <div class="col-md-4"></div>
                    <div class="col-md-4 main-content">
                        Please note that the bot does not yet place orders.<br/>
                        Click the chat bubble at the bottom to test on web.<br/><br/>
                        Or <a href="#" class="url" @click.prevent="openChat()">Click Here</a> 

                        <beautiful-chat class="chat"
                        :participants="participants" 
                        :titleImageUrl="titleImageUrl"
                        :onMessageWasSent="onMessageWasSent" 
                        :messageList="messageList"
                        :newMessagesCount="newMessagesCount" 
                        :isOpen="isChatOpen" 
                        :close="closeChat"
                        :open="openChat" 
                        :showEmoji="true" 
                        :showFile="false" 
                        :showEdition="false" 
                        :showDeletion="false"
                        :deletionConfirmation="true" 
                        :showTypingIndicator="showTypingIndicator" 
                        :showLauncher="true"
                        :showCloseButton="false" 
                        :colors="colors" 
                        :alwaysScrollToBottom="alwaysScrollToBottom"
                        :disableUserListToggle="false" 
                        :messageStyling="messageStyling" 
                        @onType="handleOnType"
                        >
                            <template v-slot:header>
                                WIT Courier
                            </template>
                        </beautiful-chat>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
import utils from '../utils'

// resources
import vanImageURL from '@/assets/img/wit_van.png'
//import anonImageURL from '@/assets/img/image.svg'
import chatPopupURL from '@/assets/sound/chat_popup.wav'
import chatMessageURL from '@/assets/sound/chat_reply.wav'

const sfxChatPopup = new Audio(chatPopupURL)
const sfxChatMessage = new Audio(chatMessageURL)

export default {
    name: 'ChatBox',
    components: {
        Topnav,
        Sidebar
    },
    data() {
      
        return {
            chatbot: {
                context: null,
            },
            participants: [
                {
                    id: 'bot',
                    name: 'WIT',
                    imageUrl: vanImageURL
                },
            ],
            /*titleImageUrl: chatImageURL,*/
            messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
            newMessagesCount: 0,
            isChatOpen: false, // to determine whether the chat window should be open or closed
            showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
            colors: {
                header: {
                    bg: '#475761',
                    text: '#ffffff'
                },
                launcher: {
                    bg: '#475761'
                },
                messageList: {
                    bg: '#ffffff'
                },
                sentMessage: {
                    bg: '#2c99cc',
                    text: '#ffffff'
                },
                receivedMessage: {
                    bg: '#eaeaea',
                    text: '#222222'
                },
                userInput: {
                    bg: '#f4f7f9',
                    text: '#565867'
                }
            }, // specifies the color scheme for the component
            alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
            messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
        }
    },
    methods: {
        async receiveMessage(text) {
            this.showTypingIndicator = "bot"

            // make interesting delays
            await utils.sleep(Math.random()*1000)

            //console.log(`receiveMessage: ${text}`)
            await sfxChatMessage.play()
            if (text.length > 0) {
                this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
                this.onMessageWasSent({ author: 'bot', type: 'text', data: { text } })
            }

            this.showTypingIndicator = ""
        },
        onMessageWasSent(message) {
            if(message.author == 'me') {
                this.queryBot(message.data.text)
            }
            // called when the user sends a message
            this.messageList = [...this.messageList, message]
        },
        async openChat() {
            // called when the user clicks on the fab button to open the chat
            this.isChatOpen = true
            this.newMessagesCount = 0

            if(this.messageList.length == 0) {
                this.showTypingIndicator = "bot"
                await utils.sleep(Math.random()*1000)
                await sfxChatPopup.play();
                this.messageList.push(
                { 
                    type: 'text', 
                    author: `bot`, 
                    data: { text: `Send me a message to begin! 🚚` },
                    suggestions: ['I need help', 'I want to make a request', 'Other services']
                })
                this.showTypingIndicator = ""
            }
        },
        closeChat() {
            // called when the user clicks on the botton to close the chat
            this.isChatOpen = false
        },
        handleScrollToTop() {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
        },
        handleOnType() {
        },

        async queryBot(text) {
            let loginToken = localStorage.getItem("user-info")
            try {
                let response = await API.post(`/chatbot`,
                    { query: text, context: this.chatbot.context },
                    {
                        headers: {
                            Authorization: `Bearer ${loginToken}`
                        }
                    })
                    if (response.status == 200) {
                        const data = response.data.data 
                        this.chatbot.context = data.context
                        await this.receiveMessage(data.reply)
                    }
            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    this.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    })
                    this.$router.push({ name: 'Login' })
                    localStorage.clear()
                } else {
                    await this.receiveMessage("Sorry, but I'm having problems. Please try again in a moment.")
                }
            }
        }
    }
}
</script>

<style scoped>
.main-content {
    text-align: center;
}

.chat{ 
    text-align: justify;
}

.url {
    text-decoration: underline;
    color: grey;
}

.url:hover {
    cursor: pointer;
    color: black;
}
</style>