import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = `${config.api.scheme}://${config.api.url}:${config.api.port}/${config.api.version}/`;

let obj = axios.create({
    headers: {
        'Content-type': 'application/json'
    }
});

obj.baseURL = axios.defaults.baseURL;

obj.checkRole = function(userId, loginToken, onSuccess, onError) {
    obj.get(`/users/${userId}`, {
        headers: {
            Authorization: `Bearer ${loginToken}`
        }
    })
    .then(function(response) {
        if(response.status == 200) {
            let data = response.data;
            if(data.roles.includes("admin") == false) {
                onError("User unauthorized");
                return;
            }
            onSuccess();
        }
    })
    .catch(function(error) {
        onError(error);
    })
};

const API = obj;

export default API;
