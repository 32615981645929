<template>
  <div>
    <div class="vue-simple-progress-text" :style="text_style" v-if="text.length > 0 && textPosition == 'top'">
      {{text}} 
      <span v-for="(action, k) in opts" :key="k">
        <a href=""
        @click.prevent="$emit(emitterName(action))" 
        :title="directiveTitle(action)">{{textName(action)}}
        </a>
        <span v-if="opts.length > 2 && k < opts.length-1">,</span>
      </span>
    </div>
    <div class="vue-simple-progress" :style="progress_style">
      <div class="vue-simple-progress-text" :style="text_style" v-if="text.length > 0 && textPosition == 'middle'">
        {{text}} 
        <span v-for="(action, k) in opts" :key="k">
          <a href=""
          @click.prevent="$emit(emitterName(action))" 
          :title="directiveTitle(action)">{{textName(action)}}
          </a>
          <span v-if="opts.length > 2 && k < opts.length-1">,</span>
        </span>
      </div>
      <div style="position: relative; left: -9999px" :style="text_style" v-if="text.length > 0 && textPosition == 'inside'">
        {{text}} 
        <span v-for="(action, k) in opts" :key="k">
          <a href=""
          @click.prevent="$emit(emitterName(action))" 
          :title="directiveTitle(action)">{{textName(action)}}
          </a>
          <span v-if="opts.length > 2 && k < opts.length-1">,</span>
        </span>
      </div>
      <div class="vue-simple-progress-bar" :style="bar_style">
        <div :style="text_style" v-if="text.length > 0 && textPosition == 'inside'">
          {{text}} 
          <span v-for="(action, k) in opts" :key="k">
            <a href=""
            @click.prevent="$emit(emitterName(action))" 
            :title="directiveTitle(action)">{{textName(action)}}
            </a>
            <span v-if="opts.length > 2 && k < opts.length-1">,</span>
          </span>
        </div>
      </div>
    </div>
    <div class="vue-simple-progress-text" :style="text_style" v-if="text.length > 0 && textPosition == 'bottom'">
      {{text}} 
      <span v-for="(action, k) in opts" :key="k">
        <a href=""
        @click.prevent="$emit(emitterName(action))" 
        :title="directiveTitle(action)">{{textName(action)}}
        </a>
        <span v-if="opts.length > 2 && k < opts.length-1">,</span>
      </span>
    </div>
  </div>
</template>

<script>
  var isNumber = function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  export default {
    props: {
      'val': {
        default: 0
      },
      'max': {
        default: 100
      },
      'size': {
        // either a number (pixel width/height) or 'tiny', 'small',
        // 'medium', 'large', 'huge', 'massive' for common sizes
        default: 3
      },
      'bg-color': {
        type: String,
        default: '#eee'
      },
      'bar-color': {
        type: String,
        default: '#2196f3' // match .blue color to Material Design's 'Blue 500' color
      },
      'bar-transition': {
        type: String,
        default: 'all 0.1s ease'
      },
      'bar-border-radius': {
        type: Number,
        default: 0
      },
      'spacing': {
        type: Number,
        default: 4
      },
      'text': {
        type: String,
        default: ''
      },
      'text-align': {
        type: String,
        default: 'center', // 'left', 'right'
      },
      'text-position': {
        type: String,
        default: 'bottom', // 'bottom', 'top', 'middle', 'inside'
      },
      'font-size': {
        type: Number,
        default: 13
      },
      'text-fg-color': {
        type: String,
        default: '#222'
      },
      'opts': {
        type: Array,
        default() {
          return [];
        }
      }
    },
    computed: {
        pct() {
            var pct = this.val / this.max * 100
            return Math.max(0, Math.min(pct, 100)).toFixed(2)
        },
        size_px() {
            switch (this.size)
            {
            case 'tiny':    return 2
            case 'small':   return 4
            case 'medium':  return 8
            case 'large':   return 12
            case 'big':     return 16
            case 'huge':    return 32
            case 'massive': return 64
            }

            return isNumber(this.size) ? this.size : 32
        },
        text_padding() {
            switch (this.size)
            {
            case 'tiny':
            case 'small':
            case 'medium':
            case 'large':
            case 'big':
            case 'huge':
            case 'massive':
                return Math.min(Math.max(Math.ceil(this.size_px/8), 3), 12)
            }

            return isNumber(this.spacing) ? this.spacing : 4
        },
        text_font_size() {
            switch (this.size)
            {
            case 'tiny':
            case 'small':
            case 'medium':
            case 'large':
            case 'big':
            case 'huge':
            case 'massive':
                return Math.min(Math.max(Math.ceil(this.size_px*1.4), 11), 32)
            }

            return isNumber(this.fontSize) ? this.fontSize : 13
        },
        progress_style() {
            var style = {
            'background': this.bgColor
            }

            if (this.textPosition == 'middle' || this.textPosition == 'inside')
            {
            style['position'] = 'relative'
            style['min-height'] = this.size_px+'px'
            style['z-index'] = '-2'
            }

            if (this.barBorderRadius > 0)
            {
            style['border-radius'] = this.barBorderRadius+'px';
            }

            return style
        },
        bar_style() {
            var style = {
            'background': this.barColor,
            'width': this.pct+'%',
            'height': this.size_px+'px',
            'transition': this.barTransition
            }

            if (this.barBorderRadius > 0)
            {
            style['border-radius'] = this.barBorderRadius+'px';
            }

            if (this.textPosition == 'middle' || this.textPosition == 'inside')
            {
            style['position'] = 'absolute'
            style['top'] = '0'
            style['height'] = '100%'
            style['min-height'] = this.size_px+'px',
            style['z-index'] = '-1'
            }

            return style
        },
        text_style() {
            var style = {
            'color': this.textFgColor,
            'font-size': this.text_font_size+'px',
            'text-align': this.textAlign,
            }

            if (this.textPosition == 'top' || this.textPosition == 'middle' || this.textPosition == 'inside')
            style['padding-bottom'] = this.text_padding+'px'
            if (this.textPosition == 'bottom' || this.textPosition == 'middle' || this.textPosition == 'inside')
            style['padding-top'] = this.text_padding+'px'

            return style
        }
    },
    methods: {
        emitterName(action) {
          return typeof action == 'string'? action.toLowerCase() : action.on.toLowerCase();
        },
        textName(action) {
          return typeof action == 'string'? action : action.text;
        },
        directiveTitle(action) {
          return typeof action == 'string'? action : action.title ?? null;
        },
    }
  }
</script>

<style scoped>
a {
  text-decoration: underline solid;
  color: #2d98ca;
  padding: 1px;
}
a:hover {
  text-decoration: underline solid;
  color: #9bd8f4;
}
</style>