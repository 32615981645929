<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid">
    
                <div class="row mt-4">
    
                    <div class="col-lg-4">
    
                        <!-- small box -->
    
                        <div class="small-box">
    
                            <div class="inner">
    
                                <p>Total Active Drivers</p>
    
                                <h3>{{drivers}}</h3>
    
                            </div>
    
                        </div>
    
                    </div>
    
                    <!-- ./col -->
    
                    <div class="col-lg-4">
    
                        <!-- small box -->
    
                        <div class="small-box ">
    
                            <div class="inner">
    
                                <p>Total Customers</p>
    
                                <h3>{{customers}}</h3>
    
                            </div>
    
                        </div>
    
                    </div>
    
                    <!-- ./col -->
    
                    <div class="col-lg-4">
    
                        <!-- small box -->
    
                        <div class="small-box ">
    
                            <div class="inner">
    
                                <p>Total Queued Jobs</p>
    
                                <h3>{{queuedJob}}</h3>
    
                            </div>
    
                        </div>
    
                    </div>
    
                </div>

    
                <div class="row mt-4">
                    <div class="col-lg-12 col-12">
    
                        <!-- small box -->

                        <div class="small-box">

                            <div class="inner">
                            
                                <h2>On-Call Support</h2>
                                <div v-if="onCall.length > 0">
                                    
                                    <DynamicTable ref="dynamicTable"
                                        :header="table.header" 
                                        :data="onCall" 
                                        :perPage="8"
                                        :actions="false"
                                        @upload="()=>{}"
                                        @edit="()=>{}"/>
                        
                                </div>
                                <div v-else>
                                <p>Add On-Call Personel</p><br/>
                                    <input v-model="newOnCall.name" type="text" placeholder="First & Last Name">
                                    <input v-model="newOnCall.email" type="email" placeholder="Email" pattern="^.+@[^\.].*\.[a-z]{2,}$">
                                    <input v-model="newOnCall.phone" type="tel" placeholder="Phone Number" pattern="^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$">
                                    &nbsp;<button class="btn btn-primary" @click.prevent="addNewOnCallPerson">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
    
        </div>
    
    </div>

</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import DynamicTable from '@/components/DynamicTable.vue';
import Switches from 'vue-switches'
import API from '../axios.config'
import utils from '../utils'

export default {
    name: 'Dashboard',
    components: {
        Topnav,
        Sidebar,
        Switches,
        DynamicTable,
    },
    data() {
        return {
            drivers: 0,
            customers: 0,
            queuedJob: 0,
            abandonedJob: 0,
            completedJob: 0,
            table: {
                header: [
                    {contactPersonName: 'Full Name'},
                    {contactPersonPhone: 'Phone'},
                    {contactPersonEmail: "Email"},
                ],
                data: [],
                busy: false,
                errorMessage: '',
                progress: 0, // for uploading / dropping records
                maxProgress: 0, // ^
            },
            onCall: [],
            newOnCall: {name:"", email: "", phone: ""}
        }
    },
    async mounted() {
        await this.checkRole();
    },
    methods: {
        async checkRole() {
            const vm = this;
            vm.msg = "";
            let userId = localStorage.getItem("user_id");
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            let loginToken = localStorage.getItem("user-info")

            API.checkRole(userId, loginToken, 
            async ()=>{
                // on success
                await this.totalDrivers();
                await this.totalCustomers();
                await this.totalQueuedJob();
                await this.getSupportInfo(); // who is on call
            }, ()=>{
                // on fail
                vm.$router.push('Profile'); // redirect to the user's profile
            });
        },

        async totalDrivers() {
            const vm = this;
            vm.msg = "";
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            vm.loginToken = localStorage.getItem("user-info")

            API.get(`/driver`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        if (response.data.data.length == 0) {
                            vm.drivers = 0
                        } else if (response.data.data) {
                            vm.drivers = response.data.data.length
                        }
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        async totalCustomers() {
            const vm = this;
            vm.msg = "";
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            vm.loginToken = localStorage.getItem("user-info")

            API.get(`/customer`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                            vm.customers = response.data.data.length
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        async totalQueuedJob() {
            const vm = this;
            vm.msg = "";
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            vm.loginToken = localStorage.getItem("user-info")

            API.get(`/QueuedJob`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        if (response.data.data.length == 0) {
                            vm.queuedJob = 0
                        } else if (response.data.data) {
                            vm.queuedJob = response.data.data.length
                        }
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                })
        },
        async totalCompletedJob() {
            const vm = this;
            vm.msg = "";
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            vm.loginToken = localStorage.getItem("user-info")

            API.get(`/pastjobs`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        if (response.data.data.length == 0 || response.data.data.status != "COMPLETED" || response.data.data.status != "ABANDONED") {
                            vm.completedJob = 0;
                            vm.abandonedJob = 0;
                        } else if (response.data.data.status == "COMPLETED") {
                            vm.completedJob = response.data.data.length
                        } else if (response.data.data.status == "ABANDONED") {
                            vm.abandonedJob = response.data.data.length
                        }
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        async getSupportInfo() {
            const vm = this;
            vm.onCall = []

            await API.get('supportInfo')
                .then(function(response) {
                    if (response.status == 200) {
                        vm.onCall = [...response.data.data]

                        if(vm.onCall.length == 0) return;

                        for(let person of vm.onCall) {
                            person.contactPersonPhone = utils.formatPhoneNumber(person.contactPersonPhone);
                        }
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        async addNewOnCallPerson() {
            let vm = this

            let error = null
            const regFullName = /^[a-z ,.'-]+$/i
            const regPhone = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
            const regEmail = /^.+@[^\.].*\.[a-z]{2,}$/

            if(this.newOnCall.name.length == 0) {
                error = "Personel needs a first and last name"
            } else if(this.newOnCall.phone.length == 0) {
                error = "Personel needs a phone number"
            } else if(this.newOnCall.email.length == 0) {
                error = "Personel needs an email"
            }else if(regFullName.test(this.newOnCall.name) == false) {
                error = "First and Last name are not valid"
            } else if(regPhone.test(this.newOnCall.phone) == false) {
                error = "Phone number is not valid"
            } else if(regEmail.test(this.newOnCall.email) == false) {
                error = "Email is not valid"
            }

            if(error) {
                this.$toast.open({
                    message: error,
                    type: 'error',
                    position: 'top'
                })

                return
            }

            const data = {
                contactPersonName: this.newOnCall.name,
                contactPersonEmail: this.newOnCall.email,
                contactPersonPhone: this.newOnCall.phone
            }

            let loginToken = localStorage.getItem("user-info")

            await API.post('supportInfo', data,
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                }
            )
            .then(function(response) {
                if (response.status == 200) {
                    let person = response.data.data
                    person.contactPersonPhone = utils.formatPhoneNumber(person.contactPersonPhone);
                    vm.onCall.push(person)
                }
            })
            .catch(function(error) {
                vm.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            });

            this.newOnCall = {name: "", email: "", phone: ""}
        }
    }
}
</script>