<template>
	<Topnav />
	
	<Sidebar />
	
	<div class="content-wrapper">
	
		<div class="content-header">
	
			<div class="container-fluid">
					
				<form @submit.prevent="userUpdate">
	
					<div class="row mt-2 mb-4">
	
						<div class="col-md-10"></div>
	
						<div class="col-md-2">
	
							<div class="drivers-btn">
	
								<router-link to="/profile" class="btn btn-block" style="padding: 0;color: #000000;border-color: #7c7c7c03;">Back</router-link>
	
								<button type="submit" class="btn btn-block btn-info">Submit</button>
	
							</div>
	
						</div>
	
					</div>
	
					<div class="row">
	
						<h2>User Account Info</h2>

						<div class="col-md-12">
 							<div class="Customer-edit-form">
								<div class="driver-image" v-if="profile_image">
									<img v-if="profile_image"  :src="`${API.baseURL}${profile_image}`" alt="" style="border-radius: 8px; width:150px; ">
								</div>
								<div class="driver-image" v-else>
									<img  src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">
								</div>
							</div>
						</div>
	
						<div class="col-md-12 mt-4">
	
							<div class="form-row">
	
								<div class="form-item col-md-2">
	
									<input type="text" id="first_name" v-model="first_name" autocomplete="off" required>
	
									<label for="first_name" style="background: #f4f6f9;">First Name</label>
	
								</div>

								<div class="form-item col-md-2">
	
									<input type="text" id="last_name" v-model="last_name" autocomplete="off" required>
	
									<label for="last_name" style="background: #f4f6f9;">Last Name</label>
	
								</div>
	

								<div class="form-item col-md-1">
	
									<input type="text" id="name" v-model="countryCode" autocomplete="off" required placeholder="AU">
	
									<label for="name" style="background: #f4f6f9;">Code</label>
	
								</div>
	
								<div class="form-item col-md-3">
	
									<input type="text" id="name" v-model="phoneNo" autocomplete="off" required>
	
									<label for="name" style="background: #f4f6f9;">Phone Number</label>
	
								</div>
	
								<div class="form-item col-md-4">
	
									<input type="text" v-model="email" id="email" autocomplete="off">
	
									<label for="email" style="background: #f4f6f9;">Email</label>
	
								</div>
	
							</div>
	
						</div>
	
					</div>
	
				</form>
				
				<hr/>
				
        		<div class="row mt-2 mb-4">
					<div class="col-md-12">
						<h4>Reset Password</h4>
					</div>
					<div class="col-md-12">
						Lost or compromised passwords need to be reset.
						<br/>
						After you submit a password reset request, your account will be disabled until your password is changed.
					</div>
				</div>
        		<div class="row mt-2 mb-4">
					<div class="col-md-12">
						<button class="btn btn-danger" @click="resetPassword">I understand, and want to continue</button>
					</div>
				</div>
			</div>
	
		</div>
	
	</div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
export default {
	name: 'EditProfile',
	data() {
		return {
			first_name: '',
			last_name: '',
			phoneNo: '',
			countryCode: '',
			email: '',
			dshow: 'd-show',
			cshow: 'd-show',
			ashow: 'd-show',
			user_role: '',
			profile_image: null,
			roles: [],
			API: API,
		}
	},
	components: {
		Topnav,
		Sidebar

	},
	mounted() {
		const vm = this;
		let users = localStorage.getItem("user-info")
		let roles = [...JSON.parse(localStorage.getItem("roles"))];
		let user_id = null;

		if (!users) {
			this.$router.push({ name: 'Login' })
		}

		if (roles.includes('admin')) {
			vm.loginToken = localStorage.getItem("user-info")
			user_id = localStorage.getItem("driver_id")
			vm.user_role = "driver";

			this.ashow = 'd-show'
			this.dshow = 'd-none'
			this.cshow = 'd-none'
		} else if (roles.includes("driver")) {
			vm.user_role = "driver";
			user_id = localStorage.getItem("driver_id")
			vm.loginToken = localStorage.getItem("user-info")
			this.dshow = 'd-show'
			this.ashow = 'd-none'
			this.cshow = 'd-none'
		} else if(roles.includes("customer")) {
			vm.user_role = "customer";
			user_id = localStorage.getItem("customer_id")
			vm.loginToken = localStorage.getItem("user-info")
			this.cshow = 'd-show'
			this.ashow = 'd-none'
			this.dshow = 'd-none'
		}

		if(user_id == null) return;

		let header = {
			'Authorization': `Bearer ${vm.loginToken}`,

		}

		API.get(`/${vm.user_role}/${user_id}`, {
				headers: header
			}, )
			.then(function(response) {
				if (response.status == 200) {
					let data = response.data.data;
					let userId = data.userId;

					vm.first_name = userId.first_name;
					vm.last_name = userId.last_name;
					vm.countryCode = userId.countryCode;
					vm.phoneNo = userId.phoneNo;
					vm.email = userId.email;
					vm.profile_image = userId.picture;
				}
			})
			.catch(function(error) {
				if (error.response && error.response.data.status == "Expired") {
					vm.$toast.open({
						message: 'Session expired. Please login again.',
						type: 'error',
						position: 'top'
					});
					vm.$router.push({ name: 'Login' })
					localStorage.clear();

				} else {
					vm.$toast.open({
						message: error.response? error.response.data.error : error,
						type: 'error',
						position: 'top'
					});
				}
			});
	},

	methods: {
		async userUpdate() {
			const vm = this;

			let uid = localStorage.getItem("user_id");
			vm.loginToken = localStorage.getItem("user-info")
			let header = {
				'Authorization': `Bearer ${vm.loginToken}`,
			}
			await API.put(`/users/${uid}`, {
						first_name: vm.first_name,
						last_name: vm.last_name,
						countryCode: vm.countryCode,
						phoneNo: vm.phoneNo,
						email: vm.email
	
					}, {
						headers: header
					},

				)
				.then(function(response) {

					if (response.status == 200) {

						vm.$toast.success('Profile Updated successfully.', {
							// override the global option
							position: 'top'
						})

						location.reload();

					}
				})
				.catch(function(error) {
					if (error.response && error.response.data.status == "Expired") {
						vm.$toast.open({
							message: 'Session expired. Please login again.',
							type: 'error',
							position: 'top'
						});
						vm.$router.push({ name: 'Dashboard' })


					} else {
						vm.$toast.open({
							message: error.response? error.response.data.message : error,
							type: 'error',
							position: 'top'
						});

					}
				});

		},

		async resetPassword() {
			try {
            	let response = await API.post('/forget-password', {email: this.email})

                if (response.status == 200) {
					this.$toast.success(response.data.message, {
						// override the global option
						position: 'top'
					})
					this.logout()
                }
			}
            catch(error) {
				this.$toast.open({
					message: utils.messageFromError(error),
					type: 'error',
					position: 'top'
				});
			}
        },

		logout(){
            localStorage.clear();
            this.$router.push({name:'Login'})
        },

	}
}
</script>