<template>
	<Topnav />
  <Sidebar /> 
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <form @submit.prevent="addDepartments">
        <div class="row mt-2 mb-4">
          <div class="col-md-10"></div>
          <div class="col-md-2">
              <div class="drivers-btn">
                <button type="button" class="btn btn-block btn-outline-secondary" style="padding: 0;color: #000000;border-color: #7c7c7c03;">Back</button>
                <button  type="button" class="btn btn-block btn-info">Submit</button>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="Customer-edit-form">
              <img src="../assets/img/profile.png" alt="">
            </div>
          </div>
          <div class="col-md-12 mt-4">
              <div class="form-row">
                <div class="form-item col-md-4">
                  <input type="text" id="name" v-model="name" autocomplete="off" required>
                  <label for="name" style="background: #f4f6f9;">Name</label>
                </div>
                <div class="form-item col-md-4">
                  <input type="text" id="name" v-model="cell" autocomplete="off" required >
                  <label for="name" style="background: #f4f6f9;">Cell</label>
                </div>
                <div class="form-item col-md-4">
                  <input type="email" id="name" v-model="email" autocomplete="off" required >
                  <label for="email" style="background: #f4f6f9;">Email</label>
                </div>
                <div class="form-item col-md-8">
                  <input type="text" id="name" v-model="address" autocomplete="off" required >
                  <label for="name" style="background: #f4f6f9;">Address</label>
                </div>
                <div class="form-item col-md-4"></div>
                <div class="col-md-6 mt-4 department">
                  <h6>Department #1</h6>
                </div>
                <div class="col-md-6 mt-4 department">
                  <div class="customer-edit-form-img">
                    <img src="../assets/img/Add-file-icon.svg" alt="" style="width:5%;">
                    <img src="../assets/img/delete-icon.svg" alt="" style="width:3%;">
                  </div>
                </div>
                <div class="form-item col-md-4 mt-4">
                  <input type="text" id="name" v-model="dept_name" autocomplete="off" required>
                  <label for="name" style="background: #f4f6f9;">Name</label>
                </div>
                <div class="form-item col-md-4 mt-4">
                  <input type="text" id="name" v-model="dept_cell" autocomplete="off" required >
                  <label for="name" style="background: #f4f6f9;">Cell</label>
                </div>
                <div class="form-item col-md-4 mt-4">
                  <input type="email" id="name" v-model="dept_email" autocomplete="off" required >
                  <label for="email" style="background: #f4f6f9;">Email</label>
                </div>
                <div class="form-item col-md-8">
                  <input type="text" id="name" autocomplete="off" v-model="dept_address" required >
                  <label for="name" style="background: #f4f6f9;">Address</label>
                </div>
                <div class="form-item col-md-4"></div>
                <div class="col-md-12 mt-4 signature">
                  <p>Signature</p>
                  <img src="../assets/img/signature.svg" alt="">
                </div>
                <div class="col-md-12 mt-4 signature">
                  <p>Photos</p>
                  <img src="../assets/img/customer-form-image.svg" alt="">
                  <img src="../assets/img/customer-form-image.svg" alt="">
                </div>
              </div>
            
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
export default {
  name:'CustomerRegistration',
  data(){
    return {
      name:'',
      email:'',
      cell:'',
      address:'',      
      dept_name:'',
      dept_email:'',
      dept_address:'',
      dept_cell:'',
      departments:[],
      pickuppoints: [],
    }
  },
  components:{
    Topnav,
    Sidebar,
  },    
  mounted(){
    const vm = this;
    let users = localStorage.getItem("user-info")
    if(!users){
      vm.$router.push({name:'Login'})
    }
  }, 
  methods: {
    async addDepartments(){
      const vm = this;
      let  user = localStorage.getItem("user-info");        
      vm.loginToken = JSON.parse(user).token;
      // console.warn(vm.loginToken);
      let header =  {
        'Authorization': `Bearer ${vm.loginToken}`,
      }

      await API.post('/department', {
          departmentName:vm.dept_name,
          departmentEmail:vm.dept_email,
          departmentAddress:vm.dept_address,
          departmentPhoneNo:vm.dept_cell,
        },
        {
          headers: header
        },
      ).then(function (/*response*/) { 
        //console.log(response.status);
        //console.log(response.data.data);
      })
      .catch(function (/*error*/) {
        //console.warn(error);
      });
    },

    async registerCustomer(){
      const vm = this;
      let user = localStorage.getItem("user-info");        
      vm.loginToken = JSON.parse(user).token;
      //console.warn(vm.loginToken);
      let header =  { 'Authorization': `Bearer ${vm.loginToken}` };

      await API.post('/customer', {
          name: vm.name,        
          address: vm.address,
          email: vm.email,
          phoneNo: vm.cell,
          departments: vm.departments,
        },
        {
          headers: header
        },
      )
      .then(function (/*response*/) { 
        //console.log(response.status);
        //console.log(response.data.data);
      })
      .catch(function (/*error*/) {
        //console.warn(error);
      });
    },
  } 
}
</script>

<style>
  .error-msg{
    color: red;
  }
</style>