// const directionsService = window.google ? new window.google.maps.DirectionsService() : null;

const utils = {
    //
    // WIT specific util logic
    //
    isNameSTATLike(name) {
        name = name.toUpperCase()
        return name == "ON CALL" || name == "STAT"
    },

    //
    // Common
    //

    pagination: function(path, page, limit) {
        return `${path}?page=${page}&count=${limit}`
    },

    range(number, start) {
        const size = parseInt(number)
        if(size <= 0 || isNaN(size)) return []

        return [...Array(size)].map((_,i) => start? start + i : i)
    },

    // This function neatly formats phone numbers for displaying
    formatPhoneNumber: function(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }

        return null;
    },

    // strips hyphens from the phone number formatted by the dashboard
    cleanPhoneNumberForAPI: function(input) {
        if(!input) return input
        return [...input].filter(i => !isNaN(i - parseFloat(i))).join('')
    },

    // This function captures phone number (US only!) inputs for submitting
    cleanPhoneNumberInput: function(input) {
        if(!input) return input

        let phone = [...input].filter(i => !isNaN(i - parseFloat(i)))
        const len = phone.length
        const isInternational= len >= 11 // leading +1

        let hyphens = [4, 7]

        if(isInternational) {
            if(len >= 2) {
                phone.splice(1, 0, '-')
            }

            hyphens = [6, 9]
        }

        if(len >= hyphens[0]) {
            phone.splice(hyphens[0]-1, 0, '-')
        }

        if(len >= hyphens[1]) {
            phone.splice(hyphens[1], 0, '-')
        }

        return phone.slice(0, isInternational? 14 : 12).join('')
    },

    // This function tries to extract the error string from the many ways errors are generated by the server
    messageFromError: function(error) {
        error = error.response?.data ?? error.request?.data ??  error.message ??  {}
        let message = error.message ?? error

        // debugging dumb axios:
        /*for(let k of Object.keys(message)) {
            console.log(`k=${k}`)
            console.table(message[k])
        }*/

        if(typeof message != "string") {
            if(error.status == 500) {
                message = "Internal Server Error: 500";

                if(error.statusText && error.statusText.length) {
                    message = error.statusText;
                }
            } else {
                message = "Unknown server error occured";
            }
        }

        return message;
    },

    createEnum: function(...values) {
        const enumObject = {};
        for (const val of values) {
            enumObject[val] = val;
        }
        enumObject.isKey = (key) => enumObject.hasOwnProperty(key);

        return Object.freeze(enumObject);
    },

    formatTimestamp: function (datetime) {
        const pad = (n,s=2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
        const d = new Date(datetime);

        return `${pad(d.getFullYear(),4)}-${pad(d.getMonth()+1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
    },

    datetimeUTCtoLocal: function(datetime) {
        if(datetime == null) return null;

        const d = new Date(datetime);
        return (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1);
    },

    datetimeLocaltoUTC: function(datetime) {
        if(datetime == null) return null;

        const fakeUtcTime = new Date(`${datetime}Z`);
        return new Date(fakeUtcTime.getTime() + fakeUtcTime.getTimezoneOffset() * 60000);
    },

    lock: function(obj) {
        let canLock = typeof obj.__lockguard__ === 'undefined';
        if(canLock) {
            obj.__lockguard__ = true;
        }
        return canLock;
    },

    unlock: function(obj) {
        delete obj.__lockguard__;
    },

    copyToClipboard: async function(text, opts) {
        let removeQueries = false

        if(typeof opts !== 'undefined') {
            removeQueries = opts['removeQueries'] ?? removeQueries
        }

        if(removeQueries) {
            text = text.split('&')[0] // everything up to the first uri encoded query
        }

        try {
            await navigator.clipboard.writeText(text);
        } catch(error) {
            return false;
        }

        return true;
    },

    makeTableFromResults: function(results) {
        if(results.length > 0) {
            // TODO: this is a temp fix to the database problem.
            //        I'd like to return exact model data but this will suffice.
            const reducer = (a, c) => {
                const len = Object.keys(c).length;
                a[len] = a[len] || [];
                a[len].push(c);
                return a;
            };
            let longest_object = results.reduce(reducer,[]).pop()[0];
            let _header = Object.getOwnPropertyNames(longest_object);

            // move MongoID's to the first column in the table
            let index = _header.indexOf("_id");
            if(index > -1) {
                _header.unshift(_header.splice(index, 1)[0]);
            }

            return {
                data: results,
                header: _header
            }
        } else {
            return {
                data: [],
                header: []
            }
        }
    },

    // Vue objects have getters and setters (functions)
    // in component properties and data refs.
    // This solution steps through the keys (getters)
    // and safely uses `structuredClone(obj[key])` so that
    // it maps to a vanilla javascript object and returns it
    safeStructuredClone(obj) {
        let clone = Array.isArray(obj)? [] : {};
        for(const key of Object.keys(obj)) {
            try {
                clone[key] = structuredClone(obj[key]);
            }catch(e) {
                try {
                    // console.log(`Using safe clone ${key} because of reason: ${e}`);
                    clone[key] = utils.safeStructuredClone(obj[key]);
                }catch(_e2) {
                    // console.error(`Failed to safely clone ${key}: ${_e2}`)
                }
            }
        }

        return clone;
    },

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    //
    // Google help utils
    //

    googleRoads(window, start, end, callback) {
        const directionsService = window.google ? new window.google.maps.DirectionsService() : null

        if(!directionsService) {
            console.warn("Google Direction Service Plugin Unavailable!")
            return
        }

        var request = {
            origin: start,
            destination: end,
            travelMode: window.google.maps.DirectionsTravelMode.DRIVING
        }
        directionsService.route(request, function(response, status) {
          if (status == window.google.maps.DirectionsStatus.OK) {
            if(response.routes.length == 0) return;
            const routePath = response.routes[0].overview_path
            callback(routePath)
          }

          //console.log(`google directions service status: ${status}`)
        });
    }
}

export default utils;
