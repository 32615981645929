<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <PickupPointsForm v-show="showPickupPoints"
            ref="pointsManager"
            :points="pickupPoints" 
            :customer="name"
            :progressText="pickupPointsProcessText"
            :progress="pickupPointsProgress"
            :maxProgress="pickupPointsMaxProgress"
            @back="showPickupPoints=false" 
            @save="savePickupPoints"
            @new="newPickupPoint"
            @edit="updatePickupPoint"/>

            <!-- Pickup Point Modal -->
    
            <div class="modal" :style="pickupModal.style">

                <div class="modal-dialog">

                    <div class="modal-content">

                        <!-- Modal Header -->

                        <div class="modal-header">

                            <h4 class="modal-title" style="font-size: 20px;">{{pickupModal.editMode? 'Edit' : 'Create'}}&nbsp;Pickup Point</h4>

                            <button type="button" class="close mt-3" @click="modalClose">&times;</button>

                        </div>

                        <!-- Modal body -->

                        <div class="modal-body pl-3" style="text-align: left;">
                            Facility: <br/>
                            <input v-model="pickupModal.point.facility" placeholder="e.g. San Marco's" style="margin-bottom: 1%; width: 95%;"><br/>
                            
                            Address: <small class="error-msg" v-show="isPickupModalAddressEmpty">Required</small> <br/>
                            <input required v-model="pickupModal.point.address" 
                            placeholder="Street Addr, City, State Zip, USA" 
                            style="margin-bottom: 1%; width: 95%;"
                            v-on:keyup="autocompletePickupPointAddress($event)">

                            <br/>

                            <ul v-show="pickupModal.autocomplete" 
                            class="autocomplete-results"
                            style="position: initial !important;"> <!-- overwrite position style -->

                                <li v-for="(result, i) in pickupModal.autocompleteResults" :key="i" 
                                @click="setPickupModalAddress(`${result.description}`)" 
                                class="autocomplete-result">
                                    {{result.description}}
                                </li>

                            </ul>
                            
                            Phone: <br/>
                            <input v-model="pickupModal.point.phone" placeholder="123-456-7890" style="margin-bottom: 1%; width: 95%;"><br/>
                            
                            <hr>

                            Nicknames: <small class="error-msg" v-show="arePickupModalNicknamesEmpty">Cannot Be Blank</small> <br/>
                            <section class="scrolling-modal">
                                <div v-for="(nick, k) in pickupModal.point.nicknames" :key="nick">
                                    <input required :value="nick" 
                                    @change="(evt) => { pickupModal.point.nicknames[k] = evt.target.value }"
                                    style="margin-bottom: 1%; margin-right: 15%; width: 70%;">
                                    <button
                                    @click="pickupModal.point.nicknames = pickupModal.point.nicknames.filter(n => n != nick)">DELETE</button>
                                </div>
                            </section>
                            <button
                            type="button" 
                            class="btn btn-info" 
                            title="Add a nickname field"
                            :disabled="arePickupModalNicknamesEmpty" 
                            @click="pickupModal.point.nicknames.unshift('')">+</button>
                        </div>

                        <!-- Modal footer -->

                        <div class="modal-footer">

                            <button v-if="pickupModal.editMode" 
                            type="button" 
                            class="btn btn-warning"
                            :disabled="arePickupModalNicknamesEmpty || isPickupModalAddressEmpty"
                            @click="modalUpdatePoint(pickupModal.point)">Update</button>

                            <button v-else
                            type="button" 
                            class="btn btn-warning"
                            :disabled="arePickupModalNicknamesEmpty || isPickupModalAddressEmpty" 
                            @click="modalAddPoint(pickupModal.point)">Create</button>
                        </div>

                    </div>

                </div>

            </div>

            <div class="modal-backdrop show" :style="pickupModal.style"></div>

            <!-- End Pickup Point Modal -->

            <div class="container-fluid" v-show="!showPickupPoints">
    
                <form @submit.prevent="busy=true">
    
                    <div class="row mt-2 mb-4">
    
                        <div class="col-md-10"></div>
    
                        <div class="col-md-2">
    
                            <div class="drivers-btn">
    
                                <router-link to="/customers-list" class="btn btn-block" style="padding: 0;color: #000000;border-color: #7c7c7c03;" v-if="roles.includes('admin')">Back</router-link>
    
                                <SpinButton class="btn btn-block btn-info" @click="validateDepartments" :spin="busy">Submit</SpinButton>
                            </div>
    
                        </div>
    
                    </div>
    
                    <div class="row">
    
                        <div class="col-md-12">
                            <h2>Customer Account</h2>
                            <div class="Customer-edit-form">
    
                                <div v-if="preview">
    
                                    <img :src="preview" class="img-fluid company-logo"/>
    
                                </div>
    
                                <p v-else-if="upload_id">
    
                                    <img name="upload_id" :src="`${API.baseURL}${upload_id}`" alt="" class="img-fluid company-logo">
    
                                </p>
    
                                <p v-else>
    
                                    <img src="../assets/img/image.svg" alt="">
    
                                </p>
    
                                <div class="image-upload">
    
                                    <label for="file-input">
    
                                        <img src="../assets/img/edit-icon.svg" class="logo-upload-btn"/>
                        
                                    </label>
    
                                    <input id="file-input" type="file" :name="uploads" class="input-file" @change="photoUpload" />
    
                                    <input type="hidden" v-model="upload_id">
    
                                </div>
    
                            </div>
    
                        </div>
    
                        <div class="col-md-12 mt-4">
    
                            <div class="form-row">

                                <div class="col-md-2 mb-4">    

                                    <button 
                                    @click="showPickupPoints=true"
                                    title="Manage common pickup points"
                                    type="button" 
                                    class="btn btn-block btn-info">
                                    
                                        <img src="../assets/img/address_book_icon.svg" alt="">
                                        
                                        &nbsp;Address Book ({{pickupPoints.length}})
                                    
                                    </button>

                                </div>

                                <div class="col-md-2 mb-4">    

                                    <button 
                                    @click="showSubscriberForm()"
                                    title="Manage subscribers"
                                    type="button" 
                                    class="btn btn-block btn-info">
                                    
                                        <img src="../assets/img/people.png" alt="">
                                        
                                        &nbsp;Subscribers ({{subscribers.length}})
                                    
                                    </button>

                                </div>

                                <div class="col-md-12 mb-4">Company Info</div>
    
                                <div class="form-item col-md-4">
    
                                    <input type="text" id="name" v-model="name" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">Company Name</label>
    
                                </div>
    
                                <div class="form-item col-md-8 autocomplete">
    
                                    <input type="text" id="name" v-model="address" autocomplete="off" required @input="customerAddress">
    
                                    <input type="hidden" id="name" v-model="addressLocation.Longitude" autocomplete="off">
    
                                    <input type="hidden" id="name" v-model="addressLocation.Latitude" autocomplete="off">

                                    <label class="selectOptions" for="name" style="background: #f4f6f9;">Company Address</label>
    
                                    <ul v-show="isOpen" class="autocomplete-results">
    
                                        <li v-for="(result, i) in results" :key="i" @click="setResult(`${result.description}`, `${result.geolocation.lat}`,`${result.geolocation.lng}`)" class="autocomplete-result">{{result.description}}</li>
    
                                    </ul>
    
                                </div>
    
                                <div class="col-md-12 mb-4">Contact Info</div>
    
    
                                <div class="form-item col-md-4">
    
                                    <input type="text" id="name" v-model="first_name" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">Customer First Name</label>
    
                                </div>
    
                                <div class="form-item col-md-4">
    
                                    <input type="text" id="name" v-model="last_name" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">Customer Last Name</label>
    
                                </div>
    
                                <div class="form-item col-md-2">
    
                                    <input type="text" id="name" v-model="countryCode" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">Country Code</label>
    
                                </div>
    
                                <div class="form-item col-md-2">
    
                                    <input required
                                        type="tel" 
                                        id="phone" 
                                        placeholder="123-456-7890" 
                                        pattern="([0-9]-)?[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        autocomplete="off"
                                        maxlength="15" 
                                        minlength="10"
                                        v-model="phoneNumber"
                                        >
    
                                    <label for="phone" style="background: #f4f6f9;">Customer Cell</label>
    
                                </div>
    
                                <div class="form-item col-md-4 mt-2">
    
                                    <input type="checkbox" class="requiresPhotoSignature_customer" v-model="requiresPhotoSignature_customer" style="width: 5%;margin-top: 5px;margin-left: 10px;">
    
                                    <label for="requiresPhotoSignature_customer" style="background: #f4f6f9;">We Require Photo And Signature Proof Of Delivery</label>
    
                                </div>
    
                                <div class="form-item col-md-12"></div>
    
                                <div class="form-item col-md-12" v-if="dept.length == 0">
    
                                    <div class="col-md-12 mt-4" style="text-align:center">
    
                                        <h6>No Departments</h6>
    
                                            <img src="../assets/img/Add-file-icon.svg" title="Add a department" style="width:5%; cursor:pointer;" @click="add(0)">
                                    
                                    </div>

                                </div>

                                <div class="form-item col-md-12"></div>

                                <div class="row" v-for="(depts,k) in dept" v-bind:key="depts._id">
    
                                    <div class="col-md-4 mt-4 department">
    
                                        <h6 style="text-align: left;">
                                            Department #{{k+1}}<br/>
                                            <small style="text-align: left;" v-if="depts._id">
                                                <span>Direct Login:&nbsp;</span>
                                                <i v-if="!depts.departmentMagicLink && depts.isGeneratingMagicLink">
                                                    Please wait...
                                                </i>
                                                <a v-else-if="depts.departmentMagicLink" :href="getMagicLink(depts)" @click.prevent="copyMagicLink(depts)">Copy Magic Link</a>
                                                <span v-else>
                                                    None. Click <a href="" @click.prevent="generateMagicLink($route.query.id, k)">here</a> to generate.
                                                </span>
                                                
                                            </small>
                                            <small v-else>
                                                <i>Unsaved</i>
                                            </small>
                                            <br />
                                            <small v-if="depts.subscribers">
                                                <span>Subscribers: &nbsp;{{ depts.subscribers.length }}. Click <a class="btn-link" role="button" @click="showSubscriberForm(`${depts._id}`)">here</a> to add, edit, or remove.</span>
                                            </small>
                                        </h6>

                                        <p class="error-msg" style="text-align: left;" v-show="depts.errorMessage">{{depts.errorMessage}}</p>
                                    </div>
    
                                    <div class="col-md-6 mt-4 department">
    
                                        <div class="customer-edit-form-img">
    
                                            <img v-if="k == dept.length-1" title="Add a new department" src="../assets/img/Add-file-icon.svg" alt="" style="width:5%; cursor:pointer;" @click="add(k+1)">
    
                                            <img title="Permanently delete this department" src="../assets/img/delete-icon.svg" alt="" style="width:3%; cursor:pointer;" data-toggle="modal" :data-target="`#deleteDept${k}`">
    
                                        </div>
    
                                    </div>
    
                                    <div class="form-item col-md-4 mt-4">
    
                                        <input type="text" :id="'name'+k" v-model="depts.departmentName" autocomplete="off" required>
    
                                        <label :for="'name'+k" style="background: #f4f6f9;">Department Name</label>
    
                                    </div>
    
                                    <div class="form-item col-md-4 mt-4">
    
                                        <input
                                            type="tel" 
                                            :id="'phone'+k" 
                                            placeholder="123-456-7890" 
                                            pattern="([0-9]-)?[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                                            autocomplete="off" 
                                            maxlength="15" 
                                            minlength="10"
                                            v-model="depts.departmentPhoneNo"
                                            >

                                        <label :for="'phone'+k" style="background: #f4f6f9;">Department Cell (optional)</label>
    
                                    </div>
    
                                    <div class="form-item col-md-4 mt-4">
    
                                        <input type="email" :id="'email'+k" v-model="depts.departmentEmail" autocomplete="off" required>
    
                                        <label :for="'email'+k" style="background: #f4f6f9;">Department Email</label>
    
                                    </div>
    
                                    <div class="form-item col-md-4 autocomplete">
    
                                        <input type="text" :id="'address'+k" autocomplete="off" v-model="depts.departmentAddress" v-on:keyup="customerAddressDept(k+1, $event)" required>
    
                                        <label :for="'address'+k" style="background: #f4f6f9;">Department Address</label>
    
                                        <ul v-show="isOpen[k+1]" class="autocomplete-results">
    
                                            <li v-for="(result, i) in customerDepartmentAddress[k]" :key="i" @click="setResultDept(`${result.description}`,k+1, `${result.geolocation.lat}`,`${result.geolocation.lng}`)" class="autocomplete-result">{{result.description}}</li>
       
                                        </ul>
    
                                    </div>
    
                                    <div class="form-item col-md-4">
    
                                        <input type="text" :id="'poc'+k" v-model="depts.departmentContactPersonName" autocomplete="off">
    
                                        <label :for="'poc'+k" style="background: #f4f6f9;">Department Contact (optional)</label>
    
                                    </div>
    
                                    <div class="form-item col-md-4">
    
                                        <input type="checkbox" class="requiresPhotoSignature" v-model="depts.requiresPhotoSignature" style="width: 5%;margin-top: 5px;margin-left: 10px;" v-on:click="requiresPhotoSignature" value="false">
    
                                        <label :for="'requiresPhotoSignature'+k" style="background: #f4f6f9;">This Department Requires Photo And Signature Proof Of Delivery</label>
    
                                    </div>

                                    <div class="form-item col-md-4"></div>
    
                                    <!-- Delete Modal -->
    
                                    <div class="modal" :id="`deleteDept${k}`">
    
                                        <div class="modal-dialog">
    
                                            <div class="modal-content">
    
                                                <!-- Modal Header -->
    
                                                <div class="modal-header">
    
                                                    <h4 class="modal-title" style="font-size: 20px;">Delete Department</h4>
    
                                                    <button type="button" class="close mt-3" data-dismiss="modal">&times;</button>
    
                                                </div>
    
                                                <!-- Modal body -->
    
                                                <div class="modal-body pl-3">
    
                                                    Are you sure you want to delete?
    
                                                </div>
    
                                                <!-- Modal footer -->
    
                                                <div class="modal-footer">
    
                                                    <button type="button" class="btn btn-success mr-3" @click="remove(k+1)" data-dismiss="modal">Yes</button>
    
                                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
    
                                                </div>
    
                                            </div>
    
                                        </div>
    
                                    </div>
    
                                    <!-- End Delete Modal -->
    
                                </div>
    
                            </div>
    
                        </div>
    
                    </div>
    
                </form>
    
            </div>
    
        </div>
        
    </div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import PickupPointsForm from '@/components/PickupPointsForm.vue'
import SpinButton from '@/components/SpinButton.vue'
import API from '../axios.config';
import eznavigator from '../eznavigator';
import utils from '../utils'
import config from '../../config'

export default {
    name: 'CustomerRegistration',
    data() {
        return {
            name: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            countryCode: 'US',
            phoneNumber: '',
            address: '',
            requiresPhotoSignature_customer: false,
            addressLocation: {
                Latitude: '',
                Longitude: '',
            },
            show: false,
            dept: [],
            uploads: [],
            deptArrayIds: [],
            deptWatchers: [],
            pickupPoints: [],
            showPickupPoints: false,
            pickupPointsProgress: 0,
            pickupPointsMaxProgress: 0,
            pickupPointsProcessText: "",
            pickupModal: {
                point: {},
                style: { display: 'none' },
                editMode: false,
                autocomplete: false,
                autocompleteResults: []
            },
            upload_id: null,
            preview: null,
            roles: '',
            results: [],
            isOpen: [],
            customerDepartmentAddress: [],
            problemWithAddressLookupFlag: false,
            busy: false,
            API: API,
            subscribers: [],
        }
    },
    components: {
        Topnav,
        Sidebar,
        PickupPointsForm,
        SpinButton
    },
    computed: {
        isPickupModalAddressEmpty() {
            if(!this.pickupModal.point.address) return true;

            return this.pickupModal.point.address.trim().length == 0;
        },
        arePickupModalNicknamesEmpty() {
            // if we don't have an array, they cannot be empty because they do not exist
            if(!this.pickupModal.point.nicknames) return false;

            const nicks = this.pickupModal.point.nicknames;

            for(let i = 0; i < nicks.length; i++) {
                if(nicks[i].trim().length == 0) return true;
            }

            return false;
        }
    },
    watch: {
        phoneNumber(val) {
            this.phoneNumber = utils.cleanPhoneNumberInput(val)
        },
    },
    mounted() {
        const vm = this;
        vm.isOpen[0] = false;
        vm.roles = [...JSON.parse(localStorage.getItem("roles"))];

        let users = localStorage.getItem("user-info")
        vm.loginToken = localStorage.getItem("user-info");
        if (!users) {
            vm.$router.push({ name: 'Login' })
        }
        let id = this.$route.query.id;

        let header = {
            'Authorization': `Bearer ${vm.loginToken}`,
        }
        API.get(`/customer/${id}`, {
                headers: header
            }, )
            .then(function(response) {
                if (response.status == 200) {
                    vm.name = response.data.data.name;
                    vm.first_name = response.data.data.userId.first_name;
                    vm.last_name = response.data.data.userId.last_name;
                    vm.countryCode = response.data.data.userId.countryCode;
                    vm.phoneNumber = response.data.data.userId.phoneNo;

                    vm.dept = [...response.data.data.departments];
                    vm.address = response.data.data.address;
                    vm.addressLocation.Longitude = response.data.data.addressLocation.Longitude;
                    vm.addressLocation.Latitude = response.data.data.addressLocation.Latitude;
                    vm.requiresPhotoSignature_customer = response.data.data.requiresPhotoSignature;

                    vm.watchDepartmentValues()

                    for (let i = 0; i < vm.dept.length; i++) {                
                        vm.customerDepartmentAddress[i] = [];
                    }

                    vm.upload_id = response.data.data.userId.picture;
                    vm.lastUploadedPicture = null;
                    if (response.data.data.uploads) {
                        if (response.data.data.uploads.photoUrls.length) {
                            vm.lastUploadedPicture = response.data.data.uploads.photoUrls.slice(-1)[0];
                        }
                    }                    

                    vm.pickupPoints = response.data.data.pickupPoints;
                    vm.subscribers = response.data.data.subscribers;
                }
            })
            .catch(function(error) {
                vm.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            });
    },
    methods: {
        async generateMagicLink(customerId, deptIdx) {
            let department = this.dept[deptIdx];

            try {
                department.isGeneratingMagicLink = true;
                this.loginToken = localStorage.getItem("user-info");

                let header = {
                    'Authorization': `Bearer ${this.loginToken}`,
                }

                let response = await API.post('/MagicLink', {
                        customer: customerId,
                        department: department._id,
                        magicLinkType: "department",
                    }, 
                    {
                        headers: header
                    });

                department.departmentMagicLink = response.data.data;

                response = await API.put('/department/'+department._id, { departmentMagicLink: department.departmentMagicLink._id }, {headers: header});
                
                this.dept[deptIdx] = response.data.data.department; // TODO: why does this return a department obj and not just data?
                this.dept[deptIdx].departmentMagicLink = department.departmentMagicLink

                this.watchSingleDepartmentValue(deptIdx)
            }catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            }

            department.isGeneratingMagicLink = false;

        },

        getMagicLink(dept) {
            if(dept.departmentMagicLink.magicToken) {
                const token_part = `token=${dept.departmentMagicLink.magicToken}`;
                return `${config.baseURL}/#/magic-link?${token_part}`;
            }

            return '';
        },

        async copyMagicLink(dept) {
            const magic_url = this.getMagicLink(dept);

            if(await utils.copyToClipboard(magic_url)) {
                this.$toast.open({
                    message: 'Login link copied to clipboard!',
                    type: 'success',
                    position: 'bottom-left'
                });
            } else {
                this.$toast.open({
                    message: 'Browser could not copy to clipboard.',
                    type: 'info',
                    position: 'bottom-left'
                });
            }
        },

        setResult(event, lat, lng) {
            // console.log(event);
            this.address = event;
            this.isOpen[0] = false; // index 0 closes customer profile address
            this.addressLocation.Longitude = lng;
            this.addressLocation.Latitude = lat;
        },
    
        async customerAddress() {
            const vm = this;

            vm.isOpen[0] = false;

            let address = vm.address;

            if(address.length == 0) return;

            let latLon = await eznavigator.getLatLong();

            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }
            // console.log(header);
            await API.post('/customer-address-autocomplete', {
                    address: address,
                    location: latLon
                }, {
                    headers: header
                }, )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.results = [...response.data.data.google_results];
                        vm.isOpen[0] = true;
                    }
                })
                .catch(function(_) {
                    if(!vm.problemWithAddressLookupFlag) {
                        vm.$toast.open({
                            message: 'There was a problem fetching nearby addresses. Please type your address manually.',
                            type: 'error',
                            position: 'top'
                        });

                        vm.problemWithAddressLookupFlag = true;
                    }

                    vm.isOpen[0] = false;
                });

        },

        setPickupModalAddress(event) {
            this.pickupModal.point.address = event;
            this.pickupModal.autocomplete = false;
        },

        setResultDept(event, k, lat, lng) {
            // console.log(event);
            this.dept[k-1].departmentAddress = event;
            this.isOpen[k] = false;
            this.dept[k-1].departmentLocation.Longitude = lng;
            this.dept[k-1].departmentLocation.Latitude = lat;
        },

        async customerAddressDept(k, $event) {
            const vm = this;

            vm.isOpen[k] = false;
            
            if($event.target.value.length == 0) return;

            vm.loginToken = localStorage.getItem("user-info");

            let latLon = await eznavigator.getLatLong();

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }
            await API.post('/customer-address-autocomplete', {
                    address: $event.target.value,
                    location: latLon
                }, {
                    headers: header
                }, )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.customerDepartmentAddress[k-1] = response.data.data.google_results;
                        vm.isOpen[k] = true;
                    }
                })
                .catch(function(_) {
                    if(!vm.problemWithAddressLookupFlag) {
                        vm.$toast.open({
                            message: 'There was a problem fetching nearby addresses. Please type your address manually.',
                            type: 'error',
                            position: 'top'
                        });

                        vm.problemWithAddressLookupFlag = true;
                    }

                    vm.isOpen[k] = false;
                });
        },

        async autocompletePickupPointAddress($event) {
            this.pickupModal.autocomplete = false;
            
            if($event.target.value.length == 0) return;

            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");

            let latLon = await eznavigator.getLatLong();

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }
            
            await API.post('/customer-address-autocomplete', {
                    address: $event.target.value,
                    location: latLon
                }, {
                    headers: header
                }, )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.pickupModal.autocompleteResults = response.data.data.google_results;
                        vm.pickupModal.autocomplete = true;
                    }
                })
                .catch(function(_) {
                    if(!vm.problemWithAddressLookupFlag) {
                        vm.$toast.open({
                            message: 'There was a problem fetching nearby addresses. Please type the address manually.',
                            type: 'error',
                            position: 'top'
                        });

                        vm.problemWithAddressLookupFlag = true;
                    }

                    vm.pickupModal.autocomplete = false;
                });
        },

        async validateDepartments() {
            let problem = false;
            for (let i = 0; i < this.dept.length; i++) {
                let d = this.dept[i];
                d.errorMessage = null;
                if(d.departmentName.length == 0) {
                    problem = true;
                    d.errorMessage = "Department must have a name.";
                }else if(d.departmentEmail.length == 0) {
                    problem = true;
                    d.errorMessage = "Department must have an email.";
                }else if(d.departmentAddress.length == 0) {
                    problem = true;
                    d.errorMessage = "Department must have an address.";
                }
            }

            if(!problem) {
                await this.updateDepartment();
            } else {
                this.$toast.open({
                    message: "Missing required department fields",
                    type: 'error',
                    position: 'top'
                });
            }
        },

        async updateDepartment() {
            const vm = this;
            this.loginToken = localStorage.getItem("user-info");
            let header = { 'Authorization': `Bearer ${this.loginToken}` }

            for (let i = 0; i < this.dept.length; i++) {
                let id = this.dept[i]._id;
                if (this.dept[i].departmentName != '' && this.dept[i].departmentEmail != '' && this.dept[i].departmentAddress) {

                    const postData = {
                        departmentName: this.dept[i].departmentName,
                        requiresPhotoSignature: this.dept[i].requiresPhotoSignature,
                        departmentEmail: this.dept[i].departmentEmail,
                        departmentAddress: this.dept[i].departmentAddress,
                        departmentPhoneNo: utils.cleanPhoneNumberForAPI(this.dept[i].departmentPhoneNo),
                        departmentContactPersonName: this.dept[i].departmentContactPersonName,
                        departmentLocation: {
                            Latitude: this.dept[i].departmentLocation.Latitude,
                            Longitude: this.dept[i].departmentLocation.Longitude,
                        },
                    }
                    if (!id) {
                        await API.post('/department', [postData], {
                                headers: header
                            }, )
                            .then(function(response) {
                                if (response.status == 200) {
                                    vm.deptArrayIds.push(response.data.data[0]._id);              
                                }
                            })
                            .catch(function(error) {
                                vm.$toast.open({
                                    message: utils.messageFromError(error),
                                    type: 'error',
                                    position: 'top'
                                });
                            });
                    } else {
                        this.deptArrayIds.push(id);
                        await API.put(`/department/${id}`, postData, {
                                headers: header
                            })
                            .then(function() {
                                /* do nothing */
                            })
                            .catch(function(error) {
                                vm.$toast.open({
                                    message: utils.messageFromError(error),
                                    type: 'error',
                                    position: 'top'
                                });
                            });
                    }
                }
            }
            this.customerUpdate()
        },

        add() {
            this.dept.push({
                departmentName: '',
                departmentEmail: '',
                departmentAddress: '',
                departmentPhoneNo: '',
                requiresPhotoSignature: false,
                departmentLocation: {
                    Latitude: '',
                    Longitude: '',
                },
                departmentContactPersonName: '',
            })

            this.watchSingleDepartmentValue(this.dept.length-1)

            this.isOpen.push(false);
        },

        remove(index) {
            this.deptWatchers[index-1].unwatch()
            this.deptWatchers.splice(index-1, 1)
            this.dept.splice(index-1, 1)
            this.isOpen.splice(index, 1)
        },

        async customerUpdate() {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }
            let id = this.$route.query.id;

            let formData = new FormData();
            if (vm.photos) {
                formData.append("photos", vm.photos);
            }

            // removes duplicates caused by naively added ID's into this array
            vm.deptArrayIds = [...new Set(vm.deptArrayIds)]

            for (let i = 0; i < vm.deptArrayIds.length; i++) {
                formData.append("departments[" + i + "]", vm.deptArrayIds[i]);
            }

            // formData.append("signatures", vm.photos);
            // formData.append("attachments", vm.photos);
            formData.append("name", vm.name);
            formData.append("address", vm.address);
            formData.append("addressLocation.Latitude", vm.addressLocation.Latitude);
            formData.append("addressLocation.Longitude", vm.addressLocation.Longitude);
            formData.append("requiresPhotoSignature", vm.requiresPhotoSignature_customer);
            
            await API.put(`/customer/${id}`,
                    formData, {
                        headers: header
                    },
                )
                .then(function(response) {
                    if (response.status == 200) {
                        let customer = response.data.data.customer;

                        // Set the new department values and watch for property changes
                        vm.dept = customer.departments;
                        vm.watchDepartmentValues()

                        vm.address = customer.address;
                        vm.addressLocation.Longitude = customer.addressLocation.Longitude;
                        vm.addressLocation.Latitude = customer.addressLocation.Latitude;
                        vm.name = customer.name;
                        if (customer.uploads) {
                            if (customer.uploads.photoUrls.length) {
                                const lastPhotoUrl = customer.uploads.photoUrls.slice(-1)[0];
                                if (vm.lastUploadedPicture == null || vm.lastUploadedPicture !== lastPhotoUrl) {
                                    vm.upload_id = lastPhotoUrl;
                                    vm.lastUploadedPicture = lastPhotoUrl;
                                }
                            }
                        }
                        let uid = customer.userId._id
                        vm.userUpdate(uid);
                        vm.photos = null;
                        vm.preview = null;
                    }
                })
                .catch(function(error) {
                    vm.busy = false;
                
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        async userUpdate(uid) {
            const vm = this;    
            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }

            await API.put(`/users/${uid}`, {
                        first_name: vm.first_name,
                        last_name: vm.last_name,
                        countryCode: vm.countryCode,
                        phoneNo: utils.cleanPhoneNumberForAPI(vm.phoneNumber),
                        picture: vm.upload_id,
                    }, {
                        headers: header
                    },
                )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.$toast.success('Profile Updated successfully.', {
                            // override the global option
                            position: 'top'
                        })

                        //location.reload();
                        vm.busy = false;
                 
                        vm.countryCode = response.data.data.countryCode;
                        vm.phoneNumber = response.data.data.phoneNo;
                        vm.userId = response.data.data.picture;
                    }
                })
                .catch(function(error) {
                    vm.busy = false;

                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });

        },

        updatePickupPoint(point) {
            this.pickupModal.style = {
                display: "initial",
            }

            const copy = {
                address: point.address,
                phone: point.phone,
                facility: point.facility,
                nicknames: [...point.nicknames],
                _id: point._id
            };

            this.pickupModal.ogPoint = point;
            this.pickupModal.point = copy;
            this.pickupModal.editMode = true;
        },

        newPickupPoint() {
            this.pickupModal.style = {
                display: "initial",
            }

            this.pickupModal.point = { 
                facility: '',
                address: '',
                phone: '',
                nicknames: [] 
            };

            this.pickupModal.ogPoint = null;
            this.pickupModal.editMode = false;
        },

        modalClose() {
            this.pickupModal.style = {
                display: "none",
            }

            this.problemWithAddressLookupFlag = true;
            this.pickupModal.autocomplete = false;
            this.pickupModal.autocompleteResults = [];
        },

        modalUpdatePoint(data) {
            this.modalClose();
            this.$refs.pointsManager.edit(data);
        },

        modalAddPoint(data) {
            this.modalClose();
            this.$refs.pointsManager.add(data);
        },

        async savePickupPoints(result) {
            const droppedPoints = result.dropped;
            const updatedPoints = result.updated;
            const newPoints = result.created;
            const untouchedPoints = result.untouched;

            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }
            
            let id = this.$route.query.id;

            // Prepare the progress bar and data
            this.pickupPointsProgress = 0;
            this.pickupPointsMaxProgress = droppedPoints.length + updatedPoints.length + newPoints.length;
            this.pickupPointsProcessText = "Preparing...";

            let newList = [];
            let failed2Drop = [];
            let stopped = null;

            droppedPoints.forEach(p => {
                failed2Drop.push(p);
            })

            untouchedPoints.forEach(p => {
                newList.push(p);
            })

            // First create the points
            for(let i = 0; i < newPoints.length; i++) {
                const p = newPoints[i];
                vm.pickupPointsProcessText = `Creating ${p.address}`;

                try {
                    let copy = {};
                    vm.$refs.pointsManager.copyPoint(copy, p);
                    delete copy._id;

                    let response = await API.post(`/pickuppoint/`, copy, { headers: header });
                    newList.push(response.data.data);
                    vm.pickupPointsProgress += 1;
                } catch(err) {
                    vm.pickupPointsProcessText = utils.messageFromError(err);
                    stopped = p._id;
                    break;
                }
            }

            // Second update the points
            for(let i = 0; i < updatedPoints.length && !stopped; i++) {
                const p = updatedPoints[i];
                vm.pickupPointsProcessText = `Updating ${p.address}`;

                try {
                    let response = await API.put(`/pickuppoint/`+p._id, p, { headers: header });
                    newList.push(response.data.data);
                    vm.pickupPointsProgress += 1;
                } catch(err) {
                    vm.pickupPointsProcessText = utils.messageFromError(err);
                    stopped = p._id;
                    break;
                }
            }

            // Third delete the dropped points
            for(let i = 0; i < droppedPoints.length && !stopped; i++) {
                const p = droppedPoints[i];
                vm.pickupPointsProcessText = `Dropping ${p.address}`;

                // dummy points being deleted are simply removed from the list
                // no remote commands required
                if(this.$refs.pointsManager.pointIsLocal(p)) {
                    failed2Drop = failed2Drop.filter(fp => fp._id != p._id);
                    vm.pickupPointsProgress += 1;
                    continue;
                }

                try {
                    await API.delete(`/pickuppoint/`+p._id, { headers: header });
                    failed2Drop = failed2Drop.filter(fp => fp._id != p._id);
                    vm.pickupPointsProgress += 1;
                } catch(err) {
                    // unlike the others, don't stop! inform the user.
                    vm.$toast.open({
                        message: "Could not delete address: " + utils.messageFromError(err),
                        type: 'error',
                        position: 'top'
                    });
                }
            }

            // Fourth collect new points array list
            let formData = new FormData();
            let points = [...failed2Drop, ...newList];

            for (let i = 0; i < points.length; i++) {
                let upPoint = points[i];

                if(!this.$refs.pointsManager.pointIsLocal(upPoint)) {
                    formData.append("pickupPoints[" + i + "]", upPoint._id);
                }
            }
    
            await API.put(`/customer/${id}`,
                formData, {
                    headers: header
                },
            )
            .then(function(response) {
                if (response.status == 200) {
                    let customer = response.data.data.customer;
                    vm.pickupPoints = customer.pickupPoints;

                    // finished successfully, clear and reload
                    // otherwise persist the changes and the error msg
                    if(!stopped) {
                        vm.pickupPointsProcessText = "";
                        vm.$refs.pointsManager.clear();
                    } else {
                        // allow fixing and rebase with new points
                        vm.$refs.pointsManager.allowFix(customer.pickupPoints, stopped);
                    }
                }
            })
            .catch(function(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();

                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });

                    // try to allow fixing with what we already have
                    vm.pickupPointsProcessText = ""; // side effect: hides progress bar
                    vm.$refs.pointsManager.allowFix();
                }
            });
        },

        async photoUpload(event) {
            const vm = this;
            vm.photos = event.target.files[0];
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },

        showSubscriberForm(departId = null) {
            const vm = this
            let path = `/subscribers-list?customerId=${vm.$route.query.id}`
            if (departId) {
                path = `${path}&departmentId=${departId}`
            }
            vm.$router.push(path)
        },

        getSubscribersListLink(departId = null) {
            const vm = this
            let path = `/subscribers-list?customerId=${vm.$route.query.id}`
            if (departId) {
                path = `${path}&departmentId=${departId}`
            }            
            return `${config.baseURL}/#${path}`;
        },

        watchDepartmentValues() {
            this.deptWatchers.forEach(watcher => watcher.unwatch())
            this.deptWatchers = []

            for (let i = 0; i < this.dept.length; i++) {
                this.watchSingleDepartmentValue(i)
            }
        },

        watchSingleDepartmentValue(idx) {
            if(idx < this.deptWatchers) {
                this.deptWatchers[idx].unwatch()
                this.deptWatchers.splice(idx, 1)
            }

            const dept_i = this.dept[idx]

            // pre-format because we are adding a watcher late
            dept_i.departmentPhoneNo=utils.cleanPhoneNumberInput(dept_i.departmentPhoneNo)

            // add watcher
            const unwatch = 
                this.$watch(
                    () => dept_i.departmentPhoneNo, 
                    () => {dept_i.departmentPhoneNo=utils.cleanPhoneNumberInput(dept_i.departmentPhoneNo)},
                    {deep:true})
            this.deptWatchers.splice(idx, 0, {unwatch: unwatch})
        }
    }
}
</script>

<style scoped>
a {
  text-decoration: underline solid;
  color: #2d98ca;
  padding: 1px;
}
a:hover {
  text-decoration: underline solid;
  color: #9bd8f4;
}
.error-msg {
    color: red;
}
.image-upload>input {
    display: none;
}
.image-upload img {
    width: 80px;
    cursor: pointer;
}
ul.autocomplete-results {
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #ddd;
    list-style: none;
    display: block;
    margin: 0 5px;
    padding: 0px;
    width: 98%;
    overflow: hidden;
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 111;
}
.autocomplete {
    display: inline-block;
    position: relative;
}
.autocomplete-results li {
    padding: 5px 10px
}
.autocomplete-results li:hover {
    background-color: #2d98ca;
    color: #fff;
}
.company-logo {
    width: 250px;
    border: solid 1px rgba(150, 150, 150, 0.4);
    border-radius: 10px;
    padding: 10px;
}
.logo-upload-btn {
    transition: all 0.2s ease;    
    max-width: 35px; 
    position: relative;
    top: -40px; 
    left: 130px;
    border-radius: 100%;
}
.logo-upload-btn:hover {
    top:-45px;
    max-width: 35px; 
    box-shadow: 2px 2px 5px 10px rgba(144, 214, 255, 0.6);
}
.scrolling-modal {
    max-height: 150px; 
    overflow-y:scroll; 
    overflow-x:hidden;
    scrollbar-color: #4e4e4e #e4e4e4;
    scrollbar-width: thin;
    margin-bottom: 1%;
}
</style>