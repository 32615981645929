<template>
    <div>
        <!-- page numbers should always be visible  -->
        <table v-if="data.length > 0" class="blue-table fixed-header">
            <tr>
                <td :colspan="tableHeaderForDisplaying.length">
                    <div class="links">
                        <a href="#" @click.prevent="prev">&laquo;</a>

                        <a v-for="num in headerPagesRange" :key="num"
                        href="#"
                        :class="numberClass(num)"
                        @click.prevent="jump(num)">
                            {{num}}
                        </a>

                        <a href="#" @click.prevent="next">&raquo;</a>
                    </div>
                </td>
            </tr>
        </table>

        <div style="overflow: auto;">
            <table class="blue-table">
                <thead>
                    <tr ref="header">
                        <th v-for="(label, k) in tableHeaderForDisplaying" :key="k">
                            <a v-if="sortableHeader.includes(label)"
                            :class="sortClass(label)" href="#"
                            @click.prevent="handleSort(label)">
                                {{label}}
                            </a>
                            <div v-else>{{label}}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr :ref="`row__${(pageIdx*perPage)+k}`"
                    v-for="(obj, k) in tableData" :key="k" :class="rowClass(pageIdx*perPage, k)"
                    :title="getHint(pageIdx*perPage, k)">
                        <td v-for="(r, _) in getTableAlignedProps(obj)" :key="`cell:${(pageIdx*perPage)+k}:${r.propertyName}`">
                            <!-- We are only allowed to edit document data, not the row count at index 0. -->
                            <SmartTableCell v-show="r.col > 0"
                            :id="(pageIdx*perPage) + k"
                            :value="getValue(_pageIdxProxy*perPage, k, r.propertyName)"
                            :error="hasCellError(pageIdx*perPage, k, r.propertyName)"
                            :propertyName="r.propertyName"
                            :edit="edit"
                            :disabled="hasDelete(pageIdx*perPage, k)"
                            :replace="replace"
                            @change="handleCellChange"
                            @error="handleCellError"
                            @click="handleCellClick"/>
                            <div v-if="r.propertyName == '#'">
                                {{(pageIdx*perPage) + k+1}}
                            </div>
                            <div v-if="r.propertyName == 'Action' && (edit || actions || customActions.length > 0)" class="actions">
                                <button v-if="edit || actions" @click="handleDelete(pageIdx*perPage, k)">Delete</button>
                                <button v-if="edit || actions" @click="handleUnstage(pageIdx*perPage, k)">Revert</button>

                                <!-- We don't wish to edit the table as a whole, but individually by exposing actions -->
                                <button v-if="!edit && actions"
                                :class="hasDelete(pageIdx*perPage, k)? '' : 'btn-warning'"
                                :disabled="hasDelete(pageIdx*perPage, k)"
                                @click="handleEdit(pageIdx*perPage, k)">Edit
                                </button>

                                <!-- Custom Actions -->
                                <button v-for="(cb, cbIdx) in customActions"
                                :key="`cb:${(pageIdx*perPage)+k}-${cbIdx}`"
                                @click="cb.callback(pageIdx*perPage+k)">{{cb.name}}</button>
                            </div>
                        </td>
                    </tr>
                    <!-- No data condition -->
                    <tr v-if="data.length == 0 && header.length > 0">No entries to show</tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import SmartTableCell from './SmartTableCell.vue';
import utils from '@/utils';
export default {
    name: 'DynamicTable',
    emits: ['upload', 'change', 'error', 'unstage', 'edit', 'click-cell', 'jump'],
    components: {
        SmartTableCell
    },
    props: {
        data: {
            type: Array,
            default() {
                return []
            }
        },
        header: {
            type: Array,
        },
        perPage: {
            type: Number,
            default: 15
        },
        pageCount: {
            type: Number,
            required: false,
            default: null
        },
        selectedPage: {
            type: Number,
            required: false,
            default: null
        },
        edit: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Boolean,
            default: false
        },
        filter: {
            type: Function,
            default() {
                return (x) => true;
            }
        },
        replace: {
            type: Object, /* Technically an associative array */
            default: {}
        },
        customActions: {
            type: Array,
            default: []
        },
        sortableHeader: {
            type: Array,
            default: []
        },
        serverSidePagination: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.reset(this.copyData(this.data))
    },
    watch: {
        data: function(newData) {
            //console.log("data changed")
            //console.log(newData)
            this.reset(this.copyData(newData))
        },
        selectedPage: function(newPage) {
            this.pageIdx = newPage-1
        }
    },
    data() {
        return {
            pageIdx: 0,
            mutData: null,
            deletedRows: [],
            updatedRows: [],
            problemRows: [],
            sortCol: null,
            sortAsc: true,
            alreadyWarned: false
        }
    },
    computed: {
        tableData() {
            if(this.mutData == null) return;

            // If the user is setting the pageCount, then the table is not paginating by itself
            // Let the user control the pagination and preview data by defaulting to virtual page zero
            let pageViewIdx = this.pageCount? 0 : this.pageIdx

            let sliceIdx = pageViewIdx * this.perPage;
            let fdata = this.sort(this.mutData).filter(this.filter);

            // Only apply slicing if server side pagination is not being used
            if (!this.serverSidePagination) {
                let view = fdata.slice(sliceIdx, sliceIdx+this.perPage);

                //add record numbers
                let idx = 1;
                view.forEach(v => {
                    v[0] = sliceIdx + idx;
                    idx++;
                });

                return view
            }

            return fdata;
        },
        tableHeader() {
            if(this.header.length == 0) return [];

            let _header = [];

            for(let i = 0; i < this.header.length; i++) {
                let str = this.header[i];

                // renaming headers { propertyName: "alias" }
                if(typeof str != 'string') {
                    str = Object.keys(str)[0];
                }

                _header.push(str);
            }

            return _header;
        },
        tableHeaderForDisplaying() {
            if(this.header.length == 0) return {0: 'No Data'};

            let _header = [];

            for(let i = 0; i < this.header.length; i++) {
                let str = this.header[i];

                // same as using renaming headers { propertyName: "alias" }
                // but extract the value
                if(typeof str != 'string') {
                    str = str[Object.keys(str)[0]];
                }

                _header.push(str);
            }

            return (this.edit || this.actions || this.customActions.length > 0)? ['#', 'Action', ..._header] : ['#', ..._header];
        },
        maxPages() {
            if(this.perPage == 0) return 0;
            if(this.pageCount && this.pageCount > 0) return this.pageCount;

            let length = this.data.length;
            if(!this.edit) {
                length = this.data.filter(this.filter).length;
            }
            return Math.ceil(Math.abs(length/this.perPage));
        },

        headerPagesRange() {
            const offset = Math.max(1, Math.min(this.pageIdx+1, this.maxPages-30))
            return utils.range(Math.min(this.maxPages,30), offset)
        },

        // proxy page Idx - may be virtual page zero when the user controls the data
        _pageIdxProxy() {
            return (this.pageCount && this.pageCount > 0)? 0 : this.pageIdx
        }
    },
    methods: {
        /* I could not deep-copy vue properties
        because they have hidden, internal functions,
        but we do know `data` should always be an array
        and we can deep-copy the individual items...*/
        copyData(data) {
            let newData = [];
            for(const i in data) {
                const entry = data[i];
                newData.push(utils.safeStructuredClone(entry));
            }

            return newData;
        },

        numberClass(pageNum) {
            if(pageNum-1 == (this.selectedPage? this.selectedPage-1 : this.pageIdx)) {
                return { active: true };
            }

            return null;
        },

        rowClass(start, offset) {
            if(this.hasProblem(start, offset)) {
                return "row-problem";
            }

            if(this.hasEdit(start, offset)) {
                return "row-edit";
            }

            if(this.hasDelete(start, offset)) {
                return "row-delete";
            }

            return null;
        },

        getValue(start, offset, propertyName) {
            if(!this.mutData || this.mutData.length == 0) return 0 // this quiets vuejs

            if(this.hasEdit(start, offset)) {
                return this.updatedRows[start+offset][propertyName]
            }

            return this.sort(this.mutData).filter(this.filter)[start+offset][propertyName]
        },

        // returns propertNames aligned with this table's headers
        getTableAlignedProps(obj) {
            let results = []

            let _displayAlignedHeader = (this.edit || this.actions || this.customActions.length > 0) ? ['#', 'Action', ...this.header] : ['#', ...this.header];

            for(let i = 0; i < _displayAlignedHeader.length; i++) {
                let str = _displayAlignedHeader[i];

                if(typeof str != 'string') {
                    str = Object.keys(str)[0]; // get the propertyName
                }

                if(obj.hasOwnProperty(str)) results.push({col: i, propertyName: str});
                else results.push({col: -1, propertyName: str});
            }

            return results;
        },

        warnOfLostChanges() {
            // only give out the warning once
            if (this.alreadyWarned) {
                this.alreadyWarned = false
                return false
            }

            // warn users
            if (this.updatedRows.length != 0 || this.deletedRows.length != 0) {
                this.$toast.open({
                    message: "Warning: your changes will be lost if you navigate to a new page."
                        + " Click again to navigate anyways, or commit your changes.",
                    type: 'warning',
                    position: 'top-left',
                    duration: 0, // manual dismiss
                });
                this.alreadyWarned = true
                return true
            }

            return false
        },

        jump(number) {
            // if there are unstaged changes and the user has not been warned yet, warn them and deny navigation
            if (this.warnOfLostChanges()) { return }
            this.pageIdx = Math.max(0, number-1);
            this.$emit('jump', number)
            //console.log("jump");
        },

        jumpToRow(id) {
            const page = Math.floor(id / this.perPage)+1;
            this.jump(page);

            // we choose the previous row "id-1" because
            // the destination row is covered by the sticky header
            const min = (page-1)*this.perPage;
            const bestId = Math.max(min, id-1);

            let ptr = null;

            // Choose the header instead of we're jumping to the top row.
            // This way, the header doesn't conceal our destination row.
            if(bestId > min) {
                const [el] = this.$refs[`row__${bestId}`];
                ptr = el;
            } else {
                const el = this.$refs.header;
                ptr = el;
            }

            if(ptr) {
                ptr.scrollIntoView({ behavior: "smooth" });
            }
        },

        prev() {
            if (this.warnOfLostChanges()) { return }
            this.pageIdx = Math.max(0, this.pageIdx-1);
            this.$emit('jump', this.pageIdx+1)
            //console.log("prev");
        },

        next() {
            if (this.warnOfLostChanges()) { return }
            if(this.perPage == 0) return;
            this.pageIdx = Math.min(this.pageIdx+1, this.maxPages-1);
            this.$emit('jump', this.pageIdx+1)
            //console.log("next");
        },

        handleSort(col) {
            if (this.sortCol == col) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortCol = col;
                this.sortAsc = true;
            }
        },

        sort(inData) {
            if(this.header.length == 0 || this.sortCol == null) return inData;

            let key = null;
            for(let i = 0; i < this.header.length; i++) {
                let kk = this.header[i];
                let vv = this.header[i];

                if(typeof vv != 'string') {
                    kk = Object.keys(vv)[0];
                    vv = vv[kk];
                }

                if (vv === this.sortCol) {
                    key = kk;
                    break;
                }
            }
            if (key) {
                const data = inData.sort((d1, d2) => {
                    let str1 = d1[key];
                    let str2 = d2[key];

                    if (this.replace[key] != undefined) {
                        if (typeof str1 == 'string' && this.replace[key][str1] != undefined) {
                            str1 = this.replace[key][str1];
                        }
                        if (typeof str2 == 'string' && this.replace[key][str2] != undefined) {
                            str2 = this.replace[key][str2];
                        }
                    }

                    if (typeof str1 != 'string') {
                        str1 = '';
                    }
                    if (typeof str2 != 'string') {
                        str2 = '';
                    }
                    if (this.sortAsc) {
                        return str1.localeCompare(str2);
                    } else {
                        return str2.localeCompare(str1);
                    }

                });
                return data;
            }
            return inData;
        },

        sortClass(col) {
            if (this.sortCol == col) {
                if (this.sortAsc) {
                    return "sort-by-up";
                } else {
                    return "sort-by-down";
                }
            }
            return "sort-by";
        },

        handleCellRestore(rowId, propertyName) {
            if(!this.problemRows[rowId]) {
                return
            }

            delete this.problemRows[rowId][propertyName];

            if(Object.keys(this.problemRows[rowId]).length == 0) {
                delete this.problemRows[rowId];
            }

            if(Object.keys(this.problemRows).length == 0) {
                this.problemRows = []
            }
        },

        // event = {id, value, propertyName}
        handleCellChange(event) {
            // collect the information to stage this change
            const idx = event.id;
            const newValue = event.value;
            const propertyName = event.propertyName;

            // if we got here, there are no problems for this property
            this.handleCellRestore(idx, propertyName);

            if(this.deletedRows[idx]) return;

            // index in the current page, not the absolute index in the collection
            // only apply the adjustment if pagination is being used
            const localIndex = this.serverSidePagination ? idx - (this.perPage * this.pageIdx): idx

            let original = this.sort(this.data).filter(this.filter)[localIndex];
            let doc = this.sort(this.mutData).filter(this.filter)[localIndex];

            doc[propertyName] = newValue;

            if(this.edit || this.actions) {
                this.updatedRows[idx] = doc; // we dont want to clone, we want a ref
            }

            // do not track identical copies
            // but be never executed because cloned?
            if(this.updatedRows[idx] == original) {
                this.updatedRows[idx] = null;
            }

            this.$emit("change", event); // bubble up
        },

        // event = {id, value, propertyName, type, reset_fn}
        handleCellError(event) {
            // track problems in the table
            if(!this.problemRows[event.id]) {
                this.problemRows[event.id] = {};
            }

            this.problemRows[event.id][event.propertyName] = {reset_fn: event.reset_fn};

            this.$emit("error", event); // bubble up
        },

        // event = {id, value, propertyName, type}
        handleCellClick(event) {
            this.$emit("click-cell", event); // bubble up
        },

        handleDelete(start, offset) {
            let idx = start+offset;
            this.deletedRows[idx] = true;
            this.updatedRows[idx] = null;
        },

        handleUnstage(start, offset) {
            let idx = start+offset;
            this.deletedRows[idx] = null;
            this.updatedRows[idx] = null;

            if(this.problemRows[idx]) {
                for(const [_, propertyName] of Object.keys(this.problemRows[idx]).entries()) {
                    const ctx = this.problemRows[idx][propertyName];
                    this.$emit("unstage", {id: idx, propertyName: propertyName});
                    ctx.reset_fn();
                }
                delete this.problemRows[idx];
            }

            const data = this.sort(this.mutData).filter(this.filter)[idx];
            const mutIdx = this.mutData.findIndex(mData => {
                return mData == data;
            });
            if(mutIdx >= 0) {
                this.mutData[mutIdx] = utils.safeStructuredClone(this.data[mutIdx]);
            }
        },

        handleEdit(start, offset) {
            const vm = this;
            let update_fn = function(obj) {
                // collect the information to stage this change
                const idx = start+offset;

                let original = vm.sort(vm.data).filter(vm.filter)[idx];
                let doc = vm.sort(vm.mutData).filter(vm.filter)[idx];

                // NOTE: Mongo docs with missing fields (optional) do not update because
                // the original data did not have those fields...
                // So I am allowing those edits to have affect by merging keys.
                // I hope this doesn't cause other issues. 10/1/2022.
                const merged_keys = new Set([...Object.keys(obj), ...Object.keys(original)])

                // assume no problems for all properties
                for(const prop of merged_keys) {
                    vm.handleCellRestore(idx, prop);
                    doc[prop] = obj[prop];
                }

                if(vm.deletedRows[idx]) return;

                vm.updatedRows[idx] = doc; // we dont want to clone, we want a ref

                // do not track identical copies
                // but be never executed because cloned?
                if(vm.updatedRows[idx] == original) {
                    vm.updatedRows[idx] = null;
                }
            };

            this.$emit("edit", this.sort(this.data).filter(this.filter)[start+offset], start+offset, update_fn);
        },

        /* we changed one of the row items programmatically, we need to notify the table to sync */
        forceEdit(idx, doc) {
            if(idx < 0 || idx >= this.data.filter(this.filter).length) return false;

            let original = this.sort(this.data).filter(this.filter)[idx];
            let mutDoc = this.sort(this.mutData).filter(this.filter)[idx];

            for(const prop of Object.keys(original)) {
                const value = doc[prop]
                if(value != mutDoc[prop]) {
                    this.handleCellChange({id: idx, value: value, propertyName: prop});
                }
            }

            return true;
        },

        hasEdit(start, offset) {
            let idx = start+offset;
            return this.updatedRows[idx] != null;
        },

        hasDelete(start, offset) {
            let idx = start+offset;
            return this.deletedRows[idx] != null;
        },

        hasProblem(start, offset) {
            let idx = start+offset;
            return this.problemRows[idx] != null;
        },

        hasCellError(start, offset, propertyName) {
            let idx = start+offset;

            if(this.problemRows[idx] == null) return false;

            return this.problemRows[idx][propertyName] != null;
        },

        getHint(start, offset) {
            if(this.hasEdit(start, offset)) {
                return "You are currently updating this record";
            } else if(this.hasDelete(start, offset)) {
                return "You are currently removing this record";
            } else if(this.hasProblem(start, offset)) {
                return "This record has a problem that needs to be fixed!"
            }

            return "This record is unchanged."
        },

        invokeUpload() {
            let outUpdates = []
            let outDeletes = []
            let tableData = this.sort(this.data).filter(this.filter);

            for(const [idx, update] of this.updatedRows.entries()) {
                if(!update) continue;
                // index in the current page, not the absolute index in the collection
                // only apply the adjustment if pagination is being used
                const localIndex = this.serverSidePagination ? idx - (this.perPage * this.pageIdx): idx
                update._id = tableData[localIndex]._id; // include if not provided in doc
                outUpdates.push(update);
            }

            // NOTE: `deletedRows` only tracks booleans, not the document!
            //       This is unlike `updatedRows` which tracks the new document.
            for(const [idx, del] of this.deletedRows.entries()) {
                if(!del) continue;
                // index in the current page, not the absolute index in the collection
                // only apply the adjustment if pagination is being used
                const localIndex = this.serverSidePagination ? idx - (this.perPage * this.pageIdx): idx
                outDeletes.push(tableData[localIndex]);
            }

            this.$emit("upload", { updates: outUpdates, deletes: outDeletes })
        },

        reset(inData) {
            // call reset on the problem cells if they still exist
            for(const [_, row] of this.problemRows.entries()) {
                if(row) {
                    for(const [_, propertyName] of Object.keys(row).entries()) {
                        const ctx = row[propertyName];
                        ctx.reset_fn()
                    }
                }
            }

            // now clear the problemRows
            this.problemRows = []

            // reset the original data
            this.mutData = [...this.copyData(inData)];
            this.updatedRows = []
            this.deletedRows = []
            this.pageIdx = this.selectedPage? this.selectedPage-1 : 0;
        },
    },
}
</script>

<style scoped>
.actions {
    display: inline-grid;
}
.row-edit {
    background-color: #a0ffa0 !important;
}
.row-delete {
    background-color: #ffaeae !important;
}
.row-problem {
    background-color: #ffec9a !important;
}

.fixed-header {
    width: 100%;
    position: sticky;
    top: 0px;
}
.fixed-header {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  border-top: 2px solid #444444;
}
table.fixed-header td {
  font-size: 14px;
}
table.fixed-header .links {
  text-align: left;
}
table.fixed-header .links a{
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}
table.blue-table {
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  overflow: auto;
}
table.blue-table td, table.blue-table th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}
table.blue-table tbody td {
  font-size: 13px;
}
table.blue-table tr:nth-child(even) {
  background: #D0E4F5;
}
table.blue-table thead {
  background: #1C6EA4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  border-bottom: 2px solid #444444;
}
table.blue-table thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}
table.blue-table thead th:first-child {
  border-left: none;
}
a {
    padding-left:0.1%;
    padding-right:0.1%;
    font-weight:lighter;
    width: 20px;
    display: inline !important;
}
a.active {
    font-weight:bold;
    background-color:black !important;
}

/* styles for sort sign */
th a {
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
	display: block !important;
	width: 100%;
}

th a.sort-by,
th a.sort-by-down,
th a.sort-by-up {
	padding-right: 0px;
	position: relative;
}
a.sort-by:before,
a.sort-by:after {
	border: 4px solid transparent;
	content: "";
	display: block;
	height: 0;
	right: 5px;
	top: 50%;
	position: absolute;
	width: 0;
}

a.sort-by:before {
	border-bottom-color: #fff;
	margin-top: -9px;
}
a.sort-by:after {
	border-top-color: #fff;
	margin-top: 1px;
}

a.sort-by-down:after {
  border: 4px solid transparent;
  content: "";
	display: block;
	height: 0;
	right: 5px;
	top: 50%;
	position: absolute;
	width: 0;
  border-top-color: #fff;
  margin-top: 1px;
}

a.sort-by-up:before {
  border: 4px solid transparent;
  content: "";
	display: block;
	height: 0;
	right: 5px;
	top: 50%;
	position: absolute;
	width: 0;
	border-bottom-color: #fff;
	margin-top: -9px;
}
</style>
