<template>

        <nav class="navbar navbar-expand-md navbar-light bg-light flex-row bd-navbar">

            <a class="navbar-brand mr-0 mr-md-2" href="#"><img src="../assets/img/black_logo.svg" alt="WIT Courier"></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#NavBar" aria-controls="NavBar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="NavBar">

                <ul class="navbar-nav ml-auto align-items-center">
    
                    <li class="nav-item">
                        <router-link class="nav-link" to="/" style="padding: 12px;">Home</router-link>
                    </li>

                    <!-- <li class="nav-item">
                        <router-link class="nav-link" to="/calendar" style="padding: 12px;">Calendar</router-link>
                    </li> -->

                    <li class="nav-item">
                        <router-link class="nav-link" to="contact-support" style="padding: 12px;">Contact Support</router-link>
                    </li>

                    <!-- NOTE: will otp_verify be the best way to check if an account is logged in? For instance: department roles do not OTP -->
                    <li v-if="!hideUserButtons && otp_verify == 'YES'" class="nav-item" id="dashboard" style="display:block ruby">
                        <router-link class="nav-link" to="/dashboard" style="padding: 12px;"><img src="../assets/img/task-address-icon-only.svg" alt=""> To Dashboard</router-link>
                    </li>

                    <li v-if="!hideUserButtons && otp_verify == 'YES'" class="nav-item" id="logout">
                        <router-link class="btn btn-primary m-2" to="#" v-on:click="logout">Logout</router-link>
                    </li>
    
                    <li v-else class="nav-item" id="login">
                        <router-link class="btn btn-primary m-2" to="/login">Login</router-link>
                    </li>
    
                </ul>

            </div>

        </nav>

</template>

<script>

export default {
    name: 'Nav',
    props: {
        hideUserButtons: { type: Boolean, default: false }
    },
    data() {
        return {
            userInfo: null,
            otp_verify: null
        }
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push({ name: 'Login' })

        },
    },
    mounted() {
        this.userInfo = localStorage.getItem("user-info")
        this.otp_verify = localStorage.getItem("otp_verify")
    }
}
</script>
<style>
.showbtn {
    display: block;
}

.hidebtn {
    display: none;
}

.navbar {
    box-shadow: 0px 1px 20px 2px #c2f0ff69;
}

.bd-navbar {
    min-height: 4rem;
    background-color: #7952b3;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.05),inset 0 -1px 0 rgba(0,0,0,0.1)
}

@media (max-width: 991.98px) {
    .bd-navbar {
        padding-right:.5rem;
        padding-left: .5rem
    }

    .bd-navbar .navbar-nav-scroll {
        max-width: 100%;
        height: 2.5rem;
        margin-top: .25rem;
        overflow: hidden !important;
    }

    .bd-navbar .navbar-nav-scroll .navbar-nav {
        padding-bottom: 2rem;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch
    }
}
</style>


