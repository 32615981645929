<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid">
                <JobForm
                create 
                edit 
                assign
                :canGoBack="lastPage != null"
                @back="goBack"/>
            </div>
        </div>
    </div>

</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import JobForm from '@/components/JobForm.vue'

export default {
    name: 'AddJob',
    data() {
        return {
            
        }

    },
    components: {
        Topnav,
        Sidebar,
        JobForm,
    },
    computed: {
        lastPage() {
            return this.$route.query.lastPage
        }
    },
    async mounted() {
        
    },
    methods: {
        goBack() {
            if(!this.lastPage) return;

            this.$router.push(this.lastPage);
        },
    }
}
</script>

<style scoped>
</style>