<template>
    <div>
        <Nav />

        <div class="banner">

            <div class="container-fluid">

                <div class="row">

                    <div class="col-md-12">

                        <div class="banner-text">

                            <h2>Welcome to WIT Courier - Whatever It Takes!</h2>

                            <p>Request A Courier</p>

                            <div class="banner-text-btn">

                                <router-link to="/" class="btn btn-block btn-warning btn-request-courier" disabled>Request
                                    Form Coming Soon</router-link>

                            </div>

                            <img src="../assets/img/wit_van.png" class="wit-van-img" />

                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div class="container-fluid">

            <div class="row">

                <div class="col-md-3"></div>

                <div class="col-md-6 mt-4">

                    <div class="about-text">

                        <h6>About Us</h6>

                        <p class="wit-van-speech">WIT Courier has been serving the Middle Tennessee area for over 2 decades.
                            We specialize in medical records transport and delivery. We also deliver medical equipment,
                            blood, etc. Serving all of Middle Tennessee, including Nashville, Murfreesboro and beyond.</p>

                    </div>

                </div>

                <div class="col-md-3"></div>

            </div>

            <div class="row">

                <div class="col-md-4"></div>

                <div class="col-md-4 mt-4">

                    <div class="about-text">

                        <h6>Contact Us</h6>

                        <div class=" contact-info-text"
                            style="border: 1px solid #ececec;box-shadow: 0 1px 3px rgb(0 0 0 / 8%), 0 1px 2px rgb(0 0 0 / 0%);padding: 40px 40px 40px 40px;">

                            <h6 class="timing-text mt-2">We are open 9am - 9pm CST on Monday-Friday</h6>

                            <p class="contact-subtext mt-2">{{ supportMsg }}</p>

                            <form @submit.prevent="contactSupport">

                                <div class="form">

                                    <div class="form-item">

                                        <input type="text" id="name" v-model="name" autocomplete="off" required>

                                        <label for="name">Name</label>

                                    </div>

                                    <div class="form-item">

                                        <input type="email" id="email" v-model="email" autocomplete="off" required>

                                        <label for="email">Email</label>

                                    </div>

                                    <div class="form-item">

                                        <input type="text" id="phone" v-model="phone" autocomplete="off" required
                                            minlength="10" maxlength="10">

                                        <label for="contactPersonPhone">Phone Number</label>

                                    </div>

                                    <div class="form-item">

                                        <textarea id="comments" rows="4" cols="50" v-model="message" autocomplete="off"
                                            required></textarea>

                                        <label for="comments">Message</label>

                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-12 mt-2 mb-4">

                                        <button type="submit"
                                            class="btn btn-primary btn-block contact-submit">SUBMIT</button>

                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>

                </div>

                <div class="col-md-4"></div>

            </div>

        </div>
        <Footer />
    </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import API from '../axios.config';
import utils from '../utils';

export default {
    name: 'Home',
    components: {
        Nav,
        Footer
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            supportMsg: '',
            supportId: null,
        }
    },
    mounted() {
        this.getSupportInfo();
    },
    methods: {
        async contactSupport() {
            if (this.supportId == null) {
                this.$toast.open({
                    message: "We're sorry but we have no email support available at this time.",
                    type: 'error',
                    position: 'top'
                });
                return;
            }

            try {
                let response = await API.post('sendsupportemail/' + this.supportId,
                    {
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        message: this.message,
                    })

                if (response.status == 200) {
                    //console.log(response.data);
                    this.$toast.success(response.data.message, {
                        // override the global option
                        position: 'top'
                    })
                    location.reload();

                }
            } catch (error) {
                // handle quietly on public-facing home screen
            }
        },

        async getSupportInfo() {
            try {
                let response = await API.get('supportInfo')
                if (response.status == 200) {
                    // console.log(response.data.data);
                    if (response.data.data.length == 0) {
                        this.supportMsg = '';
                    } else {
                        var phn = utils.formatPhoneNumber(response.data.data[0].contactPersonPhone);
                        this.supportId = response.data.data[0]._id;
                        this.supportMsg = 'For On-Call Support Dial ' + phn;
                    }
                }
            } catch (error) {
                // handle quietly on public-facing home screen
            };
        },
    }
}
</script>

<style scoped>
.btn-request-courier {
    height: 15%;
    width: 15%;
    padding: 1%;
}

.wit-van-img {
    width: 256px;
    height: auto;
    display: block;
    position: relative;
    margin-left: 10%;
}

.wit-van-speech {
    position: relative;
    background: #fff;
    border-radius: .4em;
    padding: 20px;
    box-shadow: rgba(122, 122, 122, 0.048) 0px 0px 1px 1px;
}

.wit-van-speech:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 60px solid transparent;
    border-right-color: #fff;
    border-left: 0;
    border-top: 0;
    margin-top: -40px;
    margin-left: -60px;
}

@media all and (max-width: 1800px) {
    .wit-van-img {
        margin-left: 5% !important;
    }
}

@media all and (max-width: 900px) {
    .btn-request-courier {
        height: 15%;
        width: 65% !important;
        padding: 1%;
    }

    .wit-van-img {
        display: none;
    }

    .wit-van-speech {
        background: none;
        box-shadow: none;
    }

    .wit-van-speech::after {
        display: none;
    }
}</style>
