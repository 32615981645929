<template>
	<Topnav />
  <Sidebar />
  <div class="content-wrapper">
    <div class="content-header" style="padding: 0;">
      <div class="container-fluid" style="padding-left: 0;">
         <div class="row" style="padding-top: 5%;">
         	<div class="col-md-1"> </div>
         	<div class="col-md-11">
           		<img src="../assets/img/Bitmap.svg" width="90%">
           	</div>
           	
       </div>
       <br> <br>
   </div>
</div>
</div>

</template>
<script>
	import Topnav from '@/components/Topnav.vue'
    import Sidebar from '@/components/Sidebar.vue'
	export default{
		name:'HomeSchedule',
		components:{
			Topnav,
			Sidebar

		}
	}
</script>