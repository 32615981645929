<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid">

                <ul class="nav nav-tabs" id="myTab" role="tablist" style="display: flex;border-bottom: 1px solid #f4f6f9;">

                    <li class="nav-item">

                        <a class="nav-link active" id="Payout-tab" data-toggle="tab" href="#Payout" role="tab" aria-controls="Payout" aria-selected="true" @click="switchTo(states.Payout)">Payout</a>

                    </li>

                    <li class="nav-item">

                        <a class="nav-link" id="Invoice-tab" data-toggle="tab" href="#Invoice" role="tab" aria-controls="Invoice" aria-selected="false" @click="switchTo(states.Invoice)">Invoice</a>

                    </li>

                </ul>

                <div class="tab-content" id="myTabContent">

                    <div class="tab-pane fade show active" id="Payout" role="tabpanel" aria-labelledby="Payout-tab">

                        <div class="row mt-4">
                            <div class="form-item col-lg-2 col-2">
                                <label class="selectOptions" style="background: #f4f6f9; margin-right: 2%;">Select Driver</label>

                                <select id="driverSelect" class="form-select" @change.prevent="selectDriver">
                                    <option v-show="!payoutData.preventDefault">Select</option>
                                    <option v-for="driver in drivers" :key="driver._id" :value="driver._id">{{driver.userId.first_name + ' ' + driver.userId.last_name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="form-item col-md-2">
                                <label class="selectOptions" style="background: #f4f6f9; margin-right: 2%;">Select Time Zone</label>

                                <select class="form-select" @change.prevent="selectTimeZone">
                                    <option v-show="!selectedTimeZone">Select</option>
                                    <option v-for="tz in timeZones" :key="tz" :value="tz" :selected="selectedTimeZone == tz">
                                        {{tz}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="form-item col-md-2">

                                <input type="date" id="from1" v-model="payoutData.from" autocomplete="off" required>

                                <label for="from1" style="background: #f4f6f9;">From Date</label>

                            </div>

                            <div class="form-item col-md-2">

                                <input type="date" id="to1" v-model="payoutData.to" autocomplete="off" required>

                                <label for="to1" style="background: #f4f6f9;">To Date</label>

                            </div>
                       </div>


                        <div class="row mt-4">
                            <div class="form-item col-md-3">
                                <SpinButton 
                                class="button btn-warning" 
                                style="margin-right:1%;"
                                title="Fetch driver's completed past jobs"
                                :spin="payoutData.fetching" 
                                @click="downloadTable">
                                    Fetch Preview
                                </SpinButton>
                                <SpinButton 
                                class="button btn-info" 
                                style="margin-right:1%;"
                                title="Calculate and download driver payout PDF" 
                                :spin="payoutData.fetching"  
                                @click="generate">
                                    Generate Payout
                                </SpinButton>
                            </div>
                            <div class="form-item col-md-9">
                                <p class="error-message">{{payoutData.errorMessage}}</p>
                            </div>
                        </div>
                        
                        <hr/>

                        <BillingPreviewTable 
                            :data="payoutData.jobsData" 
                            :total="payoutData.total"
                            :commission="selectedDriverCommission"/>

                    </div>

                    <div class="tab-pane fade" id="Invoice" role="tabpanel" aria-labelledby="Invoice-tab">

                        <div class="row mt-4">
                            <div class="form-item col-lg-2 col-2">
                                <label class="selectOptions" style="background: #f4f6f9; margin-right: 2%;">Select Customer</label>

                                <select class="form-select" id="customerSelect" @change.prevent="selectCustomer">
                                    <option v-show="!invoiceData.preventDefault">Select</option>
                                    <option v-for="customer in customers" :key="customer._id" :value="customer._id">{{customer.name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="form-item col-md-2">
                                <label class="selectOptions" style="background: #f4f6f9; margin-right: 2%;">Select Time Zone</label>

                                <select class="form-select" @change.prevent="selectTimeZone">
                                    <option v-show="!selectedTimeZone">Select</option>
                                    <option v-for="tz in timeZones" :key="tz" :value="tz" :selected="selectedTimeZone == tz">
                                        {{tz}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="form-item col-md-2">

                                <input type="date" id="from2" v-model="invoiceData.from" autocomplete="off" required>

                                <label for="from2" style="background: #f4f6f9;">From Date</label>

                            </div>

                            <div class="form-item col-md-2">

                                <input type="date" id="to2" v-model="invoiceData.to" autocomplete="off" required>

                                <label for="to2" style="background: #f4f6f9;">To Date</label>

                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="form-item col-md-3">
                                <SpinButton 
                                class="button btn-warning" 
                                style="margin-right:1%;"
                                title="Fetch completed past jobs for customer" 
                                :spin="invoiceData.fetching" 
                                @click="downloadTable">
                                    Preview
                                </SpinButton>
                                <SpinButton 
                                class="button btn-info" 
                                style="margin-right:1%;"
                                title="Calculate and download customer invoice PDF" 
                                :spin="invoiceData.fetching" 
                                @click="generate">
                                    Generate Invoice
                                </SpinButton>
                            </div>
                            <div class="form-item col-md-9">
                                <p class="error-message">{{invoiceData.errorMessage}}</p>
                            </div>
                        </div>
                        
                        <hr/>

                        <BillingPreviewTable
                            :data="invoiceData.jobsData"
                            :total="invoiceData.total"/>

                    </div>

                </div>

            </div>

        </div>
    
    </div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import BillingPreviewTable from '@/components/BillingPreviewTable.vue';
import SpinButton from '@/components/SpinButton.vue';
import API from '../axios.config';
import utils from '../utils';

const states = utils.createEnum('Payout', 'Invoice');

export default {
    name: 'Billing',
    components: {
        Topnav,
        Sidebar,
        BillingPreviewTable,
        SpinButton
    },
    data() {
        return {
            drivers: [],
            customers: [],
            selectedCustomer: null, // id
            selectedDriver: null, // id
            selectedTimeZone: null,
            payoutData: {
                from: null,
                to: null,
                jobsData: {},
                jobsHeader: [],
                total: 0,
                errorMessage: '',
                preventDefault: false,
                fetching: false,
            },
            invoiceData: {
                from: null,
                to: null,
                jobsData: {},
                jobsHeader: [],
                total: 0,
                errorMessage: '',
                preventDefault: false,
                fetching: false
            },
            timeZones: [
                'US/Central',
                'US/Pacific',
                'US/Eastern',
            ],
            allChargeTypes: [],
            states: states,
            mode: states.Payout // NOTE: This must match the tab directives until we can do that programatically
        }
    },
    computed: {
        modeData() {
            if(this.mode === this.states.Payout) {
                return this.payoutData;
            }

            return this.invoiceData;
        },
        hasDateRange() {
            return this.modeData.from != null && this.modeData.to != null;
        },

        hasCustomer() {
            return !!this.selectedCustomer;
        },

        hasDriver() {
            return !!this.selectedDriver;
        },

        selectedDriverCommission() {
            if(this.hasDriver) {
                return this.drivers.find(el => el._id == this.selectedDriver)?.commission
            }

            return null
        }
    },

    async mounted() {
        await this.checkRole();
        await this.fetchChargeTypes();
        await this.refresh();
    },
    methods: {
        //////////////////
        // API FETCHERS //
        //////////////////
        async fetchDrivers() {
            const vm = this;
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            
            let loginToken = localStorage.getItem("user-info")

            API.get(`/driver`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        if (response.data.data.length == 0) {
                            vm.drivers = []
                        } else if (response.data.data) {
                            vm.drivers = [...response.data.data].filter(x => x.userId.is_registered);
                        }
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        async fetchCustomers() {
            const vm = this;
            vm.msg = "";
            let loginToken = localStorage.getItem("user-info")
            if (!loginToken) {
                this.$router.push({ name: 'Login' })
            }

            API.get(`/customer`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        if (response.data.data.length == 0) {
                            vm.customers = []
                        } else if (response.data.data) {
                            vm.customers = [...response.data.data].filter(x => x.userId.is_registered)
                        }
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        async fetchChargeTypes() {
            const vm = this;

            let loginToken = localStorage.getItem("user-info")
            if (!loginToken) {
                this.$router.push({ name: 'Login' })
            }

            vm.allChargeTypes = []

            API.get(`/chargeType`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        response.data.data.forEach(el => {
                          vm.allChargeTypes.push(el);
                        })
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },

        /////////////
        // Methods //
        /////////////

        async checkRole() {
            const vm = this;
            let userId = localStorage.getItem("user_id");
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            let loginToken = localStorage.getItem("user-info")

            API.checkRole(userId, loginToken, ()=>{}, ()=>{
                vm.$router.push('Profile'); // redirect to the user's profile
            });
        },


        async refresh() {
            if(this.mode === this.states.Invoice) {
                await this.fetchCustomers();
                return;
            }

            if(this.mode === this.states.Payout) {
                await this.fetchDrivers();
                return;
            }
        },

        switchTo(state) {
            if(!this.states.isKey(state)) return;

            this.mode = state;
            this.refresh();
        },

        isFormReady() {
            this.modeData.errorMessage = '';

            if(this.mode === this.states.Invoice) {
                if(!this.hasCustomer) {
                    this.modeData.errorMessage = "Please select a CUSTOMER to bill";
                } else if(!this.hasDateRange) {
                    this.modeData.errorMessage = "Please choose a START and END date";
                } else if(!this.selectedTimeZone) {
                    this.modeData.errorMessage = "Please choose a TIMEZONE to format all dates";
                }

                return this.hasCustomer && this.hasDateRange && this.selectedTimeZone;
            }

            if(this.mode === this.states.Payout) {
                if(!this.hasDriver) {
                    this.modeData.errorMessage = "Please select a DRIVER to bill";
                } else if(!this.hasDateRange) {
                    this.modeData.errorMessage = "Please choose a START and END date";
                } else if(!this.selectedTimeZone) {
                    this.modeData.errorMessage = "Please choose a TIMEZONE to format all dates";
                }

                return this.hasDriver && this.hasDateRange;
            }

            return false;
        },

        async apiGetPastJobsHelper(from, to, isDriverPayoutMode, driverId, customerId) {
            let loginToken = localStorage.getItem("user-info")

            let routeList = []
            let statList = []
            let routineStatList = []
            let specialRequestList = []

            // NOTE: since this function shares the layout of the API
            // (and the API is designed poorly here) omit the driver ID
            // depending on which mode this is supposed to be (see API.get('/pastjobs',...) below)

            // TODO: autopopulate from future Charge Type inheritence table...
            this.allChargeTypes.forEach(charge => {
                if(charge.name == "SPECIAL REQUEST") {
                    specialRequestList.push(charge._id)
                } else if(charge.name == "ROUTINE STAT") {
                    routineStatList.push(charge._id)
                } else if(charge.name == "STAT") {
                    statList.push(charge._id)
                } else if(charge.name == "ROUTE") {
                    routeList.push(charge._id)
                } else if(charge.name == "ON CALL") {
                    statList.push(charge._id)
                }
            })

            try {
                let result = await API.get(`/pastjobs`, {
                    params: {
                        from: from,
                        to: to,
                        billing: true,
                        customerId: !isDriverPayoutMode? customerId : null,
                        driverId: isDriverPayoutMode? driverId : null,
                        isDriverPayoutMode: isDriverPayoutMode,
                        timeZone: this.selectedTimeZone,
                        Route: routeList,
                        Stat: statList,
                        RoutineStat: routineStatList,
                        SpecialRequest: specialRequestList,
                    },
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });
                
                return result.data.data;
            }
            catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            }

            return null;
        },

        async fetchPastJobs() {
            let result = null;

            this.modeData.fetching = true;

            result = await this.apiGetPastJobsHelper(this.modeData.from, this.modeData.to, this.mode === this.states.Payout, this.selectedDriver, this.selectedCustomer);

            this.modeData.fetching = false;

            return result;
        },

        async downloadTable() {
            if(!this.isFormReady()) return;

            const billing_data = await this.fetchPastJobs()

            this.modeData.jobsData = billing_data.tables
            this.modeData.total = billing_data.total_costs
        },

        async generate() {
            if(!this.isFormReady()) return;
            
            let loginToken = localStorage.getItem("user-info")

            this.modeData.fetching = true;

            try {
                const billing_data = await this.fetchPastJobs()

                if(Object.keys(billing_data.tables).length > 0) {
                    const isForCustomer = this.mode === this.states.Invoice
                    const recipient = this.getRecipientName(isForCustomer)
                    const recipient_phone = this.getRecipientPhone(isForCustomer)
                    const recipient_address = this.getRecipientAddress(isForCustomer)

                    this.$toast.open({
                        message: 'PDF download started.',
                        type: 'info',
                        position: 'bottom-left'
                    });

                    // use coversheet if invoicing customers ONLY!
                    let response = await API.post(`/billing`, 
                    {
                        recipient: recipient,
                        recipient_phone: recipient_phone,
                        recipient_address: recipient_address,
                        tables: billing_data.tables, 
                        coverSheet: isForCustomer,
                        start_date: billing_data.start_date,
                        end_date: billing_data.end_date,
                        total_miles: billing_data.total_miles,
                        total_stops: billing_data.total_stops,
                        total_costs: billing_data.total_costs,
                        final_total: billing_data.final_total,
                        earnings_invoice_label: isForCustomer? "Invoice" : "Driver Earnings",
                        timezone: this.selectTimeZone,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${loginToken}`
                        },
                        responseType: 'arraybuffer'
                    });

                    let buffer = response.data;

                    this.$toast.open({
                        message: 'PDF download complete.',
                        type: 'success',
                        position: 'bottom-left'
                    });

                    const blob = new Blob(
                        [buffer], 
                        {
                            type: 'application/pdf'
                        }
                    )
                    const url = window.URL.createObjectURL(blob)
                    window.open(url)

                } else {
                    this.$toast.open({
                        message: 'Cannot download PDF for zero completed jobs.',
                        type: 'default',
                        position: 'bottom-left'
                    });        
                }
            } catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            }

            this.modeData.fetching = false;
        },

        selectCustomer(event) {
            this.selectedCustomer = event.target.value;
            this.invoiceData.preventDefault = true;
        },

        selectDriver(event) {
            this.selectedDriver = event.target.value;
            this.payoutData.preventDefault = true;
        },

        selectTimeZone(event) {
            this.selectedTimeZone = event.target.value;
        },

        // Lookup proper contact name based on account
        getRecipientName(isForCustomer) {
            if(isForCustomer && this.selectedCustomer) {
                const customer = this.customers.find(el => el._id == this.selectedCustomer)
                return customer.name
            } else if(this.selectedDriver) {
                const driver = this.drivers.find(el => el._id == this.selectedDriver)
                return `${driver.userId.first_name} ${driver.userId.last_name}`
            }

            return null
        },

        // Lookup proper phone number based on account
        getRecipientPhone(isForCustomer) {
            if(isForCustomer && this.selectedCustomer) {
                const customer = this.customers.find(el => el._id == this.selectedCustomer)
                return customer.userId.phoneNo
            } else if(this.selectedDriver) {
                const driver = this.drivers.find(el => el._id == this.selectedDriver)
                return driver.userId.phoneNo
            }

            return null
        },

        // Lookup proper mailing address based on account
        getRecipientAddress(isForCustomer) {
            if(isForCustomer && this.selectedCustomer) {
                const customer = this.customers.find(el => el._id == this.selectedCustomer)
                return customer.address
            } else if(this.selectedDriver) {
                const driver = this.drivers.find(el => el._id == this.selectedDriver)
                return driver.home_address
            }

            return null
        }
    }
}
</script>

<style scoped>
select {
    height: 60%;
    margin-top: 2%;
}

.error-message {
    color: red;
}
</style>
