<template>
    <div class="row mt-2 mb-4">

        <div class="col-md-11">
        </div>

        <div class="col-md-1">
            <button class="btn btn-primary" @click="$emit('cancel')">CANCEL</button>
        </div>

    </div>
    <div class="card">
    
        <p class="login-box-msg">Complete Job Remotely Form</p>

        <div class="card-body login-card-body">
        
            <form @submit.prevent="verify">

                <div class="row mt-2 mb-4">
                    
                    <div class="col-md-4">
                    </div>

                    <div class="col-md-4">
                        <p class="w-10 h-10 mx-2 text-center">{{taskCheckText}}</p>
                    </div>

                    <div class="col-md-4">
                    </div>

                </div>

                <div class="row mt-2 mb-4" v-show="!taskCheckStep">
                    
                    <div class="col-md-4">
                    </div>

                    <div class="col-md-4">
                        <p class="w-10 h-10 mx-2 text-center">{{ taskCheckResultText }}</p>
                    </div>

                    <div class="col-md-4">
                    </div>

                </div>

                <table v-show="tasksNotCompleted.length > 0" v-if="taskCheckStep==false">
                    <tr>
                        <th>Facility</th>
                        <th>Address</th>
                        <th>Contact</th>
                        <th>New Time</th>
                        <th>GPS Start Location</th>
                        <th>Resolution</th>
                    </tr>
                    <tr v-for="t in tasksNotCompleted" :key="t._id">
                        <td>
                            {{t.facility ?? "N/A"}}
                        </td>
                        <td>
                            {{t.address ?? "N/A"}}
                        </td>
                        <td>
                            {{(t.contact?.length == 0? "N/A" : t.contact) ?? "N/A"}}
                        </td>
                        <td>
                            <input
                            title="New completion time"
                            type="datetime-local"
                            :value="getCompletionTime(t._id)"
                            @input="event => setCompletionTime(t._id, event.target.value)"/>
                        </td>
                        <td>
                            <div style="display: inline-block">
                                <input class="latlon"
                                title="Latitude Coordinate" 
                                :name="`Lat_${t._id}`"
                                type="number" 
                                placeholder="Lat" 
                                step="0.0000001" 
                                :value="getLatOverride(t._id)"
                                @input="event => setLatOverride(t._id, event.target.value)"/>
                                
                                <input class="latlon"
                                title="Longitude Coordinate" 
                                :name="`Lon_${t._id}`"
                                type="number" 
                                placeholder="Lon" 
                                step="0.0000001" 
                                :value="getLonOverride(t._id)"
                                @input="event => setLonOverride(t._id, event.target.value)"/>
                            </div>
                        </td>
                        <td v-if="t.requiresPhotoSignature">
                            <div class="upload-btn-wrapper" v-if="!(uploads[t._id] || t.upload)">
    
                                <button class="btn btn-warning">Needs Proof</button>

                                <input type="file" accept="image/*" multiple @change="photoUpload(t._id, $event)" />

                            </div>
                            <div v-else>{{ uploads[t._id]?.length ?? t.upload.photoUrls.length ?? 0 }} File(s)</div>
                        </td>
                        <td v-else>
                            -
                        </td>
                    </tr>
                </table>

                <div class="row mt-2 mb-4">

                    <div class="col-md-4">
                    </div>

                    <div class="col-md-4">
                        <SpinButton 
                            :title="completeNowButtonHint" 
                            :spin="isWorking || taskCheckStep" 
                            :disabled="tasksResolved==false" 
                            @click="handleSubmitClicked" 
                            class="btn btn-success full-w">
                            COMPLETE NOW
                    </SpinButton>
                    </div>

                    <div class="col-md-4">
                    </div>

                </div>

            </form>

            <div class="row">

                <div class="col-md-3"></div>

                <div class="col-md-6">

                    <div class="customer_message_officer_service">

                        <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400;">

                        </p>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
import API from '@/axios.config';
import SpinButton from '@/components/SpinButton';
import eznavigator from '@/eznavigator';
export default {
    name: 'ForceJobComplete',
    data() {
        return {
            isWorking: false,
            taskCheckStep: true,
            uploads: {},
            latLonOverrides: {},
            completionOverrides: {},
            forceComplete: null,
            eznavigator: eznavigator,
        }

    },
    emits: ['success', 'error', 'cancel'],
    components: {
        SpinButton,
    },
    props: {
        jobId: String,
        jobType: String,
        driverUserId: String,
        tasks: Array,
    },
    computed: {
        tasksMissingProof() {
            return this.tasks.filter(t => t.requiresPhotoSignature && !t.upload)
        },

        tasksNotCompleted() {
            return this.tasks.filter(t => t.status != 'COMPLETE')
        },

        taskCheckText() {
            if(this.taskCheckStep) return "Checking tasks for compliance..."

            return "✓ Task check complete"
        },

        taskCheckResultText() {
            if(this.tasksResolved) return "✓ All tasks can be completed"

            if(!this.driverUserId) return "This job must have an assigned Driver to be completed"

            const len = this.tasksMissingProof.length

            for(let el in this.completionOverrides) {
                if(this.completionOverrides[el] == null) {
                    return `Tasks must have a new time of completion`
                }
            }

            if(len > 1) {
                return `${len} tasks are missing proof` 
            }

            return `${len} task is missing proof` 
        },

        tasksResolved() {
            let canSubmit = true
            for(let el in this.completionOverrides) {
                if(this.completionOverrides[el] == null) {
                    canSubmit = false
                    break
                }
            }

            // For each task, if we have an upload entry, the total matches the number
            // of missing proof, then we have provided all uploads required
            return canSubmit && this.driverUserId && this.tasksMissingProof.length == Object.keys(this.uploads).length
        },

        completeNowButtonHint() {
            if(this.tasksResolved) return "Click to complete task"

            return "Resolve remaining issues before continuing"
        },
    },
    methods: {
        // getters/setters
        getLatOverride(taskId) {
            const override = this.latLonOverrides[taskId]
            if(!override) return 0
            return override[0]
        },

        getLonOverride(taskId) {
            const override = this.latLonOverrides[taskId]
            if(!override) return 0
            return override[1]
        },

        setLatOverride(taskId, value) {
            let override = this.latLonOverrides[taskId]
            if(!override) {
                override = [0,0]
                this.latLonOverrides[taskId] = override
            }

            override[0] = +value
        },

        setLonOverride(taskId, value) {
            let override = this.latLonOverrides[taskId]
            if(!override) {
                override = [0,0]
                this.latLonOverrides[taskId] = override
            }

            override[1] = +value
        },

        getCompletionTime(taskId) {
            return this.completionOverrides[taskId] ?? 0
        },

        setCompletionTime(taskId, value){
            this.completionOverrides[taskId] = value
        },
        
        // event handlers
        async handleSubmitClicked() {
            this.isWorking = true
            this.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${this.loginToken}`,
            }

            const taskData = []
            try {
                for(let i = 0; i < this.tasks.length; i++) {
                    const task = this.tasks[i]
                    const id = task._id

                    // skip successfully completed tasks
                    if(task.status == 'COMPLETE' || task.status == 'CANCELLED')
                        continue; 

                    const form = new FormData();

                    const toUpload = this.uploads[id]
                    for(let i = 0; i < toUpload?.length ?? 0; i++) {
                        form.append("photos", toUpload[i])
                    }

                    // Identifies who was assigned to the job and the override for their tracking location
                    form.append("forceComplete[userId]", this.driverUserId)
                    form.append("forceComplete[Latitude]", this.latLonOverrides[id][0])
                    form.append("forceComplete[Longitude]", this.latLonOverrides[id][1])
                    form.append("forceComplete[completedAt]", new Date(this.completionOverrides[id]).toUTCString())

                    let response = await API.put(`/Task/complete/${id}`, form, 
                    {
                        headers: header
                    })

                    if (response.status == 200) {
                        taskData.push(response.data.data)
                    } else {
                        this.$emit('error', response.data.data)
                    }
                }
            }
            catch(error) {
                this.$emit('error', error)
                this.isWorking = false
                return
            }

            this.$emit("success", taskData, this.forceComplete)
            this.isWorking = false
        },

        async photoUpload(taskId, event) {
            const input = event.target;
            if (input.files) {
                this.uploads[taskId] = [...input.files]
            }
        },
    },
    mounted() {
        let vm = this

        // I made this a timeout to make it look busy - Mav :)
        setTimeout(async () => {
            let latLon = [0, 0]
            if(eznavigator.available()) {
                latLon = await eznavigator.getLatLong()
            }

            for(let t of vm.tasksNotCompleted) {
                // make a copy otherwise they all point to the same object...
                vm.latLonOverrides[t._id] = [...latLon]
                vm.completionOverrides[t._id] = null // must be set by user
            }

            // this data can be used to identify who forced the completion and where
            vm.forceComplete = {
                userId: vm.driverUserId,
                Latitude: latLon[0],
                Longitude: latLon[1]
            }

            vm.taskCheckStep = false;
 
        }, 1000)
    }
}
</script>

<style scoped>
table {
    width: 100%;
    margin-bottom: 40px;
}

th {
    background-color:black;
    color:white;
    padding:10px;
}

td {
    padding:10px;
}

td .latlon {
    width: 6vw;
}

tr:nth-child(even) {
  background: #e0e0e0;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.error-msg {
    color: red;
}

.btn:disabled {
    cursor: not-allowed;
}

.wait {
  cursor:progress !important;
}

.full-w {
    width: 100%;
}
</style>