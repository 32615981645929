import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import VueToast from 'vue-toast-notification';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Chat from 'vue3-beautiful-chat'
import config from './../config'

import "./assets/plugins/fontawesome-free/css/all.min.css"
import "./assets/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css"
import "./assets/plugins/datatables-responsive/css/responsive.bootstrap4.min.css"
import "./assets/plugins/datatables-buttons/css/buttons.bootstrap4.min.css"
import "./assets/css/adminlte.min.css"
import "./assets/css/stylesheet.css"
import "./assets/css/responsive-stylesheet.css"
import "vue-toast-notification/dist/theme-sugar.css"
import "./assets/js/adminlte.min.js"

createApp(App)
    .use(Chat)
    .use(router)
    .use(VueToast)
    .use(VueGoogleMaps, {
        load: {
            key: config.google.maps_key,
        },
    })
    .mount('#app');