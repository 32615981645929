<template>
	<div class="container-fluid">
	
		<div class="row">
	
			<div class="col-md-12">
	
				<div class="row" style="display: list-item;">
	
					<nav class="main-header navbar navbar-expand navbar-white navbar-light">	
						<ul class="navbar-nav d-flex align-items-center">
							<li class="nav-item">
								<!-- <router-link class="nav-link" data-widget="pushmenu" to="/dashboard" role="button"><i class="fa fa-bars"></i></router-link> -->
								<a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fa fa-bars"></i></a>
							</li>
							<li class="nav-item">
								<small :title="`Dashboard Last Updated ${lastUpdated}`" class="version">v{{version ?? 0}}</small>
							</li>
						</ul>
	
						<ul class="navbar-nav m-auto">
							<li class="nav-item heading-text">
								<h6>{{ menuname }}</h6>
							</li>
						</ul>
	
						<ul class="navbar-nav">
							<li class="nav-item ml-auto d-flex align-items-center">
								<div v-if="profile_image" class="navbar-photo" :style="`background-image: url(${API.baseURL}${profile_image})`"/>
								<div v-else class="navbar-photo" :style="`background-image: url(../assets/img/image.svg)`"/>
								<router-link class="nav-link" data-widget="control-sidebar" data-slide="true" to="/Profile" role="button" style="padding: 11px;font-weight: 500;">{{ name }}</router-link>
							</li>
						</ul>
					</nav>
	
				</div>
	
			</div>
	
		</div>
	
	</div>
</template>
<script>
import API from '../axios.config';
import config from '../../config';
import utils from '../utils'
export default {
	name: 'Topnav',
	data() {
		return {
			name: '',
			user_role: '',
			menuname: '',
			profile_image: '',
			API: API,
			version: config.version,
			lastUpdated: config.lastUpdated
		}
	},
	mounted() {
		const vm = this;
		let username = localStorage.getItem("username");
		vm.name = username;
		let paths = vm.$route.path;
		let pathname = paths.split('-').join(' ').replace("/", "").toUpperCase();
		vm.menuname = pathname;
		vm.profile();


	},
	methods: {
		profile() {
			const vm = this;
			let users = localStorage.getItem("user-info")
			let roles = [...JSON.parse(localStorage.getItem("roles"))]

			let user_id = null;

			if (!users) {
				this.$router.push({ name: 'Login' })
				return;
			}

			if (roles.includes('admin')) {
				vm.loginToken = localStorage.getItem("user-info")
				user_id = localStorage.getItem("driver_id")
				vm.user_role = "driver"; // TODO: will admin always be a special type of Driver?
			} else if (roles.includes("driver")) {
				vm.user_role = "driver";
				user_id = localStorage.getItem("driver_id")
				vm.loginToken = localStorage.getItem("user-info")

			} else {
				vm.user_role = "customer";
				user_id = localStorage.getItem("customer_id")
				vm.loginToken = localStorage.getItem("user-info")

			}

			if(user_id == null) return;
			
			let header = {
				'Authorization': `Bearer ${vm.loginToken}`,
			}

			API.get(`/${vm.user_role}/${user_id}`, {
					headers: header
				}, )
				.then(function(response) {
					if (response.status == 200) {
						if (response.data.data.userId) {
							vm.profile_image = response.data.data.userId.picture;
						}
					}
				})
				.catch(function(error) {
					vm.$toast.open({
						message: utils.messageFromError(error),
						type: 'error',
						position: 'top'
					});
				});

		},

	}
}
</script>

<style scoped>
.navbar-photo {
    background-repeat: no-repeat;
    background-position: 50%;
	background-size: cover;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.version {
	cursor:help;
}
</style>