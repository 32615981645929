<template>
  <div class="base-timer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="46.5"
        />
        <path
          class="base-timer__path-remaining"
          :stroke-dasharray="circleDasharray"
          :style="interpolateStyle"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "></path>
      </g>
    </svg>
    <span class="base-timer__label">
      {{ formattedTimeLeft }}
    </span>
  </div>
</template>

<script>
const FULL_DASH_ARRAY = 283;

export default {
    name: 'TimerWidget',
    props: {
        time: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            timerInterval: null,
            timeLeft: 0,
        }
    },
    mounted() {
        this.timeLeft = this.time;
        var vm = this;
        this.timerInterval = window.setInterval(() => {
            vm.timeLeft = Math.max(0, vm.timeLeft-1);
        }, 1000);
    },
    watch: {
        timeLeft(newValue) {
            if (newValue === 0) {
                this.timeLeft = this.time;
                this.$emit('timeout');
            }
        }
    },
    computed: {
        formattedTimeLeft() {
            const timeLeft = this.timeLeft // The largest round integer less than or equal to the result of time divided being by 60.
            const minutes = Math.floor(timeLeft / 60) // Seconds are the remainder of the time divided by 60 (modulus operator)
            let seconds = timeLeft % 60 // If the value of seconds is less than 10, then display seconds with a leading zero

            if (seconds < 10) {
                seconds = `0${seconds}`
            } // The output in MM:SS format
            return `${minutes}:${seconds}`
        },
        // Update the dasharray value as time passes, starting with 283
        circleDasharray() {
            return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
        },    
        timeFraction() {
            const rawTimeFraction = this.timeLeft / this.time
            
            return rawTimeFraction -
                (1 / this.time) * (1 - rawTimeFraction)
        },
        interpolateStyle() {
            return this.timeLeft != this.time? {transition: "1s linear all"} : null;
        }
    }
}
</script>

<style scoped>
/* Sets the containers height and width */
div {
    width: 50px;
    height: 50px;  
    position: relative;
    font-size: 12px;
}
.base-timer__label { 
    width: 50px; /* must match div */
    height: 50px;  /* must match div */
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.base-timer {    
    /* Size should match the parent container */
    top: 0;    
    display: flex; /* Create a flexible box that centers content vertically and horizontally */    
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: black 0px 0px 5px, black 0px 0px 2px;
}
.base-timer__path-elapsed {
    stroke-width: 7px;
    stroke:grey;
}
.base-timer__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    fill-rule: nonzero;
    stroke:rgb(0, 255, 0);
}
.base-timer__circle {
    fill: none;
    stroke: none;
}/* The SVG path that displays the timer's progress */

.base-timer__svg { 
    transform: scaleX(-1);
}

</style>