<template>
    <Topnav />
  
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid" v-for="profile, in profileData" v-bind:key="profile._id" v-bind:id="profile._id">
    
                <div class="row mt-2 mb-4">
    
                    <div class="col-md-10"></div>
    
                    <div class="col-md-2">
    
                        <div class="drivers-btn">
    
                            <!--  <button type="button" class="btn btn-block btn-outline-secondary" style="padding: 0;color: #000000;border-color: #7c7c7c03;">Back</button> -->
    
                            <router-link :to="`/edit-driver-registration?id=${profile._id}`" class="btn btn-block btn-info" style="width: 50%;">Edit</router-link>
    
                        </div>
    
                    </div>
    
                </div>
    
                <div class="row">

                    <h2>Driver Account</h2>
    
                    <div class="col-md-12">
    
                        <div class="Customer-edit-form">
    
                            <div class="driver-image" v-if="profile.userId">
    
    
                                <img v-if="profile.userId.picture!=''" :src="`${API.baseURL}${profile.userId.picture}`" alt="" style="border-radius: 8px; width:150px; ">
    
                                <img v-else src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">
    
    
                            </div>
    
                            <div class="driver-image" v-else>
    
                                <img src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">
    
                            </div>
    
                        </div>
    
                    </div>
    
                    <div class="col-md-12 mt-4">
    
                        <div class="profile-data">
    
                            <div class="row" style="border-bottom: 1px solid #ededed;">
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Name</p>
    
                                        <h6>{{ profile.userId.first_name +" "+profile.userId.last_name }}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Date of Birth</p>
    
                                        <h6>{{ profile.date_of_birth }}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Work Cell</p>
    
                                        <h6>{{ profile.userId.phoneNo }}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>License</p>
    
                                        <h6>{{ profile.current_license_number}}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Home Address</p>
    
                                        <h6> {{ profile.home_address}}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Role</p>
    
                                        <h6>{{ profile.userId.roles[0] +","+profile.userId.roles[1] }}</h6>
    
                                    </div>
    
                                </div>
    
                            </div>
    
                            <div class="row">
    
                                <div class="col-md-12">
    
                                    <h6 class="driver-bank-info" style="margin-top: 2%;">Bank Information</h6>
    
                                </div>
    
                            </div>
    
                            <div class="row" style="border-bottom: 1px solid #ededed;">
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Name</p>
    
                                        <h6>{{ profile.bank_name}}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Route</p>
    
                                        <h6>{{ profile.routing_number}}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <p>Account</p>
    
                                        <h6>{{ profile.account_number}}</h6>
    
                                    </div>
    
                                </div>
    
                                <div class="col">
    
                                    <div class="profiles-data">
    
                                        <!--  <p>Home Address</p>
    
                      <h6> 1234 ST. Franklin TN</h6> -->
    
                                    </div>
    
                                </div>
    
                            </div>
    
                            <!-- {{profile.uploads}} -->
    
                            <div class="row" v-if="profile.uploads">
    
                                <div class="col-md-12" v-if="profile.uploads.attachmentUrls.length">
    
                                    <h6 class="driver-bank-info" style="margin-top: 2%;">Documents</h6>
    
                                    <div class="col-md-6">
    
                                        <div class="document-link">
    
                                            <a class="new-driver-registration-link">{{profile.uploads.attachmentUrls[0]}}</a>
    
                                            <a target="_blank" :href="`${API.baseURL}${profile.uploads.attachmentUrls[0]}`" class="btn btn-block btn-outline-secondary" style="color: #7c7c7c;border-color: #979797;font-size: 10px;font-weight: 500;text-transform: capitalize;padding: 10px 0px 10px 1px;width: 20%;margin-left: 2%;margin-right: 2%;"
    
                                                download>Download</a>
    
                                        </div>
    
                                    </div>
    
                                </div>
    
    
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
    
            </div>

    
        </div>
    
    </div>
</template>
<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
export default {
    name: 'Profile',
    data() {
        return {
            profileData: [],
            API: API,
        }
    },
    components: {
        Topnav,
        Sidebar
    },
    mounted() {
        const vm = this;

        let id = this.$route.query.id;
        vm.loginToken = localStorage.getItem("user-info")

        let header = {
            'Authorization': `Bearer ${vm.loginToken}`,
        }

        API.get(`/driver/${id}`, {
                headers: header
            }, )
            .then(function(response) {
                if (response.status == 200) {
                    vm.profileData.push(response.data.data);
                }
            })
            .catch(function(error) {
                //handle error here
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    vm.$toast.open({
                        message: error.response? error.response.data.error : error,
                        type: 'error',
                        position: 'top'
                    });

                }
            });
    },

}
</script>