<template>
    <div>
        <Nav />
        <div class="login-box" style="min-height:100vh;">

            <div class="container">

                <div class="row">

                    <div class="col-md-12">

                        <h1>Privacy Policy for WIT Courier, LLC.</h1>

                        <p>At WIT Courier, accessible from <a href="https://new.witcourier.com"
                                class="link">new.witcourier.com</a>, one of our main priorities is the privacy of our users.
                            This Privacy Policy document contains types of information that is collected and recorded by WIT
                            Courier and how we use it.</p>

                        <p>If you have additional questions or require more information about our Privacy Policy, do not
                            hesitate to contact us.</p>

                        <p>This Privacy Policy applies only to our online activities through the mobile app and is valid for
                            users to our website and app with regards to the information that they shared and/or collect in
                            WIT Courier. This policy is not applicable to any information collected

                            offline or via channels other than this website. </p>

                        <h2>Consent</h2>

                        <p>By using our website or mobile app, you hereby consent to our Privacy Policy and agree to its
                            terms.</p>

                        <h2>Information we collect</h2>

                        <p>The personal information that you are asked to provide, and the reasons why you are asked to
                            provide it, will be made clear to you at the point we ask you to provide your personal
                            information.</p>

                        <p>If you contact us directly, we may receive additional information about you such as your name,
                            email address, phone number, the contents of the message and/or attachments you may send us, and
                            any other information you may choose to provide.</p>

                        <p>When you register for an Account, we may ask for your contact information, including items such
                            as name, company name, address, email address, and telephone number.</p>

                        <h2>How we use your information</h2>

                        <p>We use the information we collect in various ways, including to:</p>

                        <ul>

                            <li>Provide, operate, and maintain our website</li>

                            <li>Improve, personalize, and expand our website</li>

                            <li>Understand and analyze how you use our website</li>

                            <li>Develop new products, services, features, and functionality</li>

                            <li>Communicate with you, either directly or through one of our partners, including for customer
                                service, to provide you with updates and other information relating to the website, and for
                                marketing and promotional purposes</li>

                            <li>Send you emails</li>

                            <li>Find and prevent fraud</li>

                            <li>For mobile, which is exclusive only to our employees on an invite-only basis, we use this
                                information to facilitate courier services to our customers and maintain business operations
                            </li>

                        </ul>

                        <h2>Log Files</h2>

                        <p>WIT Courier follows a standard procedure of using log files. These files log visitors when they
                            visit websites. All hosting companies do this and a part of hosting services' analytics. The
                            information collected by log files include internet

                            protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
                            referring/exit pages, and possibly the number of clicks. These are not linked to any information
                            that is personally identifiable. The purpose

                            of the information is for analyzing trends, administering the site, tracking users' movement on
                            the website, and gathering demographic information.</p>

                        <h2>Cookies and Web Beacons</h2>

                        <p>Like any other website, WIT Courier uses 'cookies'. These cookies are used to store information
                            including visitors' preferences, and the pages on the website that the visitor accessed or
                            visited. The information is used to optimize the

                            users' experience by customizing our web page content based on visitors' browser type and/or
                            other information.</p>

                        <p>For more general information on cookies, please read <a
                                href="https://www.generateprivacypolicy.com/#cookies">the Cookies article on Generate
                                Privacy Policy website</a>.</p>

                        <h2>Advertising Partners Privacy Policies</h2>

                        <p>You may consult this list to find the Privacy Policy for each of the advertising partners of WIT
                            Courier.</p>

                        <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons
                            that are used in their respective advertisements and links that appear on WIT Courier, which are
                            sent directly to users' browser. They automatically

                            receive your IP address when this occurs. These technologies are used to measure the
                            effectiveness of their advertising campaigns and/or to personalize the advertising content that
                            you see on websites that you visit.</p>

                        <p>Note that WIT Courier has no access to or control over these cookies that are used by third-party
                            advertisers.</p>

                        <h2>Third Party Privacy Policies</h2>

                        <p>WIT Courier's Privacy Policy does not apply to other advertisers or websites. Thus, we are
                            advising you to consult the respective Privacy Policies of these third-party ad servers for more
                            detailed information. It may include their practices

                            and instructions about how to opt-out of certain options.</p>

                        <p>You can choose to disable cookies through your individual browser options. To know more detailed
                            information about cookie management with specific web browsers, it can be found at the browsers'
                            respective websites.</p>

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'PrivacyPolicy',
    components: {
        Nav,
        Footer
    },
    data() {
        return {}
    },
    methods: {},
    mounted() {

    }
}
</script>

<style scoped>.link {
    color: rgb(4, 76, 209);
}

h1 {
    background: #000000;
    box-shadow: rgb(255, 255, 255) 1px 1px 1px;
    color: white;
}

h2 {
    background: #fffefa;
    box-shadow: black 1px 1px 1px;
}
</style>
