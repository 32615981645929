<template>
    <div>
        <button :class="button_class" @click.prevent="handleClick()">
            <slot name="title"></slot>
        </button>
        <div :class="content_class">
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Collapsible',
    data() {
        return {
            mutOpen: false
        }
    },
    watch: {
        open() {
            this.mutOpen = this.open;
        }
    },
    props: {
        open: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        button_class() {
            return "collapsible " + (this.mutOpen? "active" : "");
        },
        content_class() {
            return "content " + (this.mutOpen? "active" : "");
        }
    },
    methods: {
        handleClick() {
            this.mutOpen = !this.mutOpen;
        }
    }
}
</script>

<style scoped>
.collapsible {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  background-color: #33383e;
}
.collapsible:hover {
    background-color: #1d1f23;
}
.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
.content.active {
    margin-top: 1%;
    overflow: auto !important;
    max-height: 200px !important;
    scrollbar-color: white transparent;
}
.collapsible.active:after {
  content: "\2212";
}
.collapsible:after {
  content: '\002B';
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
</style>