<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid">
    
                <!-- 'toolbar' row -->
                <div class="row mt-2 mb-4 sticky-toolbar">
    
                    <div class="col-md-8 search">

                        <input type="text" placeholder="Search..." v-model="filter"/>

                        <i class="fa fa-search"></i>

                        <input type="date" id="from" v-model="searchDateRange.from" autocomplete="off">
                        -
                        <input type="date" id="to" v-model="searchDateRange.to" autocomplete="off">&nbsp;

                    </div>

                    <div class="col-md-2"></div>
    
                    <div class="col-md-2">
    
                        <div class="drivers-btn">
    
                            <button type="button" class="btn btn-block btn-info" data-toggle="modal" data-target="#myModal" @click="resetInviteForm">Invite Customer</button>
    
                        </div>
    
                    </div>

                </div>

                <!-- Pagination Top -->
                <div class="row mt-12 mb-12" v-show="!busy">
                    <div class="col-md-4 right-align"></div>
                    <div class="col-md-4" style="display: inline-flex;">
                        <Pagination v-show="mutCustomerslist.length > 0" :page="pagination.curr" :total="pagination.total" @click="fetchCustomerPage($event)"/>
                    </div>
                    <div class="col-md-4"></div>
                </div>
                <!-- /Pagination Top -->

                <div class="row mt-2 mb-4 sticky-toolbar">
                    <Busy class="center-screen" :busy="busy" text="Loading..."/>
                </div>

                <div class="row mt-4" v-if="!busy && Customerslist.length == 0">

                    <h4 style="text-align: center;width: 100%;"> No Results </h4>

                </div>
    
                <div class="row mt-4" v-for="customers, in mutCustomerslist" v-bind:key="customers._id">
    
                    <div class="col-lg-12 col-6">
    
                        <!-- small box -->
                        <div class="small-box" v-if="customers.userId.is_registered == false">
                            <div class="row" style="padding: 1rem;">
                                <div class="col-md-2">
                                    <img src="../assets/img/email-icon.svg" alt=" " style="width:30px; height:auto">
                                </div>

                                <div class="col-md-10">
                                Pending Invite to &nbsp;<i>{{ customers.userId.email }}</i>
                                </div>
                            </div>
                        </div>

                        <div class="small-box" v-else>
    
                            <div class="row" style="padding: 1rem;">
    
                                <div class="col-md-2">
    
    
                                    <div class="driver-image" v-if="customers.uploads">
    
                                        <!-- {{customers.uploads.photoUrls}} -->
    
                                        <div v-if="customers.userId">
    
                                            <img v-if="customers.userId.picture" :src="`${API.baseURL}${customers.userId.picture}`" alt="" style="width:150px; ">
    
                                            <img v-else src="../assets/img/profile.png" alt="" style="width: 150px;">
    
                                        </div>
    
                                        <div v-else>
    
                                            <img src="../assets/img/profile.png" alt="" style="width: 150px;">
    
                                        </div>
    
    
    
                                    </div>
    
                                    <div class="driver-image" v-else>
    
                                        <img src="../assets/img/profile.png" alt="" style="width: 150px;">
    
                                    </div>
    
                                </div>
    
                                <div class="col-md-3 drive-info">
    
                                    <div class="inner">
    
                                        <h5>{{ customers.name? customers.name : `(Invite Pending) ${customers.userId.email}` }}</h5>
    
                                        <div class="driver-address">
    
                                            <img src="../assets/img/address-icon.svg" alt="" style="margin-bottom: 1rem;">
    
                                            <p style="margin-bottom: 0;margin-left: 2%;font-size: 10px;">{{ customers.address}}</p>
    
                                        </div>
    
                                    </div>
    
                                </div>
    
                                <div class="col-md-7 drive-info">
    
                                    <div class="row">
    
                                        <div class="col-md-4">
    
                                            <div class="inner drive-bios">
    
                                                <div class="drivers-certificate" style="margin-top: 0rem;">
    
                                                    <img src="../assets/img/address_book_contact_contacts_human_icon.svg" alt="" style="width: 10%;">
    
                                                    <h5 style="margin-top: 10px; font-size: 10px;">
    
                                                        <a v-if="customers.departments!=''" href="#" data-toggle="modal" :data-target="`#largeModal${customers._id}`">Departments</a>
    
                                                        <a style="margin-top: 10px; font-size: 10px;" v-if="customers.departments==''">Departments</a>
    
                                                    </h5>
    
                                                    <span v-if="customers.departments!=null" class="Certifications-number" style="padding: 2px;font-size: 12px;margin-top: 5px;width: 22px;height: 22px;">{{ customers.departments.length}}</span>
    
                                                    <span v-else class="Certifications-number" style="padding: 2px;font-size: 12px;margin-top: 5px;width: 22px;height: 22px;">0</span>
    
                                                </div>
    
                                            </div>
    
                                        </div>
    
                                        <div class="col-md-3">
    
                                            <div class="inner drive-bio">
    
                                                <p>Work Cell</p>
    
                                                <h6 style="font-size:11px; font-weight: 600;" v-if="customers.userId!=null">{{ customers.userId.phoneNo}}</h6>
    
                                            </div>
    
                                        </div>
    
                                        <div class="col-md-2">
    
                                            <div class="inner drive-bio">
    
                                                <p>Email</p>
    
                                                <h6 style="font-size:11px; font-weight: 600;" v-if="customers.userId!=null">{{ customers.userId.email}}</h6>
    
                                            </div>
    
                                        </div>
    
                                        <div class="col-md-3">
    
                                            <div class="btn-group dropleft">
    
                                                <a class="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    
                                                  <img src="../assets/img/select-edit-icon.svg" alt=" " style="width: 65%;">
                        
                                                </a>
    
                                                <div class="dropdown-menu">
    
                                                    <div class="jobs-edits">
    
                                                        <ul class="jobs-lists">
    
                                                            <router-link :to="`edit-customer-registration?id=${customers._id}`">
                                                                
                                                                <li class="jobs-lists-add action-menu-item">
        
                                                                    Edit
        
                                                                </li>
                                                            
                                                            </router-link>
    
                                                            <a class="del_driver" data-toggle="modal" :data-target="`#deactivateCustomer${customers._id}`">
                                                                
                                                                <li class="jobs-lists-add action-menu-item">
                                                                    
                                                                    Deactivate
                                                                    
                                                                </li>

                                                            </a>
                                                        </ul>
    
                                                    </div>
    
                                                </div>
    
                                            </div>
    
                                        </div>
    
                                    </div>
    
                                </div>
    
                            </div>
    
                        </div>
    
                    </div>
    
                    <!-- Department Modal -->
    
                    <div class="modal" :id="`largeModal${customers._id}`" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
    
                        <div class="modal-dialog">
    
                            <div class="modal-content">
    
                                <div class="modal-body">
    
                                    <!-- carousel -->
    
                                    <div :id="`carouselExampleIndicators${customers._id}`" class='carousel slide' data-ride='carousel'>
    
                                        <div class='carousel-inner'>
    
                                            <div class='carousel-item' :class="index==0?' active':''" v-for="(depts,index) in customers.departments" v-bind:key="depts._id" v-bind:index="index">
    
                                                <div class="modal-content" style="height: 570px;">
    
                                                    <div class="row task-popup-box" style="padding: 0 40px 70px 40px;">
    
                                                        <div class="header-modal-popup">
    
                                                            <h4 class="modal-title" style="font-size: 24px;font-weight: 600;/* padding: 16px 40px 16px 40px; */margin: auto;margin-top: 3%;">Department {{index+1}}</h4>
    
                                                            <button type="button" class="close" data-dismiss="modal" style="width: 20px;height: 20px;border: 2px solid black;/* padding-left: 16px; */border-radius: 50px;margin-top: 3%;">×</button>
    
                                                        </div>
    
                                                        <div class="col-md-12 task-data" style="margin-top: 5%;">
    
                                                            <p>Department Name</p>
    
                                                            <div class="mb-2 task-popup-heading-task">
    
                                                                <h6>{{depts.departmentName}}</h6>
    
                                                            </div>
    
                                                        </div>
    
                                                        <div class="col-md-12 mt-2 task-data">
    
                                                            <p>Address</p>
    
                                                            <div class="mb-2 task-popup-heading-task">
    
                                                                <h6>{{depts.departmentAddress}}</h6>
    
                                                            </div>
    
                                                        </div>
    
                                                        <div class="col-md-12 mt-2 task-data">
    
                                                            <p>Work Cell</p>
    
                                                            <div class="mb-2 task-popup-heading-task">
    
                                                                <h6>{{depts.departmentPhoneNo}}</h6>
    
                                                            </div>
    
                                                        </div>
    
                                                        <div class="col-md-12 mt-2 task-data">
    
                                                            <p>Email</p>
    
                                                            <div class="mb-2 task-popup-heading-task">
    
                                                                <h6>{{depts.departmentEmail}}</h6>
    
                                                            </div>
    
                                                        </div>
    
                                                        <div class="col-md-12 mt-2">
    
                                                            <p style=" font-size: 12px;font-weight: 300;">Proof Of Delivery <img style="width: 10px; height: auto;" src="../assets/img/check.png"></p>

                                                            <div class="mb-2 task-popup-heading-task">
                                                                <h6 v-if="depts.requiresPhotoSignature">This department requires photos and signatures.</h6>
                                                                                                                           
                                                                <h6 v-else-if="customers.requiresPhotoSignature">{{customers.name}} requires photos and signatures for all departments.</h6>

                                                                <h6 v-else>No.</h6>

                                                            </div>
    
                                                        </div>
    
                                                    </div>
    
                                                </div>
    
                                            </div>
    
                                        </div>
    
                                        <div class="arrows">
    
                                            <a class='carousel-control-prev' :data-target="`#carouselExampleIndicators${customers._id}`" role='button' data-slide='prev'>
    
                                              <span class='carousel-control-prev-icon'
                                
                                                    aria-hidden='true'
                                
                                                    ></span>
                                
                                              <span class='sr-only'>Previous</span>
                                
                                            </a>
    
                                            <a class='carousel-control-next' :data-target="`#carouselExampleIndicators${customers._id}`" role='button' data-slide='next'>
    
                                              <span
                                
                                                    class='carousel-control-next-icon'
                                
                                                    aria-hidden='true'
                                
                                                    ></span>
                                
                                              <span class='sr-only'>Next</span>
                                
                                            </a>
    
                                        </div>
    
                                    </div>
    
                                </div>
    
                            </div>
    
                        </div>
    
                    </div>
    
                    <!-- Deactivate Modal -->
    
                    <div class="modal" :id="`deactivateCustomer${customers._id}`">
    
                        <div class="modal-dialog">
    
                            <div class="modal-content">
    
                                <!-- Modal Header -->
    
                                <div class="modal-header">
    
                                    <h4 class="modal-title" style="font-size: 20px;">Deactivate Customer</h4>
    
                                    <button type="button" class="close mt-3" data-dismiss="modal">&times;</button>
    
                                </div>
    
                                <!-- Modal body -->
    
                                <div class="modal-body pl-3">
    
                                    Are you sure you want to deactivate this customer?
    
                                </div>
    
                                <!-- Modal footer -->
    
                                <div class="modal-footer">
    
                                    <button type="button" class="btn btn-success mr-3" :id="`${customers._id}`" v-on:click='deactivateCustomer(customers._id)' data-dismiss="modal">Yes</button>
    
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
    
                                </div>
    
                            </div>
    
                        </div>
    
                    </div>
    
                    <!-- End Delete Modal -->
    
                </div>

                <!-- Pagination Bottom -->
                <div class="row mt-12 mb-12" v-show="!busy">
                    <div class="col-md-4 right-align"></div>
                    <div class="col-md-4" style="display: inline-flex;">
                        <Pagination v-show="mutCustomerslist.length > 0" :page="pagination.curr" :total="pagination.total" @click="fetchCustomerPage($event)"/>
                    </div>
                    <div class="col-md-4"></div>
                </div>
                <!-- /Pagination Bottom -->
    
            </div>
    
        </div>
    
    </div>
    
    <div class="modal" id="myModal">
    
        <div class="modal-dialog" style="max-width: 450px;">
    
            <div class="modal-content">
  
                <!-- Modal Header -->
    
                <div class="modal-header" style="padding-left: 2rem; padding-right: 2rem;">
    
                    <h4 class="modal-title" style="font-size: 24px;font-weight: 600;">Invite Customer</h4>
    
                    <button type="button" class="close" data-dismiss="modal" style="margin-top: 2px; margin-left: 10px;">&times;</button>
    
                </div>
    
                <!-- Modal body -->
    
                <form @submit.prevent="inviteCustomer">
    
                    <div class="modal-body">
    
                        <div class="form-group" style="padding-left: 2rem; padding-right: 2rem;">
    
                            <input type="email" 
                            class="form-control" 
                            id="exampleInputEmail1" 
                            v-model="email" 
                            aria-describedby="emailHelp" 
                            placeholder="Email" 
                            style="padding: 3%;height: calc(3rem + 0px);"
                            autocomplete="off"
                            required>

                            <span>
                                <small>
                                    <input type="checkbox" v-model="dontSendEmail" class="custom-switch"/>&nbsp;Check this box to prevent email notification.
                                </small>
                            </span>
    
                        </div>
    
                        <p class="error-msg"> {{error}} </p>
    
                    </div>
    
    
                    <!-- Modal footer -->
    
                    <div class="modal-footer" style="margin-top: -4%;margin-bottom: 6%;padding-left: 2rem;padding-right: 2rem;">
    
                        <button 
                        type="submit" 
                        class="btn btn-block btn-info" 
                        style="width: 100%; padding: 2%; font-size: 16px;" 
                        :disabled="show && url">{{(show && url)? "Invite Sent!" : "Send"}}
                        </button>
                        
                    </div>
    
                </form>
    
                <div class="" v-show="show">
    
                    <div class="modal-body" style="margin-top: -7%;padding-left: 2rem;padding-right: 2rem;">
    
                        <form>
    
                            <div class="form-group">
    
                                <label for="magic-link" style="color: #000; font-size: 13px;">Web Magic Link</label>
    
                                <input disabled type="text" v-model="url" id="magic-link" class="form-control" aria-describedby="emailHelp" placeholder="">
    
                                <button @click.prevent="copylink(this.url)" 
                                type="submit" 
                                class="btn btn-primary btn-block contact-submit" 
                                style="width: 100%;padding: 2%;font-size: 12px;">
                                    <img src="@/assets/img/copy_document_paper_file_multimedia_icon.svg" 
                                    alt="" 
                                    style="margin-right: 2%;width: 5%;">

                                    Copy to Clipboard

                                </button>

                                <br/>
    
                                <label for="magic-link" style="color: #000; font-size: 13px;">Mobile App Magic Link</label>
    
                                <input disabled type="text" v-model="urlMobile" id="magic-link" class="form-control" aria-describedby="emailHelp" placeholder="">
    
                                <button @click.prevent="copylink(this.urlMobile)" 
                                type="submit" 
                                class="btn btn-primary btn-block contact-submit" 
                                style="width: 100%;padding: 2%;font-size: 12px;">
                                    <img src="@/assets/img/copy_document_paper_file_multimedia_icon.svg" 
                                    alt="" 
                                    style="margin-right: 2%;width: 5%;">

                                    Copy to Clipboard

                                </button>
                            </div>
    
                        </form>
    
                    </div>
    
                    <!-- Modal footer -->
    
                    <div class="modal-footer" style="margin-top: -4%;margin-bottom: 6%;padding-left: 2rem; padding-right: 2rem;">
    
                        <!-- nothing for now -->

                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>

</template>
<script>
import Pagination from '@/components/Pagination.vue'
import Busy from '@/components/Busy.vue'
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
import utils from '../utils';
import "@/assets/plugins/bootstrap/js/bootstrap.bundle.min.js"
export default {
    name: 'CustomerAddressBook',
    data() {
        return {
            Customerslist: [],
            pagination: {
                curr: 1,
                total: 1,
                limit: 10,
            },
            searchDateRange: {
                from: null,
                to: null
            },
            email: '',
            show: false,
            url: '',
            error: '',
            urlMobile: '',
            roles: '',
            dontSendEmail: false,
            filter: '',
            API: API,
            busy: true,
        }
    },
    components: {
        Pagination,
        Topnav,
        Sidebar,
        Busy
    },
    computed: {
        mutCustomerslist() {
            return this.Customerslist.filter(this.filterCustomerData).filter(this.filterCustomerByDateRange)
        },
    },
    async mounted() {
        await this.getNextCustomers()
    },
    methods: {
        resetInviteForm() {
            this.url = null;
            this.urlMobile = null;
            this.show = false;
            this.email = '';
        },

        fetchCustomerPage(page) {
            this.pagination.curr = page
            this.getNextCustomers()
        },

        async getNextCustomers() {
            this.Customerslist = [] // clear

            this.roles = [...JSON.parse(localStorage.getItem("roles"))];

            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }

            this.loginToken = localStorage.getItem("user-info");

            this.busy = true

            try {
                const path = utils.pagination('/customer', this.pagination.curr, this.pagination.limit)
                const response = await API.get(path, {
                    headers: {
                        Authorization: `Bearer ${this.loginToken}`
                    }
                })

                if (response.status == 200) {
                    this.Customerslist = response.data.data
                    this.Customerslist.sort((a, b) => b.userId.is_registered - a.userId.is_registered)
                    this.pagination.total = response.data.pages
                }
            } catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            }

            this.busy = false
        },

        async inviteCustomer() {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${this.loginToken}`,
                'content-type': 'application/json',
            }
            await API.post('/MagicLink', {
                    recipientEmail: vm.email,
                    magicLinkType: 'customer',
                    sendEmailNotification: !vm.dontSendEmail
                }, {
                    headers: header
                })
                .then(function(response) {
                    if (response.status == 200) {
                        vm.show = true;
                        vm.generateMagicLink(response.data.data.magicToken);
                    }
                })
                .catch(function(error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();

                    } else {
                        vm.$toast.open({
                            message: error.response.data.error,
                            type: 'error',
                            position: 'top'
                        });

                    }
                });


        },
        generateMagicLink(magicToken) {
            this.url = `${window.origin}/#/magic-link?token=${magicToken}`
            this.urlMobile = `wit://app.witcourier.com/${magicToken}`
        },

        async copylink(url) {
            if(await utils.copyToClipboard(url)) {
                this.$toast.open({
                    message: 'Login link copied to clipboard!',
                    type: 'success',
                    position: 'bottom-left'
                });
            } else {
                this.$toast.open({
                    message: 'Browser could not copy to clipboard.',
                    type: 'info',
                    position: 'bottom-left'
                });
            }
        },

        async deactivateCustomer(customer_id) {
            const vm = this;

            API.delete(`/customer/${customer_id}`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    },

                })
                .then(function(response) {
                    if (response.status == 200) {
                        vm.$toast.success('Customer deactivated successfully.', {
                            // override the global option
                            position: 'top'
                        })
                        location.reload();
                    }
                })
                .catch(function(error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();

                    } else {
                        vm.$toast.open({
                            message: error.response.data.error,
                            type: 'error',
                            position: 'top'
                        });

                    }
                });

        },

        filterCustomerByDateRange(data) {
            const from = this.searchDateRange.from
            const to = this.searchDateRange.to

            if(!data) return false;
            if(!from || !to) return true;``

            const createdAt = Date.parse(data.createdAt)
            const dateFrom = Date.parse(from)

            // `from` will begin at the first minute of the day
            // `to` will extend to the last possible hours and minute of the day
            const dateTo = Date.parse(to) + 86400000

            return (dateFrom <= createdAt) && createdAt <= dateTo;
        },

        filterCustomerData(data) {
            if(this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim()+".*", 'i');
                for(const [key, value] of Object.entries(data)) {
                    if(key == "userId") { /* special case, filter user data too */
                        if(this.filterCustomerData(value)) { return true; }
                        continue;
                    }

                    if(regex.test(value)) {
                        return true;
                    }
                }
                
                return false;
            }

            return true;
        }
    }

}
</script>

<style>
.action-menu-item {
    color: #000;
}
.action-menu-item:hover {
    background-color: rgba(202, 225, 255, 0.81);
}
.error-msg {
    color: red;
}

.dropleft:hover {
    cursor:pointer;
}

.sticky-toolbar {
    width: 100%;
    position: sticky;
    top: 10px;
    z-index: 1000;
}

.center-screen {
    text-align: center; 
    width: 100%;
    position: absolute;
}

div.search i {
    left: -30px;
    position: relative;
}
</style>
