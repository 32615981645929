<template>
    <!-- show customer logo at the top of this form -->
    <div v-if="(isUserCustomerRole || logo)" style="text-align:center;">

        <img v-if="logoUrl" :src="`${API.baseURL}${logoUrl}`" alt="" style="width:200px;">

    </div>

    <!-- Force Job Complete Form -->
    <Transition>
        <div v-show="forceJobCompleteState">
            <ForceJobComplete :jobId="jobId" :tasks="tasks" :driverUserId="assignedDriverUser"
                @cancel="forceJobCompleteState = false" @error="handleError" @success="handleTaskDataChanged" />

        </div>
    </Transition>

    <Transition>
        <form class="form-slide" @submit.prevent="busy = true" v-show="!forceJobCompleteState">

            <div class="row mt-2 mb-4">

                <div class="col-md-10">
                    <Busy :busy="loading" text="Loading..." />
                </div>

                <div class="col-md-2">

                    <div class="drivers-btn">
                        <a v-show="canGoBack" title="Return to the previous page" class="btn btn-block"
                            style="padding: 0;color: #000000;border-color: #7c7c7c03;"
                            @click.prevent="$emit('back')">Back</a>
                        <SpinButton v-if="edit" title="Submit changes to job form" class="btn btn-block btn-info"
                            @click="handleSubmit" :spin="busy">SUBMIT</SpinButton>
                        <SpinButton v-if="!isNewJob && !ogJob.completedAt && edit"
                            title="Complete job with required materials" type="button" class="btn btn-block btn-success"
                            @click="handleJobCompleteRequest" :spin="busy">COMPLETE</SpinButton>
                        <button v-if="!isNewJob" title="Copy job link to clipboard" type="button"
                            class="btn btn-block btn-warning" style="hover:pointer;"
                            @click.prevent="copyJobLink()">SHARE</button>
                    </div>

                </div>

            </div>

            <div class="row">

                <div class="col-md-12 mt-4">

                    <div class="form-row">

                        <div class="form-item col-md-6">

                            <select class="form-select" name="chargeType" v-model="chargeType" id="Service" required
                                :disabled="isUserCustomerRole || !edit">

                                <option value="" disabled hidden selected>Please Select</option>

                                <option v-for="option in allchargeTypes" :value="option._id" :key="option._id">{{
                                    option.name }}</option>

                            </select>

                            <label class="selectOptions" for="Service" style="background: #f4f6f9;">Service
                                {{ isUserCustomerRole ? "(Locked-In)" : "" }}</label>

                        </div>

                        <div class="col-md-12 mb-4">

                            <h6>Bill To</h6>

                        </div>

                        <div class="form-item col-md-6">

                            <select class="form-select" id="Customer" @change.prevent="getCustomerbyID($event)"
                                v-model="customer" required :disabled="isUserCustomerRole || !edit">

                                <option value="" disabled hidden selected v-if="isUserCustomerRole">Please Select</option>

                                <option v-for="option in allcustomers" :value="option._id" :key="option._id">{{ option.name
                                }}</option>

                            </select>

                            <label class="selectOptions" for="Customer" style="background: #f4f6f9;">Customer
                                {{ isUserCustomerRole ? "(Locked-In)" : "" }}</label>

                        </div>

                        <div class="form-item col-md-6" v-show="false">

                            <input type="tel" id="phoneNo" v-model="phoneNumber">

                            <label for="phoneNo" style="background: #f4f6f9;">Phone Number</label>

                        </div>

                        <div class="form-item col-md-6" v-show="false">

                            <input type="text" id="customerAddress" v-model="customer_address" required>

                            <label for="customerAddress" style="background: #f4f6f9;">Address</label>

                        </div>

                        <div class="form-item col-md-6">

                            <select class="form-select" id="Department" v-model="department" autocomplete="off"
                                @change.prevent="getDepartmentbyID($event)" :disabled="isUserDepartmentRole || !edit">

                                <option value="" disabled selected hidden>Please Select</option>

                                <option v-for="dept in customer_dept_facility" :value="dept._id" :key="dept._id">{{
                                    dept.departmentName }}</option>

                            </select>

                            <label class="selectOptions" for="Department" style="background: #f4f6f9;">Department
                                {{ isUserDepartmentRole ? "(Locked-In)" : "" }}</label>

                        </div>

                        <div v-if="customer != null">
                            <div class="row" v-for="(input, k) in tasks" :key="k">

                                <div class="col-md-6 mb-4">

                                    <h6>Tasks</h6>
                                    <section v-if="input._id">
                                        <b>ID</b>&nbsp;
                                        <small>{{ input._id }}</small>
                                        <span v-if="input.checked_into_location">
                                            <br />
                                            <img src="../assets/img/check.svg" class="icon" />&nbsp;
                                            <small class="time">Checked in {{ new
                                                Date(input.checked_into_location).toLocaleString('en-US', {
                                                    dateStyle:
                                                        "medium", timeStyle: "long", hour12: true
                                                }) }}</small>
                                        </span>
                                        <span v-if="input.completedAt">
                                            <br />
                                            <img src="../assets/img/clock.svg" class="icon" />&nbsp;
                                            <small class="time">Completed {{ new
                                                Date(input.completedAt).toLocaleString('en-US', {
                                                    dateStyle: "medium",
                                                    timeStyle: "long", hour12: true
                                                }) }}</small>
                                        </span>
                                        <span v-else>
                                            <br />
                                            <img src="../assets/img/clock.svg" class="icon" />&nbsp;
                                            <small class="time">Updated {{ new Date(input.updatedAt).toLocaleString('en-US',
                                                { dateStyle: "medium", timeStyle: "long", hour12: true }) }}</small>
                                        </span>

                                        <!-- Post delivery notes -->
                                        <span>
                                            <br />
                                            <img src="../assets/img/edit_icon.svg" class="icon" />&nbsp;
                                            <small>
                                                Driver's Notes: <a href="#"
                                                    @click.prevent="openPostDeliveryNotesModal(input._id, input.postDeliveryNotes)">{{
                                                        input.postDeliveryNotes
                                                        ? "Available" : "None" }}</a>
                                            </small>
                                        </span>
                                    </section>
                                    <i v-else>Unsaved</i>
                                    <p class="error-msg" style="text-align: left;" v-show="input.errorMessage">
                                        {{ input.errorMessage }}</p>
                                    <img src="../assets/img/attachment-clip.svg" class="icon" />&nbsp;
                                    <small class="attachments" @click="openAttachmentsModal(input.upload)">{{
                                        countAttachments(input.upload) }}
                                        attachments</small>

                                </div>

                                <div class="col-md-6  mb-4 department">

                                    <div class="btn-group btn-toggle">

                                        <div class="boxed" style="margin-right: 20px;">

                                            Is Pickup <input type="checkbox" v-model="input.isPickup" name="isPickup"
                                                data-bootstrap-switch data-off-color="danger" data-on-color="success"
                                                style="margin-top: 5px;width: 35px;height: 16px;">

                                        </div>

                                    </div>

                                    <img v-if="edit" src="../assets/img/Add-file-icon.svg" alt=""
                                        style="width: 5%;margin-right: 10px; cursor:pointer;" @click="add(k)"
                                        v-show="k == tasks.length - 1">

                                    <img v-if="edit" @click="remove(k)" v-show="k" src="../assets/img/delete-icon.svg"
                                        alt="" style="width:3%; cursor: pointer;">

                                </div>

                                <div :class="`form-item col-md-6`">

                                    <input :required="!isUserDriverRole" type="text" id="facility" v-model="input.facility"
                                        autocomplete="off" v-on:keyup="taskNickname(k, $event)">

                                    <label for="contact" style="background: #f4f6f9;">Facility Name
                                        {{ isUserDriverRole ? "(Optional)" : "(Required)" }}</label>

                                    <ul v-show="Facility_isOpen[k + 1]" class="autocomplete-results">

                                        <li v-for="(obj, i) in TaskFacilityNicks[k]" :key="i" class="autocomplete-result"
                                            @click="setNicknameResultTasks(obj, k)">
                                            {{ obj.nickname }}
                                        </li>

                                    </ul>
                                </div>

                                <div class="form-item col-md-6 ">

                                    <input type="text" id="address" v-model="input.address" autocomplete="off" required
                                        v-on:keyup="taskAddress(k, $event)">

                                    <label class="selectOptions" for="address" style="background: #f4f6f9;">Address</label>

                                    <ul v-show="isOpen[k + 1]" class="autocomplete-results">

                                        <li v-for="(result, i) in TaskAddress[k]" :key="i"
                                            @click="setResultTasks(`${result.address}`, k)" class="autocomplete-result">
                                            {{ result.address }}</li>

                                    </ul>

                                </div>

                                <div class="form-item col-md-6 ">

                                    <input :required="!isUserDriverRole" type="tel" id="contact" name="contact"
                                        class="contacts" v-model="input.phoneNumber" minlength="10" maxlength="10"
                                        autocomplete="off" @keypress="onlyNumber($event)">

                                    <label for="contact" style="background: #f4f6f9;">Contact Number
                                        {{ isUserDriverRole ? "(Optional)" : "(Required)" }}</label>

                                </div>

                                <div class="form-item col-md-12 ">

                                    <textarea id="comments" v-model="input.extraNotes" autocomplete="off"></textarea>

                                    <label for="comments" style="background: #f4f6f9;">Notes (Optional)</label>

                                </div>

                            </div>
                        </div>

                        <div v-if="canRoleAssignDriver && assign && (edit || create)" class="col-md-12">

                            <div class="col-md-12">

                                <h6 style="width: 30%; display: inline">Assign Driver
                                    <input type="checkbox" class="show_onlineDrivers" v-on:click="assignDriver"
                                        style="width: 35px;height: 15px; cursor:pointer;">
                                </h6>

                                <a href="" :class="`${show}`" title="Refresh on-duty driver list"
                                    @click.prevent="onlineDriversList">Refresh</a>

                            </div>

                            <div :class="`col-md-6 mt-4 signature ${show}`" v-if="onlineDrivers.length == 0">

                                <h6>No On-Duty Drivers Available</h6>

                            </div>

                            <div :class="`col-md-6 mt-4 signature ${show}`" v-for="assign in onlineDrivers"
                                v-bind:key="assign._id" v-bind:id="assign._id">

                                <div class="col-md-2 driver-image" v-if="assign.userId">

                                    <img v-if="assign.userId.picture" :src="`${API.baseURL}${assign.userId.picture}`" alt=""
                                        style="border-radius: 8px; width:30px; margin-top: 10px;">

                                    <img v-else src="../assets/img/profile.png" alt=""
                                        style="border-radius: 8px;width: 30px;margin-top: 10px;">

                                </div>

                                <div class="col-md-2 driver-image" v-else>

                                    <img src="../assets/img/profile.png" alt=""
                                        style="border-radius: 8px;width: 30px;margin-top: 10px;">

                                </div>

                                <div class="col-md-5">

                                    <h6 class="add-job-drives">{{ getDriverName(assign) }}</h6>

                                </div>

                                <div class="col-md-4">

                                    <input :id="`Assign${assign._id}`" type="radio" name="Driver"
                                        :checked="Driver ? Driver._id == assign._id : false" :value="assign"
                                        v-model="Driver" class="btn btn-block btn-outline-secondary"
                                        style="margin: 20px 10px 0px;">

                                    <label :for="`Assign${assign._id}`" style="margin: 15px 0px 0px;">Assign</label>

                                </div>

                            </div>

                        </div>

                        <!-- if readonly, show assigned driver -->
                        <div v-if="(!edit || !canRoleAssignDriver) && !create" class="col-md-6">

                            <div class="col-md-12">

                                <h6>Driver</h6>

                            </div>

                            <div class="col-md-6 mt-4 signature" v-if="!Driver">

                                <h6>No Driver Available</h6>

                            </div>

                            <div class="col-md-6 mt-4 signature" v-else>

                                <div class="col-md-2 driver-image" v-if="Driver.userId">

                                    <img v-if="Driver.userId.picture" :src="`${API.baseURL}${Driver.userId.picture}`" alt=""
                                        style="border-radius: 8px; width:30px; margin-top: 10px;">

                                    <img v-else src="../assets/img/profile.png" alt=""
                                        style="border-radius: 8px;width: 30px;margin-top: 10px;">

                                </div>

                                <div class="col-md-2 driver-image" v-else>

                                    <img src="../assets/img/profile.png" alt=""
                                        style="border-radius: 8px;width: 30px;margin-top: 10px;">

                                </div>

                                <div class="col-md-5">

                                    <h6 class="add-job-drives">{{ getDriverName(Driver) }}</h6>

                                </div>

                            </div>

                        </div>

                        <div class="col-md-6" v-if="pastJobId != null">

                            <div class="col-md-12">
                                <h6>Driver Route Preview</h6><br />
                                <div>
                                    <img src="../assets/img/task-address-icon-only.svg" class="icon" />&nbsp;
                                    <a href="#" @click.prevent="handleDriverRoutePreviewClicked">Click to open in a new
                                        window</a>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>

            </div>

        </form>
    </Transition>

    <!-- backdrop for any modal -->
    <div class="modal-backdrop show" :style="modalBackdrop.style"></div>

    <!-- view attachments modal -->
    <div class="modal" :style="attachmentsModal.style">

        <div class="modal-dialog" style=" max-width: 600px;">

            <div class="modal-content">

                <div class="modal-header">

                    <h4 class="modal-title" style="font-size: 24px;font-weight: 600;">Attachments</h4>

                </div>

                <form @submit.prevent="">
                    <div class="modal-body">
                        <div class="container">
                            <div class="row mt-4 mb-2" v-for="(a, index) in allTaskAttachments" v-bind:key="a"
                                style="padding-left: 2rem;">
                                <a :href="`${API.baseURL}${a}`">View Upload {{ index + 1 }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 footer-btns mt-4">

                        <div class="modal-footer" style="margin-top: -2%;margin-bottom: 4%; justify-content: center;">

                            <button type="button" class="btn btn-primary btn-block" style="font-size: 12px;margin-left: 1%;"
                                @click="closeAttachmentsModal">CLOSE</button>

                        </div>

                    </div>

                </form>

            </div>

        </div>

    </div>

    <!-- post-delivery notes modal -->
    <div class="modal" :style="postDeliveryNotesModal.style">

        <div class="modal-dialog" style=" max-width: 600px;">

            <div class="modal-content">

                <div class="modal-header">

                    <h4 class="modal-title" style="font-size: 24px;font-weight: 600;">Driver's Notes</h4>

                </div>

                <form @submit.prevent="">
                    <div class="modal-body">
                        <div class="container">
                            <div class="row mt-4 mb-2" style="padding-left: 2rem;">
                                <span v-if="postDeliveryNotesModal.isEditing == false">
                                    {{ postDeliveryNotesModal.notes ? postDeliveryNotesModal.notes : "(Empty)" }}
                                </span>
                                <textarea v-else v-model="postDeliveryNotesModal.notes">
                                </textarea>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 footer-btns mt-4">

                        <div class="modal-footer" style="margin-top: -2%;margin-bottom: 4%; justify-content: right;">
                            <div class="row mt-4 mb-2" style="padding-left: 2rem;">
                                <button v-if="postDeliveryNotesModal.isEditing" type="button"
                                    class="btn btn-warning btn-block" style="font-size: 12px;margin-left: 1%;"
                                    @click.prevent="handlePostDeliveryNotesSaveClicked()">SAVE</button>
                                <button v-else type="button" class="btn btn-warning btn-block"
                                    style="font-size: 12px;margin-left: 1%;"
                                    @click.prevent="handlePostDeliveryNotesEditClicked()">EDIT</button>

                                <button type="button" class="btn btn-primary btn-block"
                                    style="font-size: 12px;margin-left: 1%;"
                                    @click="closePostDeliveryNotesModal">CLOSE</button>
                            </div>

                        </div>

                    </div>

                </form>

            </div>

        </div>

    </div>
</template>

<script>
import SpinButton from '@/components/SpinButton.vue'
import Busy from '@/components/Busy.vue'
import ForceJobComplete from '@/components/ForceJobComplete.vue'
import API from '../axios.config'
import $ from 'jquery'
import eznavigator from '../eznavigator'
import utils from '../utils'

export default {
    name: 'JobForm',
    components: {
        SpinButton,
        ForceJobComplete,
        Busy,
    },
    props: {
        jobId: {
            type: String,
            default: null,
        },
        queuedJobId: {
            type: String,
            default: null,
        },
        pastJobId: {
            type: String,
            default: null,
        },
        edit: {
            type: Boolean,
            default: false
        },
        create: {
            type: Boolean,
            default: false
        },
        assign: {
            type: Boolean,
            default: false,
        },
        logo: {
            type: Boolean,
            default: false
        },
        canGoBack: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            forceJobCompleteState: false,
            readonly: false, // toggled to true if `edit` is true and form is filled
            busy: false,
            show: 'd-none',
            facility_show: [],
            depart_show: [],
            chargeType: '',
            estimatedArrival: '',
            customer: null, // id ??
            department: null, // id ??
            Driver: null,
            taskIds: [],
            tasks: [{
                customerID: null,
                address: '',
                contact: '',
                extraNotes: '',
                facility: '',
                department: null,
                isPickup: false,
                addressLocation: [],
                checked_into_location: null,
                upload: null,
                requiresPhotoSignature: false,
                status: 'INVALID'
            }],
            droppedTasks: [],
            allchargeTypes: [],
            allcustomers: [],
            selected: [],
            Tselected: [],
            selectedDept: [],
            TaskDeptselected: [],
            customer_address: '',
            phoneNumber: '',
            onlineDrivers: [],
            customer_dept: [],
            customer_dept_facility: [],
            facility: '',
            TaskAddress: [],
            TaskFacilityNicks: [],
            isOpen: [],
            Facility_isOpen: [],
            API: API,
            canRoleAssignDriver: false,
            myCustomerId: null,
            myDepartmentId: null,
            logoUrl: null,
            ogJob: null,
            selectedTaskAttachments: [],
            attachmentsModal: { style: { display: 'none' } },
            postDeliveryNotesModal: { notes: null, taskId: null, isEditing: false, style: { display: 'none' } },
            modalBackdrop: { style: { display: 'none' } },
            loading: true,
        }

    },
    emits: ["back", "jobCompleted"],
    computed: {
        // Resolves the assigned driver's user ID or uses the current session user ID
        assignedDriverUser() {
            const d = this.Driver?.userId._id
            return d
        },

        isUserCustomerRole() {
            return this.myCustomerId != null;
        },

        isUserDepartmentRole() {
            return this.myDepartmentId != null;
        },

        isUserDriverRole() {
            return !(this.isUserCustomerRole || this.isUserDepartmentRole)
        },

        allTaskAttachments() {
            let result = [];

            if (this.selectedTaskAttachments.length) {
                for (let i = 0; i < this.selectedTaskAttachments.length; i++) {
                    const entry = this.selectedTaskAttachments[i];
                    if (typeof entry === 'string' && entry.length > 0) {
                        result.push(entry);
                    }
                }
            }

            return result;
        },

        isNewJob() {
            return this.ogJob == null
        },
    },
    async mounted() {
        const vm = this;

        let loginToken = localStorage.getItem("user-info")

        if (!loginToken) {
            this.$router.push({ name: 'Login' })
        }

        vm.loginToken = loginToken;

        let roles = [...JSON.parse(localStorage.getItem("roles"))];

        // Roles without ability to edit can only view job information
        const customer_associated_roles = ['customer', 'department', 'subscriber']
        let isCustomerAssociated = false
        const isDepartmentRole = roles.includes('department')

        for (const key of customer_associated_roles) {
            if (roles.includes(key)) {
                isCustomerAssociated = true
                break
            }
        }

        // NOTE: all of this code beneath is very hacky 8/3/2022
        if (isCustomerAssociated) {
            vm.myCustomerId = localStorage.getItem("customer_id")

            let event = { target: { value: vm.myCustomerId } };
            let customer = await vm.getCustomerbyID(event);
            vm.allcustomers = [customer];
            vm.customer = customer._id;

            if (isDepartmentRole) {
                vm.myDepartmentId = localStorage.getItem("department_id");

                let event = { target: { value: vm.myDepartmentId } };

                // weirdly, this getter function doesn't return anything 8/3/2022
                await vm.getDepartmentbyID(event);

                for (let i = 0; i < vm.customer_dept_facility.length; i++) {
                    let dep = vm.customer_dept_facility[i]
                    if (dep._id == vm.myDepartmentId) {
                        vm.customer_dept_facility = [dep];
                        vm.department = dep._id;
                        break;
                    }
                }
            }
        } else {
            // NOTE: Used to be that these lines were for admins only but anyone can create jobs
            // 5/11/2023
            await vm.onlineDriversList();
            await vm.getCustomers();
            vm.canRoleAssignDriver = true;
            // END
        }

        await vm.getOriginalJob();
        await vm.getChargeType();

        vm.loading = false
    },
    methods: {
        getDriverName(driver) {
            return driver.userId.first_name + " " + driver.userId.last_name;
        },

        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },

        countAttachments(uploads) {
            if (!uploads) return 0;
            return uploads.signatureUrls.length + uploads.photoUrls.length + uploads.attachmentUrls.length;
        },

        setResultTasks(event, k) {
            this.tasks[k].address = event;
            this.isOpen[k + 1] = false;
        },

        setNicknameResultTasks(event, k) {
            this.tasks[k].facility = event.facility;
            this.tasks[k].phoneNumber = event.phone;
            this.tasks[k].address = event.address;
            this.Facility_isOpen[k + 1] = false;
        },

        async taskAddress(k, $event) {
            const vm = this;

            vm.isOpen[k + 1] = false;

            if ($event.target.value.length == 0) return;

            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let geolocation = await eznavigator.getLatLong();

            await API.post('/customer-address-autocomplete', {
                address: $event.target.value,
                location: geolocation,
                customerId: this.selected ? this.selected : null,
            }, {
                headers: header
            },)
                .then(function (response) {
                    if (response.status == 200) {
                        const address_suggestions = response.data.data.address_suggestions;
                        const google_results = response.data.data.google_results;
                        vm.TaskAddress[k] = vm.flattenStreets(address_suggestions, google_results);
                        vm.isOpen[k + 1] = true;
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                    vm.isOpen[k + 1] = false;
                });
        },

        flattenNicknames(address_suggestions) {
            let result = [];

            address_suggestions.forEach(suggested => {
                suggested.nicknames.forEach(nick => {
                    result.push({
                        address: suggested.address,
                        phone: suggested.phone,
                        facility: suggested.facility,
                        nickname: nick
                    });
                })
            });

            return result;
        },

        flattenStreets(matches, google_suggestions) {
            let result = []

            matches.forEach(m => {
                result.push({
                    address: m.address
                })
            })

            google_suggestions.forEach(suggested => {
                result.push({
                    address: suggested.description,
                });
            });

            return result;
        },

        async taskNickname(k, $event) {
            const vm = this;

            vm.Facility_isOpen[k + 1] = false;

            if ($event.target.value.length == 0) return;

            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let geolocation = await eznavigator.getLatLong();

            await API.post('/customer-address-autocomplete', {
                address: $event.target.value,
                location: geolocation,
                customerId: this.selected ? this.selected : null,
            }, {
                headers: header
            },)
                .then(function (response) {
                    if (response.status == 200) {
                        vm.TaskFacilityNicks[k] = vm.flattenNicknames(response.data.data.address_suggestions);
                        vm.Facility_isOpen[k + 1] = true;
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                    vm.Facility_isOpen[k + 1] = false;
                });

        },

        async assignDriver() {
            if ($('.show_onlineDrivers').prop('checked')) {
                this.show = 'd-show'
            } else {
                this.show = 'd-none'
            }

        },

        getAddressData(addressData) {
            const vm = this;
            vm.address = addressData;
        },

        async populateForm(jobData) {
            this.loading = true

            // clear form programatically (because this thing is a mess)
            for (let i = 0; i < this.tasks.length; i++) {
                this.remove(i);
            }

            // purge the droppedTasks list - it is invalid for this case
            this.droppedTasks = [];

            // copy over tasks
            for (let i = 0; i < (this.queuedJobId ? jobData.tasks.length : jobData.Tasks.length); i++) {
                this.add(); //create a new blank task to overwrite below

                // TODO: this is horrible. the backend uses too many different cases..... FIX
                const task = this.queuedJobId ? jobData.tasks[i] : jobData.Tasks[i];

                // NOTE: this creates a pointer to `this.tasks[i]` below
                let myTask = this.tasks[i];

                myTask._id = task._id;
                myTask.customerID = task.customerID;
                myTask.address = task.address;
                myTask.phoneNumber = task.phoneNumber;
                myTask.extraNotes = task.extraNotes;
                myTask.facility = task.facility;
                myTask.department = task.department;
                myTask.isPickup = task.isPickup;
                myTask.upload = task.upload ?? null;
                myTask.updatedAt = task.updatedAt ?? null;
                myTask.completedAt = task.completedAt ?? null;
                myTask.postDeliveryNotes = task.postDeliveryNotes ?? null;
                myTask.checked_into_location = task.checked_into_location ?? null;
                myTask.requiresPhotoSignature = task.requiresPhotoSignature ?? false;
                myTask.status = task.status ?? 'INVALID'
            }

            // we didn't have tasks to copy over, so provide 1 empty task to fill out
            // Jobs MUST have at least 1 task as is required
            if (this.tasks.length == 0) {
                this.add(); // create blank task at index 1
            }

            // copy over job data
            this.chargeType = jobData.chargeType._id;
            this.customer = jobData.customer._id;
            this.department = jobData.department;
            this.Driver = jobData.Driver ?? null;

            // TODO: at this time, we have no mechanism for re-assigning in-progress jobs
            //       therefore prevent this
            if (this.Driver) {
                this.canRoleAssignDriver = false;
            }

            let event = { target: { value: this.customer } };
            await this.getCustomerbyID(event);

            event = { target: { value: this.department } };
            await this.getDepartmentbyID(event);

            this.loading = false
        },

        async getOriginalJob() {
            if (!(this.jobId || this.pastJobId || this.queuedJobId)) return;

            const vm = this;

            let endpoint = '/QueuedJob/';
            let id = this.queuedJobId;

            if (this.jobId) {
                endpoint = '/jobs/';
                id = this.jobId;
            } else if (this.pastJobId) {
                endpoint = '/pastjobs/';
                id = this.pastJobId;
            }

            API.get(endpoint + id, {
                headers: {
                    Authorization: `Bearer ${vm.loginToken}`
                }
            })
                .then(function (response) {

                    if (response.status == 200) {
                        vm.ogJob = response.data.data;

                        if (!vm.ogJob) {
                            vm.$router.push({ name: 'Jobs' });
                            throw new Error('That job does not exist.');
                        }

                        vm.populateForm(vm.ogJob);
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                });
        },

        async getChargeType() {
            const vm = this;

            API.get(`/chargeType`, {
                headers: {
                    Authorization: `Bearer ${vm.loginToken}`
                }
            })
                .then(function (response) {

                    if (response.status == 200) {
                        response.data.data.forEach(el => {
                            vm.allchargeTypes.push(el);

                            // STAT is a special charge code
                            if (el.name == 'STAT') {
                                vm.chargeType = el._id;
                            }
                        })

                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                });
        },

        add() {
            this.tasks.push({
                customerID: '',
                address: '',
                contact: '',
                extraNotes: '',
                facility: '',
                department: "",
                isPickup: false,
                checked_into_location: null,
                status: 'INVALID',
            })
            this.facility_show[this.tasks.length - 1] = 'd-none';
            this.isOpen.push(false);
            this.Facility_isOpen.push(false);
        },

        remove(index) {
            let task = this.tasks.splice(index, 1)[0];

            if (task._id) {
                this.droppedTasks.push(task);
            }

            this.isOpen.splice(index + 1, 1)
            this.Facility_isOpen.splice(index + 1, 1);
        },

        async handleSubmit() {
            this.busy = true;
            // This function first validates the tasks
            // Then submits the tasks, new, updates, or deletes
            // Finally updates the job via another function
            const vm = this;
            let users = localStorage.getItem("user-info")

            if (!users) {
                this.$router.push({ name: 'Login' })
            }

            let problem = false;
            for (let i = 0; i < this.tasks.length; i++) {
                let t = this.tasks[i];
                t.errorMessage = null;

                if (t.address.length == 0) {
                    problem = true;
                    t.errorMessage = "Task must have an address.";
                }
            }

            if (problem) {
                this.$toast.open({
                    message: "Missing required task fields",
                    type: 'error',
                    position: 'top'
                });

                vm.busy = false;
                return;
            }

            if (vm.tasks.length == 0) {
                vm.$toast.open({
                    message: "A job must have at least one task.",
                    type: 'error',
                    position: 'top'
                });

                vm.busy = false;
                return;
            }

            vm.loginToken = localStorage.getItem("user-info")
            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let promises = [];

            for (let i = 0; i < vm.tasks.length; i++) {
                let taskId = vm.tasks[i]._id;
                let taskData = {
                    countryCode: '+1', /* US for now */
                    phoneNumber: utils.cleanPhoneNumberForAPI(vm.tasks[i].phoneNumber),
                    extraNotes: vm.tasks[i].extraNotes,
                    chargeType: vm.chargeType,
                }
                if (vm.tasks[i].isPickup == "") {
                    taskData.isPickup = false;
                } else {
                    taskData.isPickup = vm.tasks[i].isPickup;
                }

                taskData.facility = vm.tasks[i].facility;
                taskData.address = vm.tasks[i].address;
                taskData.customerID = vm.customer;
                taskData.department = vm.department;

                if (taskId) {
                    let p = API.put('/task/' + taskId, taskData, { headers: header })
                    promises.push(p);
                } else {
                    // create
                    let p = API.post('/task', taskData, { headers: header })
                    promises.push(p);
                }
            }

            for (let i = 0; i < vm.droppedTasks.length; i++) {
                let p = API.delete('/task/' + vm.droppedTasks[i]._id, { headers: header })
                promises.push(p);
            }

            await Promise.all(promises).then(function (responses) {
                responses.forEach(response => {
                    // ignore the deleted tasks from these responses
                    // only add the well-formed tasks to our job
                    if (response.data.data.task) {
                        vm.taskIds.push(response.data.data.task._id);
                    }
                })

                // now try and update the job with these new records
                vm.addOrUpdateJob();
            })
                .catch(function (error) {
                    vm.handleError(error)
                }).finally(() => {
                    vm.droppedTasks = []; // reset and use whatever comes back from the job update as latest
                    vm.busy = false;
                });
        },

        async getCustomers() {
            const vm = this;

            if (vm.isUserCustomerRole) return; // we auto-select this

            try {
                let response = await API.get(`/customer`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })

                if (response.status == 200) {
                    vm.allcustomers = [...response.data.data].filter(customer => customer.userId.is_registered);
                }
            } catch (error) {
                this.handleError(error)
            }
        },

        async getCustomerbyID(event) {
            let customerResult = null;

            this.selected = event.target.value;

            this.loginToken = localStorage.getItem("user-info")

            let response = await API.get(`/customer/${this.selected}`, {
                headers: {
                    Authorization: `Bearer ${this.loginToken}`
                }
            })

            try {
                if (response.status == 200) {
                    const data = response.data.data;
                    this.customer_dept_facility = data.departments;
                    this.customer_address = data.address
                    this.phoneNumber = data.userId.phoneNo
                    this.logoUrl = data.userId.picture;

                    // NOTE: took out because it triggered a loop that broke it
                    //vm.customer = response.data.data;

                    customerResult = response.data.data;
                }
            } catch (error) {
                this.handleError(error)
            }

            return customerResult;
        },

        async getDepartmentbyID(event) {
            const vm = this
            vm.selectedDept = event.target.value;

            if (!vm.selectedDept) return;

            vm.loginToken = localStorage.getItem("user-info")

            await API.get(`/department/${vm.selectedDept}`, {
                headers: {
                    Authorization: `Bearer ${vm.loginToken}`
                }
            })
                .then(function (response) {
                    if (response.status == 200) {
                        vm.customer_address = response.data.data.departmentAddress
                        vm.phoneNumber = response.data.data.departmentPhoneNo
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                });

        },

        async getCustomerbyID_task(event, k) {
            const vm = this;
            var selValue = event.target.value

            if (selValue == "Others") {
                vm.facility_show[k] = 'd-show'
                vm.depart_show[k] = 'd-none'

            } else {
                vm.depart_show[k] = 'd-show'
                vm.facility_show[k] = 'd-none'
                vm.Tselected[k] = event.target.value;
                vm.loginToken = localStorage.getItem("user-info")

                await API.get(`/customer/${vm.Tselected[k]}`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                    .then(function (response) {
                        if (response.status == 200) {
                            vm.customer_dept[k] = response.data.data.departments;
                            vm.tasks[k].phoneNumber = response.data.data.userId.phoneNo
                            vm.tasks[k].address = response.data.data.address
                            vm.tasks[k].addressLocation = response.data.data.addressLocation
                        }
                    })
                    .catch(function (error) {
                        vm.handleError(error)
                    });
            }
        },

        async getDepartmentbyID_task(event, k) {
            const vm = this;

            vm.TaskDeptselected[k] = event.target.value;
            vm.loginToken = localStorage.getItem("user-info")

            await API.get(`/department/${vm.TaskDeptselected[k]}`, {
                headers: {
                    Authorization: `Bearer ${vm.loginToken}`
                }
            })
                .then(function (response) {
                    if (response.status == 200) {
                        vm.tasks[k].phoneNumber = response.data.data.departmentPhoneNo
                        vm.tasks[k].address = response.data.data.departmentAddress
                        vm.tasks[k].departmentLocation.Latitude = response.data.data.departmentLocation.Latitude
                        vm.tasks[k].departmentLocation.Longitude = response.data.data.departmentLocation.Longitude
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                });

        },

        async getUser(uid) {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info")
            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }

            await API.get(`/users/${uid}`,
                {
                    headers: header
                },
            )
                .then(function (response) {
                    if (response.status == 200) {
                        let code = response.data.phoneNo
                        let codes = code.substr(code.length - 10)

                        vm.phoneNumber = codes;
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                });
        },

        async getUser_task(uid) {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info")
            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            await API.get(`/users/${uid}`,
                {
                    headers: header
                },
            )
                .then(function (response) {
                    if (response.status == 200) {
                        let code = response.data.phoneNo
                        let codes = code.substr(code.length - 10)
                        vm.tasks[0].phoneNumber = codes
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                });

        },


        async onlineDriversList() {
            const vm = this;

            await API.get(`/driversonline`, {
                headers: {
                    Authorization: `Bearer ${vm.loginToken}`
                }
            })
                .then(function (response) {

                    if (response.status == 200) {
                        vm.onlineDrivers = [...response.data.data];
                    }
                })
                .catch(function (error) {
                    vm.handleError(error)
                });
        },

        async addOrUpdateJob() {
            if (this.taskIds.length == 0) {
                this.$toast.open({
                    message: "A job must have one or more tasks to submit",
                    type: 'error',
                    position: 'top'
                });

                return;
            }

            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd + 'T';
            let estimatedArrivalTime = today + this.estimatedArrival

            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            this.loginToken = localStorage.getItem("user-info")
            let header = {
                'Authorization': `Bearer ${this.loginToken}`,
            }

            let jobData = {
                estimatedArrival: estimatedArrivalTime,
                customer: this.customer,
                chargeType: this.chargeType,
                department: this.department,
                Tasks: this.taskIds,
                tasks: this.taskIds, // added because queuedJob has a different property format than jobs!!!
                items: ""
            }

            let endpoint = '/QueuedJob/';
            let id = this.queuedJobId;

            if (this.jobId) {
                endpoint = '/jobs/';
                id = this.jobId;
            } else if (this.pastJobId) {
                endpoint = '/pastjobs/';
                id = this.pastJobId;
            }

            try {
                if (this.edit) {
                    let response = null;

                    if (id) {
                        response = await API.put(endpoint + id, jobData, { headers: header });

                        if (response && response.status == 200) {
                            this.$toast.success('Job changed successfully.', {
                                position: 'top'
                            })

                            // At this time, we can only assign drivers to queued jobs, not live jobs
                            if (this.Driver && id == this.queuedJobId) {
                                let endpoint = `/QueuedJob/assign/${id}/to/${this.Driver._id}`;

                                // convert to job by assigning to Driver
                                let response = await API.put(endpoint, {}, { headers: header })

                                if (response.status == 200) {
                                    this.$toast.success(`Job assigned to ${this.getDriverName(this.Driver)}`, {
                                        position: 'top'
                                    })
                                }
                            }

                            this.$emit('back');
                        }
                    } else {
                        if (this.Driver) {
                            jobData.Driver = this.Driver._id;
                            endpoint = '/jobs'; // NOTE: this endpoint should be used for creating both Queued and live Jobs...
                        }
                        response = await API.post(endpoint, jobData, { headers: header });

                        if (response && response.status == 200) {
                            let payload = response.data;
                            let responseMessage = payload.message;
                            let message = responseMessage ? responseMessage : 'Job created successfully.';
                            this.$toast.success(message, {
                                position: 'top'
                            })

                            if (payload.data.Driver) {
                                this.$toast.success(`Job assigned to ${this.getDriverName(this.Driver)}`, {
                                    position: 'top'
                                })
                            }

                            this.$emit('back');
                        }
                    }
                } else {
                    let response = await API.post(endpoint, jobData, { headers: header })

                    if (response.status == 200) {
                        this.$toast.success('Job added successfully.', {
                            position: 'top'
                        })

                        this.$emit('back');
                    }
                }
            } catch (error) {
                this.handleError(error)
            }
        },

        async copyJobLink() {
            const job_url = window.location.href

            if (await utils.copyToClipboard(job_url, { removeQueries: true })) {
                this.$toast.open({
                    message: 'Job link copied to clipboard!',
                    type: 'success',
                    position: 'bottom-left'
                });
            } else {
                this.$toast.open({
                    message: 'Browser could not copy to clipboard.',
                    type: 'info',
                    position: 'bottom-left'
                });
            }
        },

        async handleDriverRoutePreviewClicked() {
            let colorData = []
            let stopData = []

            try {
                this.loginToken = localStorage.getItem("user-info")
                let header = {
                    'Authorization': `Bearer ${this.loginToken}`,
                }

                let sorted_tasks = [...this.tasks];
                sorted_tasks.sort((a, b) => { return new Date(a.completedAt) < new Date(b.completedAt) });

                const ctyperef = this.allchargeTypes.find(value => value._id == this.chargeType)
                const chargeTypeName = ctyperef.name

                let pointOfOrigin

                // To highlight point of origin route
                if (utils.isNameSTATLike(chargeTypeName) && sorted_tasks.length) {
                    const tFirstResponse = await API.get(`/task/${sorted_tasks[0]._id}`, { headers: header })
                    const pointOfOriginId = tFirstResponse.data.data.trackingLocation
                    const pointOfOriginResponse = await API.get(`/geolocation/${pointOfOriginId}`, { headers: header })
                    pointOfOrigin = pointOfOriginResponse.data.data

                    const position = { lat: pointOfOrigin.Latitude, lng: pointOfOrigin.Longitude }
                    const address = "Point of Origin"
                    stopData.push({ address: address, position: position })

                    colorData.push("#81D4FA")
                }

                for (let i = 0; i < sorted_tasks.length; i++) {
                    const t = sorted_tasks[i]

                    const response = await API.get(`/task/${t._id}`, { headers: header })
                    const addressLoc = response.data.data.addressLocation
                    const position = { lat: addressLoc.Latitude, lng: addressLoc.Longitude }
                    const address = t.address
                    stopData.push({ address: address, position: position })

                    let color = '#039BE5'

                    // To highlight point of origin route
                    if (i == 0) {
                        color = "#81D4FA"
                    }
                    else if (utils.isNameSTATLike(chargeTypeName)) {
                        color = '#FBC02D'
                    }

                    colorData.push(color)
                }

                if (sorted_tasks.length) {
                    // Add Round-Trip
                    //Round trip back to point of origin if stat job
                    if (utils.isNameSTATLike(chargeTypeName)) {
                        const position = { lat: pointOfOrigin.Latitude, lng: pointOfOrigin.Longitude }
                        const address = "Point of Origin"
                        stopData.push({ address: address, position: position })
                    }
                    else {
                        stopData.push(stopData[0])
                    }

                    colorData.push("#81D4FA")
                }

                const stops = encodeURIComponent(JSON.stringify(stopData))
                const colors = encodeURIComponent(JSON.stringify(colorData))
                const paths = encodeURIComponent(JSON.stringify(this.ogJob.paths))
                const settings = "width=400,height=400,menubar=no,toolbar=no,location=no"
                const title = "Route"
                window.open(`#/path-preview?stops=${stops}&paths=${paths}&colors=${colors}`, title, settings)
            } catch (error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'bottom-left'
                });
            }
        },

        openAttachmentsModal(uploads) {
            if (!uploads) return

            this.selectedTaskAttachments = [...uploads.photoUrls, ...uploads.signatureUrls, ...uploads.attachmentUrls]

            let style = {
                display: "initial",
            }

            this.attachmentsModal.style = style
            this.modalBackdrop.style = style
        },

        openPostDeliveryNotesModal(taskId, notes) {
            let style = {
                display: "initial",
            }

            this.postDeliveryNotesModal = {
                taskId: taskId,
                notes: notes,
                style: style,
                isEditing: false,
            }

            this.modalBackdrop.style = style;
        },

        closeAttachmentsModal() {
            this.selectedTaskAttachments = []

            let style = {
                display: "none",
            }

            this.attachmentsModal.style = style
            this.modalBackdrop.style = style
        },

        closePostDeliveryNotesModal() {
            let style = {
                display: "none",
            }

            this.postDeliveryNotesModal = {
                taskId: null,
                notes: null,
                isEditing: false,
                style: style
            }

            this.modalBackdrop.style = style
        },

        handlePostDeliveryNotesEditClicked() {
            this.postDeliveryNotesModal.isEditing = true
        },

        async handlePostDeliveryNotesSaveClicked() {
            this.busy = true;

            const vm = this;
            vm.loginToken = localStorage.getItem("user-info")
            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let taskId = vm.postDeliveryNotesModal.taskId
            let taskData = vm.tasks.find(t => t._id == taskId)

            if (!taskData) {
                vm.$toast.open({
                    message: "Notes not saved: Task ID was not valid",
                    type: 'error',
                    position: 'top'
                });
                return
            }

            taskData.postDeliveryNotes = vm.postDeliveryNotesModal.notes

            if (taskData.postDeliveryNotes) {
                taskData.postDeliveryNotes = taskData.postDeliveryNotes.trim()
            }

            try {
                let response = await API.put('/task/' + taskId, taskData, { headers: header })
                taskData.postDeliveryNotes = response.data.data.task.postDeliveryNotes

                vm.$toast.open({
                    message: "Notes updated",
                    type: 'success',
                    position: 'top'
                });
            } catch (error) {
                vm.handleError(error)
            } finally {
                vm.busy = false
                vm.postDeliveryNotesModal.isEditing = false
                vm.postDeliveryNotesModal.notes = taskData.postDeliveryNotes
            }
        },

        handleJobCompleteRequest() {
            this.forceJobCompleteState = true
        },

        handleError(error) {
            this.$toast.open({
                message: utils.messageFromError(error),
                type: 'error',
                position: 'top'
            });
        },

        async handleTaskDataChanged(taskList, forceComplete) {
            // Check if we can auto-complete this job
            const incompleteTasks = this.ogJob.Tasks.filter(t => t.status != 'COMPLETE')
            //console.table(incompleteTasks.length)
            //console.table(taskList.length)

            const props = forceComplete ? { forceComplete: forceComplete } : {}

            if (incompleteTasks.length == taskList.length) {
                // attempt to complete job
                let header = {
                    'Authorization': `Bearer ${this.loginToken}`,
                    'content-type': 'application/json',
                }

                try {
                    let response = await API.put(`/completejob/${this.ogJob._id}`, props,
                        {
                            headers: header
                        })

                    if (response.status == 200) {
                        const jobData = response.data.data

                        this.$toast.open({
                            message: "Job completed successfully",
                            type: 'success',
                            position: 'top'
                        });

                        this.$emit('jobCompleted', { pastJobId: jobData._id })
                    } else {
                        this.handleError(response.data.data)
                    }
                }
                catch (error) {
                    this.handleError(error)
                }
            }
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: underline solid;
    color: #2d98ca;
    padding: 1px;
}

a:hover {
    text-decoration: underline solid;
    color: #9bd8f4;
}

.time {
    margin: 1px;
}

.attachments {
    margin: 1px;
    cursor: pointer;
    text-decoration: underline;
}

.attachments:hover {
    text-decoration: none;
}

.icon {
    width: 2%;
}

.autocomplete {
    position: relative;
    display: inline-block;
}

.autocomplete-result {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-result:hover {
    background-color: #e9e9e9;
}

.autocomplete-results {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    list-style-type: none;
    padding-left: 0px;
    margin-left: 15px;
}

.drivers-btn .btn {
    margin-right: 10px;
}

/* vue-js transition classes */
.v-enter-active,
.v-leave-active {
    transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
</style>
