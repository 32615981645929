<template>
<footer class="main-footer">
    <h5>© Copyright {{ new Date().getFullYear() }} WIT Courier</h5>
	<a v-for="item in links" :key="item.name" class="footer-link" @click="gotoPage(item.to)">{{item.name}}</a>                
 </footer>
</template>
<script>
	export default{
		name:'Footer',
		data() {
			return {
				links: [
					{
						to: '/',
						name: 'Home'
					},
					{
						to: '/privacy-policy',
						name: 'Privacy Policy'
					},
					{
						to: '/contact-support',
						name: 'Contact'
					}
				]
			}
		},

		methods: {
			gotoPage(path) {
				this.$router.push(path);
				this.scrollToTop();
			},
			scrollToTop() {
				window.scrollTo(0, 0);
			}
		}
	}
</script>
<style scoped>
.footer-link {
	color:rgb(207, 205, 205);
	padding-left: 15px;
	cursor:pointer;
	position: relative;
}

.footer-link:hover {
	color:white;
	top: -2px;
}
</style>