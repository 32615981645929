<template>
    <div>
    <Nav />
    
    <div class="login-box" style="min-height:100vh;">
    
        <div class="container">
    
            <div class="row">
    
                <div class="col-md-4"></div>
    
                <div class="col-md-4">
    
                    <div class="card">
    
                        <p class="login-box-msg">FORGOT YOUR PASSWORD?</p>
    
                        <p class="forgot-text">Enter in your email address below and we will send you a link that will reset your password.</p>
    
                        <div class="card-body login-card-body">
    
                            <form @submit.prevent="forgotPassword">
    
                                <div class="form">
    
                                    <div class="form-item">
    
                                        <input type="email" id="username" autocomplete="off" required v-model="email">
    
                                        <label for="username">Email</label>
    
                                        <span class="input-group-text"><img src="../assets/img/email-icon.svg" alt=" " style="width:70%;"></span>
    
                                    </div>
    
                                </div>
    
                                <div class="row">
    
                                    <div class="col-12">
    
                                        <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
    
                                    </div>
    
                                </div>
    
                            </form>
    
                            <!-- <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400;">
    
            <a href="forgot-password.html" class="fp">Forgot Your Password?</a>
    
          </p> -->
    
                            <div class="row">
    
                                <div class="col-md-3"></div>
    
                                <div class="col-md-6">
    
                                    <div class="customer_message_officer_service">
    
                                        <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400; margin-top: 4%;">
    
                                            <router-link to="/login" class="fp">Back</router-link>
    
                                        </p>
    
                                    </div>
    
                                </div>
    
                                <div class="col-md-3"></div>
    
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
    
                <div class="col-md-4"></div>
    
            </div>
    
        </div>
    
    </div>
    <Footer />
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import API from '../axios.config'
import utils from '../utils'
export default {
    name: 'ForgotPassword',
    components: {
        Nav, Footer
    },
    data() {
        return {
            email: '',
        }
    },
    methods: {
        async forgotPassword() {
            const vm = this;
            await API.post('/forget-password', {
                        email: vm.email
                    },

                )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.email = ''; // clear on success
                        vm.$toast.success(response.data.message, {
                            // override the global option
                            position: 'top'
                        })
                        
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });
        },
    }
}
</script>