<template>
    <div class="table-section" v-for="(table, name) in data" :key="name">
        <h4>{{name}}</h4><br/>
        <DynamicTable
        :perPage="10"
        :data="table" 
        :header="makeHeader(table)"
        :replace="makeJobLinkReplacer(table)"/>
        <br/>
        <!--<span v-if="commission">Earnings: ${{((commission/100.0)*total).toFixed(2)}}</span>-->
        <hr/>
    </div>
    <div class="table-section" v-if="groups.length == 0">
        <h4>No Data To Show</h4>
    </div>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
import config from '../../config';
import utils from '../utils';

export default {
    name: "BillingPreviewTable",
    components: {
        DynamicTable,
    },
    data() {
        return {
        };
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        total: {
            type: Number
        },
        commission: {
            type: Number,
            default: null
        }
    },
    computed: {
        groups() {
            return Object.keys(this.data)
        }
    },
    methods: {
        makeHeader(table_data) {
            let header = utils.makeTableFromResults(table_data).header
            
            // Don't display the Job column
            const i = header.indexOf('Job')
            if(i >= 0) {
                header.splice(i, 1)
            }

            return header
        },
        makeJobLinkReplacer(table_data) {
            let remapper = {}
            for(let i = 0; i < table_data.length; i++) {
                const entry = table_data[i]
                remapper[entry.Task] = `<a class="link" href="${config.baseURL}/#/view-job?pastJobId=${entry.Job}">${entry.Task}</a>`
            }

            return { 'Task': remapper}
        },
    },
}
</script>
<style scoped>
</style>