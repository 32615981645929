<template>
	<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <div class="logo-img">
      <a href="/" class="brand-link">
        <img src="../assets/img/new_logo.svg" alt="WIT Courier" style="width: 50%;">
      </a>
    </div>
    <div class="sidebar">
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item menu-open" v-if="isAdmin">
            <router-link to="/dashboard" class="nav-link">
             <img src="../assets/img/dashboard-icon.svg" alt="">
              <p>
                Dashboard
              </p>
            </router-link>
          </li>
          <li class="nav-item menu-open" v-if="isAdmin">
            <router-link to="/audit" class="nav-link">
             <img src="../assets/img/dashboard-icon.svg" alt="">
              <p>
                Audit
              </p>
            </router-link>
          </li>
          <li class="nav-item menu-open" v-if="isAdmin">
            <router-link to="/chatbot" class="nav-link">
             <img src="../assets/img/chat-left-dots-fill.svg" alt="">
              <p>
                Chat Bot
              </p>
            </router-link>
          </li>
          <li class="nav-item menu-open" v-if="isAdmin || isDriver || isCustomer || isDepartment">
            <router-link to="/calendar" class="nav-link">
             <img src="@/assets/img/calendar_icon.svg" alt="">
              <p>
                Calendar
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link to="/drivers-list" class="nav-link ">
             <img src="../assets/img/Drivers-icon.svg" alt="">
              <p>
               Drivers
              </p>
            </router-link>
          </li> 
           <li class="nav-item" v-if="isAdmin">
            <router-link to="/customers-list" class="nav-link ">
             <img src="../assets/img/address_book_icon.svg" alt="">
              <p>
               Customers
              </p>
            </router-link>
          </li> 
          <li class="nav-item" v-if="isAdmin || isDriver">
            <router-link to="/jobs" class="nav-link ">
             <img src="../assets/img/Jobs-icon.svg" alt="">
              <p>
               Jobs
             </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isCustomer || isDepartment">
            <router-link to="/add-job?lastPage=/profile" class="nav-link ">
             <img src="../assets/img/Jobs-icon.svg" alt="">
              <p>
               Add Job
             </p>
            </router-link>
          </li> 
          <li class="nav-item" v-if="isAdmin">
            <router-link to="fleet-management" class="nav-link ">
              <img src="../assets/img/car-front-fill.svg" alt="">
              <p>
              Fleet Management              
            </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link to="billing" class="nav-link ">
              <img src="../assets/img/piggy-bank-fill.svg" alt="">
              <p>
              Billing              
            </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link to="weekly-route-mgmt" class="nav-link ">
              <img src="../assets/img/clipboard2-check-fill.svg" alt="">
              <p>
              Weekly Route Mgmt              
            </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link to="cms" class="nav-link ">
             <img src="../assets/img/server.svg" alt="">
              <p>
              CMS              
            </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link to="magic-link-mgmt" class="nav-link ">
             <img src="../assets/img/magic_link.png" alt="">
              <p>
              Magic Link Mgmt
            </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin || isDriver || isCustomer">
            <router-link to="/profile" class="nav-link ">
             <img src="../assets/img/profile-icon.svg" alt="">
              <p>
              Profile
              </p>
            </router-link>
          </li> 
          <li class="nav-item" v-if="isDepartment">
            <router-link to="/subscribers-list" class="nav-link ">
             <img src="../assets/img/people.png" alt="">
              <p>
              Subscribers
              </p>
            </router-link>
          </li> 
          <li class="nav-item">
            <a href="#" class="nav-link" v-on:click="logout">
             <img src="../assets/img/switch.png" alt="" style="width: 7%;">
              <p>
              Logout
              </p>
            </a>
          </li> 
        </ul>
      </nav>
    </div>
  </aside>
</template>
<script >
	export default{
		name:'Sidebar',
    data(){
      return{
        roles:[],
      }
    },
    computed: {
      isDriver() {
        return this.roles.includes("driver");
      },
      isCustomer() {
        return this.roles.includes("customer");
      },
      isDepartment() {
        return this.roles.includes("department");
      },
      isAdmin() {
        return this.roles.includes("admin");
      }
    },
    methods:{
      logout(){
        localStorage.clear();
        this.$router.push({name:'Login'})
        this.$toast.success("Successfully logged out", {
          // override the global option
          position: 'top'
        })
      },
    },
      mounted(){
        this.roles = [...JSON.parse(localStorage.getItem("roles"))];
      },
    
	}
</script>

<style scoped>
.nav-item{
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}

.nav-item:hover {
  border-radius: 1px;
  background: linear-gradient(to right, #000000 0%, #475761 100%);
  text-indent: 5%;
}

.nav-link {
  color: white;
  fill: white;
}
</style>