<template>
    <Topnav />
    
    <Sidebar />

    <div class="content-wrapper">
        <div class="content-header" style="padding: 20px;">
            <div class="container-fluid" style="padding-left: 0;">
                <div class="row">
                    <!-- search bar -->
                    <div class="col-md-8 search">
                        <input type="text" placeholder="Search..." v-model="filter"/>
                        <i class="fa fa-search"></i>
                        <div v-if="!loaded" style="display: inline;">
                            <img src="@/assets/img/spinner.gif" style="width:40px;height:auto;"/>
                            <span style="margin-left: 10px;">Loading...</span>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <DynamicTable ref="dynamicTable"
                        :header="table.header"
                        :sortableHeader="table.sortableHeader"
                        :data="table.data"
                        :perPage="20"
                        :actions="false"
                        :customActions="[{name: `Regenerate`, callback: generateMagicLink}]"
                        :filter="filterData"
                        :replace="replacementRules"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config'
import DynamicTable from '../components/DynamicTable.vue';
import utils from '../utils';

export default {
    name: 'MagicLinkMgmt',
    data() {
        return {
            API: API,
            loaded: false,
            allCustomersMapper: {},
            allDepartmentsMapper: {},
            allUsersMapper: {},
            allCustomers: [],
            allMagicLinks: [],
            table: {
                header: [
                    {userId: "User"},
                    {magicToken: 'Token'},
                    {type: "Type"},
                    {customer: "Customer"},
                    {department: "Department"},
                    {recipient: "Recipient"},
                ],
                data: [],
                busy: false,
                sortableHeader: ["User", "Token", "Type", "Customer", "Department", "Recipient"],
            },
            filter: '',
        }
    },
    components: {
        Topnav,
        Sidebar,
        DynamicTable,
    },
    computed: {
        replacementRules() {
            return {
                'customer': this.allCustomersMapper, 
                'department': this.allDepartmentsMapper,
                'userId': this.allUsersMapper,
            }
        },
    },
    async mounted() {
        await this.fetchCustomers();
        await this.fetchDepartments();
        await this.fetchUsers();
        await this.getAllMagicLinks();

        this.reloadTable();
        this.loaded = true;
    },
    methods: {
        async fetchCustomers() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info")

            try {
                let response = await API.get(`/customer`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })

                if (response.status == 200) {
                    vm.allCustomersMapper = {}
                    vm.allCustomers = [];
                    response.data.data.forEach(el => {
                        vm.allCustomersMapper[el._id] = el.name
                        vm.allCustomers.push(el);
                    })
                }
            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }
        },

        async fetchDepartments() {
            let failedCount = 0;
            const vm = this;
            let loginToken = localStorage.getItem("user-info")
            vm.allDepartmentsMapper = {}

            for(let i = 0; i < this.allCustomers.length; i++) {
                const customer = this.allCustomers[i];

                for(let j = 0; j < customer.departments.length; j++) {
                    const id = customer.departments[j]._id;
                    try {
                        let response = await API.get(`/department/${id}`, {
                            headers: {
                                Authorization: `Bearer ${loginToken}`
                            }
                        })

                        if (response.status == 200) {
                            const el = response.data.data;
                            vm.allDepartmentsMapper[el._id] = el.departmentName;
                        }
                    } catch(error) {
                        if (error.response && error.response.data.status == "Expired") {
                            vm.$toast.open({
                                message: 'Session expired. Please login again.',
                                type: 'error',
                                position: 'top'
                            });
                            vm.$router.push({ name: 'Login' })
                            localStorage.clear();
                        } else {
                            failedCount++;
                        }
                    }
                }
            }

            if(failedCount > 0) {
                vm.$toast.open({
                    message: utils.messageFromError(`${failedCount} departments failed to populate`),
                    type: 'error',
                    position: 'top'
                });
            }
        },

        async fetchUsers() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info")

            try {
                let response = await API.get(`/users`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })

                if (response.status == 200) {
                    vm.allUsersMapper = {}
                    response.data.forEach(el => {
                        vm.allUsersMapper[el._id] = `${el.first_name} ${el.last_name}`;
                    })
                }
            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }
        },
        async getAllMagicLinks() {
            this.table.busy = true;
            const loginToken = localStorage.getItem("user-info")
            try {
                let result = await API.get(`/magicLinks`, {
                    params: { },
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });
                this.allMagicLinks = [...result.data.data];
            }
            catch(error) {
                if (error.status == 401) return null;

                if (error.status == "Expired") {
                    this.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    this.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    this.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
                this.table.data = [];
            }
            this.table.busy = false;
        },

        reloadTable() {
            this.table.data = this.allMagicLinks.map(mglk => {
                let row = {};
                row["_id"] = mglk["_id"];
                this.table.header.forEach(header => {
                    const field = Object.keys(header)[0];
                    if (field === "userId") {
                        row["userId"] = mglk["userId"];
                        // added replacement here to make it searchable
                        if (mglk.hasOwnProperty("userId")) {
                            const _id = mglk["userId"];
                            if (this.allUsersMapper.hasOwnProperty(_id)) {
                                row["userId"] = this.allUsersMapper[_id];
                            } else {
                                row["userId"] = _id;
                            }
                        }
                    } else if (field === "magicToken") {
                        row["magicToken"] = mglk["magicToken"];
                    } else if (field === "type") {
                        row["type"] = mglk["magicLinkType"];
                    } else if (field === "recipient") {
                        row["recipient"] = mglk["recipientEmail"];
                    } else if (field === "customer") {
                        row["customer"] = mglk["customer"];
                        // added replacement here to make it searchable
                        if (mglk.hasOwnProperty("customer")) {
                            const _id = mglk["customer"];
                            if (this.allCustomersMapper.hasOwnProperty(_id)) {
                                row["customer"] = this.allCustomersMapper[_id];
                            } else {
                                row["customer"] = _id;
                            }
                        }
                    } else if (field === "department") {
                        row["department"] = mglk["department"];
                        // added replacement here to make it searchable
                        if (mglk.hasOwnProperty("department")) {
                            const _id = mglk["department"];
                            if (this.allDepartmentsMapper.hasOwnProperty(_id)) {
                                row["department"] = this.allDepartmentsMapper[_id];
                            } else {
                                row["department"] = _id;
                            }
                        }
                    }
                });
                return row;
            });
        },

        async generateMagicLink(idx) {
            try {
                const offset = idx % this.$refs.dynamicTable.perPage;
                const start = idx - offset;
                const magicLink_id = this.$refs.dynamicTable.getValue(start, offset, "_id");
                const loginToken = localStorage.getItem("user-info");
                let header = {
                    'Authorization': `Bearer ${loginToken}`,
                }

                let response = await API.post('/newMagicLink', {
                        magicLinkId: magicLink_id,
                    }, 
                    {
                        headers: header
                    });
                if (response.status == 200) {
                    this.$toast.open({
                        message: 'Regenerated!',
                        type: 'success',
                        position: 'top'
                    });
                }
                const arrIndex = this.allMagicLinks.findIndex(mglk => {
                    return mglk._id == magicLink_id;
                });
                if (arrIndex >= 0) {
                    this.allMagicLinks[arrIndex]["magicToken"] = response.data.data["magicToken"];
                    this.table.data[arrIndex]["magicToken"] = response.data.data["magicToken"];
                    this.$refs.dynamicTable.handleCellChange({
                        id: idx,
                        value: response.data.data["magicToken"],
                        propertyName: "magicToken",
                    });
                }
            } catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            }
        },

        filterData(data) {
            if(this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim()+".*", 'i');
                for(const [_, value] of Object.entries(data)) {
                    if(regex.test(value)) {
                        return true;
                    }
                }
                
                return false;
            }

            return true;
        },
    }
}

</script>

<style scoped>
div.search i {
    left: -30px;
    position: relative;
}
</style>