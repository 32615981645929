<template>
    <div>
        <Nav />

        <div class="login-box" style="min-height:100vh;">

            <div class="container">

                <div class="row">

                    <div class="col-md-4"></div>

                    <div class="col-md-4">

                        <div class="card">

                            <p class="login-box-msg">LOGIN TO YOUR ACCOUNT</p>

                            <div class="card-body login-card-body">

                                <form @submit.prevent="login">

                                    <div class="form">

                                        <div class="form-item">

                                            <input type="text" id="username" autocomplete="off" v-model="username" required
                                                :disabled="isDisabled">

                                            <label for="username" v-if="!isDisabled">Username</label>

                                            <span class="input-group-text"><img src="../assets/img/email-icon.svg" alt=" "
                                                    style="width:70%;"></span>

                                        </div>

                                        <div class="form-item">

                                            <input :type="showPassword ? 'text' : 'password'" id="password"
                                                autocomplete="off" v-model="password" required :disabled="isDisabled">

                                            <label for="password" v-if="!isDisabled">Password</label>

                                            <span class="input-group-text"><img src="../assets/img/password-icon.svg"
                                                    alt=" " style="width:70%;" @click="togglePassword"></span>

                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="col-12">
                                            <button type="submit" class="btn btn-primary btn-block"
                                                v-if="!isDisabled">Login</button>
                                            <button class="btn btn-primary btn-block wait" v-else><span
                                                    class="spin" /></button>
                                        </div>

                                    </div>

                                </form>

                                <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400;">

                                    <router-link to="/forgot-password" class="fp">Forgot Your Password? </router-link>

                                </p>

                                <div class="row">

                                    <div class="col-md-3"></div>

                                    <div class="col-md-6">

                                        <div class="contactlogin-box"></div>

                                        <div class="customer_message_officer_service">

                                            <img src="../assets/img/customer_message_officer_service_user_icon.svg" alt="">

                                            <p class="mb-1 mt-4"
                                                style="text-align: center;font-size: 13px;font-weight: 400;">

                                                <router-link to="/contact-support" class="fp">Contact Support </router-link>

                                            </p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import API from '../axios.config';

import $ from 'jquery'
export default {
    name: 'Login',
    components: {
        Nav,
        Footer
    },
    data() {
        return {
            username: '',
            password: '',
            token: '',
            loginToken: '',
            error: '',
            isDisabled: false,
            showPassword: false,
        }

    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword
        },

        async login() {
            const vm = this;
            this.isDisabled = true;
            $('#pageloader').addClass('loading');
            await API.post('/login',
                {
                    username: this.username,
                    password: this.password
                }
            )
                .then(function (response) {
                    if (response.status == 200) {
                        if (response.data.success == true) {
                            localStorage.setItem('username', vm.username);
                            localStorage.setItem("otp_verify", "NO");
                            localStorage.setItem('user-info', response.data.data.token);
                            vm.$router.push({ name: 'OtpLogin' });
                        }
                    }
                })
                .catch(function (error) {
                    $('#pageloader').removeClass('loading');
                    vm.$toast.open({
                        message: "User not found or password did not match", // TODO: for some reaosn my messageFromError() util function doesn't work here
                        type: 'error',
                        position: 'top'
                    });
                    vm.isDisabled = false;
                });
        },

    },
    mounted() {
        let verified = localStorage.getItem("opt_verify")
        if (verified == 'YES') {
            this.$router.push({ name: 'Dashboard' })
        }
    }
}
</script>

<style scoped>
.wait {
    cursor: progress !important;
}

.spin {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 4px solid rgba(255, 255, 255, .3);
    border-radius: 100%;
    border-top-color: rgb(179, 190, 255);
    animation: spin 0.5s linear infinite;
    -webkit-animation: spin 0.5s linear infinite;
    margin: -10px;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }

    0% {
        border-top-color: rgb(253, 147, 147);
    }

    50% {
        border-top-color: rgb(157, 157, 255);
    }

    75% {
        border-top-color: rgb(162, 255, 162);
    }

    100% {
        border-top-color: rgb(255, 255, 255);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }

    0% {
        border-top-color: rgb(253, 147, 147);
    }

    50% {
        border-top-color: rgb(157, 157, 255);
    }

    75% {
        border-top-color: rgb(162, 255, 162);
    }

    100% {
        border-top-color: rgb(255, 255, 255);
    }
}
</style>
