<template>
    <button :class="_class" :disabled="spin || disabled" @click.stop.prevent="handleClick">
        <div :class="_text_class">
            <slot></slot>
        </div>
    </button>
</template>

<script>
export default {
    name: 'SpinButton',
    data() {
        return {}
    },
    props: {
        class: {
            type: String,
            default: '',
        },
        spin: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ["click"],
    computed: {
        _class() {
            return (this.spin? "loading wait " : this.disabled? "disabled " : "") + this.class;
        },
        _text_class() {
            return (this.spin? "hide" : "");
        },
    },
    methods: {
        handleClick() {
            if(this.spin) return;
            this.$emit('click');
        }
    }
}
</script>

<style scoped>
button {
    position: relative;
}
button.wait {
    cursor:wait;
}
.hide {
    visibility:hidden;
}
.disabled {
    cursor:not-allowed;
}
.loading::after {
   content: "";
   position: absolute;
   width: 3vh;
   height: auto;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   margin: auto;
   border: 4px solid transparent;
   border-top-color: #ffffff;
   border-radius: 100%;
   animation: button-loading-spinner 0.5s linear infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

@-webkit-keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}
</style>