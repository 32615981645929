export default {
	baseURL: "https://witcourier.com",
	api: {
		scheme: "https",
		url: "witcourier.com",
		port: 3000,
		version: "v1"
	},
	fleet: {
		interval: 10, // how often to poll for an update, in seconds
	},
	google: {
		maps_key: "AIzaSyBlc2-BM9O0t86-yjg2adZTzr7NWP99n-c"
	},
	version: "1.1.0a",
	lastUpdated: "March 25th, 2023" // TODO: pull time and hash from git
};


