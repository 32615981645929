<template>

    <div class="container-fluid">

        <div>

            <div class="row mt-2 mb-4">

                <div class="col-md-2">

                    <button
                    type="button" 
                    title="Go back to customer information"
                    class="btn btn-primary" 
                    @click.prevent="back()"
                    :disabled="busy"
                    >
                    Back
                    </button> <small v-show="customer.length > 0">to {{customer}}</small>

                </div>

                <div class="col-md-1">

                </div>

                <div class="col-md-6" style="text-align:center;">

                    <h4>
                        
                        {{!busy? "Address Book" : "Working..." }}
                                                
                    </h4>

                </div>
                
                <div class="col-md-2">

                    <div>
                        <div v-show="showSaveReminder" class="tooltiptext">
                            Don't forget to save!
                        </div>
                        <SpinButtonVue
                        title="Process and save pickup points"
                        class="btn btn-block btn-info"
                        @click="emitSaveData"
                        :spin="busy">
                        Save
                        </SpinButtonVue>
                    </div>

                </div>

                <div class="col-md-1">

                    <button
                    type="button"
                    title="Add new pickup point"
                    class="btn btn-block btn-info" 
                    @click.prevent="emitNewPointPrompt"
                    :disabled="busy">
                    +
                    </button>

                </div>

            </div>  

            <div class="row mt-2 mb-4">

                <div class="col-md-8" v-if="progressText.length > 0" >

                    <progress-bar
                    :bar-color="progressColor"
                    :val="progress" 
                    :max="maxProgress" 
                    :text="`(${progress}/${maxProgress}) ${progressText}`"
                    :opts="getFixOptions"
                    @cancel="progressText=''"
                    @fix="launchFixModal(errorId)"
                    />
                </div>

                <div class="col-md-8 search" v-else>

                    <input type="text" placeholder="Search..." v-model="filter"/>

                    <i class="fa fa-search"></i>

                </div>

                <div class="col-md-4" style="text-align:center">

                    <small>Click the browse button to import from .CSV file </small> 
                    
                    <input type="file" @change="importPickupPoints" :disabled="busy"/>

                </div>

            </div>

            <form @submit.prevent="updatePickupPoints">  
                    
                <div>

                    <div class="form-group">

                        <hr>

                        <section class="scrolling-section">

                            <div v-for="(point, k) in dynamicPointsList" :key="k" 
                            :style="getPointStyle(point)"
                            :title="getPointTitle(point)"
                            >
                                <span style="width:2%; display:inline-block; margin-right:1%;">{{k+1}}.</span>

                                <input :value="point.facility" style="width:20%; display:inline-block; margin-right:1%;" disabled>

                                <input :value="point.address" style="width:30%; display:inline-block; margin-right:1%;" disabled>

                                <input type="tel" style="width:10%; display:inline-block;" :value="formatPhoneNumber(point.phone)" disabled>

                                <span style="text-align:center; width:10%; display:inline-block;"> 
                                
                                    <a @click.prevent="emitEditPointPrompt(point)" href="">
                                        {{ point.nicknames.length }} nicknames 
                                    </a>
                                
                                </span>

                                <div style="text-align:right; width:24%; display: inline-block;">

                                    <button type="button" v-if="!isPointRemoved(point)"
                                    style="margin-right: 4%;"
                                    @click="emitEditPointPrompt(point)"
                                    :disabled="busy">
                                    EDIT
                                    </button>

                                    <button type="button" v-if="!isPointRemoved(point) && !isPointChanged(point)"
                                    @click="removePickupPoint(point)"
                                    :disabled="busy">
                                    DROP
                                    </button>

                                    <button type="button" v-else
                                    @click="restorePickupPoint(point)"
                                    :disabled="busy">
                                    UNDO
                                    </button>

                                </div>

                            </div>

                        </section>
                        
                        <div class="row mt-2 mb-4 entries-msg">

                            <div class="col-md-11">
                                <p v-if="filter.trim().length > 0">{{dynamicPointsList.length}} matches found</p>
                                <p v-else-if="dynamicPointsList.length == 0" class="error-msg">You have no pickup points</p>
                                <p v-else>{{getStagedChangesLabel}}</p>
                            </div>
                            
                            <div class="col-md-1" v-show="dynamicPointsList.length > 0">
                                <a v-if="!allAreDropped" href="" style="text-align:right" @click.prevent="dropAll" title="Mark all pickup points for removal">Drop All</a>
                                <a v-else href="" style="text-align:right" @click.prevent="undoDropAll" title="Restores all pickup points">Undo All</a>
                            </div>
                        </div>
                    </div>

                </div>

            </form>

        </div>

    </div>

</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue'
import SpinButtonVue from '@/components/SpinButton.vue';

export default {
    name: 'PickupPointsForm',
    components: {
        ProgressBar,
        SpinButtonVue
    },
    props: {
        points: {
            type: Array,     
            default() {
                return []
            }
        },
        customer: {
            type: String,
            default: ""
        },
        progressText: {
            type: String,
            default: "",
        },
        progress: {
            type: Number,
            default: 0
        },
        maxProgress: {
            type: Number,
            default: 100
        },
    },
    data() {
        return { 
            dummyPointId: 0,
            errorId: null, // quick link to the entry with the last error
            newPoints: [],
            updatePoints: [],
            removePoints: [],
            filter: "",
            canGoBack: true,
            showSaveReminder: false,
            busy: false,
        };
    },
    computed: {
        progressColor() {
            // This specific state is reserved for when there is an error 
            // and our message is in the text
            if(this.busy == false && this.progressText.length > 0) {
                return "#ff000f" ;
            }

            return "#00ff0f";
        },

        dynamicPointsList() {
            let untouched = [...this.points];
            untouched = untouched.filter(p => !this.findById(p, this.updatePoints));
            untouched = untouched.filter(p => !this.findById(p, this.removePoints));
            let list = [...untouched, ...this.updatePoints, ...this.newPoints, ...this.removePoints];

            // CUSTOMER ADDRESS BOOK SEARCH CRITERIA
            if(this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim()+".*", 'i');

                // 1. MATCH ADDRESS
                let address_matches = list.filter((p) => regex.test(p.address));

                // 2. MATCH NICKNAMES
                let nickname_matches = []
                list.forEach(p => {
                    for(let i = 0; i < p.nicknames.length; i++) {
                        let n = p.nicknames[i];

                        if(regex.test(n)) {
                            nickname_matches.push(p);
                            break; // we no longer need to consider this point
                        }
                    }
                });

                // 3. MATCH FACILITY NAMES
                let facility_matches = list.filter((p) => regex.test(p.facility));

                // Return unique entries only!
                let filtered_list = nickname_matches.concat(address_matches.filter((p) => !this.findById(p, nickname_matches)));
                return filtered_list.concat(facility_matches.filter((p) => !this.findById(p, filtered_list)));
            }

            return list;
        },

        allAreDropped() {
            let len = this.dynamicPointsList.length;
            return len == this.removePoints.length && len > 0;
        },
        
        getStagedChangesLabel() {
            let arr = [];
            let update = this.updatePoints.length;
            let removed = this.removePoints.length;
            let newest = this.newPoints.length;
            let untouched = this.points.length;

            if(untouched > 0) {
                let entry = untouched == 1? ' entry' : ' entries';
                arr.push('' + untouched + entry);
            }

            if(newest > 0) {
                arr.push('' + newest + ' to add');
            }

            if(update > 0) {
                arr.push('' + update + ' to update');
            }
            
            if(removed > 0) {
                arr.push('' + removed + ' to delete');
            }

            return arr.join(', ');
        },

        getFixOptions() {
            if(this.errorId) {
                return [{text: 'Manual Fix', on: 'fix', title: 'Use the autocomplete feature to find a better address'}, 'Cancel'];
            }

            return [];
        }
    },
    methods: {
        makeGoogleFriendly(address) {
            // google maps does not like Dr.
            return address.replace('Dr.', 'Dr');
        },

        getPointStyle(point) {
            let style = {
                "background-color": "#eaeaea",
                "padding":"1%",
                "margin":"1%",
            };

            if(this.findById(point, this.newPoints)) {
                style["background-color"] = "#dbdbfb";
            } else if(this.findById(point, this.removePoints)) {
                style["background-color"] = "#fbdbdb";
            } else if(this.findById(point, this.updatePoints)) {
                style["background-color"] = "#dbfbdf";
            }

            return style;
        },

        getPointTitle(point) {
            if(this.findById(point, this.newPoints)) {
                return "You are creating a new pickup point";
            } else if(this.findById(point, this.removePoints)) {
                return "You are removing this pickup point";
            } else if(this.findById(point, this.updatePoints)) {
                return "You are updating this pickup point";
            }

            return "This pickup point is unchanged";
        },

        isPointRemoved(point) {
            return this.findById(point, this.removePoints);
        },

        isPointChanged(point) {
            return this.findById(point, this.updatePoints);
        },

        allowFix(newList, errorId) {
            this.canGoBack = true;
            this.showSaveReminder = false;
            this.busy = false;
            this.errorId = errorId ?? null;

            if(newList) {
                this.updatePoints = this.updatePoints.filter(p => newList.filter(p2 => this.pointsAreEqual(p, p2, true)).length == 0);
                this.newPoints = this.newPoints.filter(p => newList.filter(p2 => this.pointsAreEqual(p, p2, true)).length == 0);
            }
        },

        clear() {
            this.newPoints = [];
            this.removePoints = [];
            this.updatePoints = [];
            
            this.allowFix();
        },

        back() {
            if(this.busy) {
                return;
            }

            if(!this.canGoBack) {
                // reset all
                this.canGoBack = true;
                this.showSaveReminder = true;
                this.progress = 0;
                this.maxProgress = 0;
                this.progressText = '';
                return;
            }

            this.clear(); 
            this.$emit('back');
        },

        formatPhoneNumber (str) {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');
            
            //Check if the input is of correct
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            
            if (match) {
                //Remove the matched extension code
                //Change this to format for any country code.
                let intlCode = (match[1] ? '+1 ' : '')
                return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            }
            
            return null;
        },

        add(point) {
            point.phone = point.phone.replace(/\D/g,'');
            point._id = this.nextDummyId();
            this.canGoBack = this.showSaveReminder = false;
            this.newPoints.unshift(point);
        },

        edit(point) {
            point.phone = point.phone.replace(/\D/g,'');
            this.canGoBack = this.showSaveReminder = false;

            if(!this.pointIsLocal(point)) {
                let result = this.findById(point, this.updatePoints);

                if(result) {
                    this.copyPoint(result, point);
                } else {
                    this.updatePoints.push(point);
                }

                return;
            }

            // else point is local and not saved in DB
            
            let result = this.findById(point, this.newPoints);

            if(result) {
                this.copyPoint(result, point);
            }
        },

        nextDummyId() {
            return "DUMMY-" + this.dummyPointId++;
        },

        findById(point, arr) {
            return arr.filter(p => p._id == point._id).shift();
        },

        pointIsLocal(point) {
            return point._id.startsWith("DUMMY");
        },

        pointsAreEqual(p1, p2, skipId) {
            for(let i = 0; i < p1.nicknames.length; i++) {
                if(p2.nicknames.indexOf(p1.nicknames[i]) == -1) {
                    return false;
                }
            }

            return (skipId? true : p1._id == p2._id)
            && p1.address == p2.address 
            && p1.facility == p2.facility 
            && p1.phone == p2.phone;
        },

        copyPoint(pDest, pSrc) {
            pDest._id = pSrc._id;
            pDest.address = pSrc.address;
            pDest.facility = pSrc.facility;
            pDest.phone = pSrc.phone;
            pDest.nicknames = [...pSrc.nicknames];
        },

        removePickupPoint(point) {
            this.canGoBack = this.showSaveReminder = false;
            let result = this.findById(point, this.removePoints);

            if(!result) {
                this.removePoints.push(point);

                this.updatePoints = this.updatePoints.filter(p => p._id != point._id);
                this.newPoints = this.newPoints.filter(p => p._id != point._id);
            }

            return;
        },

        restorePickupPoint(point) {
            this.canGoBack = this.showSaveReminder = false;

            this.updatePoints = this.updatePoints.filter(p => p._id != point._id);
            this.removePoints = this.removePoints.filter(p => p._id != point._id);

            if(this.pointIsLocal(point)) {
                this.newPoints.push(point);
            }
        },

        emitSaveData() {
            this.filter = '';

            let untouched = [...this.points];
            untouched = untouched.filter(p => !this.findById(p, this.updatePoints));
            untouched = untouched.filter(p => !this.findById(p, this.removePoints));

            this.$emit('save', {
                dropped: this.removePoints,
                created: this.newPoints,
                updated: this.updatePoints,
                untouched: untouched
            });

            this.showSaveReminder = false;
            this.busy = true;
            this.errorId = null; // reset
        },

        emitNewPointPrompt() {
            this.$emit('new');
        },

        emitEditPointPrompt(point) {
            if(!point) return;
            this.$emit('edit', point);
        },

        fullPointFromId(id) {
            if(!id) return;

            const p = { _id: id };

            let newPoint = this.findById(p, this.newPoints);
            if(newPoint) {
                return newPoint;
            }

            let updatePoint = this.findById(p, this.updatePoints);
            if(updatePoint) {
                return updatePoint;
            }

            let removePoint = this.findById(p, this.removePoints);
            if(removePoint) {
                return removePoint;
            }

            return null;
        },

        dropAll() {
            let remove = [...this.dynamicPointsList];
            for(let i = 0; i < remove.length; i++) {
                this.removePickupPoint(remove[i]);
            }
        },

        undoDropAll() {
            let revert = [...this.removePoints];
            for(let i = 0; i < revert.length; i++) {
                this.restorePickupPoint(revert[i]);
            }
        },

        launchFixModal(id) {
            this.emitEditPointPrompt(this.fullPointFromId(id));
        },

        async importPickupPoints(event) {
            let vm = this;

            this.busy = true;

            let file = event.target.files[0];

            var reader = new FileReader();
            reader.onload = (e) => {
                // preserve original points, but overwrite any incoming changes
                vm.removePoints = [];
                vm.updatePoints = [];
                vm.newPoints = [];

                let csv = e.target.result;

                const space = ' ';
                const comma = ', ';
                const rows = csv.split("\n");

                let i = 0;
                let msg = "";
                rows.map(function (row) {
                    i++;
                    let values = row.split(',');
                    
                    if(values.length % 11 > 0) {
                        msg += `csv is malformed: skipping row ${i}\n`;
                        return;
                    }

                    for(let j = 0; j < values.length; j++) {
                        values[j] = values[j].trim();
                    }

                    let facility = values[1];
                    let phone = values[2];

                    if(values[10] == 'US') {
                        values[10] = 'USA';
                    }
                    
                    let address = values[3] 
                    + (values[4].length > 0? (values[4] + comma) : comma)
                    + values[5] + comma 
                    + values[6] + space 
                    + values[7] + comma 
                    + values[10];

                    const point = {
                        _id: vm.nextDummyId(),
                        facility: facility,
                        phone: phone,
                        address: vm.makeGoogleFriendly(address),
                        nicknames: []
                    };

                    // only add new items
                    if(vm.points.filter(p => vm.pointsAreEqual(p, point, true)).length == 0) {       
                        vm.newPoints.push(point);
                    }
                });

                if(msg.length > 0) {
                    alert(msg);
                }

                vm.canGoBack = vm.newPoints.length == 0;
                vm.showSaveReminder = false;
                vm.busy = false;
            }
            reader.readAsText(file);
        },
    }
}

</script>

<style scoped>
.error-msg {
    color: red;
    text-align: center;
}

.entries-msg {
    margin-top: 1%;
}
.scrolling-section {
    max-height: 570px; 
    overflow-y:scroll; 
    overflow-x:hidden;
    scrollbar-color: #4e4e4e #e4e4e4;
    scrollbar-width: thin;
}
.tooltiptext {
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 105%;

  /* Start the shake animation and make the animation last for 1s seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

div.search i {
    left: -30px;
    position: relative;
}

a {
    text-decoration: underline dotted;
}
</style>