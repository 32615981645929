<template>
        <div class="content-header">

            <div class="container-fluid">

                <Nav :hideUserButtons="true"/>

                <Otp :username="email"
                    :phoneNo="cleanPhoneNo"
                    :countryCode="countryCode"
                    :signUp="true"
                    v-if="waitingForOTP"
                    @verify="verifyOTP"
                    @resend="handleOtpResend"
                    @error="handleError">
                </Otp>

                <form @submit.prevent="verifyPhone" v-if="!waitingForOTP">

                    <div class="row">

                        <h2>New Customer Registration</h2>

                        <div class="col-md-12">

                            <div class="Customer-edit-form">
                                  <p v-if="preview">

                                    <img :src="preview" class="img-fluid driver-pic">

                                </p>

                                <p v-else>

                                    <img src="../assets/img/image.svg" alt="">

                                </p>

                                <div class="image-upload">

                                    <label for="file-input">

                                      <img src="../assets/img/edit-icon.svg" class="logo-upload-btn"/>

                                    </label>

                                    <input id="file-input" type="file" :name="uploads" class="input-file" @change="photoUpload" />

                                    <input type="hidden" v-model="upload_id">

                                </div>

                            </div>

                        </div>

                        <div class="col-md-12 mt-4">

                            <div class="form-row">

                                <div class="col-md-12 mb-4">Login Info</div>

                                <div class="form-item col-md-6">

                                    <input type="text" id="name" v-model="username" autocomplete="off" required>

                                    <label for="name" style="background: #f4f6f9;">New Username</label>

                                </div>


                                <div class="form-item col-md-6">

                                    <input type="text" id="password" v-model="password" autocomplete="off" required>

                                    <label for="password" style="background: #f4f6f9;">Set Password</label>

                                </div>

                                  <div class="form-item col-md-1" hidden>

                                    <input type="text" id="country" v-model="countryCode" autocomplete="off" required>

                                    <label for="country" style="background: #f4f6f9;">Phone Country Code</label>

                                </div>

                                <div class="form-item col-md-4">

                                    <input required
                                        type="tel"
                                        id="phone"
                                        placeholder="123-456-7890"
                                        pattern="([0-9]-)?[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        autocomplete="off"
                                        maxlength="15"
                                        minlength="10"
                                        v-model="phoneNumber"
                                        >

                                    <label for="phone" style="background: #f4f6f9;">Login Phone Number</label>

                                </div>

                                <div class="col-md-12 mb-4">Company Info</div>

                                <div class="form-item col-md-6">

                                    <input type="text" id="company-name" v-model="name" autocomplete="off" required>

                                    <label for="company-name" style="background: #f4f6f9;">Company Name</label>

                                </div>


                                <div class="form-item col-md-6 autocomplete">

                                    <input type="text" id="address" v-model="address" autocomplete="off" v-on:keyup="customerAddress" required>


                                    <!-- <vue-google-autocomplete id="map" v-model="address" placeholder="Start typing" v-on:placechanged="getAddressData" required>

                    </vue-google-autocomplete> -->

                                    <label for="address" style="background: #f4f6f9;">Company Address</label>

                                    <ul v-show="isOpen[0]" class="autocomplete-results">

                                        <li v-for="(result, i) in results" :key="i" @click="setResult(`${result.description}`, `${result.geolocation.lat}`,`${result.geolocation.lng}`)" class="autocomplete-result">{{result.description}}</li>

                                    </ul>

                                </div>

                                <div class="col-md-12 mb-4">Contact Info</div>

                                <div class="form-item col-md-4">

                                    <input type="text" id="first-name" v-model="first_name" autocomplete="off" required>

                                    <label for="first-name" style="background: #f4f6f9;">Customer First Name</label>

                                </div>

                                <div class="form-item col-md-4">

                                    <input type="text" id="last-name" v-model="last_name" autocomplete="off" required>

                                    <label for="last-name" style="background: #f4f6f9;">Customer Last Name</label>

                                </div>


                                <div class="form-item col-md-4 mt-2">

                                    <input type="checkbox" class="requiresPhotoSignature_customer" v-model="requiresPhotoSignature_customer" style="width: 5%;margin-top: 5px;margin-left: 10px;">

                                    <label for="requiresPhotoSignature_customer" style="background: #f4f6f9;">We Require Photo And Signature Proof Of Delivery</label>

                                </div>


                                <div class="form-item col-md-12"></div>

                                <div class="form-item col-md-12" v-if="dept.length == 0">

                                    <div class="col-md-12 mt-4" style="text-align:center">

                                        <h6>No Departments</h6>

                                            <img title="Add a new department" src="../assets/img/Add-file-icon.svg" alt="" style="width:5%; cursor:pointer;" @click="add(0)">

                                    </div>

                                </div>

                                <div class="row" v-for="(input,k) in dept" :key="k">

                                    <div class="col-md-6 mt-4 department">

                                        <h6>Department {{k+1}}</h6>

                                    </div>

                                    <div class="col-md-6 mt-4 department">

                                        <div class="customer-edit-form-img">

                                            <img title="Add another department" src="../assets/img/Add-file-icon.svg" alt="" style="width:5%; cursor:pointer;" @click="add(k+1)" v-show="k == dept.length-1">

                                            <img title="Remove this department" @click="remove(k+1)" src="../assets/img/delete-icon.svg" alt="" style="width:3%; cursor:pointer;" v-on:click="addRow">

                                        </div>

                                    </div>

                                    <div class="form-item col-md-4 mt-4">

                                        <input type="text" id="department-name" v-model="input.departmentName" autocomplete="off" required>

                                        <label for="department-name" style="background: #f4f6f9;">Department Name</label>

                                    </div>

                                    <div class="form-item col-md-4 mt-4">

                                        <input
                                            type="tel"
                                            :id="'phone'+k"
                                            placeholder="123-456-7890"
                                            pattern="([0-9]-)?[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                            autocomplete="off"
                                            maxlength="15"
                                            minlength="10"
                                            v-model="input.departmentPhoneNo"
                                            required
                                            >

                                        <label for="name" style="background: #f4f6f9;">Department Phone</label>

                                    </div>

                                    <div class="form-item col-md-4 mt-4">

                                        <input type="email" id="department-email" v-model="input.departmentEmail" autocomplete="off" required>

                                        <label for="department-email" style="background: #f4f6f9;">Department Email</label>

                                    </div>

                                    <div class="form-item col-md-8 autocomplete">

                                        <input type="text" id="department-address" autocomplete="off" v-model="input.departmentAddress" v-on:keyup="customerAddressDept(k+1, $event)" required>

                                        <label for="department-address" style="background: #f4f6f9;">Department Address</label>

                                        <ul v-show="isOpen[k+1]" class="autocomplete-results">

                                            <li v-for="(result, i) in customerDepartmentAddress[k]" :key="i" @click="setResultDept(`${result.description}`,k+1, `${result.geolocation.lat}`,`${result.geolocation.lng}`)" class="autocomplete-result">{{result.description}}</li>

                                        </ul>

                                    </div>

                                    <div class="form-item col-md-4">

                                        <input type="text" id="department-contact" v-model="input.departmentContactPersonName" autocomplete="off" required>

                                        <label for="department-contact" style="background: #f4f6f9;">Department Point-Of-Contact</label>

                                    </div>

                                    <div class="form-item col-md-4 mt-4">

                                        <input type="checkbox" class="requiresPhotoSignature" v-model="input.requiresPhotoSignature" style="width: 5%;margin-top: 5px;margin-left: 10px;">

                                        <label for="requiresPhotoSignature" style="background: #f4f6f9;">This Department Requires Photo And Signature Proof Of Delivery</label>

                                    </div>

                                </div>

                                <div id="content"> </div>

                                <div class="form-item col-md-4"></div>


                            </div>

                        </div>

                    </div>

                    <div class="row mt-2 mb-4">

                        <div class="col-md-12">

                            <div class="drivers-btn">

                                <button type="submit" class="btn btn-block btn-info">COMPLETE REGISTRATION</button>

                            </div>

                        </div>

                    </div>

                </form>

            </div>

        </div>

</template>
<script>
import API from '../axios.config';
import eznavigator from '../eznavigator';
import Nav from '@/components/Nav';
import Otp from '@/components/Otp.vue';
import utils from '../utils'

export default {
    name: 'CustomerRegistration',
    components: {
        Nav, Otp
    },
    data() {
        return {
            name: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            countryCode: 'US', // default US code
            phoneNumber: '',
            cleanPhoneNo: '',
            address: '',
            email: '',
            username: '',
            password: '',
            show: 'd-none',
            deptArrayIds: [],
            photos: '',
            requiresPhotoSignature_customer: false,
            dept: [],
            deptWatchers: [],
            addressLocation: {
                Latitude: '',
                Longitude: '',
            },
            upload_id: '',
            preview: null,
            roles: '',
            profileImage: '',
            uploads: [],
            results: [],
            isOpen: [],
            customerDepartmentAddress: [],
            problemWithAddressLookupFlag: false,
            waitingForOTP: false,
            phoneVerified: false
        }
    },

    mounted() {
        this.isOpen[0] = false;
    },
    watch: {
        phoneNumber(val) {
            this.phoneNumber = utils.cleanPhoneNumberInput(val)
        },
        phoneVerified(newVal) {
            if (newVal) {
                this.addDepartments()
            }
        }
    },
    methods: {
        setResult(event, lat, lng) {
            this.address = event;
            this.isOpen[0] = false; // index 0 closes customer profile address
            this.addressLocation.Longitude = lng;
            this.addressLocation.Latitude = lat;
        },
        async customerAddress() {
            const vm = this;

            vm.isOpen[0] = false;

            // zero length address makes the endpoint error
            let address = vm.address;
            if(address.length == 0) return;

            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let geolocation = await eznavigator.getLatLong();

            await API.post('/customer-address-autocomplete', {
                    address: address,
                    location: geolocation
                }, {
                    headers: header
                })
                .then(function(response) {
                    if (response.status == 200) {
                        vm.results = response.data.data.google_results;
                        vm.isOpen[0] = true;
                    }
                })
                .catch(function(_) {
                    if(!vm.problemWithAddressLookupFlag) {
                        vm.$toast.open({
                            message: 'There was a problem fetching nearby addresses. Please type your address manually.',
                            type: 'error',
                            position: 'top'
                        });

                        vm.problemWithAddressLookupFlag = true;
                    }

                    vm.isOpen[0] = false;
                })

        },
        setResultDept(event, k, lat, lng) {
            this.dept[k-1].departmentAddress = event;
            this.isOpen[k] = false;
            this.dept[k-1].departmentLocation.Longitude = lng;
            this.dept[k-1].departmentLocation.Latitude = lat;
        },
        async customerAddressDept(k, $event) {
            const vm = this;

            vm.isOpen[k] = false;

            let address = $event.target.value;

            if(address.length == 0) return;

            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let geolocation = await eznavigator.getLatLong();

            await API.post('/customer-address-autocomplete', {
                    address: address,
                    location: geolocation
                }, {
                    headers: header
                }, )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.customerDepartmentAddress[k-1] = response.data.data.google_results;
                        vm.isOpen[k] = true;
                    }
                })
                .catch(function(_) {
                    if(!vm.problemWithAddressLookupFlag) {
                        vm.$toast.open({
                            message: 'There was a problem fetching nearby addresses. Please type your address manually.',
                            type: 'error',
                            position: 'top'
                        });

                        vm.problemWithAddressLookupFlag = true;
                    }

                    vm.isOpen[k] = false;
                })
        },

        getAddressData: function(addressData) {
            const vm = this;
            vm.address = addressData;
        },

        async addDepartments() {
            const vm = this;

            let loginToken = localStorage.getItem("user-info");
            let header = {
                'Authorization': `Bearer ${loginToken}`,
            }

            if (vm.dept.length > 0) {
                const dept_copy = [...vm.dept]
                for(let i = 0; i < dept_copy.length; i++) {
                    dept_copy[i].departmentPhoneNo = utils.cleanPhoneNumberForAPI(dept_copy[i].departmentPhoneNo)
                }

                await API.post('/department',
                    dept_copy,
                    { headers: header },
                    )
                    .then(function(response) {
                        if (response.status == 200) {

                            for (let i = 0; i < response.data.data.length; i++) {
                                vm.deptArrayIds.push(response.data.data[i]._id);
                            }
                            vm.userUpdate();

                        }
                    })
                    .catch(function(error) {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: 'error',
                            position: 'top'
                        });
                    })
            } else {
                vm.userUpdate();
            }
        },

        add() {
            this.dept.push({
                departmentName: '',
                departmentEmail: '',
                departmentAddress: '',
                departmentPhoneNo: '',
                requiresPhotoSignature: false,
                departmentLocation: {
                    Latitude: '',
                    Longitude: '',
                },
                departmentContactPersonName: '',

            })

            this.watchSingleDepartmentValue(this.dept.length-1)

            this.isOpen.push(false);
            this.customerDepartmentAddress.push({})
        },

        remove(index) {
            this.deptWatchers[index-1].unwatch()
            this.deptWatchers.splice(index-1, 1)
            this.dept.splice(index-1, 1)
            this.isOpen.splice(index, 1)
            this.customerDepartmentAddress.splice(index-1, 1)
        },

        async customerUpdate() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info");
            let customer_id = localStorage.getItem("customer_id");

            let header = {
                'Authorization': `Bearer ${loginToken}`,
            }

            let formData = new FormData();

            if (vm.photos != undefined) {
                formData.append("photos", vm.photos);
            }
            for (var i = 0; i < vm.deptArrayIds.length; i++) {
                formData.append("departments[" + i + "]", vm.deptArrayIds[i]);
            }
            formData.append("signatures", vm.photos);
            formData.append("attachments", vm.photos);
            formData.append("name", vm.name);
            formData.append("address", vm.address);
            formData.append("addressLocation.Latitude", vm.addressLocation.Latitude);
            formData.append("addressLocation.Longitude", vm.addressLocation.Longitude);
            formData.append("requiresPhotoSignature", vm.requiresPhotoSignature_customer);

            await API.put(`/customer/${customer_id}`,
                    formData, {
                        headers: header
                    },

                )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.$toast.success('Profile Added successfully.', {
                            // override the global option
                            position: 'top'
                        })
                        vm.$router.push(`/dashboard`)
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    })
                })

        },
        async userUpdate() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info");
            let user_id = localStorage.getItem("user_id");

            let header = {
                'Authorization': `Bearer ${loginToken}`,

            }
            await API.put(`/users/${user_id}`, {
                        first_name: vm.first_name,
                        last_name: vm.last_name,
                        username: vm.username,
                        email: vm.email,
                        password: vm.password,
                        countryCode: vm.countryCode,
                        phoneNo: utils.cleanPhoneNumberForAPI(vm.phoneNumber),

                    }, {
                        headers: header
                    },
                )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.customerUpdate();
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    })
                })
        },

        async verifyPhone() {
            const vm = this;
            vm.phoneVerified = false
            vm.email = localStorage.getItem("username");
            let loginToken = localStorage.getItem("loginToken");
            let header = {
                'Authorization': `Bearer ${loginToken}`,
            }

            vm.cleanPhoneNo = utils.cleanPhoneNumberForAPI(vm.phoneNumber)

            await API.post('/send-twilio-ott-code', {
                    phoneNo: vm.cleanPhoneNo,
                    countryCode: vm.countryCode,
                },
                {
                    headers: header
                },
            )
            .then(function(response) {
                if (response.status == 200) {
                    vm.waitingForOTP = true
                }
            })
            .catch(function(error) {
                vm.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            });
        },

        async photoUpload(event) {
            const vm = this;
            vm.photos = event.target.files[0];

            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                }
                this.image = input.files[0];
                reader.readAsDataURL(input.files[0]);
            }
        },

        watchDepartmentValues() {
            this.deptWatchers.forEach(watcher => watcher.unwatch())
            this.deptWatchers = []

            for (let i = 0; i < this.dept.length; i++) {
                this.watchSingleDepartmentValue(i)
            }
        },

        watchSingleDepartmentValue(idx) {
            if(idx < this.deptWatchers) {
                this.deptWatchers[idx].unwatch()
                this.deptWatchers.splice(idx, 1)
            }

            const dept_i = this.dept[idx]

            // pre-format because we are adding a watcher late
            dept_i.departmentPhoneNo=utils.cleanPhoneNumberInput(dept_i.departmentPhoneNo)

            // add watcher
            const unwatch =
                this.$watch(
                    () => dept_i.departmentPhoneNo,
                    () => {dept_i.departmentPhoneNo=utils.cleanPhoneNumberInput(dept_i.departmentPhoneNo)},
                    {deep:true})
            this.deptWatchers.splice(idx, 0, {unwatch: unwatch})
        },

        verifyOTP() {
            this.$toast.success("Phone Verified Successfully", {
                position: 'top'
            })

            this.waitingForOTP = false
            this.phoneVerified = true
        },

        handleError(error) {
            this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
        },

        handleOtpResend() {
            this.$toast.open({
                    message: "Sending new OTP",
                    type: 'info',
                    position: 'top'
                });

            this.waitingForOTP = true
        }
    }

}
</script>

<style scoped>
.error-msg {
    color: red;
}

.image-upload>input {
    display: none;
}

.image-upload img {
    width: 80px;
    cursor: pointer;
}

.driver-pic {
    width: 150px;
    border-radius: 5%;
    padding: 10px;
    border: solid 1px rgba(150, 150, 150, 0.4);
}

.logo-upload-btn {
    transition: all 0.2s ease;
    max-width: 35px;
    position: relative;
    top: -40px;
    left: 40px;
    border-radius: 100%;
}

.logo-upload-btn:hover {
    top:-45px;
    max-width: 35px;
    box-shadow: 2px 2px 5px 10px rgba(144, 214, 255, 0.6);
}

.autocomplete {
  position: relative;
  display: inline-block;
}

.autocomplete-result {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-result:hover {
  background-color: #e9e9e9;
}

.autocomplete-results {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  list-style-type: none;
  padding-left: 0px;
  margin-left: 15px;
}

.department {
    width: 100%;
}

h2 {
    margin: auto;
    text-align: center;
    padding: 1%;
}
</style>
