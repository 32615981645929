import Login from '@/views/Login.vue'
import OtpLogin from '@/views/OtpLogin.vue'
import ForgotPassword from '@/views/ForgotPassword'
import ContactSupport from '@/views/ContactSupport.vue'
import Calendar from '@/components/Calendar.vue'
import Dashboard from '@/views/Dashboard.vue'
import DriversList from '@/views/DriversList'
import CustomersList from '@/views/CustomersList.vue'
import CustomerRegistration from '@/views/CustomerRegistration.vue'
import DriverRegistration from '@/views/DriverRegistration.vue'
import NewDriverRegistration from '@/views/NewDriverRegistration.vue'
import NewCustomerRegistration from '@/views/NewCustomerRegistration.vue'
import MagicLink from '@/views/MagicLink';
import Profile from '@/views/Profile.vue'
import EditDriverRegistration from '@/views/EditDriverRegistration.vue'
import EditCustomerRegistration from '@/views/EditCustomerRegistration.vue'
import Jobs from '@/views/Jobs.vue'
import AddJob from '@/views/AddJob.vue'
import EditJob from '@/views/EditJob.vue'
import ViewJob from '@/views/ViewJob.vue'
import NotFound from '@/views/NotFound.vue'
import Billing from '@/views/Billing.vue'
import RequestCourier from '@/views/RequestCourier.vue'
import FleetManagement from '@/views/FleetManagement.vue'
import HomeSchedule from '@/views/HomeSchedule.vue'
import EditProfile from '@/views/EditProfile.vue'
import VerifyPassword from '@/views/VerifyPassword.vue'
import DriverProfile from '@/views/DriverProfile.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import ViewCalendar from '@/views/ViewCalendar'
import Logout from '@/views/Logout'
import CMS from '@/views/CMS'
import WeeklyRouteMgmt from '@/views/WeeklyRouteMgmt'
import Home from '@/views/Home.vue'
import ChatBot from '@/views/ChatBot.vue'
import GMapWithCachedPath from '@/views/GMapWithPath.vue'
import MagicLinkMgmt from '@/views/MagicLinkMgmt.vue'
import SubscribersList from '@/views/SubscribersList.vue'
import AuditTable from '@/views/AuditTable.vue'
import {createRouter, createWebHashHistory} from 'vue-router'

const routes=[
	{
		name:'Home',
		component:Home,
		path:'/'
	},
	{
		name:'Login',
		component:Login,
		path:'/login'
	},
	{
		name:'Logout',
		component:Logout,
		path:'/logout'
	},
	{
		name:'ForgotPassword',
		component:ForgotPassword,
		path:'/forgot-password'
	},
	{
		name:'ContactSupport',
		component:ContactSupport,
		path:'/contact-support'
	},
	{
		name:'Calendar',
		component:Calendar,
		path:'/calendar'
	},
	{
		name: 'ChatBot',
		component:ChatBot,
		path:'/chatbot' 
	},
	{
		name:'OtpLogin',
		component:OtpLogin,
		path:'/otp'
	},
	{
		name:'Dashboard',
		component:Dashboard,
		path:'/dashboard'
	},
	{
		name:'AuditTable',
		component:AuditTable,
		path:'/audit'
	},
	{
		name:'DriversList',
		component:DriversList,
		path:'/drivers-list'
	},
	{
		name:'CustomersList',
		component:CustomersList,
		path:'/customers-list'
	},
	{
		name:'CustomerRegistration',
		component:CustomerRegistration,
		path:'/customer-registration'
	},
	{
		name:'NewCustomerRegistration',
		component:NewCustomerRegistration,
		path:'/new-customer-registration'
	},
	{
		name:'DriverRegistration',
		component:DriverRegistration,
		path:'/driver-registration'
	},
	{
		name:'NewDriverRegistration',
		component:NewDriverRegistration,
		path:'/new-driver-registration'
	},
	{
		name:'EditDriverRegistration',
		component:EditDriverRegistration,
		path:'/edit-driver-registration'
	},
	{
		name:'EditCustomerRegistration',
		component:EditCustomerRegistration,
		path:'/edit-customer-registration'
	},
	{
		name:'MagicLink',
		component:MagicLink,
		path:'/magic-link'
	},
	{
		name:'Profile',
		component:Profile,
		path:'/profile'
	},
	{
		name:'Jobs',
		component:Jobs,
		path:'/jobs'
	},
	{
		name:'AddJob',
		component:AddJob,
		path:'/add-job'
	},
	{
		name:'EditJob',
		component:EditJob,
		path:'/edit-job'
	},
	{
		name:'ViewJob',
		component:ViewJob,
		path:'/view-job'
	},
	{
		name:'RequestCourier',
		component:RequestCourier,
		path:'/request-courier'
	},
	{
		name:'FleetManagement',
		component:FleetManagement,
		path:'/fleet-management'
	},
	{
		name:'HomeSchedule',
		component:HomeSchedule,
		path:'/home-schedule'
	},
	{
		name:'EditProfile',
		component:EditProfile,
		path:'/edit-profile'
	},
	{
		name:'VerifyPassword',
		component:VerifyPassword,
		path:'/verify-password'
	},
	{
		name:'DriverProfile',
		component:DriverProfile,
		path:'/driver-profile'
	},
	{
		name:'PrivacyPolicy',
		component:PrivacyPolicy,
		path:'/privacy-policy'
	},
	{
		name:'Billing',
		component:Billing,
		path:'/billing'
	},
	{
		name:'Calendar',
		component:ViewCalendar,
		path:'/calendar'
	},
	{
		name:'CMS',
		component: CMS,
		path:'/cms'
	},
	{
		name:'Weekly Route Mgmt',
		component: WeeklyRouteMgmt,
		path:'/weekly-route-mgmt'
	},
	{
		name: '404',
		component: NotFound,
		path:'/:pathMatch(.*)*'
	},
	{
		name: 'DriverRoutePath',
		component: GMapWithCachedPath,
		path:'/path-preview'
	},
	{
		name: 'Magic Link Mgmt',
		component: MagicLinkMgmt,
		path:'/magic-link-mgmt'
	},
	{
		name:'SubscribersList',
		component:SubscribersList,
		path:'/subscribers-list'
	},
]

const router = createRouter({
	history:createWebHashHistory(),
	mode: 'hash',
	routes
})

router.beforeEach(async (to, _from) => {
	const isAuthenticated = localStorage.getItem("user-info")
	const publicPages = [
		'Login',
		'404',
		'PrivacyPolicy',
		'VerifyPassword',
		'MagicLink',
		'ForgotPassword',
		'ContactSupport',
		'Home'
	]

	const isPublicPage = publicPages.findIndex(p => to.name == p) > -1

	if (!isAuthenticated && !isPublicPage) {
	  // redirect the user to the login page
	  return { name: 'Login' }
	}
  })

export default router