<template>
    <span class="center">
        <button v-for="i in utils.range(total)" :key="i" :title="_title(i+1)" :class="_class(i+1)" @click.prevent="handleClick(i+1)">{{ i+1 }}</button>
    </span>
</template>

<script>
import utils from '@/utils'

export default {
    name: 'Pagination',
    emits: ['click'],
    components: {
    },
    props: {
        page: {
            type: Number
        },
        total: {
            type: Number
        
        }
    },
    computed: {
    },
    data() {
        return { utils: utils }
    },
    methods: {
        handleClick(page) {
            this.$emit('click', page)
        },
        
        _title(idx) {
            if(idx == this.page) {
                return `Showing page ${idx}`
            }

            return `Jump to page ${idx}`
        },

        _class(idx) {
            if(idx == this.page) {
                return "paginate-btn selected lerp"
            }

            return "paginate-btn lerp"
        }
    }
}
</script>

<style scoped>
.center {
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    scrollbar-width: thin;
    scrollbar-color: #343a40 rgba(0, 0, 0, 0);
}

.right-align {
    text-align: right;
}

.paginate-btn {
    border-radius: 5px;
    background-color: #343a40;
    color: white;
}

.paginate-btn.selected {
    background-color: #2d98ca;
}

.lerp {
  transition: all 0.15s ease;
}

</style>