<template>
    <Topnav />

    <Sidebar />

    <div class="content-wrapper" @mousemove="onMouseMove">

        <div class="content-header">

            <div class="container-fluid">

                <div class="row mt-4">

                    <!-- search bar -->
                    <div class="col-md-8 search">

                        <input type="text" placeholder="Search..." v-model="filter"/>

                        <i class="fa fa-search"></i>

                    </div>
                </div>

                <hr/>

                <DynamicTable ref="dynamicTable"
                :header="tableData.header"
                :data="tableData.data"
                :filter="filterData"
                :replace="replacementRules"
                :pageCount="tableData.pageCount"
                :perPage="45"
                :selectedPage="tableData.pageIdx"
                :serverSidePagination="true"
                @jump="handlePageJump"/>

            </div>

        </div>

    </div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import DynamicTable from '@/components/DynamicTable.vue';
import API from '../axios.config';
import utils from '../utils';

export default {
    name: 'AuditTable',
    components: {
        Topnav,
        Sidebar,
        DynamicTable,
    },
    data() {
        return {
            allDriversMapper: {}, // an associative array mapping id -> name
            tableData: {
                data: [],
                header: [],
                preventDefault: false,
                busy: false,
                pageCount: 1,
                pageIdx: 1,
            },
            filter: '',
            mouse: {x: 0, y: 0},
            clickedMouse: {x: 0, y: 0},
        }
    },
    computed: {
        replacementRules() {
            return {
            }
        },
    },

    async mounted() {
        this.tableData.busy = true

        this.checkRole()
        await this.fetchTable()

        // Note: For the customer 05/11/2023
        this.$toast.open({
            message: "The Audit Table is READ-ONLY",
            type: 'info',
            position: 'top',
            duration: 0, // manual dismiss
        })

        this.tableData.busy = false
    },
    methods: {
        async checkRole() {
            const vm = this;
            let userId = localStorage.getItem("user_id");
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            let loginToken = localStorage.getItem("user-info")

            API.checkRole(userId, loginToken, ()=>{}, ()=>{
                vm.$router.push('Profile') // redirect to the user's profile
            });
        },

        async fetchDrivers() {
            const vm = this
            let loginToken = localStorage.getItem("user-info")

            try {
                let response = await API.get(`/driver`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })

                if (response.status == 200) {
                    vm.allDriversMapper = {}
                    response.data.data.forEach(el => {
                        vm.allDriversMapper[el._id] = `${el.userId.first_name} ${el.userId.last_name}`
                    })
                }
            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    })
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear()
                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    })
                }
            }
        },

        async apiGetTableData(tableName) {
            let loginToken = localStorage.getItem("user-info")

            try {
                let results = await API.get(utils.pagination(`/schemas/${tableName}`, this.tableData.pageIdx, 45), {
                    params: { },
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                this.filter = ''
                return {data: [...results.data.data], pageCount: results.data.pages}
            }
            catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                })
            }

            return null
        },

        async fetchTable() {
            this.tableData.busy = true

            const results = await this.apiGetTableData("Audit")

            if(!results) return // abort

            this.tableData.busy = false
            const tdata = utils.makeTableFromResults(results.data)
            this.tableData.header = tdata.header
            this.tableData.data = tdata.data
            this.tableData.pageCount = results.pageCount
        },

        handleCellClick(event) {
            // We only care about array element inspections
            if(event.type != "array") return

            this.tableData.header = ["value"]
            this.tableData.data = event.value.map((v) => { return {value: v}})

            this.clickedMouse = {x: this.mouse.x, y: this.mouse.y }
        },

        filterData(data) {
            if(this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim()+".*", 'i');
                for(const [_, value] of Object.entries(data)) {
                    if(regex.test(value)) {
                        return true;
                    }
                }

                return false
            }

            return true
        },

        onMouseMove(e) {
            this.mouse.x = e.pageX
            this.mouse.y = e.pageY
        },

        onMouseLeave() {
            // Reset to clear the widget
            // this.tableData.header = []
            // this.tableData.data = []
        },

        async handlePageJump(pageIdx) {
            this.tableData.pageIdx = pageIdx
            await this.fetchTable()
        }
    }
}
</script>

<style scoped>
div.search i {
    left: -30px;
    position: relative;
}

.mouse-follow {
    position: absolute;
    z-index: 1000;
    overflow:hidden;
}
</style>
