<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid">
                <FullCalendar :options="calendarOptions" />
            </div>
        
        </div>
    
    </div>

</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import '@fullcalendar/core/vdom' // solves problem with Vite (Mav Note: I don't know if I should remove this 10/6/2022)
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
  },
  components: {
    Topnav,
    Sidebar,
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        contentHeight: 600,
        expandRows: true,
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: { center: 'dayGridMonth,timeGridWeek' }, // buttons for switching between views
        events: [
          { title: 'event 1', date: '2022-10-06' },
          { title: 'event 2', date: '2022-10-09' }
        ]
      }
    }
  }
}
</script>

<style scoped>
.t1 { padding: 10px; }
</style>