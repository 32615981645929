
let options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
};
  
const eznavigator = {
    getCurrentPosition: function() {
        return new Promise( (resolve, reject) => {
            if(!navigator.geolocation) {
                reject("Navigator is unavailable");
                return;
            }

            navigator.geolocation.getCurrentPosition(
                position => resolve(position),
                error => reject(error),
                options
            )
        })
    },

    // returns google map's compatible lat/lon array
    getLatLong: async function() {
        // TN geolocation
        let lat = 35.83052;
        let lon =  -85.9786;

        try {
            const position = await this.getCurrentPosition();
            lat = position.coords.latitude;
            lon = position.coords.longitude;
        } catch(error) {
            console.error(error);
        }

        return [lat, lon];
    },

    available: function() {
        return navigator.geolocation != null;
    }
};

export default eznavigator;