<template>
	<Nav />
	<div class="login-box" style="min-height:100vh;">
 <div class="container">
   <div class="row">
     <div class="col-md-3"></div>
     <div class="col-md-6">
          <p class="login-box-msg">TN Oncology – Courier Request</p>
    <div class="card-body">
      <form action="#" method="post">
       <div class="form">
  <div class="form-item">
    <input type="text" id="username" autocomplete="off" required value="test">
    <label for="username" style="background-color: #f0f2f5;">Name *</label>
  </div>
  <div class="form-item">
    <input type="email" id="email" autocomplete="off" required>
   <label for="email" style="background-color: #f0f2f5;">Email *</label>
  </div>
  <div class="form-item">
    <input type="number" id="phone-number" autocomplete="off" required>
    <label for="phone-number" style="background-color: #f0f2f5;">Phone Number *</label>
  </div>
  </div>
    <h6>Pick-Up Information</h6>
    <div class="form-group">
    <label for="exampleFormControlTextarea1" style="font-size: 11px;">What is it? Include patient name if needed</label>
   <textarea class="form-control" id="exampleFormControlTextarea1" rows="8" placeholder="Describe the package and contents. Include any descriptive text or label information." style="padding: 15px 15px;"></textarea>
  </div>
    <div class="form-group">
    <label for="exampleFormControlSelect1" style="font-size: 11px;">Select Location for Pick-Up</label>
    <select class="form-control" id="exampleFormControlSelect1">
      <option>Start typing any part of office name</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
    </select>
  </div>
  <div class="form-group">
  <label for="example" style="font-size: 12px;">What day do we need to pick it up?</label>
  <span class="input-group-text" style="
    margin-left: 0;"> <input placeholder="Select" type="text" id="example" class="form-control"><img src="../assets/img/calendar_date_event_month_icon.svg" alt=" " style="margin-left: -4%;width: 3%;"></span>
  </div>
   <h6>Drop-Off Information</h6>
    <div class="form-group">
    <label for="exampleFormControlSelect1" style="font-size: 11px;">Select Location for Pick-Up</label>
    <select class="form-control" id="exampleFormControlSelect1">
      <option>Start typing any part of office name</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
    </select>
  </div>
  <div class="form-group">
  <label for="example" style="font-size: 12px;">What day do we need to pick it up?</label>
  <span class="input-group-text" style="
    margin-left: 0;"> <input placeholder="Select" type="text" id="example" class="form-control"><img src="../assets/img/calendar_date_event_month_icon.svg" alt=" " style="margin-left: -4%;width: 3%;"></span>
  </div>
  <div class="form-group">
    <label for="exampleFormControlTextarea1" style="font-size: 11px;">Please provide any additional information and/or special requirements.</label>
   <textarea class="form-control" id="exampleFormControlTextarea1" rows="8" placeholder="Additional Information/Instructions." style="padding: 15px 15px;"></textarea>
  </div>
        <div class="row">
          <div class="col-12 mt-4">
           <div class="Request-btn">
              <button type="submit" class="btn btn-primary btn-block" style="width: 30%;padding: 2%;margin: auto;margin-bottom: 5%;">Submit</button>
           </div>
          </div>
        </div>
      </form>

    </div>
     </div>
     <div class="col-md-3"></div>
   </div>
 </div>
</div>
<Footer />
</template>
<script>
	import Nav from '@/components/Nav.vue'
	import Footer from '@/components/Footer.vue'
	export default{
		name:'RequestCourier',
		components:{
			Nav,
			Footer,
		}
	}
</script>