<template>
    <Topnav v-if="isLoggedIn"/>
    
    <Sidebar v-if="isLoggedIn"/>
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid">

                <div class="row">

                    <div class="col-md-4"></div>
                    <div class="col-md-4 message">
                        <h1>404</h1><br/>
                        Page <span class="url" title="Missing or invalid page">{{ currentRoute }}</span> Not Found
                        <hr/>
                        <a href="#" @click.prevent="goBack">&lt; Back</a>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
        
        </div>
    
    </div>

</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
    name: 'NotFound',
    data() {
        return {
            currentRoute: this.$router.currentRoute._value.fullPath,
            isLoggedIn: false
        }
    },
    components: {
        Topnav,
        Sidebar,
    },
    computed: {
    },
    mounted() {
        this.isLoggedIn = localStorage.getItem("user-info") != null
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
.message {
    text-align:center;
    padding: 5%;
}

.url {
    text-decoration: underline;
    color: grey;
}

.url:hover {
    cursor:pointer;
    color: black;
}
</style>