<template>
    <form class="takeFullSpace" noValidate onsubmit="return false;" :title="`column: ${propertyName}\ntype: ${type}`">
        <input type="password" v-if="propertyPassword" :value="value" :class="classStr" disabled/>
        <div v-else-if="isArray">
            <span>
                <a href="#" @click="handleClick" class="link">Array[{{value.length}}]</a>
            </span>
        </div>
        <div v-else-if="edit && hasReplacementData(propertyName)">
            <select @change="handleEdit">
                <option v-for="item in replacementDataAsDropdownList(propertyName)"
                :key="item.value"
                :value="item.value"
                :selected="item.value==value">
                {{item.name}}
                </option>
            </select>
        </div>
        <input type="text" v-else-if="property__v || propertyCreated || propertyUpdated" :value="value" :class="classStr" disabled/>
        <input type="text" list="mongoIdInput" v-else-if="edit && propertyMongoId && !property_id" :value="value" @change="handleEdit" :class="classStr" :placeholder="placeholderMongoId" maxlength="24" minlength="24" :disabled="disabled" />
        <input type="email" v-else-if="edit && propertyEmail" :value="value" @change="handleEdit" :class="classStr" :placeholder="placeholderEmail" :disabled="disabled"/>
        <input type="datetime-local" v-else-if="edit && propertyISODatetime" :value="valueAsDatetime" @change="handleEdit" :class="classStr" :disabled="disabled"/>
        <input type="text" v-else-if="edit && isString && !property_id" :value="value" @change="handleEdit" :class="classStr" :placeholder="placeholderString" :disabled="disabled"/>
        <input type="checkbox" v-else-if="edit && isBoolean" :checked="value" @change="handleEdit" :class="classStr" :disabled="disabled"/>
        <input type="number" v-else-if="edit && isNumber" :value="value" @change="handleEdit" step="0.1" :class="classStr" :placeholder="placeholderNumber" :disabled="disabled"/>
        <a v-else-if="isHyperlink && !edit" :href="value || 'https://example.com'" target="_blank" class="link">
            {{ value || 'Default Link' }}
        </a>
        <div v-else v-html="displayValueOrReplacement"></div>
        <datalist id="mongoIdInput">
            <option :value="null">NULL</option>
        </datalist>
    </form>
</template>

<script>
import utils from '@/utils'

export default {
    name: 'SmartTableCell',
    emits: ['change', 'error', 'click'],
    components: {

    },
    props: {
        id: undefined,
        value: undefined,
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        propertyName: {
            type: String,
            required: true
        },
        edit: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        replace: {
            type: Object, /* Technically an associative array */
            default: {}
        }
    },
    created() {
        if(this.propertyMongoId) {
            this.type = "mongoid";
        } else if(this.propertyISODatetime) {
            this.type = "datetime";
        } else if(this.isString) {
            this.type = "string";
        } else if(this.isBoolean) {
            this.type = "boolean";
        } else if(this.isNumber) {
            this.type = "number";
        } else if(this.isArray) {
            this.type = "array";
        } else if(this.isObject) {
            this.type = "object";
        } else {
            this.type = "undefined";
        }
    },
    data() {
        return {
            type: undefined,

            // placeholder strings
            placeholderEmail: "Email field",
            placeholderMongoId: "MongoID field",
            placeholderString: "Text field",
            placeholderNumber: "Number field",
            placeholderDatetime: "UTC Datetime field",

            // pattern regex
            patternMongoId: /^[a-f\d]{24}$/i,
            patternEmail: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            patternISODatetime: /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
        }
    },
    computed: {
        displayValueOrReplacement() {
            if(this.hasReplacementData(this.propertyName)) {
                const replacedValue = this.replace[this.propertyName][this.value]
                if(replacedValue) return replacedValue
            }

            return this.value
        },
        classStr() {
            if(this.error) {
                return "error"
            }

            return null
        },

        propertyLowerCase() {
            return this.propertyName.toLowerCase()
        },

        propertyPassword() {
            return this.propertyLowerCase == "password"
        },

        propertyCreated() {
            return this.propertyLowerCase == "created" || this.propertyLowerCase == "createdat"
        },

        propertyUpdated() {
            return this.propertyLowerCase == "updated" || this.propertyLowerCase == "updatedat"
        },

        propertyEmail() {
            return this.propertyLowerCase == "email"
        },

        property__v() {
            return this.propertyLowerCase == "__v"
        },

        property_id() {
            return this.propertyLowerCase == "_id"
        },

        propertyMongoId() {
            if(this.isString) {
                return this.patternMongoId.test(this.value)
            }

            return false;
        },

        propertyISODatetime() {
            if(this.isString) {
                return this.patternISODatetime.test(this.value)
            }

            return false;
        },

        isString() {
            return typeof this.value === "string"
        },

        isNumber() {
            return typeof this.value === "number"
        },

        isBoolean() {
            return typeof this.value === "boolean"
        },

        isDatetime() {
            return typeof this.value === "datetime"
        },

        isObject() {
            return typeof this.value === "object"
        },

        isUndefined() {
            return typeof this.value == 'undefined'
        },

        isArray() {
            return Array.isArray(this.value)
        },

        valueAsDatetime() {
            return utils.datetimeUTCtoLocal(this.value);
        },

        isHyperlink() {

            return this.value && (typeof this.value === 'string') && (this.value.startsWith('http://') || this.value.startsWith('https://'));
        }
    },
    methods: {
        cast(value, targetType, success_fn, error_fn) {
            if(targetType == "number") {
                const numberVal = Number(value);
                const result = {value: numberVal, type: targetType};
                if(numberVal.toString() == value.toString()) {
                    success_fn(result);
                } else {
                    error_fn(result);
                }
                return;
            }

            if(targetType == "datetime") {
                if(value.length == 0) {
                    return error_fn({value: "empty date", type: targetType});
                }

                const datetimeVal = utils.datetimeLocaltoUTC(value).toISOString();
                const result = {value: datetimeVal, type: targetType};
                if(this.patternISODatetime.test(datetimeVal)) {
                    success_fn(result);
                } else {
                    error_fn(result);
                }
                return;
            }

            if(targetType == "object") {
                const objectVal = Object(value);
                const result = {value: objectVal, type: targetType};
                if(objectVal.toString() == value.toString()) {
                    success_fn(result);
                } else {
                    error_fn(result);
                }
                return;
            }

            if(targetType == "boolean") {
                const booleanVal = Boolean(value);
                const result = {value: booleanVal, type: targetType};
                if(booleanVal.toString() == value.toString()) {
                    success_fn(result);
                } else {
                    error_fn(result);
                }
                return;
            }

            if(targetType == "mongoid") {
                if(value == "NULL") {
                    success_fn({value: null, type: targetType})
                    return; // exit early from special case
                }

                const result = {value: value, type: targetType}
                if(value.toString() == value && this.patternMongoId.test(value)) {
                    success_fn(result)
                } else {
                    error_fn(result);
                }
                return;
            }

            if(targetType == "string") {
                const result = {value: value, type: targetType}
                if(value.toString() == value) {
                    success_fn(result)
                } else {
                    error_fn(result);
                }
                return;
            }
        },

        handleEdit(event) {
            event.preventDefault()

            const value = this.type == "boolean"? event.target.checked : event.target.value
            const vm = this

            const on_success = res => {
                vm.$emit("change", {id: vm.id, value: res.value, propertyName: vm.propertyName})
            }

            const on_fail = res => {
                const reset_fn = () => {
                    //console.log("reset_fn() called")
                    // TODO: keep bad value and only switch back to good value here when reset
                };

                vm.$emit("error", {id: vm.id, value: value, propertyName: vm.propertyName, expected: res.type, reset_fn: reset_fn})
            }

            this.cast(value, this.type, on_success, on_fail);
        },

        handleClick(event) {
            event.preventDefault()

            // Try to use event target values first and default to internal values otherwise for click inspection
            const value = this.type == "boolean"? event.target.checked : event.target?.value ?? this.value

            this.$emit("click", {id: this.id, value: value, propertyName: this.propertyName, type: this.type})
        },

        hasReplacementData(propertyName) {
            return typeof this.replace[propertyName] != 'undefined'
        },

        replacementDataAsDropdownList(propertyName) {
            let list = [{
                name: "NULL",
                value: null
            }]

            const table = this.replace[propertyName]
            for(const key of Object.keys(table)) {
                list.push({
                    name: table[key],
                    value: key
                })
            }

            return list
        }
    },
}
</script>

<style scoped>
.error {
    border-color: red;
}
.takeFullSpace {
    display: ruby; /* for now, make cells take up full space */
}
a .link {
    text-decoration: underline;
}
</style>
