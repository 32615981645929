<template>
    <div class="card">

        <p class="login-box-msg">OTP Verification</p>

        <p class="otp-number" v-if="signUp">You must verify your phone number before signing up</p>

        <p class="otp-number">Enter the OTP sent to your registered mobile number</p>

        <p class="otp-number" v-if="signUp">Refresh the page to enter try a different phone number</p>

        <div class="card-body login-card-body">

            <form @submit.prevent="verify">

                <div class="row otp-box">

                    <input type="text"
                    class="w-10 h-10 mx-2 text-center"
                    required id="key"
                    v-model="token"
                    minlength="6"
                    maxlength="6"
                    title="please enter 6 digit code"
                    style="width:95%; margin: 0 auto;"
                    autocomplete="off">

                </div>

                    <div class="row">

                    <div class="col-12">
                        <button type="submit" class="btn btn-primary btn-block" v-if="!isVerifying">Continue</button>
                        <button class="btn btn-primary btn-block wait" v-else><span class="spin"/></button>
                    </div>

                </div>

            </form>

            <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400;">

            <span class="otp-link-text">Didn’t receive OTP?  </span>

            <button v-on:click="resend_otp" type="button" class="fp btn" style="border:unset" :disabled="isDisabled">RESEND</button>

            <span class="otp-link-text js-timeout">  01:20</span>

            </p>

            <div class="row">

                <div class="col-md-3"></div>

                <div class="col-md-6">

                    <div class="customer_message_officer_service">

                        <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400;">

                        </p>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
import API from '../axios.config';
import $ from 'jquery';
export default {
    name: 'Otp',
    data() {
        return {
            token: '', // OTP token
            loginToken: '',
            interval: null,
            isDisabled: true,
            isVerifying: false
        }

    },
    emits: ['verify', 'resend', 'error'],
    components: {
    },
    props: {
        username: String,
        phoneNo: {
            type: String,
            default: ""
        },
        countryCode: {
            type: String,
            default: "US"
        },
        signUp: {
            type: Boolean,
            deafault: false
        }
    },
    methods: {
        async verify() {
            this.isVerifying = true
            this.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${this.loginToken}`,
                'content-type': 'application/json',
            }

            try {
                let response;
                if (this.signUp) {
                    response = await API.post('/verify-phone-twilio', {
                        token: this.token,
                        username: this.username,
                        phoneNo: this.phoneNo,
                        countryCode: this.countryCode
                    }, {
                        headers: header
                    })

                } else {
                    response = await API.post('/verifyToken', {
                        token: this.token,
                        username: this.username
                    }, {
                        headers: header
                    })
                }

                if (response.status == 200) {
                    clearInterval(this.interval)
                    this.isVerifying = false
                    this.signUp ? this.$emit('verify'): this.$emit('verify', {data: response.data.data})
                } else {
                    console.log("HELLO")
                    this.signUp ?
                        this.$emit('error', {error: response.data.message}):
                        this.$emit('error', {error: response.data.data})
                }
            }
            catch(error) {
                console.log(error)
                this.$emit('error', error)
            }

            this.isVerifying = false
        },

        async countdown() {
            clearInterval(this.interval)
            let interval = null
            let vm = this
            interval = setInterval(function() {
                let timer = $('.js-timeout').html();

                // TODO: why does this happen?
                if(typeof timer == "undefined") return;

                timer = timer.split(':');
                let minutes = timer[0];
                let seconds = timer[1];
                seconds -= 1;
                if (minutes < 0) return;
                else if (seconds < 0 && minutes != 0) {
                    minutes -= 1;
                    seconds = 59;
                } else if (seconds < 10 && length.seconds != 2) seconds = '0' + seconds;

                $('.js-timeout').html(minutes + ':' + seconds);

                if (minutes == 0 && seconds == 0) {
                    clearInterval(interval);
                    vm.isDisabled = false;
                }

            }, 1000);
            this.interval = interval
        },

        async resend_otp() {
            const vm = this;
            $('.js-timeout').text("02:00");
            this.countdown();
            this.isDisabled = true;

            this.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${this.loginToken}`,
                'content-type': 'application/json',
            }

            try {
                let response;
                if (this.signUp) {
                    response = await API.post('/send-twilio-ott-code', {
                        phoneNo: this.phoneNo,
                        countryCode: this.countryCode,
                    },
                    {
                        headers: header
                    })
                } else {
                    response = await API.get('/ott/resend', {
                        headers: header
                    })
                }

                if (response.status == 200) {
                    this.$emit('resend')
                }

            } catch(error) {
                this.$emit('error', {error: error})
            }

            this.isDisabled = false
        }
    },
    mounted() {
        $('.js-timeout').text("02:00");
        this.countdown();
    }
}
</script>

<style scoped>
.error-msg {
    color: red;
}

.btn:disabled {
    cursor: not-allowed;
}

.wait {
  cursor:progress !important;
}

.spin {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 4px solid rgba(255, 255, 255, .3);
  border-radius: 100%;
  border-top-color: rgb(179, 190, 255);
  animation: spin 0.5s linear infinite;
  -webkit-animation: spin 0.5s linear infinite;
  margin: -10px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }

  0%   {border-top-color: rgb(253, 147, 147); }
  50%  {border-top-color: rgb(157, 157, 255); }
  75%  {border-top-color: rgb(162, 255, 162); }
  100% {border-top-color: rgb(255, 255, 255); }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }

  0%   {border-top-color: rgb(253, 147, 147); }
  50%  {border-top-color: rgb(157, 157, 255); }
  75%  {border-top-color: rgb(162, 255, 162); }
  100% {border-top-color: rgb(255, 255, 255); }
}

</style>
