<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header" style="padding: 0;">
    
            <div class="container-fluid" style="padding-left: 0;">
    
                <div class="row">
    
                    <div class="col-md-6">

                        <GMapMap ref="gmap" :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100vw; height: 100vh">
    
                                <div class="timerWidget">
                                    <TimerWidget :time="fleetInterval" @timeout="onlineDriversList(false)"></TimerWidget>
                                </div>
                                
                                <GMapMarker v-for="(m, index) in markers" :key="index"
                                :title="getDriverName(m.driver)"
                                :position="m.position" 
                                :clickable="true" 
                                :draggable="false"
                                @click="center = m.position" 
                                />
                                <GMapPolyline v-for="(m, index) in paths" :key="index"
                                :path="m.route"
                                :editable="false"
                                :options="{strokeColor: m.color, label:'dashed'}"
                                />
                        </GMapMap>
    
                    </div>
    
                    <div class="col-md-6">
    
                        <div class="card card-primary card-outline card-outline-tabs" style="margin-top: 0;background-color: #f4f6f9;">
    
                            <div class="card-header p-0 border-bottom-0">
    
                                <ul class="nav nav-tabs Fleet-Management-navs" id="custom-tabs-four-tab" role="tablist">
    
                                    <li class="nav-item">
    
                                        <a class="nav-link " id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true" v-on:click="QueuedJobs">Queued Jobs</a>
    
                                    </li>
    
                                    <li class="nav-item">
    
                                        <a class="nav-link active" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false" v-on:click="onlineDriversList(false)">On Duty</a>
    
                                    </li>

                                    <Busy :busy="busy" text="Loading..."/>
    
                                </ul>

                            </div>
    
                            <div class="card-body scrolling-section">
    
                                <div class="tab-content" id="custom-tabs-four-tabContent">
    
                                    <div class="tab-pane fade" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
    
                                        <div class="row jobs-address-assign mb-4" style=" border-bottom: 1px solid #d8d8d861;width: 100%;" v-if="QueuedJobList.length == 0">
    
                                            <h4 style="text-align: center;width: 100%;"> Job Queue Is Empty </h4>
    
                                        </div>
    
                                        <div class="row jobs-address-assign mb-4" style=" border-bottom: 1px solid #d8d8d861;width: 100%;" v-for="queuedjobs in QueuedJobList" v-bind:key="queuedjobs._id">
    
                                            <div class="col-md-8 Queued-jobs-tabs">
    
                                                <router-link :to="`edit-job?queuedJobId=${queuedjobs._id}&lastPage=${$route.path}`"  style="color: #000;">
                                                    <p><b>Job Id : </b><span class="link">{{ queuedjobs._id }}</span></p>
                                                </router-link>

                                                <p><b>Bill To :</b> <router-link :to="`edit-customer-registration?id=${queuedjobs.customer._id}`" style="color: #000; text-decoration: underline dotted;">{{ queuedjobs.customer.name }}</router-link></p>
    
                                                <p><b># of Tasks :</b> {{ queuedjobs.tasks.length }}</p>
    
                                                <p><b>Charge Type :</b> {{ queuedjobs.chargeType.name }}</p>
                                            </div>
    
                                            <div class="col-md-4 fleetmanagement-assign">
    
                                                <input :jobid="`${queuedjobs._id}`" type="radio" id="Assign" style="margin: 20px 10px 0px;">
    
                                                <label for="Assign" data-toggle="modal" :data-target="`#myModal${queuedjobs._id}`" v-on:click="onlineDriversList(false)" style="cursor: pointer;">ASSIGN</label>
    
                                            </div>
    
                                            <div class="modal" :id="`myModal${queuedjobs._id}`">
    
                                                <div class="modal-dialog" style=" max-width: 900px;">
    
                                                    <div class="modal-content">
    
                                                        <div class="modal-header">
    
                                                            <h4 class="modal-title" style="font-size: 24px;font-weight: 600;"></h4>
    
                                                            <button type="button" class="close" data-dismiss="modal" style="margin-top: 3px;margin-left: 3rem;">×</button>
    
                                                        </div>
    
                                                        <form @submit.prevent="assignDriver" id="myForm">
    
                                                            <div class="row modal-body mt-4 mb-2 assign-drivers-modal" style="padding-left: 2rem;padding-right: 2rem;height: 190px; margin: 0px;">
    
                                                                <div class="col-md-6 mt-4 mb-2" id="" v-if="onlineDrivers.length == 0">
                                                                    No Online Drivers Found
                                                                </div>

                                                                <div class="col-md-6 mt-4 mb-2" id="" v-for="Drivers in onlineDrivers" v-bind:key="Drivers._id">
    
                                                                    <div class="row signature">
    
                                                                        <div class="col-md-2" v-if="Drivers.userId">
    
                                                                            <img v-if="Drivers.userId.picture" :src="`${API.baseURL}${Drivers.userId.picture}`" alt="" style="width:30px;margin-top:13px;">
    
                                                                            <img v-else src="../assets/img/profile.png" alt="" style="width:30px;margin-top:13px;">
    
                                                                        </div>
    
                                                                        <div v-else class="col-md-2">
    
                                                                            <img src="../assets/img/profile.png" alt="" style="width:30px;margin-top:13px;">
    
                                                                        </div>
    
                                                                        <div class="col-md-6">
    
                                                                            <h6 class="add-job-drives">{{getDriverName(Drivers)}}</h6>
    
                                                                        </div>
    
                                                                        <div class="col-md-4">
    
                                                                            <input class="driverAssign" 
                                                                            :jobid="`${queuedjobs._id}`" 
                                                                            type="radio" 
                                                                            :id="`Assign-${Drivers._id}-${queuedjobs._id}`"
                                                                            :checked="Drivers._id===driverId"
                                                                            :value="`${Drivers._id}`" 
                                                                            style="margin: 20px 10px 0px;" 
                                                                            v-on:click="assignDriverJob(`${Drivers._id}`,`${queuedjobs._id}`)"
                                                                            />
    
                                                                            <label :for="`Assign-${Drivers._id}-${queuedjobs._id}`" style="cursor:pointer; margin: 15px 0px 0px;background-color: #fff; ">SELECT</label>
    
                                                                        </div>
    
                                                                    </div>
    
                                                                </div>
    
                                                            </div>
    
                                                            <div class="col-md-12 footer-btns mt-4">
    
                                                                <div class="modal-footer" style="margin-top: -2%;margin-bottom: 4%; justify-content: center;">
    
                                                                    <button type="submit" class="btn btn-block btn-info" style="width: 10%;font-size: 12px;padding: 10px;margin: 0;">ASSIGN</button>
    
                                                                    <button type="button" class="btn btn-primary btn-block" data-dismiss="modal" style="width: 10%;font-size: 12px;margin-left: 1%;">CANCEL</button>
    
                                                                </div>
    
                                                            </div>
    
                                                        </form>
    
                                                    </div>
    
                                                </div>
    
                                            </div>
    
                                        </div>
    
                                    </div>
    
                                    <div class="tab-pane fade show active" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
    
                                        <div v-if="onlineDrivers.length == 0" class="row mb-4 activeDrivers" style=" border-bottom: 1px solid #d8d8d861;width: 100%;">
                                            No Online Drivers Found
                                        </div>

                                        <div class="row mb-4 activeDrivers" style=" border-bottom: 1px solid #d8d8d861;width: 100%;" v-for="drivers in onlineDrivers" v-bind:key="drivers._id">
    
                                            <div class="col-md-1" v-if="drivers.userId">
    
                                                <img v-if="drivers.userId.picture" :src="`${API.baseURL}${drivers.userId.picture}`" alt="" style="width:50px;">
    
                                                <img v-else src="../assets/img/profile.png" alt="" style="width:50px;">
    
                                            </div>
    
                                            <div v-else class="col-md-1">
    
                                                <img src="../assets/img/profile.png" alt="" style="width:50px;">
    
                                            </div>
    
                                            <div class="col-md-9 Fleet-Management-onduty">
    
                                                <h6>{{getDriverName(drivers)}}&nbsp;
                                                    <img src="../assets/img/address-icon.svg" 
                                                    alt="" 
                                                    title="Locate me" 
                                                    style="cursor:pointer;"
                                                    @click="locateDriver(drivers)">
                                                </h6>
    
                                                <div v-if="drivers.jobTrackingId!=null">
    
                                                    <p class="Active-Job">Active Job</p>
    
                                                    <div>
    
                                                        <router-link :to="`view-job?jobId=${drivers.jobTrackingId}&lastPage=${$route.path}`"  style="color: #000;">
                                                            <p class="Active-Job"><b>Job Tracking Id</b>: <span class="link">{{drivers.jobTrackingId}}</span></p>
                                                        </router-link>

                                                        <p class="Active-Job"><b>Task Tracking Id</b>: {{drivers.taskTrackingId}}</p>
    
                                                    </div>
    
                                                </div>
    
                                                <div v-else>
    
                                                    <p class="Active-Job">No Active Job</p>
    
                                                </div>
    
                                            </div>
    
                                            <div class="col-md-6"></div>
    
                                        </div>
    
                                    </div>
    
                                </div>
    
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
    </div>
</template>
<script>
import Busy from '@/components/Busy.vue'
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import TimerWidget from '@/components/TimerWidget.vue'
import API from '../axios.config'
import eznavigator from '../eznavigator'
import utils from '../utils'
import config from '../../config'

export default {
    name: 'FleetManagement',
    data() {
        return {
            QueuedJobList: [],
            onlineDrivers: [],
            msg: '',
            driverId: null,
            jobId: null,
            center: {lat: 0, lng: 0},
            zoom: 10,
            markers: [],
            paths: [],
            API: API,
            fleetInterval: config.fleet.interval,
            busy: true,
        }
    },
    components: {
        Topnav,
        Sidebar,
        TimerWidget,
        Busy,
    },
    async mounted() {
        const vm = this;

        let latLon = await eznavigator.getLatLong();
        vm.center.lat = latLon[0];
        vm.center.lng = latLon[1];

        await vm.onlineDriversList(true);

        this.busy = false
    },
    methods: {
        getDriverName(driver) {
            return driver.userId.first_name + " " + driver.userId.last_name;
        },

        async QueuedJobs() {
            const vm = this;
            vm.msg = "";
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }

            vm.loginToken = localStorage.getItem("user-info")
            await API.get(`/QueuedJob`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(async function(response) {

                    if (response.status == 200) {
                        if (response.data.data.length == 0) {
                            vm.msg = "No Result Found"
                        } else {
                            vm.QueuedJobList = [...response.data.data];
                        }

                    }
                })
                .catch(function(error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })


                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: 'error',
                            position: 'top'
                        });

                    }
                });

        },

        locateDriver(driver) {
            this.markers.forEach(m => {
                if(m.driver._id == driver._id) {
                    let center = this.center = m.position;

                    this.$refs.gmap.$mapPromise.then((map) => {
                        map.panTo(center)
                    });
                    return;
                }
            });
        },

        async onlineDriversList(bCenterView) {
            const vm = this;
            vm.msg = "";
            vm.loginToken = localStorage.getItem("user-info");

            await API.get(`/driversonline`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(async function(response) {
                    if (response.status == 200) {
                        vm.onlineDrivers = response.data.data
                        vm.markers = []

                        let newPaths = []
                        if (response.data.data.length > 0) {
                            let latBounds = null
                            let lonBounds = null

                            for (let i = 0; i < vm.onlineDrivers.length; i++) {
                                let driver = vm.onlineDrivers[i]

                                if(driver.addressLocation == null)
                                continue
                                
                                const addressLocation = driver.addressLocation
                                if(addressLocation.Latitude.length == 0 || addressLocation.Longitude.length == 0) 
                                continue

                                let lat = parseFloat(driver.addressLocation.Latitude)
                                let long = parseFloat(driver.addressLocation.Longitude)

                                if(isNaN(lat) || isNaN(long) || (lat == 0 && long == 0)) 
                                continue

                                vm.markers.push({
                                    driver: driver,
                                    position: {
                                        lat: lat,
                                        lng: long
                                    }
                                })

                                // fetch the next task path
                                const jobTrackingId = driver.jobTrackingId;
                                const taskTrackingId = driver.taskTrackingId;
                                if(jobTrackingId && taskTrackingId) {
                                    // only fetch new directions if the job or task tracking has changed
                                    const keepIdx = vm.paths.findIndex(p => p.driverId == driver._id)
                                    if(keepIdx != -1 && vm.paths[keepIdx].jobTrackingId == jobTrackingId && vm.paths[keepIdx].taskTrackingId == taskTrackingId) {
                                        newPaths.push(vm.paths[keepIdx])
                                    } else {
                                        try {
                                            const response = await API.get(`/jobs/${driver.jobTrackingId}`, {
                                                headers: {
                                                    Authorization: `Bearer ${vm.loginToken}`
                                                }
                                            })

                                            const job = response.data.data
                                            for(let i = 0; i < job.Tasks.length; i++) {
                                                const task = job.Tasks[i]
                                                if(task._id == driver.taskTrackingId) {
                                                    // draw the route
                                                    let color = '#FF0055';

                                                    if(utils.isNameSTATLike(task.chargeType.name)) {
                                                        color = '#FFFF00';
                                                    }

                                                    let callback = function(route) {
                                                        newPaths.push({
                                                            route: route,
                                                            color: color,
                                                            driverId: driver._id,
                                                            jobTrackingId: jobTrackingId,
                                                            taskTrackingId: taskTrackingId
                                                        })
                                                    }
                                                    const destLatLon = {lat: parseFloat(task.addressLocation.Latitude), lng: parseFloat(task.addressLocation.Longitude)};
                                                    utils.googleRoads(window, vm.markers[vm.markers.length-1].position, destLatLon, callback)
                                                }
                                            }
                                        } catch (_err) {
                                            /* do not add this line */
                                        }
                                    }
                                }

                                if(!latBounds) {
                                    latBounds = {
                                        min: lat, max: lat
                                    }
                                } else {
                                    latBounds.min = Math.min(latBounds.min, lat);
                                    latBounds.max = Math.max(latBounds.max, lat);
                                }

                                if(!lonBounds) {
                                    lonBounds = {
                                        min: long, max: long
                                    }
                                } else {
                                    lonBounds.min = Math.min(lonBounds.min, long);
                                    lonBounds.max = Math.max(lonBounds.max, long);
                                }
                            }
                            
                            if(bCenterView && latBounds && lonBounds) {
                                // re-calculate center of all drivers
                                vm.center.lat = (latBounds.min + latBounds.max)/2.0;
                                vm.center.lng = (lonBounds.min + lonBounds.max)/2.0;
                            }
                        }
                        vm.paths = newPaths;
                    }
                })
                .catch(function(error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();

                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: 'error',
                            position: 'top'
                        });
                    }
                });
        },
        async assignDriverJob(id, jId) {
            this.msg = "";

            // we must uncheck if the same because we are using radio inputs
            if(this.driverId == id) {
                this.driverId = null;
                return;
            }
            this.driverId = id;
            this.jobId = jId;
        },
        async assignDriver() {
            const vm = this;

            if(vm.jobId == null || vm.driverId == null) return;

            vm.loginToken = localStorage.getItem("user-info")
            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }

            await API.put(`/QueuedJob/assign/${vm.jobId}/to/${vm.driverId}`, 
                {

                }, 
                {
                    headers: header
                }
            )
            .then(function(response) {
                if (response.status == 200) {
                    vm.$toast.success('Job Assigned successfully.', {
                        // override the global option
                        position: 'top'
                    })
                    location.reload();
                }
            })
            .catch(function(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();

                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            });

            vm.jobId = null;
            vm.driverId = null;
        },
    }
}
</script>
<style>
.link {
    text-decoration: underline dotted;
}
.timerWidget {
    position:absolute;
    top: 10px;
    left: 200px;
}

.vue-map-container {
    height: 80vh;
}
.row.jobs-address-assign,
.row.activeDrivers {
    border-radius: 8px;
    box-shadow: 0 0 1px #dfdddd, 0 1px 3px #dfdddd;
    background-color: #fff;
    padding: 15px;
}
.assign-drivers-modal {
    overflow-x: hidden; /* Hide horizontal scrollbar */
    overflow-y: scroll;
}
.scrolling-section {
    max-height: 80vh; 
    overflow-y:scroll; 
    overflow-x:hidden;
    scrollbar-color: #4e4e4e #e4e4e4;
    scrollbar-width: thin;
}
</style>