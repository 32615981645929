<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header">
    
            <div class="container-fluid">
    
                <form @submit.prevent="driverUpdate">
    
                    <div class="row mt-2 mb-4">
    
                        <div class="col-md-10"></div>
    
                        <div class="col-md-2">
    
                            <div class="drivers-btn">
    
                                <router-link to="/drivers-list" class="btn btn-block" style="padding: 0;color: #000000;border-color: #7c7c7c03;" v-if='roles.includes("admin")'>Back</router-link>
    
                                <button type="submit" class="btn btn-block btn-info">Submit</button>
    
                            </div>
    
                        </div>
    
                    </div>
    
                    <div class="row">
    
                        <div class="col-md-12">
    
                            <div class="Customer-edit-form">
    
                                <div v-if="preview">
    
                                    <img :src="preview" class="img-fluid driver-pic"/>
    
                                </div>
    
                                <p v-else-if="profilePicture">
    
                                    <img name="upload_id" :src="`${API.baseURL}${profilePicture}`" alt="" class="img-fluid driver-pic">
    
                                </p>
    
                                <p v-else>
    
                                    <img src="../assets/img/image.svg" alt="">
    
                                </p>
    
                                <div class="image-upload">
    
                                    <label for="file-input">
    
                                        <img src="../assets/img/edit-icon.svg" class="logo-upload-btn"/>
                        
                                    </label>
    
                                    <input id="file-input" type="file" :name="uploads" class="input-file" @change="photoUpload" />
    
                                    <input type="hidden" v-model="upload_id">
    
                                </div>
    
                                <button v-if='isCurrentUserAdmin && !roles.includes("admin")' 
                                type="button" 
                                class="btn btn-block btn-info" 
                                style="margin: auto;width: 15%;margin-top: 1rem;" @click="upgradeToAdmin">Upgrade to Admin</button>
    
                                <button v-else-if='isCurrentUserAdmin && roles.includes("admin")' 
                                type="button" 
                                class="btn btn-block btn-danger" 
                                style="margin: auto;width: 15%;margin-top: 1rem;" @click="demoteToDriver">Demote to Driver</button>

                            </div>
    
                        </div>
    
                        <div class="col-md-12 mt-4">
    
                            <div class="form-row">
    
                                <div class="form-item col-md-4">
    
                                    <input type="text" id="name" v-model="first_name" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">First Name</label>
    
                                </div>
    
                                <div class="form-item col-md-4">
    
                                    <input type="text" id="name" v-model="last_name" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">Last Name</label>
    
                                </div>
    
                                <div class="form-item col-md-4">
    
                                    <input type="date" id="name" v-model="date_of_birth" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">Date of Birth</label>
    
                                </div>
    
                                <div class="form-item col-md-4">
    
                                    <input type="text" id="name" v-model="current_license_number" autocomplete="off" required>
    
                                    <label for="name" style="background: #f4f6f9;">License #</label>
    
                                </div>
    
                                <div class="form-item col-md-1">
    
                                    <input type="text" id="name" v-model="countryCode" autocomplete="off" required placeholder="AU">
    
                                    <label for="name" style="background: #f4f6f9;">Code</label>
    
                                </div>
    
                                <div class="form-item col-md-3">
    
                                    <input required
                                        type="tel" 
                                        id="phone" 
                                        placeholder="123-456-7890" 
                                        pattern="([0-9]-)?[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        autocomplete="off"
                                        maxlength="15" 
                                        minlength="10"
                                        v-model="work_cell_number"
                                        >

                                    <label for="name" style="background: #f4f6f9;">Work Cell</label>
    
                                </div>
    
                                <div class="form-item col-md-4 autocomplete">
    
                                    <input type="text" id="name" v-model="home_address" required v-on:keyup="customerAddress" autocomplete="off">
    
                                    <input type="hidden" id="name" v-model="addressLocation.Longitude" autocomplete="off">
    
                                    <input type="hidden" id="name" v-model="addressLocation.Latitude" autocomplete="off">
    
                                    <label class="selectOptions" for="name" style="background: #f4f6f9;">Home Address</label>
    
                                    <ul v-show="isOpen" class="autocomplete-results">
    
                                        <li v-for="(result, i) in results" :key="i" @click="setResult(`${result.description}`, `${result.geolocation.lat}`,`${result.geolocation.lng}`)" class="autocomplete-result">{{result.description}}</li>
    
                                    </ul>
    
                                </div>
    
                                <div class="form-item col-md-4">
    
                                    <select v-model="driverType" class="form-select" required>
    
                                      <option value="STAT">STAT </option>
                    
                                      <option value="REGULAR"> REGULAR</option>
                    
                                    </select>
    
                                    <label for="name" style="background: #f4f6f9;">Driver Type</label>
    
                                </div>
    
                                <div class="form-item col-md-4">
    
                                    <input type="text" id="commission" v-model="commission" required>
    
                                    <label for="name" style="background: #f4f6f9;">Commision %</label>
    
                                </div>
    
                                <div class="col-md-12 mt-4 department">
    
                                    <h6 style="text-align: left;">Bank Info</h6>
    
                                </div>
    
                                <div class="form-item col-md-4 mt-4">
    
                                    <input type="text" id="name" autocomplete="off" required v-model="bank_name">
    
                                    <label for="name" style="background: #f4f6f9;">Name</label>
    
                                </div>
    
                                <div class="form-item col-md-4 mt-4">
    
                                    <input type="text" id="name" autocomplete="off" required v-model="routing_number">
    
                                    <label for="name" style="background: #f4f6f9;">Route #</label>
    
                                </div>
    
                                <div class="form-item col-md-4 mt-4">
    
                                    <input type="text" id="name" autocomplete="off" required v-model="account_number">
    
                                    <label for="name" style="background: #f4f6f9;">Account #</label>
    
                                </div>
    
                                <div class="form-item col-md-8">
    
                                    <input type="text" id="name" autocomplete="off" required v-model="iban_number">
    
                                    <label for="name" style="background: #f4f6f9;">Iban Number</label>
    
                                </div>
    
                                <div class="col-md-12 mt-2 department">
    
                                    <h6 style="text-align: left;">Uploaded Certificates</h6>
    
                                </div>
    
                                <div class="col-md-6 mb-4 signature" v-if="attachments.length == 0">
                                    <span><i>None</i></span>
                                </div>

                                <div class="col-md-6 mb-4 signature" v-for="url in attachments" :key="url">
                                    <a :href="API.baseURL + url" class="new-driver-registration-link" download>{{url}}</a>
                                </div>
                                <div class="col-md-12 mt-2 mb-4 department">
                                    <h6 style="text-align: left;">Upload Certificates</h6>
                                </div>
                                <div class="col-md-12">
                                    <input id="file-input" type="file" multiple :name="uploads" class="input-file" @change="certificateUpload" />
                                    <input type="hidden" v-model="upload_id">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
import eznavigator from '../eznavigator';
import utils from '../utils';
import $ from 'jquery'
export default {
    name: 'EditDriverRegistration',
    data() {
        return {
            name: '',
            first_name: '',
            last_name: '',
            date_of_birth: '',
            current_license_number: '',
            work_cell_number: '',
            home_address: '',
            address: '',
            bank_name: '',
            account_number: '',
            routing_number: '',
            uploads: [],
            show: false,
            driverType: '',
            account_title: '',
            iban_number: '',
            countryCode: '',
            is_deleted: true,
            commission: '',
            roles: [],
            isCurrentUserAdmin: false,
	        addressLocation: {
                Latitude: '',
                Longitude: '',
            },
            upload_id: '',
            preview: null,
            profilePicture: null,
            results: [],
            isOpen: [],
            customerDepartmentAddress: [],
            attachments: [],
            API: API
        }
    },
    components: {
        Topnav,
        Sidebar
    },
    watch: {
        work_cell_number(val) {
            this.work_cell_number = utils.cleanPhoneNumberInput(val)
        }
    },
    mounted() {
        const vm = this;
        let users = localStorage.getItem("user-info")
        vm.loginToken = localStorage.getItem("user-info")
        let roles = [...JSON.parse(localStorage.getItem("roles"))];
        if (!users) {
            vm.$router.push({ name: 'Login' })
        }
        if (!roles.includes('admin')) {
            $('#commission').attr("readonly")
        } else {
            vm.isCurrentUserAdmin = true;
            $('#commission').removeAttr("readonly")
        }
        let id = this.$route.query.id;

        let header = {
            'Authorization': `Bearer ${vm.loginToken}`,

        }
        API.get(`/driver/${id}`, {
                headers: header
            }, )
            .then(function(response) {
                if (response.status == 200) {
                    let dob = response.data.data.date_of_birth.split("T")
                    vm.date_of_birth = dob[0];
                    vm.first_name = response.data.data.userId.first_name;
                    vm.last_name = response.data.data.userId.last_name;
                    vm.current_license_number = response.data.data.current_license_number;
                    vm.countryCode = response.data.data.userId.countryCode;
                    vm.work_cell_number = response.data.data.userId.phoneNo;
                    vm.bank_name = response.data.data.bank_name;
                    vm.account_number = response.data.data.account_number;
                    vm.routing_number = response.data.data.routing_number;
                    vm.iban_number = response.data.data.iban_number;
                    vm.commission = response.data.data.commission;
                    vm.driverType = response.data.data.driverType;
                    vm.home_address = response.data.data.home_address;
                    vm.addressLocation.Longitude = response.data.data.addressLocation.Longitude;
                    vm.addressLocation.Latitude = response.data.data.addressLocation.Latitude;
                    vm.roles = response.data.data.userId.roles;
                    vm.profilePicture = response.data.data.userId.picture;
                    vm.photos = null;
                    vm.lastUploadedPicture = null;
                    if (response.data.data.uploads) {
                        if (response.data.data.uploads.photoUrls.length) {
                            vm.lastUploadedPicture = response.data.data.uploads.photoUrls.slice(-1)[0];
                        }
                        if (response.data.data.uploads.attachmentUrls.length) {
                            vm.attachments = response.data.data.uploads.attachmentUrls;
                        }
                    }
                }
            })
            .catch(function(error) {
                vm.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });

                vm.$router.push({ name: 'Login' })
            });
    },
    methods: {
        upgradeToAdmin() {
            if(this.roles.includes('admin') || !this.isCurrentUserAdmin) return;

            this.roles.push('admin');

            this.driverUpdate();
        },
        demoteToDriver() {
           if(this.roles.includes('admin') == false || !this.isCurrentUserAdmin) return;

           let idx = this.roles.indexOf('admin');
           this.roles.splice(idx, 1);

           this.driverUpdate();
        },
        setResult(event, lat, lng) {
            this.home_address = event;
            this.isOpen = false;
            this.addressLocation.Longitude = lng;
            this.addressLocation.Latitude = lat;
        },

        async customerAddress() {
            const vm = this;

            vm.isOpen = false;

            let home_address = vm.home_address;

            // zero length home address makes the endpoint error
            if(home_address.length == 0) return;

            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let geolocation = await eznavigator.getLatLong();

            await API.post('/customer-address-autocomplete', {
                    address: home_address,
                    location: geolocation
                }, {
                    headers: header
                })
                .then(function(response) {
                    if (response.status == 200) {
                        vm.results = response.data.data.google_results;
                        vm.isOpen = true;
                    }
                })
                .catch(function(error) {
                    //handle error here
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();

                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: 'error',
                            position: 'top'
                        });

                    }
                });

        },
        getAddressData: function(addressData) {
            const vm = this;
            vm.address = addressData;
            vm.addressLocation.latitude = vm.address.latitude
            vm.addressLocation.longitude = vm.address.longitude
        },

        async driverUpdate() {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }
            let id = this.$route.query.id;

            let formData = new FormData();

            if (vm.photos) {
                formData.append("photos", vm.photos);
            }
            if (vm.attachments.length > 0) {

                formData.append("attachments", vm.attachments);
            }

            formData.append("date_of_birth", vm.date_of_birth);
            formData.append("current_license_number", vm.current_license_number);
            formData.append("bank_name", vm.bank_name);
            formData.append("account_title", vm.bank_name);
            formData.append("account_number", vm.account_number);
            formData.append("routing_number", vm.routing_number);
            formData.append("driverType", vm.driverType);
            formData.append("commission", vm.commission);
            formData.append("iban_number", vm.iban_number);
            formData.append("home_address", vm.home_address);
            formData.append("addressLocation.Latitude", vm.addressLocation.Latitude);
            formData.append("addressLocation.Longitude", vm.addressLocation.Longitude);


            await API.put(`/driver/${id}`,
                formData, 
                {
                    headers: header
                })
                .then(async function(response) {

                    if (response.status == 200) {
                        const uid = response.data.data.userId._id;
                        if (response.data.data.uploads) {
                            if (response.data.data.uploads.photoUrls.length) {
                                const lastPhotoUrl = response.data.data.uploads.photoUrls.slice(-1)[0];
                                if (vm.lastUploadedPicture == null || vm.lastUploadedPicture !== lastPhotoUrl) {
                                    vm.profilePicture = lastPhotoUrl;
                                    vm.lastUploadedPicture = lastPhotoUrl;
                                }
                            }
                        }

                        const user = await vm.userUpdate(uid);
                        vm.$toast.success('Profile Updated successfully.', {
                            // override the global option
                            position: 'top'
                        })

                        let dob = response.data.data.date_of_birth.split("T")
                        vm.date_of_birth = dob[0];
                        vm.first_name = user.first_name;
                        vm.last_name = user.last_name;
                        vm.current_license_number = response.data.data.current_license_number;
                        vm.countryCode = user.countryCode;
                        vm.work_cell_number = user.phoneNo;
                        vm.bank_name = response.data.data.bank_name;
                        vm.account_number = response.data.data.account_number;
                        vm.routing_number = response.data.data.routing_number;
                        vm.iban_number = response.data.data.iban_number;
                        vm.commission = response.data.data.commission;
                        vm.driverType = response.data.data.driverType;
                        vm.uploads = [];
                        vm.home_address = response.data.data.home_address;
                        vm.addressLocation.Longitude = response.data.data.addressLocation.Longitude;
                        vm.addressLocation.Latitude = response.data.data.addressLocation.Latitude;
                        if (response.data.data.uploads) {
                            if (response.data.data.uploads.attachmentUrls.length) {
                                vm.attachments = response.data.data.uploads.attachmentUrls;
                            }
                        }
                        vm.photos = null;
                        vm.preview = null;
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });

        },

        async userUpdate(uid) {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");
            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,

            }
            const response = await API.put(`/users/${uid}`, {
                    first_name: vm.first_name,
                    last_name: vm.last_name,
                    countryCode: vm.countryCode,
                    phoneNo: utils.cleanPhoneNumberForAPI(vm.work_cell_number),
                    roles: vm.roles,
                    picture: vm.profilePicture,
                }, 
                { headers: header }
            )
            
            return response.data.data
        },

        async photoUpload(event) {
            const vm = this;
            vm.photos = event.target.files[0];
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },

        async certificateUpload(event) {
            const vm = this;
            vm.attachments = event.target.files[0]; // TODO: how to support multiple uploads? How to keep out current uploads?
        },
    }
}
</script>

<style scoped>
.error-msg {
    color: red;
}

.image-upload>input {
    display: none;
}

.image-upload img {
    width: 80px;
    cursor: pointer;
}

.driver-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: solid 5px rgba(150, 150, 150, 0.4);
    object-fit: cover;
}

.logo-upload-btn {
    transition: all 0.2s ease;
    max-width: 35px;
    position: relative;
    top: -40px;
    left: 40px;
    border-radius: 100%;
}

.logo-upload-btn:hover {
    top:-45px;
    max-width: 35px;
    box-shadow: 2px 2px 5px 10px rgba(144, 214, 255, 0.6);
}

.autocomplete {
  position: relative;
  display: inline-block;
}

.autocomplete-result {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-result:hover {
  background-color: #e9e9e9;
}

.autocomplete-results {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  list-style-type: none;
  padding-left: 0px;
  margin-left: 15px;
}
</style>
