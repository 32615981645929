<template>
    <Topnav />

    <Sidebar />

    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <!-- 'toolbar' row -->
                <div class="row mt-2 mb-4 sticky-toolbar">
                    <div class="col-md-8 search">
                        <input type="text" placeholder="Search..." v-model="filter" />
                        <i class="fa fa-search"></i>
                        <input type="date" id="from" v-model="searchDateRange.from" autocomplete="off" />
                        -
                        <input type="date" id="to" v-model="searchDateRange.to" autocomplete="off" />&nbsp;
                    </div>

                    <div class="col-md-2"></div>

                    <div class="col-md-2">
                        <div class="drivers-btn">
                            <!--  <button type="button" class="btn btn-block btn-outline-secondary" style="padding: 0;color: #000000;border-color: #7c7c7c03;">Back</button> -->

                            <router-link to="/add-job" class="btn btn-block btn-info">Add Job</router-link>
                        </div>
                    </div>
                </div>

                <div class="row mt-2 mb-4">
                    <div class="col-md-12">
                        <div class="jobs-lists">
                            <ul class="nav nav-tabs" id="myTab" role="tablist"
                                style="display: flex; border-bottom: 1px solid #f4f6f9">
                                <!-- 
                                    MAV NOTE 01/21/2023
                                    Added vue2 regs to each tab so we can call .click() on the previously selected tab as a QOL feature.
                                    There are a lot of redundant IDs in these tabs to make vue2 work with old-school HTML tab switching...
                                    This could be drastically improved.
                                -->
                                <li class="nav-item">
                                    <a class="nav-link active" ref="QUEUED-tab" id="Queued-tab" data-toggle="tab"
                                        href="#Queued" role="tab" aria-controls="home" aria-selected="true"
                                        @click="handleTabClick('QUEUED')">Queued</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" ref="ONGOING-tab" id="Ongoing-tab" data-toggle="tab" href="#Ongoing"
                                        role="tab" aria-controls="Ongoing" aria-selected="false"
                                        @click="handleTabClick('ONGOING')">Ongoing</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" ref="COMPLETED-tab" id="Completed-tab" data-toggle="tab"
                                        href="#Completed" role="tab" aria-controls="Completed" aria-selected="false"
                                        @click="handleTabClick('COMPLETED')">Completed</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" ref="ABANDONED-tab" id="Abandoned-tab" data-toggle="tab"
                                        href="#Abandoned" role="tab" aria-controls="Abandoned" aria-selected="false"
                                        @click="handleTabClick('ABANDONED')">Abandoned</a>
                                </li>

                            </ul>

                            <div class="row mt-2 mb-4 sticky-toolbar">
                                <Busy class="center-screen" :busy="busy" text="Loading..."/>
                            </div>
                            
                            <!-- Pagination Top -->
                            <div class="row mt-12 mb-12" v-show="!busy">
                                <div class="col-md-4 right-align"></div>
                                <div class="col-md-4" style="display: inline-flex;">
                                    <Pagination :page="pagination.curr" :total="pagination.total" @click="fetchJobPage($event)"/>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                            <!-- /Pagination Top -->

                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="Queued" role="tabpanel"
                                    aria-labelledby="Queued-tab">

                                    <div class="row mt-4" v-if="!busy && mutQueuedJobList.length == 0">
                                        <h4 style="text-align: center; width: 100%">No Results</h4>
                                    </div>

                                    <div v-if="view == 'QUEUED'">
                                        <div class="row mt-4" v-for="queue in mutQueuedJobList" v-bind:key="queue._id">
                                            <div class="col-lg-12 col-6">
                                                <!-- small box -->

                                                <div class="small-box">
                                                    <div class="row" style="padding: 1rem">
                                                        <!-- {{queue.customer}} -->

                                                        <div class="col-md-1">
                                                            <div class="driver-image" v-if="users[queue.customer.userId]">
                                                                <img v-if="users[queue.customer.userId].picture" :src="`${API.baseURL}${users[queue.customer.userId].picture
                                                                    }`" alt="" style="width: 100%" />

                                                                <img v-else src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>

                                                            <div class="driver-image" v-else>
                                                                <img src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-3 drive-info" style="margin-top: 10px">
                                                            <div class="inner" v-if="queue.customer">
                                                                <h5 class="inner-text">
                                                                    Job For {{ queue.customer.name }}
                                                                </h5>

                                                                <div class="driver-address">
                                                                    <img src="../assets/img/task-address-icon-only.svg"
                                                                        alt="" />

                                                                    <p style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        color: red;
                                                                    " v-if="queue.tasks.length == 0">
                                                                        Error Job has zero tasks
                                                                    </p>
                                                                    <p style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        " v-else>
                                                                        Starting from {{ queue.tasks[0].address }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-8 drive-info" style="margin-top: 10px">
                                                            <div class="row">
                                                                <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Job ID</p>

                                                                        <router-link
                                                                            :to="`edit-job?queuedJobId=${queue._id}&lastPage=${$route.path}`"
                                                                            style="color: #000">
                                                                            <h6 style="
                                                                                font-weight: 600;
                                                                                text-decoration: underline dotted;
                                                                                ">
                                                                                {{ queue._id }}
                                                                            </h6>
                                                                        </router-link>
                                                                    </div>
                                                                </div>

                                                                <!-- <div class="col-md-3">      
                                                                    <div class="inner drives-bio">
                                                                        <p>Job Time</p>
                                                                        <h6 style="font-weight: 600;">{{queue.startTime +" ---"+ queue.endTime}}</h6>
                                                                    </div>
                                                                </div> -->

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <p>Service</p>

                                                                        <h6 style="font-weight: 600">
                                                                            {{ queue.chargeType.name }}
                                                                        </h6>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <div class="drivers-certificate"
                                                                            style="margin-top: 0rem">
                                                                            <img src="../assets/img/article_blog_content_news_note.svg"
                                                                                alt="" style="width: 10%" />

                                                                            <h5 style="font-size: 10px">
                                                                                <a v-if="queue.tasks != ''" href="#"
                                                                                    data-toggle="modal"
                                                                                    :data-target="`#largeModal${queue._id}`">Tasks</a>

                                                                                <a style="
                                                                                    margin-top: 10px;
                                                                                    font-size: 10px;
                                                                                " v-if="queue.tasks == ''">Task</a>
                                                                            </h5>

                                                                            <span class="Certifications-number" style="
                                                                                padding: 1px;
                                                                                font-size: 9px;
                                                                                width: 16px;
                                                                                height: 16px;
                                                                                ">{{ queue.tasks.length }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="inner drives-bio">
                                                                        <div class="drivers-certificate" style="margin-top: 0rem;">
                                                                        <img src="../assets/img/count_stop_time_watch_icon.svg" alt="" style="width: 10%;margin-top: 3px;">
                                                                        <h5 style="margin-top: 10px; font-size: 10px;">Stops<a href="#" data-toggle="modal-1" data-target="#largeModal-1">Stops</a></h5>
                                                                        <span class="Certifications-number" style="padding: 1px;font-size: 9px;width: 16px;height: 16px;margin-top: 10px;">2</span>
                                                                        </div>
                                                                        </div> -->
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="btn-group dropleft">
                                                                        <a class="" data-toggle="dropdown"
                                                                            aria-haspopup="true" aria-expanded="false">
                                                                            <img src="../assets/img/select-edit-icon.svg"
                                                                                alt=" " style="width: 65%" />
                                                                        </a>

                                                                        <div class="dropdown-menu">
                                                                            <div class="jobs-edits">
                                                                                <ul class="jobs-lists">
                                                                                    <li
                                                                                        class="jobs-lists-add action-menu-item">
                                                                                        <router-link
                                                                                            :to="`edit-job?queuedJobId=${queue._id}&lastPage=${$route.path}`"
                                                                                            style="color: #000">Edit</router-link>
                                                                                    </li>

                                                                                    <li
                                                                                        class="jobs-lists-add action-menu-item">
                                                                                        <a class="del_driver"
                                                                                            data-toggle="modal"
                                                                                            :data-target="`#deleteQueuedJOb${queue._id}`"
                                                                                            style="color: #000">Delete
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Delete Modal -->

                                            <div class="modal" :id="`deleteQueuedJOb${queue._id}`">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <!-- Modal Header -->

                                                        <div class="modal-header">
                                                            <h4 class="modal-title" style="font-size: 20px">
                                                                Delete Job
                                                            </h4>

                                                            <button type="button" class="close mt-3" data-dismiss="modal">
                                                                &times;
                                                            </button>
                                                        </div>

                                                        <!-- Modal body -->

                                                        <div class="modal-body pl-3">
                                                            Are you sure you want to permanently delete?
                                                        </div>

                                                        <!-- Modal footer -->

                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-success mr-3"
                                                                :id="`${queue._id}`" v-on:click="deleteQueuedJob(queue._id)"
                                                                data-dismiss="modal">
                                                                Yes
                                                            </button>

                                                            <button type="button" class="btn btn-danger"
                                                                data-dismiss="modal">
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- End Delete Modal -->

                                            <!-- task Modal -->

                                            <div class="modal" :id="`largeModal${queue._id}`" tabindex="-1" role="dialog"
                                                aria-labelledby="basicModal" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-body">
                                                            <!-- carousel -->

                                                            <div :id="`carouselExampleIndicators${queue._id}`"
                                                                class="carousel slide" data-ride="carousel">
                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item"
                                                                        :class="index == 0 ? ' active' : ''"
                                                                        v-for="(task, index) in queue.tasks"
                                                                        v-bind:key="task._id" v-bind:index="index">
                                                                        <div class="modal-content" style="height: 500px">
                                                                            <div class="row task-popup-box"
                                                                                style="padding: 0 40px 70px 40px">
                                                                                <div class="header-modal-popup">
                                                                                    <h4 class="modal-title" style="
                                                                                        font-size: 24px;
                                                                                        font-weight: 600; /* padding: 16px 40px 16px 40px; */
                                                                                        margin: auto;
                                                                                        margin-top: 3%;
                                                                                        ">
                                                                                        Task {{ index + 1 }}
                                                                                    </h4>

                                                                                    <button type="button" class="close"
                                                                                        data-dismiss="modal" style="
                                                                                        width: 20px;
                                                                                        height: 20px;
                                                                                        border: 2px solid black; /* padding-left: 16px; */
                                                                                        border-radius: 50px;
                                                                                        margin-top: 3%;
                                                                                        ">
                                                                                        ×
                                                                                    </button>
                                                                                </div>

                                                                                <div class="col-md-12 task-data"
                                                                                    style="margin-top: 5%"
                                                                                    v-if="task.customerID">
                                                                                    <p>Company</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.customerID.name }}</h6>

                                                                                        <img src="../assets/img/task-address-icon.svg"
                                                                                            alt="" v-if="task.isPickup" />
                                                                                        <small v-else>Dropoff</small>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.facility">
                                                                                    <p>Facility</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.facility }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Address</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.address }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.department">
                                                                                    <p>Department</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>
                                                                                            {{
                                                                                                task.department.departmentName
                                                                                            }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Contact</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.contact }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Phone</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.phoneNumber }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2">
                                                                                    <p style="
                                                                                        font-size: 12px;
                                                                                        font-weight: 300;
                                                                                        ">
                                                                                        Notes
                                                                                    </p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6 style="line-height: 20px">
                                                                                            {{ task.extraNotes }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <a class="carousel-control-prev"
                                                                    :href="`#carouselExampleIndicators${queue._id}`"
                                                                    role="button" data-slide="prev">
                                                                    <span class="carousel-control-prev-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Previous</span>
                                                                </a>

                                                                <a class="carousel-control-next"
                                                                    :href="`#carouselExampleIndicators${queue._id}`"
                                                                    role="button" data-slide="next">
                                                                    <span class="carousel-control-next-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Next</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="Ongoing" role="tabpanel" aria-labelledby="Ongoing-tab">
                                    <div class="row mt-4" v-if="!busy && mutOngoingJobList.length == 0">
                                        <h4 style="text-align: center; width: 100%">No Results</h4>
                                    </div>

                                    <div v-if="view == 'ONGOING'">
                                        <div class="row mt-4" v-for="ongoing in mutOngoingJobList" v-bind:key="ongoing._id">
                                            <div class="col-lg-12 col-6">
                                                <!-- small box -->

                                                <div class="small-box">
                                                    <div class="row" style="padding: 1rem">
                                                        <div class="col-md-1">
                                                            <!-- {{ongoing.customer}} -->

                                                            <div class="driver-image" v-if="users[ongoing.customer.userId]">
                                                                <img v-if="users[ongoing.customer.userId].picture" :src="`${API.baseURL}${users[ongoing.customer.userId].picture
                                                                    }`" alt="" style="width: 100%" />

                                                                <img v-else src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>

                                                            <div class="driver-image" v-else>
                                                                <img src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-3 drive-info" style="margin-top: 10px">
                                                            <div class="inner" v-if="ongoing.customer != null">
                                                                <h5 class="inner-text">
                                                                    Job For {{ ongoing.customer.name }}
                                                                </h5>

                                                                <div class="driver-address">
                                                                    <img src="../assets/img/task-address-icon-only.svg"
                                                                        alt="" />

                                                                    <p style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        color: red;
                                                                        " v-if="ongoing.Tasks.length == 0">
                                                                        Error Job has zero tasks
                                                                    </p>
                                                                    <p style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        " v-else>
                                                                        Starting from {{ ongoing.Tasks[0].address }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-8 drive-info" style="margin-top: 10px">
                                                            <div class="row">
                                                                <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Job Id</p>

                                                                        <router-link
                                                                            :to="`edit-job?jobId=${ongoing._id}&lastPage=${$route.path}`"
                                                                            style="color: #000">
                                                                            <h6 style="
                                                                                font-weight: 600;
                                                                                text-decoration: underline dotted;
                                                                                ">
                                                                                {{ ongoing._id }}
                                                                            </h6>
                                                                        </router-link>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Driver</p>

                                                                        <router-link
                                                                            :to="`edit-driver-registration?id=${ongoing.Driver._id}`">
                                                                            <h6 style="
                                                                                font-weight: 600;
                                                                                color: black;
                                                                                text-decoration: underline dashed;
                                                                                ">
                                                                                {{ getDriverName(ongoing.Driver) }}
                                                                            </h6>
                                                                        </router-link>
                                                                    </div>
                                                                </div>

                                                                <!--  <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Job Time</p>
                                                                        <h6 style="font-weight: 600;">{{ongoing.start_time}}</h6>
                                                                    </div>
                                                                    </div> -->

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <p>Service</p>

                                                                        <h6 style="font-weight: 600">
                                                                            {{ ongoing.chargeType.name }}
                                                                        </h6>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <div class="drivers-certificate"
                                                                            style="margin-top: 0rem">
                                                                            <img src="../assets/img/article_blog_content_news_note.svg"
                                                                                alt="" style="width: 10%" />

                                                                            <h5 style="font-size: 10px">
                                                                                <a v-if="ongoing.Tasks != ''" href="#"
                                                                                    data-toggle="modal"
                                                                                    :data-target="`#largeModal${ongoing._id}`">Tasks</a>

                                                                                <a style="margin-top: 10px;
                                                                                    font-size: 10px;" v-if="ongoing.Tasks == ''">Tasks</a>
                                                                            </h5>

                                                                            <span class="Certifications-number" style="
                                                                                padding: 1px;
                                                                                font-size: 9px;
                                                                                width: 16px;
                                                                                height: 16px;
                                                                                ">{{ ongoing.Tasks.length }}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <!-- <div class="inner drives-bio">
                                                                        <div class="drivers-certificate" style="margin-top: 0rem;">
                                                                        <img src="../assets/img/count_stop_time_watch_icon.svg" alt="" style="width: 10%;margin-top: 3px;">
                                                                        <h5 style="margin-top: 10px; font-size: 10px;">Stops<a href="#" data-toggle="modal-1" data-target="#largeModal-1">Stops</a></h5>
                                                                        <span class="Certifications-number" style="padding: 1px;font-size: 9px;width: 16px;height: 16px;margin-top: 10px;">2</span>
                                                                    </div>
                                                                    </div> -->
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="btn-group dropleft">
                                                                        <a class="" data-toggle="dropdown"
                                                                            aria-haspopup="true" aria-expanded="false">
                                                                            <img src="../assets/img/select-edit-icon.svg"
                                                                                alt=" " style="width: 65%" />
                                                                        </a>

                                                                        <div class="dropdown-menu">
                                                                            <div class="jobs-edits">
                                                                                <ul class="jobs-lists">
                                                                                    <li
                                                                                        class="jobs-lists-add action-menu-item">
                                                                                        <router-link
                                                                                            :to="`edit-job?jobId=${ongoing._id}&lastPage=${$route.path}`"
                                                                                            style="color: #000">Edit</router-link>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- End Delete Modal -->

                                            <!-- task Modal -->

                                            <div class="modal" :id="`largeModal${ongoing._id}`" tabindex="-1" role="dialog"
                                                aria-labelledby="basicModal" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-body">
                                                            <!-- carousel -->

                                                            <div :id="`carouselExampleIndicators${ongoing._id}`"
                                                                class="carousel slide" data-ride="carousel">
                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item"
                                                                        :class="index == 0 ? ' active' : ''"
                                                                        v-for="(task, index) in ongoing.Tasks"
                                                                        v-bind:key="task._id" v-bind:index="index">
                                                                        <div class="modal-content" style="height: 500px">
                                                                            <div class="row task-popup-box"
                                                                                style="padding: 0 40px 70px 40px">
                                                                                <div class="header-modal-popup">
                                                                                    <h4 class="modal-title" style="
                                                                                        font-size: 24px;
                                                                                        font-weight: 600; /* padding: 16px 40px 16px 40px; */
                                                                                        margin: auto;
                                                                                        margin-top: 3%;
                                                                                        ">
                                                                                        Task {{ index + 1 }}
                                                                                    </h4>

                                                                                    <button type="button" class="close"
                                                                                        data-dismiss="modal" style="
                                                                                        width: 20px;
                                                                                        height: 20px;
                                                                                        border: 2px solid black; /* padding-left: 16px; */
                                                                                        border-radius: 50px;
                                                                                        margin-top: 3%;
                                                                                        ">
                                                                                        ×
                                                                                    </button>
                                                                                </div>

                                                                                <div class="col-md-12 task-data"
                                                                                    style="margin-top: 5%"
                                                                                    v-if="task.customerID">
                                                                                    <p>Company</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.customerID.name }}</h6>

                                                                                        <img src="../assets/img/task-address-icon.svg"
                                                                                            alt="" v-if="task.isPickup" />
                                                                                        <small v-else>Dropoff</small>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.facility">
                                                                                    <p>Facility</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.facility }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Address</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.address }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.department">
                                                                                    <p>Department</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>
                                                                                            {{
                                                                                                task.department.departmentName
                                                                                            }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Contact</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.contact }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Phone</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.phoneNumber }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2">
                                                                                    <p style="
                                                                                        font-size: 12px;
                                                                                        font-weight: 300;
                                                                                        ">
                                                                                        Notes
                                                                                    </p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6 style="line-height: 20px">
                                                                                            {{ task.extraNotes }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <a class="carousel-control-prev"
                                                                    :href="`#carouselExampleIndicators${ongoing._id}`"
                                                                    role="button" data-slide="prev">
                                                                    <span class="carousel-control-prev-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Previous</span>
                                                                </a>

                                                                <a class="carousel-control-next"
                                                                    :href="`#carouselExampleIndicators${ongoing._id}`"
                                                                    role="button" data-slide="next">
                                                                    <span class="carousel-control-next-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Next</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--  -->
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="Completed" role="tabpanel" aria-labelledby="Completed-tab">
                                    <div class="row mt-4" v-if="!busy && mutCompleteJobList.length == 0">
                                        <h4 style="text-align: center; width: 100%">No Results</h4>
                                    </div>

                                    <div v-if="view == 'COMPLETED'">
                                        <div class="row mt-4" v-for="completed in mutCompleteJobList"
                                            v-bind:key="completed._id">
                                            <div class="col-lg-12 col-6">
                                                <!-- small box -->

                                                <div class="small-box">
                                                    <div class="row" style="padding: 1rem">
                                                        <div class="col-md-1">
                                                            <div class="driver-image"
                                                                v-if="users[completed.customer.userId]">
                                                                <img v-if="users[completed.customer.userId].picture
                                                                    " :src="`${API.baseURL}${users[completed.customer.userId].picture
                                                                    }`" alt="" style="width: 100%" />

                                                                <img v-else src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>

                                                            <div class="driver-image" v-else>
                                                                <img src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-3 drive-info" style="margin-top: 10px">
                                                            <div class="inner" v-if="completed.customer != null">
                                                                <h5 class="inner-text">
                                                                    Job For {{ completed.customer.name }}
                                                                </h5>

                                                                <div class="driver-address">
                                                                    <img src="../assets/img/task-address-icon-only.svg"
                                                                        alt="" />

                                                                    <p style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        color: red;
                                                                        " v-if="completed.Tasks.length == 0">
                                                                        Error Job has zero tasks
                                                                    </p>
                                                                    <p style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        " v-else>
                                                                        Started from
                                                                        {{ completed.Tasks[0].address }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-8 drive-info" style="margin-top: 10px">
                                                            <div class="row">
                                                                <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Job Id</p>

                                                                        <router-link
                                                                            :to="`view-job?pastJobId=${completed._id}&lastPage=${$route.path}`"
                                                                            style="color: #000">
                                                                            <h6 style="
                                                                                font-weight: 600;
                                                                                text-decoration: underline dotted;
                                                                                ">
                                                                                {{ completed._id }}
                                                                            </h6>
                                                                        </router-link>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Driver</p>

                                                                        <router-link
                                                                            :to="`edit-driver-registration?id=${completed.Driver._id}`">
                                                                            <h6 style="
                                                                                font-weight: 600;
                                                                                color: black;
                                                                                text-decoration: underline dashed;
                                                                                ">
                                                                                {{ getDriverName(completed.Driver) }}
                                                                            </h6>
                                                                        </router-link>
                                                                    </div>
                                                                </div>

                                                                <!-- <div class="col-md-3">
                                                                <div class="inner drives-bio">
                                                                    <p>Job Time</p>
                                                                    <h6 style="font-weight: 600;">{{complete.start_time}}</h6>
                                                                </div>
                                                                </div> -->

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <p>Service</p>

                                                                        <h6 style="font-weight: 600">
                                                                            {{ completed.chargeType.name }}
                                                                        </h6>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <div class="drivers-certificate"
                                                                            style="margin-top: 0rem">
                                                                            <img src="../assets/img/article_blog_content_news_note.svg"
                                                                                alt="" style="width: 10%" />

                                                                            <h5 style="font-size: 10px">
                                                                                <a v-if="completed.Tasks != ''" href="#"
                                                                                    data-toggle="modal"
                                                                                    :data-target="`#largeModal${completed._id}`">Tasks</a>

                                                                                <a style="
                                                                                    margin-top: 10px;
                                                                                    font-size: 10px;
                                                                                " v-if="completed.Tasks == ''">Tasks</a>
                                                                            </h5>

                                                                            <span class="Certifications-number" style="
                                                                                padding: 1px;
                                                                                font-size: 9px;
                                                                                width: 16px;
                                                                                height: 16px;
                                                                                ">{{ completed.Tasks.length }}</span>
                                                                        </div>
                                                                    </div>

                                                                        <!-- <div class="inner drives-bio">
                                                                            <div class="drivers-certificate" style="margin-top: 0rem;">
                                                                            <img src="../assets/img/count_stop_time_watch_icon.svg" alt="" style="width: 10%;margin-top: 3px;">
                                                                            <h5 style="margin-top: 10px; font-size: 10px;">Stops<a href="#" data-toggle="modal-1" data-target="#largeModal-1">Stops</a></h5>
                                                                            <span class="Certifications-number" style="padding: 1px;font-size: 9px;width: 16px;height: 16px;margin-top: 10px;">2</span>
                                                                        </div>
                                                                        </div> -->
                                                                </div>

                                                                <div class="col-md-2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- task Modal -->

                                            <div class="modal" :id="`largeModal${completed._id}`" tabindex="-1"
                                                role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-body">
                                                            <!-- carousel -->

                                                            <div :id="`carouselExampleIndicators${completed._id}`"
                                                                class="carousel slide" data-ride="carousel">
                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item"
                                                                        :class="index == 0 ? ' active' : ''"
                                                                        v-for="(task, index) in completed.Tasks"
                                                                        v-bind:key="task._id" v-bind:index="index">
                                                                        <div class="modal-content" style="height: 500px">
                                                                            <div class="row task-popup-box"
                                                                                style="padding: 0 40px 70px 40px">
                                                                                <div class="header-modal-popup">
                                                                                    <h4 class="modal-title" style="
                                                                                        font-size: 24px;
                                                                                        font-weight: 600; /* padding: 16px 40px 16px 40px; */
                                                                                        margin: auto;
                                                                                        margin-top: 3%;
                                                                                        ">
                                                                                        Task {{ index + 1 }}
                                                                                    </h4>

                                                                                    <button type="button" class="close"
                                                                                        data-dismiss="modal" style="
                                                                                        width: 20px;
                                                                                        height: 20px;
                                                                                        border: 2px solid black; /* padding-left: 16px; */
                                                                                        border-radius: 50px;
                                                                                        margin-top: 3%;
                                                                                        ">
                                                                                        ×
                                                                                    </button>
                                                                                </div>

                                                                                <div class="col-md-12 task-data"
                                                                                    style="margin-top: 5%"
                                                                                    v-if="task.customerID">
                                                                                    <p>Company</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.customerID.name }}</h6>

                                                                                        <img src="../assets/img/task-address-icon.svg"
                                                                                            alt="" v-if="task.isPickup" />
                                                                                        <small v-else>Dropoff</small>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.facility">
                                                                                    <p>Facility</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.facility }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Address</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.address }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.department">
                                                                                    <p>Department</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>
                                                                                            {{
                                                                                                task.department.departmentName
                                                                                            }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Contact</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.contact }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Phone</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.phoneNumber }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2">
                                                                                    <p style="
                                                                                        font-size: 12px;
                                                                                        font-weight: 300;
                                                                                        ">
                                                                                        Notes
                                                                                    </p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6 style="line-height: 20px">
                                                                                            {{ task.extraNotes }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <a class="carousel-control-prev"
                                                                    :href="`#carouselExampleIndicators${completed._id}`"
                                                                    role="button" data-slide="prev">
                                                                    <span class="carousel-control-prev-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Previous</span>
                                                                </a>

                                                                <a class="carousel-control-next"
                                                                    :href="`#carouselExampleIndicators${completed._id}`"
                                                                    role="button" data-slide="next">
                                                                    <span class="carousel-control-next-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Next</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="Abandoned" role="tabpanel" aria-labelledby="Abandoned-tab ">
                                    <div class="row mt-4" v-if="!busy && mutAbandonedList.length == 0">
                                        <h4 style="text-align: center; width: 100%">No Results</h4>
                                    </div>

                                    <div v-if="view == 'ABANDONED'">
                                        <div class="row mt-4" v-for="abandoned in mutAbandonedList"
                                            v-bind:key="abandoned._id">
                                            <div class="col-lg-12 col-6">
                                                <!-- small box -->

                                                <div class="small-box">
                                                    <div class="row" style="padding: 1rem">
                                                        <div class="col-md-1">
                                                            <div class="driver-image"
                                                                v-if="users[abandoned.customer.userId]">
                                                                <img v-if="users[abandoned.customer.userId].picture" 
                                                                    :src="`${API.baseURL}${users[abandoned.customer.userId].picture}`" 
                                                                    alt="" style="width: 100%" />

                                                                <img v-else src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>

                                                            <div class="driver-image" v-else>
                                                                <img src="../assets/img/profile.png" alt=""
                                                                    style="width: 100%" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-3 drive-info" style="margin-top: 10px">
                                                            <div class="inner" v-if="abandoned.customer != null">
                                                                <h5 class="inner-text">
                                                                    Job For {{ abandoned.customer.name }}
                                                                </h5>

                                                                <div class="driver-address">
                                                                    <img src="../assets/img/task-address-icon-only.svg"
                                                                        alt="" />

                                                                    <p v-if="abandoned.Tasks.length == 0" style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        ">
                                                                        All tasks skipped.
                                                                    </p>
                                                                    <p v-else style="
                                                                        margin-bottom: 0;
                                                                        margin-left: 2%;
                                                                        font-size: 10px;
                                                                        ">
                                                                        Started from
                                                                        {{ abandoned.Tasks[0].address }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-8 drive-info" style="margin-top: 10px">
                                                            <div class="row">
                                                                <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Job Id</p>

                                                                        <router-link
                                                                            :to="`view-job?pastJobId=${abandoned._id}&lastPage=${$route.path}`"
                                                                            style="color: #000">
                                                                            <h6 style="font-weight: 600;decoration: underline dotted;">
                                                                                {{ abandoned._id }}
                                                                            </h6>
                                                                        </router-link>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Driver</p>

                                                                        <router-link
                                                                            :to="`edit-driver-registration?id=${abandoned.Driver._id}`">
                                                                            <h6 style="font-weight: 600;color: black;text-decoration: underline dashed;">
                                                                                {{ getDriverName(abandoned.Driver) }}
                                                                            </h6>
                                                                        </router-link>
                                                                    </div>
                                                                </div>

                                                                <!-- <div class="col-md-3">
                                                                    <div class="inner drives-bio">
                                                                        <p>Job Time</p>
                                                                        <h6 style="font-weight: 600;">{{complete.start_time}}</h6>
                                                                    </div>
                                                                    </div> -->

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <p>Service</p>

                                                                        <h6 style="font-weight: 600">
                                                                            {{ abandoned.chargeType.name }}
                                                                        </h6>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="inner drives-bio">
                                                                        <div class="drivers-certificate"
                                                                            style="margin-top: 0rem">
                                                                            <img src="../assets/img/article_blog_content_news_note.svg"
                                                                                alt="" style="width: 10%" />

                                                                            <h5 style="font-size: 10px">
                                                                                <a href="#" data-toggle="modal"
                                                                                    :data-target="`#largeModal${abandoned._id}`">Tasks</a>
                                                                            </h5>

                                                                            <span class="Certifications-number" style="
                                                                                padding: 1px;
                                                                                font-size: 9px;
                                                                                width: 16px;
                                                                                height: 16px;
                                                                                ">{{ abandoned.Tasks.length }}</span>
                                                                        </div>
                                                                    </div>

                                                                    <!--  <div class="inner drives-bio">
                                                                        <div class="drivers-certificate" style="margin-top: 0rem;">
                                                                        <img src="../assets/img/count_stop_time_watch_icon.svg" alt="" style="width: 10%;margin-top: 3px;">
                                                                        <h5 style="margin-top: 10px; font-size: 10px;">Stops<a href="#" data-toggle="modal-1" data-target="#largeModal-1">Stops</a></h5>
                                                                        <span class="Certifications-number" style="padding: 1px;font-size: 9px;width: 16px;height: 16px;margin-top: 10px;">2</span>
                                                                        </div>
                                                                    </div> -->
                                                                </div>

                                                                <div class="col-md-2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Delete Modal -->

                                            <div class="modal" :id="`deleteCompletedJob${abandoned._id}`">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <!-- Modal Header -->

                                                        <div class="modal-header">
                                                            <h4 class="modal-title" style="font-size: 20px">
                                                                Delete Job
                                                            </h4>

                                                            <button type="button" class="close mt-3" data-dismiss="modal">
                                                                &times;
                                                            </button>
                                                        </div>

                                                        <!-- Modal body -->

                                                        <div class="modal-body pl-3">
                                                            Are you sure you want to permanently delete?
                                                        </div>

                                                        <!-- Modal footer -->

                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-success mr-3"
                                                                :id="`${abandoned._id}`"
                                                                v-on:click="deleteCompletedJob(abandoned._id)">
                                                                Yes
                                                            </button>

                                                            <button type="button" class="btn btn-danger"
                                                                data-dismiss="modal">
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- End Delete Modal -->

                                            <!-- task Modal -->

                                            <div class="modal" :id="`largeModal${abandoned._id}`" tabindex="-1"
                                                role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-body">
                                                            <!-- carousel -->

                                                            <div :id="`carouselExampleIndicators${abandoned._id}`"
                                                                class="carousel slide" data-ride="carousel">
                                                                <div class="carousel-inner">
                                                                    <div class="carousel-item"
                                                                        :class="index == 0 ? ' active' : ''"
                                                                        v-for="(task, index) in abandoned.Tasks"
                                                                        v-bind:key="task._id" v-bind:index="index">
                                                                        <div class="modal-content" style="height: 500px">
                                                                            <div class="row task-popup-box"
                                                                                style="padding: 0 40px 70px 40px">
                                                                                <div class="header-modal-popup">
                                                                                    <h4 class="modal-title" style="
                                                                                        font-size: 24px;
                                                                                        font-weight: 600; /* padding: 16px 40px 16px 40px; */
                                                                                        margin: auto;
                                                                                        margin-top: 3%;
                                                                                        ">
                                                                                        Task {{ index + 1 }}
                                                                                    </h4>

                                                                                    <button type="button" class="close"
                                                                                        data-dismiss="modal" style="
                                                                                            width: 20px;
                                                                                            height: 20px;
                                                                                            border: 2px solid black; /* padding-left: 16px; */
                                                                                            border-radius: 50px;
                                                                                            margin-top: 3%;
                                                                                            ">
                                                                                        ×
                                                                                    </button>
                                                                                </div>

                                                                                <div class="col-md-12 task-data"
                                                                                    style="margin-top: 5%"
                                                                                    v-if="task.customerID">
                                                                                    <p>Company</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.customerID.name }}</h6>

                                                                                        <img src="../assets/img/task-address-icon.svg"
                                                                                            alt="" v-if="task.isPickup" />
                                                                                        <small v-else>Dropoff</small>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.facility">
                                                                                    <p>Facility</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.facility }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Address</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.address }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data"
                                                                                    v-if="task.department">
                                                                                    <p>Department</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>
                                                                                            {{
                                                                                                task.department.departmentName
                                                                                            }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Contact</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.contact }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2 task-data">
                                                                                    <p>Phone</p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6>{{ task.phoneNumber }}</h6>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-12 mt-2">
                                                                                    <p style="
                                                                                        font-size: 12px;
                                                                                        font-weight: 300;
                                                                                        ">
                                                                                        Notes
                                                                                    </p>

                                                                                    <div
                                                                                        class="mb-2 task-popup-heading-task">
                                                                                        <h6 style="line-height: 20px">
                                                                                            {{ task.extraNotes }}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <a class="carousel-control-prev"
                                                                    :href="`#carouselExampleIndicators${abandoned._id}`"
                                                                    role="button" data-slide="prev">
                                                                    <span class="carousel-control-prev-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Previous</span>
                                                                </a>

                                                                <a class="carousel-control-next"
                                                                    :href="`#carouselExampleIndicators${abandoned._id}`"
                                                                    role="button" data-slide="next">
                                                                    <span class="carousel-control-next-icon"
                                                                        aria-hidden="true"></span>

                                                                    <span class="sr-only">Next</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--  -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Pagination Bottom -->
                            <div class="row mt-12 mb-12" v-show="!busy">
                                <div class="col-md-4 right-align"></div>
                                <div class="col-md-4" style="display: inline-flex;">
                                    <Pagination :page="pagination.curr" :total="pagination.total" @click="fetchJobPage($event)"/>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                            <!-- /Pagination Bottom -->

                        </div>
                    </div>

                    <!-- <div class="col-md-2">
                  <div class="jobs-search">
                   <div class="input-group input-group-sm " style="width: 220px;">
                    <div class="jobs-search-search-icon">
                      <i class="fas fa-search"></i>
                    </div>
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search" style="border-radius: 50px;padding: 18px 12px 18px 30px;font-size: 12px;">
                    </div>
                  </div>
                </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination.vue'
import Topnav from "@/components/Topnav.vue"
import Sidebar from "@/components/Sidebar.vue"
import Busy from "@/components/Busy.vue"
import API from "../axios.config"
import utils from "../utils"

export default {
    name: "Jobs",
    data() {
        return {
            QueuedJobList: [],
            OngoingJobList: [],
            CompleteJobList: [],
            AbandonedList: [],
            users: [],
            pagination: {
                curr: 1,
                total: 1,
                limit: 10,
            },
            searchDateRange: {
                from: null,
                to: null,
            },
            msg: "",
            view: "QUEUED",
            filter: "",
            API: API,
            busy: true,
        };
    },
    components: {
        Pagination,
        Topnav,
        Sidebar,
        Busy,
    },
    watch: {
    },
    async mounted() {
        this.view = localStorage.getItem("job_view_tab") ?? "QUEUED"
        await this.CollectUsers()
        await this.fetchJobPage(1)
        this.busy = false
    },
    computed: {
        mutQueuedJobList() {
            return this.QueuedJobList.filter(this.filterJobData).filter(
                this.filterJobByDateRange
            );
        },
        mutOngoingJobList() {
            return this.OngoingJobList.filter(this.filterJobData).filter(
                this.filterJobByDateRange
            );
        },
        mutCompleteJobList() {
            return this.CompleteJobList.filter(this.filterJobData).filter(
                this.filterJobByDateRange
            );
        },
        mutAbandonedList() {
            return this.AbandonedList.filter(this.filterJobData).filter(
                this.filterJobByDateRange
            );
        },
    },
    methods: {
        getDriverName(driver) {
            if (!driver.userId.first_name || !driver.userId.last_name)
                return "Name Unavailable";

            return driver.userId.first_name + " " + driver.userId.last_name;
        },

        async fetchJobPage(page) {
            this.pagination.curr = page
            
            const last_selected_tab = this.view
            if (last_selected_tab) {
                this.$refs[`${last_selected_tab}-tab`]?.click()
            }
            
            if(last_selected_tab == "COMPLETED" || last_selected_tab == "ABANDONED") {
                await this.PastJobs()
            } else if(last_selected_tab == "ONGOING") {
                await this.OngoingJobs()
            } else {
                await this.QueuedJobs()
            }
        },

        handleTabClick(tab) {
            if(this.view == tab) return

            this.view = tab
            localStorage.setItem("job_view_tab", tab)
            this.pagination.total = 1 // specifically changing to a new tab
            this.fetchJobPage(1)
        },

        async CollectUsers() {
            this.msg = "";
            let users = localStorage.getItem("user-info");
            if (!users) {
                this.$router.push({ name: "Login" });
            }
            this.loginToken = localStorage.getItem("user-info");

            try {
                let response = await API.get(`/users`, {
                        headers: {
                            Authorization: `Bearer ${this.loginToken}`,
                        },
                    })
                if (response.status == 200) {
                    let data = response.data
                    let vm = this
                    data.forEach((el) => {
                        vm.users[el._id] = el
                    })
                }
            }
            catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: "error",
                    position: "top",
                })
            }
        },

        async QueuedJobs() {
            this.msg = "";
            let users = localStorage.getItem("user-info");
            if (!users) {
                this.$router.push({ name: "Login" });
            }
            this.loginToken = localStorage.getItem("user-info");

            try {
                const path = utils.pagination('/QueuedJob', this.pagination.curr, this.pagination.limit)
                let response = await API.get(path, {
                        headers: {
                            Authorization: `Bearer ${this.loginToken}`,
                        },
                    })
                
                if (response.status == 200) {
                    this.pagination.total = response.data.pages
                    this.QueuedJobList = []
                    if (response.data.data.length == 0) {
                        this.msg = "No Result Found"
                    } else {
                        this.QueuedJobList = response.data.data
                    }
                }
            }
            catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: "error",
                    position: "top",
                })
            }
        },

        async PastJobs() {
            this.msg = "";
            this.loginToken = localStorage.getItem("user-info");

            let header = {
                Authorization: `Bearer ${this.loginToken}`,
            }

            try {
                const path = utils.pagination('/pastjobs', this.pagination.curr, this.pagination.limit)
                // Note the status does not match with the tab names
                const statusFilter = "&status="+(this.view=="ABANDONED"?"CANCELLED":"COMPLETE")

                let response = await API.get(
                        path+statusFilter,
                        {
                            headers: header,
                        }
                    )
                
                if (response.status == 200) {
                    this.pagination.total = response.data.pages

                    this.CompleteJobList = []
                    this.AbandonedList = []

                    // Assign to the correct list
                    if(this.view == "ABANDONED"){
                        this.AbandonedList = response.data.data
                    } else {
                        this.CompleteJobList = response.data.data
                    }
                }
            } catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: "error",
                    position: "top",
                })
            }
        },

        async OngoingJobs() {
            this.msg = ""
            this.loginToken = localStorage.getItem("user-info")

            let header = {
                Authorization: `Bearer ${this.loginToken}`,
            }

            try {
                const path = utils.pagination('/jobs', this.pagination.curr, this.pagination.limit)
                let response = await API.get(
                        path,
                        {
                            headers: header,
                        }
                    )

                if (response.status == 200) {
                    this.OngoingJobList = []

                    this.pagination.total = response.data.pages
                    if (response.data.data.length == 0) {
                        this.msg = "No Result Found";
                    } else {
                        this.OngoingJobList = response.data.data
                    }
                }
            }
            catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: "error",
                    position: "top",
                })
            }
        },

        async deleteQueuedJob(queuedJob_id) {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");
            API.delete(`/QueuedJob/${queuedJob_id}`, {
                headers: {
                    Authorization: `Bearer ${vm.loginToken}`,
                },
            })
                .then(function (response) {
                    if (response.status == 200) {
                        vm.$toast.success("Job deleted successfully.", {
                            // override the global option
                            position: "top",
                        });

                        vm.QueuedJobList = vm.QueuedJobList.filter(
                            (j) => j._id != queuedJob_id
                        );
                    }
                })
                .catch(function (error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: "Session expired. Please login again.",
                            type: "error",
                            position: "top",
                        });
                        vm.$router.push({ name: "Login" });
                        localStorage.clear();
                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: "error",
                            position: "top",
                        });
                    }
                });
        },

        async deleteOngoingJOb(ongoingJob_id) {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");
            API.delete(`/jobs/${ongoingJob_id}`, {
                headers: {
                    Authorization: `Bearer ${vm.loginToken}`,
                },
            })
                .then(function (response) {
                    if (response.status == 200) {
                        vm.$toast.success("Job deleted successfully.", {
                            // override the global option
                            position: "top",
                        });
                        location.reload();
                    }
                })
                .catch(function (error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: "Session expired. Please login again.",
                            type: "error",
                            position: "top",
                        });
                        vm.$router.push({ name: "Login" });
                        localStorage.clear();
                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: "error",
                            position: "top",
                        });
                    }
                });
        },

        filterJobByDateRange(data) {
            const from = this.searchDateRange.from;
            const to = this.searchDateRange.to;

            if (!data) return false;
            if (!from || !to) return true;

            const createdAt = Date.parse(data.createdAt);
            const dateFrom = Date.parse(from);

            // `from` will begin at the first minute of the day
            // `to` will extend to the last possible hours and minute of the day
            const dateTo = Date.parse(to) + 86400000;

            return dateFrom <= createdAt && createdAt <= dateTo;
        },

        filterJobData(data) {
            if (!data) return false;

            if (this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim() + ".*", "i");
                for (const [key, value] of Object.entries(data)) {
                    /* special case, task data */
                    if (
                        key.toLowerCase() == "tasks" &&
                        value != null &&
                        value.length > 0
                    ) {
                        const tasks = value;
                        for (const task of tasks) {
                            if (this.filterJobData(task)) {
                                return true;
                            }
                        }
                        continue;
                    }

                    if (key == "Driver") {
                        /* special case, filter driver data too */
                        if (this.filterJobData(value)) {
                            return true;
                        }
                        continue;
                    }

                    if (key == "department") {
                        /* special case, filter department data too */
                        if (this.filterJobData(value)) {
                            return true;
                        }
                        continue;
                    }

                    if (key == "customerID") {
                        /* special case, filter customer data too */
                        if (this.filterJobData(value)) {
                            return true;
                        }
                        continue;
                    }

                    if (key == "customer") {
                        /* same as above but for nested objects */
                        if (this.filterJobData(value)) {
                            return true;
                        }
                        continue;
                    }

                    if (key == "chargeType") {
                        /* special case, filter chargeType data too */
                        if (this.filterJobData(value)) {
                            return true;
                        }
                        continue;
                    }

                    if (key == "userId") {
                        /* special case, filter user data too */
                        if (this.filterJobData(value)) {
                            return true;
                        }
                        continue;
                    }

                    if (regex.test(value)) {
                        return true;
                    }
                }

                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped>
.action-menu-item {
    color: #000;
}

.action-menu-item:hover {
    background-color: rgba(202, 225, 255, 0.81);
}

.dropleft:hover {
    cursor: pointer;
}

.sticky-toolbar {
    width: 100%;
    position: sticky;
    top: 10px;
    z-index: 1000;
}

.center-screen {
    text-align: center; 
    width: 100%;
    position: absolute;
}

div.search i {
    left: -30px;
    position: relative;
}
</style>
