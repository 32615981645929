<template>
	<Topnav />
  <Sidebar /> 
  <div class="content-wrapper">
  <div class="content-header">
        <div class="container-fluid">
      <form @submit.prevent="driverUpdate">
      <div class="row mt-2 mb-4">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <div class="drivers-btn">
            <button type="button" class="btn btn-block btn-outline-secondary" style="padding: 0;color: #000000;border-color: #7c7c7c03;">Back</button>
            <button  type="submit" class="btn btn-block btn-info">Submit</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="Customer-edit-form">
            <img src="../assets/img/new_customer.svg" alt="">
            <button type="button" class="btn btn-block btn-info" style="margin: auto;width: 15%;margin-top: 1rem;">Upgrade to Admin</button>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          
            <div class="form-row">
              <div class="form-item col-md-4">
                <input type="text" id="name" v-model="name" autocomplete="off" required>
                <label for="name" style="background: #f4f6f9;"></label>
              </div>
               <div class="form-item col-md-4">
                <input type="text" id="name" v-model="date_of_birth" autocomplete="off" >
                <label for="name" style="background: #f4f6f9;">Date of Birth</label>
              </div>
               <div class="form-item col-md-4">
                <input type="text" id="name" v-model="current_license_number" autocomplete="off" required >
                <label for="name" style="background: #f4f6f9;">License #</label>
              </div>
              <div class="form-item col-md-4">
                 <input type="text" id="name" v-model="work_cell_number" autocomplete="off" required >
                <label for="name" style="background: #f4f6f9;">Work Cell</label>
              </div>
              <div class="form-item col-md-8">
                <input type="text" id="name" autocomplete="off"  v-model="home_address" required >
                <label for="name" style="background: #f4f6f9;">Home Address</label>
              </div>
              <div class="col-md-12 mt-4 department">
                <h6>Bank Info</h6>
              </div>
              <!-- <div class="col-md-6 mt-4 department">
                <div class="customer-edit-form-img">
                  <img src="assets/img/Add-file-icon.svg" alt="" style="width:5%;">
                <img src="assets/img/delete-icon.svg" alt="" style="width:3%;">
                </div>
              </div> -->
              <div class="form-item col-md-4 mt-4">
                <input type="text" id="name" autocomplete="off" required v-model="bank_name">
                <label for="name" style="background: #f4f6f9;">Name</label>
              </div>
               <div class="form-item col-md-4 mt-4">
                <input type="text" id="name" autocomplete="off" required v-model="routing_number">
                <label for="name" style="background: #f4f6f9;">Route #</label>
              </div>
               <div class="form-item col-md-4 mt-4">
                <input type="text" id="name" autocomplete="off" required v-model="account_number">
                <label for="name" style="background: #f4f6f9;">Account #</label>
              </div>
              <!-- <div class="form-item col-md-8">
                <input type="text" id="name" autocomplete="off" required >
                <label for="name" style="background: #f4f6f9;">Address</label>
              </div>
              <div class="form-item col-md-4"></div> -->
              <div class="col-md-12  signature">
                <input type="file" class="btn btn-block btn-outline-secondary" style="color: #7c7c7c;border-color: #979797;font-size: 10px;font-weight: 500;text-transform: capitalize;padding: 10px 0px 10px 1px;width: 15%;" value="Upload Document">
              </div>
              <div class="col-md-6 mt-4 signature">
                <a href="" class="new-driver-registration-link">CDL_Verifi1. pdf</a>
                 <button type="button" class="btn btn-block btn-outline-secondary" style="color: #7c7c7c;border-color: #979797;font-size: 10px;font-weight: 500;text-transform: capitalize;padding: 10px 0px 10px 1px;width: 20%;margin-left: 2%;margin-right: 2%;">Download</button>
                  <button type="button" class="btn btn-block btn-outline-secondary" style="color: #7c7c7c;border-color: #979797;font-size: 10px;font-weight: 500;text-transform: capitalize;padding: 10px 0px 10px 1px;width: 20%;">Delete</button>
              </div>


            </div>
          
        </div>
      </div>
    </form>
    </div>
  </div>
</div>

</template>
<script>
  import Topnav from '@/components/Topnav.vue'
  import Sidebar from '@/components/Sidebar.vue'
  import API from '../axios.config';
  export default{
    name:'DriverRegistration',
     data(){
      return {
        name:'',
        date_of_birth:'',
        current_license_number:'',
        work_cell_number:'',
        home_address:'',
        bank_name:'',
        account_number:'',
        routing_number:'',
        uploads:'',
        show:false
      
      }
    },
    components:{
      Topnav,
      Sidebar,
    },    
    mounted(){
      const vm = this;
      let driver_id = localStorage.getItem("driver_id")
      let loginToken = localStorage.getItem("loginToken")

      let header =  {
                  'Authorization': `Bearer ${loginToken}`,
                 
               }
    API.get(`/driver/${driver_id}`,
      {
        headers: header
      },
    )
    .then(function (response) { 
         if(response.status == 200){ 
               vm.name= response.data.data.name;
               vm.current_license_number= response.data.data.current_license_number;
               vm.work_cell_number= response.data.data.work_cell_number;
               vm.home_address= response.data.data.home_address;
               vm.bank_name= response.data.data.bank_name;
               vm.account_number= response.data.data.account_number;
               vm.routing_number= response.data.data.routing_number;
               
                           
         }
       })
       .catch(function (error) {
        vm.$toast.open({
             message: error,
             type: 'error',
             position: 'top'
          });
       });
    
  }, 
  methods:{
    async driverUpdate(){
      const vm = this;
     let  user = localStorage.getItem("user-info");        
        vm.loginToken = JSON.parse(user).token;
      let header =  {
                  'Authorization': `Bearer ${vm.loginToken}`,
                 
                }
          let id= this.$route.query.id;

      await API.put(`/driver/${id}`,
        {
        name: vm.name, 
        date_of_birth:vm.date_of_birth,
        current_license_number:vm.current_license_number,
        work_cell_number:vm.work_cell_number,
        home_address:vm.home_address,
        bank_name:vm.bank_name,
        account_number:vm.account_number,
        routing_number:vm.routing_number,      

      },
      {
        headers: header
      },
      
      )
    .then(function (response) { 
         if(response.status == 200){ 
              vm.$toast.success('Profile Updated successfully.', {
                    // override the global option
                    position: 'top'
                })
                
                //console.log(response.data.data.driver.name);  
                let dob =  response.data.data.driver.date_of_birth.split("T")    
                vm.date_of_birth= dob[0];
                // let dname =  response.data.data.driver.name.split(" ")
                vm.name= response.data.data.driver.name;
                vm.current_license_number= response.data.data.driver.current_license_number;
                vm.work_cell_number= response.data.data.driver.work_cell_number;
                vm.home_address= response.data.data.driver.home_address;
                vm.bank_name= response.data.data.driver.bank_name;
                vm.account_number= response.data.data.driver.account_number;
                vm.routing_number= response.data.data.driver.routing_number; 
                location.reload();             
         }
       })
       .catch(function (error) {
          //handle error here
          if(error.response.data.status=="Expired"){
            vm.$toast.open({
              message: 'Session expired. Please login again.',
              type: 'error',
              position: 'top'
            });
            vm.$router.push({name:'Login'})
            localStorage.clear();

         }else{
            vm.$toast.open({
              message: error,
              type: 'error',
              position: 'top'
          });

         }
       });

    },

  }  
    

  }


</script>

<style>
  .error-msg{
    color: red;
  }
</style>