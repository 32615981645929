<template>
    <Nav />
    
    <div class="login-box" style="min-height:100vh;">
    
        <div class="container">
    
            <div class="row">
    
                <div class="col-md-4"></div>
    
                <div class="col-md-4">
    
                    
                    <Otp :username="username" v-if="waitingForOTP"
                    @verify="verifyOTP"
                    @resend="handleResend"
                    @error="handleError">
                    </Otp>
                    <div class="card" v-else>
    
                        <p class="login-box-msg">ENTER YOUR NEW PASSWORD</p>
    
                        <p class="forgot-text">Enter your new password and click SUBMIT.</p>
    
                        <div class="card-body login-card-body">
    
                            <form @submit.prevent="verifyPassword">
    
                                <div class="form">
    
                                    <div class="form-item">
    
                                        <input type="text" id="password" autocomplete="off" required v-model="password">
    
                                        <label for="password">Password</label>
    
                                        <span class="input-group-text"><img src="../assets/img/password-icon.svg" alt=" " style="width:70%;"></span>
    
                                    </div>
    
                                </div>
    
                                <div class="row">
    
                                    <div class="col-12">
    
                                        <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
    
                                    </div>
    
                                </div>
    
                            </form>
    
                        </div>
    
                    </div>
    
                </div>
    
                <div class="col-md-4"></div>
    
            </div>
    
        </div>
    
    </div>
    <Footer />
</template>

<script lang="js">
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Otp from '@/components/Otp.vue'
import API from '../axios.config'
import utils from '../utils'
import { handleError } from 'vue'
export default {
    name: 'VerifyPassword',
    components: {
        Nav, Footer, Otp
    },
    data() {
        return {
            password: '',
            token: null,
            username: null,
            waitingForOTP: false,
        }
    },
    mounted() {
        this.username = localStorage.getItem("username")
    },
    methods: {
        async verifyPassword() {
            let token = this.$route.query.token;

            try {
                let response = await API.put(`/forget-password-verify/${token}`, {
                        password: this.password
                    },
                )
                
                if (response.status == 200) {
                    this.password = '';
                    
                    /*this.$toast.success("OTP sent", {
                        // override the global option
                        position: 'top'
                    })
                    this.waitingForOTP = true*/
                    this.$toast.success("Password reset successfully", {
                        position: 'top'
                    })
                    this.$router.push({ name: 'Login' })
                }
            }
            catch(error) {
                handleError(error)
            }
        },

        verifyOTP(data) {
            localStorage.setItem("otp_verify", "YES");
            localStorage.setItem("user_id", data.userId);

            let roles = JSON.stringify(data.roles);
            //console.log("OTP roles: " + roles);
            localStorage.setItem("roles", roles);
            
            if (roles.includes("driver")) {
                localStorage.setItem("driver_id", data.driver._id);
            } else if (roles.includes("customer")) {
                localStorage.setItem("customer_id", data.customer._id);
            }

            clearInterval(this.interval);

            this.$toast.success("Password changed successfully", {
                // override the global option
                position: 'top'
            })

            this.$toast.success("Signing you in, please wait...", {
                position: 'top',
                type: 'info'
            })

            this.waitingForOTP = false

            this.$router.push({ name: 'Dashboard' })
        },

        handleError(error) {
            this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
        },

        handleResend() {
            this.$toast.open({
                    message: "Sending new OTP",
                    type: 'info',
                    position: 'top'
                });

            this.waitingForOTP = true
        }
    }
}
</script>