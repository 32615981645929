<template>
        <div class="content-header">

            <div class="container-fluid">

                <Nav :hideUserButtons="true"/>

                <Otp :username="email"
                    :phoneNo="cleanPhoneNo"
                    :countryCode="countryCode"
                    :signUp="true"
                    v-if="waitingForOTP"
                    @verify="verifyOTP"
                    @resend="handleOtpResend"
                    @error="handleError">
                </Otp>

                <form @submit.prevent="verifyPhone" enctype="multipart/form-data" v-if="!waitingForOTP">

                    <div class="row">

                        <h2>New Driver Registration</h2>

                        <div class="col-md-12">

                            <div class="Customer-edit-form">

                                <p v-if="preview">

                                    <img :src="preview" class="img-fluid driver-pic">

                                </p>

                                <p v-else>

                                    <img src="../assets/img/image.svg" alt="">

                                </p>

                                <div class="image-upload">

                                    <label for="file-input">

                                      <img src="../assets/img/edit-icon.svg" class="logo-upload-btn"/>

                                    </label>

                                    <input id="file-input" type="file" :name="uploads" class="input-file" @change="photoUpload" />

                                    <input type="hidden" v-model="upload_id">

                                </div>

                            </div>

                        </div>

                        <div class="col-md-12 mt-4">

                            <div class="form-row">

                                <div class="col-md-12 mb-4">Login Info</div>

                                <div class="form-item col-md-6">

                                    <input type="text" id="name" v-model="username" autocomplete="off" required>

                                    <label for="name" style="background: #f4f6f9;">New Username</label>

                                </div>

                                <div class="form-item col-md-6">

                                    <input type="text" id="password" v-model="password" autocomplete="off" required>

                                    <label for="password" style="background: #f4f6f9;">Set Password</label>

                                </div>

                                <div class="form-item col-md-1" hidden>

                                    <input type="text" id="country" v-model="countryCode" autocomplete="off" required>

                                    <label for="country" style="background: #f4f6f9;">Phone Country Code</label>

                                </div>

                                <div class="form-item col-md-3">

                                    <input required
                                        type="tel"
                                        id="phone"
                                        placeholder="123-456-7890"
                                        pattern="([0-9]-)?[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        autocomplete="off"
                                        maxlength="15"
                                        minlength="10"
                                        v-model="work_cell_number"
                                        >

                                    <label for="phone" style="background: #f4f6f9;">Login Phone Number</label>

                                </div>

                                <div class="col-md-12 mb-4">Contact Info</div>

                                <div class="form-item col-md-4">

                                    <input type="text" id="first-name" v-model="first_name" autocomplete="off" required>

                                    <label for="first-name" style="background: #f4f6f9;">First Name</label>

                                </div>

                                <div class="form-item col-md-4">

                                    <input type="text" id="last-name" v-model="last_name" autocomplete="off" required>

                                    <label for="last-name" style="background: #f4f6f9;">Last name</label>

                                </div>

                                <div class="form-item col-md-4">

                                    <input type="date" id="birthday" v-model="date_of_birth" autocomplete="off" required>

                                    <label for="birthday" style="background: #f4f6f9;">Date of Birth</label>

                                </div>

                                <div class="form-item col-md-4">

                                    <input type="license" id="name" v-model="current_license_number" autocomplete="off" required>

                                    <label for="license" style="background: #f4f6f9;">License #</label>

                                </div>

                                <div class="form-item col-md-4 autocomplete">

                                    <input type="text" id="address" v-model="home_address" required v-on:keyup="customerAddress" autocomplete="off">

                                    <label for="address" style="background: #f4f6f9;">Home Address</label>

                                    <ul v-show="isOpen" class="autocomplete-results">

                                        <li v-for="(result, i) in results" :key="i" @click="setResult(`${result.description}`, `${result.geolocation.lat}`,`${result.geolocation.lng}`)" class="autocomplete-result">{{result.description}}</li>

                                    </ul>

                                </div>

                                <div class="form-item col-md-4">

                                    <select id="driver-type" v-model="driverType" class="form-select" required>

                                      <option value="STAT">STAT </option>

                                      <option value="REGULAR"> REGULAR</option>

                                    </select>

                                    <label for="driver-type" style="background: #f4f6f9;">Driver Type</label>

                                </div>


                                <div class="col-md-12 mt-4 department">

                                    <h6 style="text-align: left">Bank Info</h6>

                                </div>

                                <div class="form-item col-md-4 mt-4">

                                    <input type="text" id="bank-name" autocomplete="off" required v-model="bank_name">

                                    <label for="bank-name" style="background: #f4f6f9;">Name</label>

                                </div>

                                <div class="form-item col-md-4 mt-4">

                                    <input type="text" id="routing-number" autocomplete="off" required v-model="routing_number">

                                    <label for="routing-number" style="background: #f4f6f9;">Route #</label>

                                </div>

                                <div class="form-item col-md-4 mt-4">

                                    <input type="text" id="account-number" autocomplete="off" required v-model="account_number">

                                    <label for="account-number" style="background: #f4f6f9;">Account #</label>

                                </div>

                                <div class="form-item col-md-8">

                                    <input type="text" id="iban-number" autocomplete="off" required v-model="iban_number">

                                    <label for="iban-number" style="background: #f4f6f9;">Iban Number</label>

                                </div>

                                <div class="form-item col-md-4"></div>

                                <div class="col-md-12 mt-2 mb-4 department">

                                    <h6 style="text-align: left;">Upload Certificates</h6>

                                </div>

                                <div class="col-md-12">

                                  <input id="file-input" type="file" multiple :name="uploads" class="input-file" @change="certificateUpload" />

                                  <input type="hidden" v-model="upload_id">

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="row mt-2 mb-4">

                    <div class="col-md-12">

                        <div class="drivers-btn">

                            <!-- <button type="button" class="btn btn-block btn-outline-secondary" style="padding: 0;color: #000000;border-color: #7c7c7c03;">Back</button> -->

                            <button type="submit" class="btn btn-block btn-info">COMPLETE REGISTRATION</button>

                        </div>

                    </div>

                </div>

                </form>

            </div>

        </div>
</template>
<script>
import API from '../axios.config';
import eznavigator from '../eznavigator';
import Nav from '@/components/Nav';
import Otp from '@/components/Otp.vue';
import utils from '../utils'

export default {
    name: 'DriverRegistration',
    components: {
        Nav, Otp
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            current_license_number: '',
            work_cell_number: '',
            cleanPhoneNo: '',
            home_address: '',
            address: '',
            bank_name: '',
            account_number: '',
            routing_number: '',
            uploads: [],
            upload_img: "",
            photos: '',
            email: '',
            username: '',
            password: '',
            attachments: '',
            signatures: '',
            show: false,
            driverType: 'REGULAR', // Default: REGULAR
            account_title: '',
            iban_number: '',
            countryCode: 'US', // Default: US country code for phone number
            is_deleted: false,
            commission: 1, // in %
            addressLocation: {
                Latitude: '',
                Longitude: '',
            },
            upload_id: '',
            preview: null,
            roles: '',
            profileImage: '',
            results: [],
            isOpen: [],
            customerDepartmentAddress: [],
            problemWithAddressLookupFlag: false,
            waitingForOTP: false,
            phoneVerified: false
        }
    },
    mounted() {

    },

    watch: {
        work_cell_number(val) {
            this.work_cell_number = utils.cleanPhoneNumberInput(val)
        },
        phoneVerified(newVal) {
            if (newVal) {
                this.userUpdate()
            }
        }
    },

    methods: {
        setResult(event, lat, lng) {
            this.home_address = event;
            this.isOpen = false;
            this.addressLocation.Longitude = lng;
            this.addressLocation.Latitude = lat;
        },
        async customerAddress() {
            const vm = this;
            vm.isOpen = false;

            let home_address = vm.home_address;

            // zero length home address makes the endpoint error
            if(home_address.length == 0) return;

            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }

            let geolocation = await eznavigator.getLatLong();

            await API.post('/customer-address-autocomplete', {
                    address: home_address,
                    location: geolocation
                }, {
                    headers: header
                })
                .then(function(response) {
                    if (response.status == 200) {
                        vm.results = response.data.data.google_results;
                        vm.isOpen = true;
                    }
                })
                .catch(function(_) {
                    if(!vm.problemWithAddressLookupFlag) {
                        vm.$toast.open({
                            message: 'There was a problem fetching nearby addresses. Please type your home address manually.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.problemWithAddressLookupFlag = true;
                    }
                });

        },
        async driverUpdate() {
            const vm = this;
            let loginToken = localStorage.getItem("loginToken");
            let driver_id = localStorage.getItem("driver_id");
            let dobs = vm.date_of_birth;
            let dob = dobs.replace("/", "-")

            let header = {
                'Authorization': `Bearer ${loginToken}`,
            }

            let formData = new FormData();

            if (vm.photos != undefined) {
                formData.append("photos", vm.photos);
            }
            if (vm.attachments != undefined) {

                formData.append("attachments", vm.attachments);
            }

            formData.append("date_of_birth", dob);
            formData.append("current_license_number", vm.current_license_number);
            formData.append("bank_name", vm.bank_name);
            formData.append("account_title", vm.bank_name);
            formData.append("account_number", vm.account_number);
            formData.append("routing_number", vm.routing_number);
            formData.append("driverType", vm.driverType);
            formData.append("commission", vm.commission);
            formData.append("iban_number", vm.iban_number);
            formData.append("home_address", vm.home_address);
            formData.append("addressLocation.Latitude", vm.addressLocation.Latitude);
            formData.append("addressLocation.Longitude", vm.addressLocation.Longitude);

            await API.put(`/driver/${driver_id}`,
                    formData,
                    {
                        headers: header
                    },
                )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.$toast.success('Profile Added successfully.', {
                            // override the global option
                            position: 'top'
                        })
                        vm.$router.push(`/dashboard`);
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });

        },
        async userUpdate() {
            const vm = this;
            let loginToken = localStorage.getItem("loginToken");
            let user_id = localStorage.getItem("user_id");

            let header = {
                'Authorization': `Bearer ${loginToken}`,
            }
            await API.put(`/users/${user_id}`, {
                        first_name: vm.first_name,
                        last_name: vm.last_name,
                        username: vm.username,
                        password: vm.password,
                        email: vm.email,
                        countryCode: vm.countryCode,
                        phoneNo: utils.cleanPhoneNumberForAPI(vm.work_cell_number),
                    },
                    {
                        headers: header
                    },
                )
                .then(function(response) {
                    if (response.status == 200) {
                        vm.driverUpdate();
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });

        },

        async verifyPhone() {
            const vm = this;
            vm.phoneVerified = false
            vm.email = localStorage.getItem("username");
            let loginToken = localStorage.getItem("loginToken");
            let header = {
                'Authorization': `Bearer ${loginToken}`,
            }

            vm.cleanPhoneNo = utils.cleanPhoneNumberForAPI(vm.work_cell_number)

            await API.post('/send-twilio-ott-code', {
                    phoneNo: vm.cleanPhoneNo,
                    countryCode: vm.countryCode,
                },
                {
                    headers: header
                },
            )
            .then(function(response) {
                if (response.status == 200) {
                    vm.waitingForOTP = true
                }
            })
            .catch(function(error) {
                vm.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
            });
        },

        async photoUpload(event) {
            const vm = this;
            vm.photos = event.target.files[0];
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                }
                this.image = input.files[0];
                reader.readAsDataURL(input.files[0]);
            }
        },

        async certificateUpload(event) {
            const vm = this;
            vm.attachments = event.target.files[0];
        },

        verifyOTP() {
            this.$toast.success("Phone Verified Successfully", {
                position: 'top'
            })

            this.waitingForOTP = false
            this.phoneVerified = true
        },

        handleError(error) {
            this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });
        },

        handleOtpResend() {
            this.$toast.open({
                    message: "Sending new OTP",
                    type: 'info',
                    position: 'top'
                });

            this.waitingForOTP = true
        }
    }
}
</script>

<style scoped>
.error-msg {
    color: red;
}

.image-upload>input {
    display: none;
}

.image-upload img {
    width: 80px;
    cursor: pointer;
}

.driver-pic {
    width: 150px;
    border-radius: 50%;
    border: solid 1px rgba(150, 150, 150, 0.4);
}

.logo-upload-btn {
    transition: all 0.2s ease;
    max-width: 35px;
    position: relative;
    top: -40px;
    left: 40px;
    border-radius: 100%;
}

.logo-upload-btn:hover {
    top:-45px;
    max-width: 35px;
    box-shadow: 2px 2px 5px 10px rgba(144, 214, 255, 0.6);
}

.autocomplete {
  position: relative;
  display: inline-block;
}

.autocomplete-result {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-result:hover {
  background-color: #e9e9e9;
}

.autocomplete-results {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  list-style-type: none;
  padding-left: 0px;
  margin-left: 15px;
}

h2 {
    margin: auto;
    text-align: center;
    padding: 1%;
}
</style>
