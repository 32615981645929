<template>
	<div v-if="problem != null">
		<h1>{{ problem }}</h1>
	</div>
	<GMapMap v-else ref="gmap" :center="center" :zoom="zoom" map-type-id="terrain" style="width: 100vh; height: 100vh;">
		<GMapMarker v-for="(m, index) in markers" :key="index" :title="m.address" :position="m.position" :clickable="true"
			:draggable="false" />
		<GMapPolyline v-for="(p, index) in paths" :key="index" :path="p.route" :editable="false"
			:options="{ strokeColor: p.color, icons: p.icons }" />
	</GMapMap>
</template>

<script>
import utils from '../utils'

export default {
	name: 'GMapRoutePreview',
	components: {
	},
	computed: {

	},
	data() {
		return {
			markers: [],
			paths: [],
			center: { lat: 0, lng: 0 },
			problem: null,
			zoom: 8,
		}
	},
	mounted() {
		window.addEventListener('load', this.loadAndDisplay)
	},
	methods: {
		loadAndDisplay() {
			//console.log("=== window.google ===")
			//console.log(window.google)
			const stops = JSON.parse(decodeURIComponent(this.$route.query.stops))
			const colors = JSON.parse(decodeURIComponent(this.$route.query.colors))

			if (stops.length != colors.length) {
				console.warn("length of stops[] and colors[] must be the same")
				return;
			}

			let latBounds = null
			let lonBounds = null
			for (let i = 0; i < stops.length; i++) {
				const s_i = stops[i]

				const lat = parseFloat(s_i.position.lat)
				const long = parseFloat(s_i.position.lng)

				if (isNaN(lat) || isNaN(long) || (lat == 0 && long == 0)) {
					this.markers = []
					this.paths = []
					this.problem = `There was a problem with address for Task #${i + 1}`
					return
				}

				this.markers.push({ address: s_i.address, position: { lat: lat, lng: long } })

				if (!latBounds) {
					latBounds = {
						min: lat, max: lat
					}
				} else {
					latBounds.min = Math.min(latBounds.min, lat);
					latBounds.max = Math.max(latBounds.max, lat);
				}

				if (!lonBounds) {
					lonBounds = {
						min: long, max: long
					}
				} else {
					lonBounds.min = Math.min(lonBounds.min, long);
					lonBounds.max = Math.max(lonBounds.max, long);
				}

				// https://developers.google.com/maps/documentation/javascript/reference/marker#SymbolPath.FORWARD_CLOSED_ARROW
				const icons = [{ icon: { path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 4, fillOpacity: 1.0 } }]
				const vm = this;
				const c_i = colors[i]
				let callback = function (route) {
					vm.paths.push({
						route: route,
						color: c_i,
						icons: icons
					})
				}

				// create a path from each pair of points
				if (this.markers.length > 1) {
					const destLatLon = this.markers[this.markers.length - 2].position
					const startLatLon = this.markers[this.markers.length - 1].position
					utils.googleRoads(window, startLatLon, destLatLon, callback)
				}
			} // end for-loop

			if (latBounds && lonBounds) {
				// re-calculate center of all drivers
				this.center.lat = (latBounds.min + latBounds.max) / 2.0;
				this.center.lng = (lonBounds.min + lonBounds.max) / 2.0;
			}
		}
	}
}
</script>

<style scoped>
.vue-map-container {
	height: 100vh;
}
</style>