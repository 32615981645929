<template>

    <div class="container-fluid">

        <div>

            <div class="row mt-2 mb-4">

                <div class="col-md-2">

                    <button
                    type="button" 
                    title="Go back to customer information"
                    class="btn btn-primary" 
                    @click.prevent="back()"
                    :disabled="busy"
                    v-show="role == 'customer' || role == 'admin'"
                    >
                    Back
                    </button>

                </div>

                <div class="col-md-1">

                </div>

                <div class="col-md-6" style="text-align:center;">

                    <h4 v-show="busy">Working</h4>

                </div>
                
                <div class="col-md-2">

                    <div>
                        <div v-show="showSaveReminder" class="tooltiptext">
                            Don't forget to save!
                        </div>
                        <SpinButtonVue
                        title="Process and save subscribers"
                        class="btn btn-block btn-info"
                        @click="emitSaveData"
                        :spin="busy">
                        Save
                        </SpinButtonVue>
                    </div>

                </div>

                <div class="col-md-1">

                    <button
                    type="button"
                    title="Add new subscriber"
                    class="btn btn-block btn-info" 
                    @click.prevent="emitNewSubscriberPrompt"
                    :disabled="busy">
                    +
                    </button>

                </div>

            </div>  

            <div class="row mt-2 mb-4">

                <div class="col-md-8" v-if="progressText.length > 0" >

                    <progress-bar
                    :bar-color="progressColor"
                    :val="progress" 
                    :max="maxProgress" 
                    :text="`(${progress}/${maxProgress}) ${progressText}`"
                    :opts="getFixOptions"
                    @cancel="progressText=''"
                    @fix="launchFixModal(errorId)"
                    />
                </div>

                <div class="col-md-8 search" v-else>

                    <input type="text" placeholder="Search..." v-model="filter"/>

                    <i class="fa fa-search"></i>

                </div>

            </div>

            <form @submit.prevent="updateSubscribers">

                <div>

                    <div class="form-group">

                        <hr>

                        <section class="scrolling-section">

                            <div v-for="(sub, k) in dynamicSubscribersList" :key="k" 
                            :style="getSubscriberStyle(sub)"
                            :title="getSubscriberTitle(sub)"
                            >
                                <span style="width:2%; display:inline-block; margin-right:1%;">{{k+1}}.</span>

                                <input :value="sub.email" style="width:20%; display:inline-block; margin-right:1%;" disabled>

                                <input type="tel" style="width:20%; display:inline-block; margin-right: 1%;" :value="formatPhoneNumber(sub.phoneNo)" disabled>

                                <div style="width: 30%; margin-right: 1%; display: inline-block;">
                                    <input type="checkbox" v-model="sub.requires2FA" :id="`check${k}`" disabled>
                                    <label :for="`check${k}`">&nbsp; Is 2FA required?</label>
                                </div>

                                <div style="text-align:right; width:24%; display: inline-block;">

                                    <button type="button" v-if="!isSubscriberRemoved(sub)"
                                    style="margin-right: 4%;"
                                    @click="emitEditSubscriberPrompt(sub)"
                                    :disabled="busy">
                                    EDIT
                                    </button>

                                    <button type="button" v-if="!isSubscriberRemoved(sub) && !isSubscriberChanged(sub)"
                                    @click="removeSubscriber(sub)"
                                    :disabled="busy">
                                    DROP
                                    </button>

                                    <button type="button" v-else
                                    @click="restoreSubscriber(sub)"
                                    :disabled="busy">
                                    UNDO
                                    </button>

                                </div>

                            </div>

                        </section>
                        
                        <div class="row mt-2 mb-4 entries-msg">

                            <div class="col-md-11">
                                <p v-if="filter.trim().length > 0">{{dynamicSubscribersList.length}} matches found</p>
                                <p v-else-if="dynamicSubscribersList.length == 0" class="error-msg">You have no subscribers</p>
                                <p v-else>{{getStagedChangesLabel}}</p>
                            </div>
                            
                            <div class="col-md-1" v-show="dynamicSubscribersList.length > 0">
                                <a v-if="!allAreDropped" href="" style="text-align:right" @click.prevent="dropAll" title="Mark all subscribers for removal">Drop All</a>
                                <a v-else href="" style="text-align:right" @click.prevent="undoDropAll" title="Restores all subscribers">Undo All</a>
                            </div>
                        </div>
                    </div>

                </div>

            </form>

        </div>

    </div>

</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue'
import SpinButtonVue from '@/components/SpinButton.vue';

export default {
    name: 'SubscribersForm',
    components: {
        ProgressBar,
        SpinButtonVue
    },
    props: {
        subscribers: {
            type: Array,     
            default() {
                return []
            }
        },
        role: {
            type: String,
            default: ""
        },
        progressText: {
            type: String,
            default: "",
        },
        progress: {
            type: Number,
            default: 0
        },
        maxProgress: {
            type: Number,
            default: 100
        },
    },
    data() {
        return { 
            dummyPointId: 0,
            errorId: null, // quick link to the entry with the last error
            newSubscribers: [],
            updateSubscribers: [],
            removeSubscribers: [],
            filter: "",
            canGoBack: true,
            showSaveReminder: false,
            busy: false,
        };
    },
    computed: {
        progressColor() {
            // This specific state is reserved for when there is an error 
            // and our message is in the text
            if(this.busy == false && this.progressText.length > 0) {
                return "#ff000f" ;
            }

            return "#00ff0f";
        },

        dynamicSubscribersList() {
            let untouched = [...this.subscribers];
            untouched = untouched.filter(p => !this.findById(p, this.updateSubscribers));
            untouched = untouched.filter(p => !this.findById(p, this.removeSubscribers));
            let list = [...untouched, ...this.updateSubscribers, ...this.newSubscribers, ...this.removeSubscribers];

            if(this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim()+".*", 'i');

                // 1. MATCH EMAIL
                let email_matches = list.filter((p) => regex.test(p.email));

                // 2. MATCH PHONE NUMBER
                let phone_matches = list.filter((p) => regex.test(p.phoneNo));

                // Return unique entries only!
                let filtered_list = email_matches.concat(phone_matches.filter((p) => !this.findById(p, email_matches)));
                return filtered_list
            }

            return list;
        },

        allAreDropped() {
            let len = this.dynamicSubscribersList.length;
            return len == this.removeSubscribers.length && len > 0;
        },
        
        getStagedChangesLabel() {
            let arr = [];
            let update = this.updateSubscribers.length;
            let removed = this.removeSubscribers.length;
            let newest = this.newSubscribers.length;
            let untouched = this.subscribers.length;

            if(untouched > 0) {
                let entry = untouched == 1? ' entry' : ' entries';
                arr.push('' + untouched + entry);
            }

            if(newest > 0) {
                arr.push('' + newest + ' to add');
            }

            if(update > 0) {
                arr.push('' + update + ' to update');
            }
            
            if(removed > 0) {
                arr.push('' + removed + ' to delete');
            }

            return arr.join(', ');
        },

        getFixOptions() {
            if(this.errorId) {
                return [{text: 'Manual Fix', on: 'fix', title: 'Use the autocomplete feature to find a better address'}, 'Cancel'];
            }

            return [];
        }
    },
    methods: {
        getSubscriberStyle(sub) {
            let style = {
                "background-color": "#eaeaea",
                "padding":"1%",
                "margin":"1%",
            };

            if(this.findById(sub, this.newSubscribers)) {
                style["background-color"] = "#dbdbfb";
            } else if(this.findById(sub, this.removeSubscribers)) {
                style["background-color"] = "#fbdbdb";
            } else if(this.findById(sub, this.updateSubscribers)) {
                style["background-color"] = "#dbfbdf";
            }

            return style;
        },

        getSubscriberTitle(sub) {
            if(this.findById(sub, this.newSubscribers)) {
                return "You are adding a new subscriber";
            } else if(this.findById(sub, this.removeSubscribers)) {
                return "You are removing this subscriber";
            } else if(this.findById(sub, this.updateSubscribers)) {
                return "You are updating this subscriber";
            }

            return "This subscriber is unchanged";
        },

        isSubscriberRemoved(sub) {
            return this.findById(sub, this.removeSubscribers);
        },

        isSubscriberChanged(sub) {
            return this.findById(sub, this.updateSubscribers);
        },

        allowFix(newList, errorId) {
            this.canGoBack = true;
            this.showSaveReminder = false;
            this.busy = false;
            this.errorId = errorId ?? null;

            if(newList) {
                this.updateSubscribers = this.updateSubscribers.filter(p => newList.filter(p2 => this.subscribersAreEqual(p, p2, true)).length == 0);
                this.newSubscribers = this.newSubscribers.filter(p => newList.filter(p2 => this.subscribersAreEqual(p, p2, true)).length == 0);
            }
        },

        clear() {
            this.newSubscribers = [];
            this.removeSubscribers = [];
            this.updateSubscribers = [];
            
            this.allowFix();
        },

        back() {
            if(this.busy) {
                return;
            }

            if(!this.canGoBack) {
                // reset all
                this.canGoBack = true;
                this.showSaveReminder = true;
                this.progress = 0;
                this.maxProgress = 0;
                this.progressText = '';
                return;
            }

            this.clear(); 
            this.$emit('back');
        },

        formatPhoneNumber (str) {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');
            
            //Check if the input is of correct
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            
            if (match) {
                //Remove the matched extension code
                //Change this to format for any country code.
                let intlCode = (match[1] ? '+1 ' : '')
                return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            }
            
            return null;
        },

        add(sub) {
            sub.phoneNo = sub.phoneNo.replace(/\D/g,'');
            sub._id = this.nextDummyId();
            this.canGoBack = this.showSaveReminder = false;
            this.newSubscribers.unshift(sub);
        },

        edit(sub) {
            sub.phoneNo = sub.phoneNo.replace(/\D/g,'');
            this.canGoBack = this.showSaveReminder = false;

            if(!this.subscriberIsLocal(sub)) {
                let result = this.findById(sub, this.updateSubscribers);

                if(result) {
                    this.copySubscriber(result, sub);
                } else {
                    this.updateSubscribers.push(sub);
                }

                return;
            }

            // else point is local and not saved in DB
            
            let result = this.findById(sub, this.newSubscribers);

            if(result) {
                this.copySubscriber(result, sub);
            }
        },

        nextDummyId() {
            return "DUMMY-" + this.dummyPointId++;
        },

        findById(sub, arr) {
            return arr.filter(p => p._id == sub._id).shift();
        },

        subscriberIsLocal(sub) {
            return sub._id.startsWith("DUMMY");
        },

        subscribersAreEqual(p1, p2, skipId) {
            return (skipId? true : p1._id == p2._id)
            && p1.email == p2.email
        },

        copySubscriber(pDest, pSrc) {
            pDest._id = pSrc._id;
            pDest.email = pSrc.email;
            pDest.phoneNo = pSrc.phoneNo;
            pDest.countryCode = pSrc.countryCode;
            pDest.requires2FA = pSrc.requires2FA;
        },

        removeSubscriber(sub) {
            this.canGoBack = this.showSaveReminder = false;
            let result = this.findById(sub, this.removeSubscribers);

            if(!result) {
                this.removeSubscribers.push(sub);

                this.updateSubscribers = this.updateSubscribers.filter(p => p._id != sub._id);
                this.newSubscribers = this.newSubscribers.filter(p => p._id != sub._id);
            }

            return;
        },

        restoreSubscriber(sub) {
            this.canGoBack = this.showSaveReminder = false;

            this.updateSubscribers = this.updateSubscribers.filter(p => p._id != sub._id);
            this.removeSubscribers = this.removeSubscribers.filter(p => p._id != sub._id);

            if(this.subscriberIsLocal(sub)) {
                this.newSubscribers.push(sub);
            }
        },

        emitSaveData() {
            this.filter = '';

            let untouched = [...this.subscribers];
            untouched = untouched.filter(p => !this.findById(p, this.updateSubscribers));
            untouched = untouched.filter(p => !this.findById(p, this.removeSubscribers));

            this.$emit('save', {
                dropped: this.removeSubscribers,
                created: this.newSubscribers,
                updated: this.updateSubscribers,
                untouched: untouched
            });

            this.showSaveReminder = false;
            this.busy = true;
            this.errorId = null; // reset
        },

        emitNewSubscriberPrompt() {
            this.$emit('new');
        },

        emitEditSubscriberPrompt(sub) {
            if(!sub) return;
            this.$emit('edit', sub);
        },

        fullSubscriberFromId(id) {
            if(!id) return;

            const p = { _id: id };

            let newSubscriber = this.findById(p, this.newSubscribers);
            if(newSubscriber) {
                return newSubscriber;
            }

            let updateSubscriber = this.findById(p, this.updateSubscribers);
            if(updateSubscriber) {
                return updateSubscriber;
            }

            let removeSubscriber = this.findById(p, this.removeSubscribers);
            if(removeSubscriber) {
                return removeSubscriber;
            }

            return null;
        },

        dropAll() {
            let remove = [...this.dynamicSubscribersList];
            for(let i = 0; i < remove.length; i++) {
                this.removeSubscriber(remove[i]);
            }
        },

        undoDropAll() {
            let revert = [...this.removeSubscribers];
            for(let i = 0; i < revert.length; i++) {
                this.restoreSubscriber(revert[i]);
            }
        },

        launchFixModal(id) {
            this.emitEditSubscriberPrompt(this.fullSubscriberFromId(id));
        },
    }
}

</script>

<style scoped>
.error-msg {
    color: red;
    text-align: center;
}

.entries-msg {
    margin-top: 1%;
}
.scrolling-section {
    max-height: 570px; 
    overflow-y:scroll; 
    overflow-x:hidden;
    scrollbar-color: #4e4e4e #e4e4e4;
    scrollbar-width: thin;
}
.tooltiptext {
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 105%;

  /* Start the shake animation and make the animation last for 1s seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

div.search i {
    left: -30px;
    position: relative;
}

a {
    text-decoration: underline dotted;
}
</style>