<template>
    <div>
        <Topnav />

        <Sidebar />

        <div class="content-wrapper">

            <div class="content-header">

                <div class="container-fluid">

                    <!-- 'toolbar' row -->
                    <div class="row mt-2 mb-4 sticky-toolbar">

                        <div class="col-md-8 search">

                            <input type="text" placeholder="Search..." v-model="filter"/>

                            <i class="fa fa-search"></i>

                        </div>

                        <div class="col-md-2"></div>

                        <div class="col-md-2">

                            <div class="drivers-btn">

                                <button type="button" class="btn btn-block btn-info" data-toggle="modal" data-target="#myModal" @click="resetInviteForm">Invite Driver</button>

                            </div>

                        </div>

                    </div>

                    <!-- Pagination Top -->
                    <div class="row mt-12 mb-12" v-show="!busy">
                        <div class="col-md-4 right-align"></div>
                        <div class="col-md-4" style="display: inline-flex;">
                            <Pagination :show="mutDriverslist.length > 0" :page="pagination.curr" :total="pagination.total" @click="fetchDriverPage($event)"/>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                    <!-- /Pagination Top -->

                    <div class="row mt-2 mb-4 sticky-toolbar">
                        <Busy class="center-screen" :busy="busy" text="Loading..."/>
                    </div>

                    <div class="row mt-4" v-for="drivers in mutDriverslist" v-bind:key="drivers._id" v-bind:id="drivers._id">

                        <div class="col-lg-12 col-6">

                            <!-- small box -->
                            <div class="small-box" v-if="drivers.userId.is_registered == false">
                                <div class="row" style="padding: 1rem;">
                                    <div class="col-md-2">
                                        <img src="../assets/img/email-icon.svg" alt=" " style="width:30px; height:auto">
                                    </div>

                                    <div class="col-md-10">
                                    Pending Invite to &nbsp;<i>{{ drivers.userId.email }}</i>
                                    </div>
                                </div>
                            </div>

                            <div class="small-box" v-else>

                                <div class="row" style="padding: 1rem;">

                                    <div class="col-md-11"></div>

                                    <div class="col-md-1">

                                        <div class="btn-group dropleft">

                                            <a class="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                                <img src="../assets/img/select-edit-icon.svg" alt=" " style="width: 65%;">

                                            </a>

                                            <div class="dropdown-menu">

                                                <div class="jobs-edits">

                                                    <ul class="jobs-lists">

                                                        <router-link :to="`edit-driver-registration?id=${drivers._id}`" style="color: #000;">

                                                            <li class="jobs-lists-add action-menu-item">

                                                                Edit

                                                            </li>

                                                        </router-link>

                                                        <a class="del_driver" data-toggle="modal" :data-target="`#deactivateDriver${drivers._id}`" style="color: #000;">

                                                            <li class="jobs-lists-add action-menu-item">Deactivate</li>

                                                        </a>
                                                    </ul>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="col-md-2">

                                        <div class="driver-image" v-if="drivers.userId">

                                            <img v-if="drivers.userId.picture" :src="`${API.baseURL}${drivers.userId.picture}`" alt="" style="border-radius: 8px; width:150px; ">

                                            <img v-else src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">


                                        </div>

                                        <div class="driver-image" v-else>

                                            <img src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">

                                        </div>

                                    </div>

                                    <div class="col-md-3 drive-info">

                                        <div class="inner">

                                            <h5>{{ drivers.userId.first_name +" "+ drivers.userId.last_name }}</h5>

                                            <div class="driver-address" style="margin-bottom: 20px;">

                                                <img src="../assets/img/address-icon.svg" alt="" style="margin-bottom: 1rem;">

                                                <p style="margin-bottom: 0;margin-left: 2%;font-size: 10px;">{{ drivers.home_address}}</p>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="col-md-7 drive-info">

                                        <div class="row">

                                            <div class="col-md-3">

                                                <div class="inner drive-bios">

                                                    <p>Email</p>

                                                    <h6 style="font-size:10px; font-weight: 600;">{{ drivers.userId.email }}</h6>

                                                </div>

                                            </div>

                                            <div class="col-md-3">

                                                <div class="inner drive-bios">

                                                    <p>License #</p>

                                                    <h6 style="font-size:11px; font-weight: 600;">{{ drivers.current_license_number}}</h6>

                                                </div>

                                            </div>

                                            <div class="col-md-3">

                                                <div class="inner drive-bio">

                                                    <p>Work Cell</p>

                                                    <h6 style="font-size:11px; font-weight: 600;" v-if="drivers.userId!=null">{{ drivers.userId.phoneNo }}</h6>

                                                </div>

                                            </div>

                                            <div class="col-md-3">

                                                <div class="inner drive-bio">

                                                    <p>Commission (%)</p>

                                                    <h6 style="font-size:11px; font-weight: 600;">{{ drivers.commission}}</h6>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div class="col-md-2"></div>

                                    <div class="col-md-10">

                                        <div class="drvie-border"></div>

                                    </div>

                                    <div class="col-md-2"></div>

                                    <div class="col-md-10">

                                        <h6 class="driver-bank-info">Bank Information</h6>

                                        <div class="row">

                                            <div class="col-md-2">

                                                <div class="inner drive-bios">

                                                    <p>Name</p>

                                                    <h6 style="font-size:11px; font-weight: 600;">{{ drivers.bank_name }}</h6>

                                                </div>

                                            </div>

                                            <div class="col-md-2">

                                                <div class="inner drive-bio">

                                                    <p>Route #</p>

                                                    <h6 style="font-size:11px; font-weight: 600;">{{ drivers.routing_number }}</h6>

                                                </div>

                                            </div>

                                            <div class="col-md-2">

                                                <div class="inner drive-bio">

                                                    <p>Account #</p>

                                                    <h6 style="font-size:11px; font-weight: 600;">{{ drivers.account_number }}</h6>

                                                </div>

                                            </div>

                                            <div class="col-md-4">

                                                <div class="drivers-certificate">

                                                    <img src="../assets/img/Certifications.svg" alt="">

                                                    <h5 v-if="drivers.uploads">

                                                        <a v-if="drivers.uploads.attachmentUrls!=''" href="#myModal-" data-toggle="modal" :data-target="`#myModal-${drivers.uploads._id}`">Certifications</a>

                                                        <!-- certificate modal -->

                                                        <div class="modal" :id="`myModal-${drivers.uploads._id}`">

                                                            <div class="modal-dialog" style="max-width: 500px;">

                                                                <div class="modal-content">

                                                                    <div class="modal-header">

                                                                        <div class="modal-heading">

                                                                            <h4 class="modal-title" style="font-size: 22px;font-weight: 500;">Certifications</h4>

                                                                        </div>

                                                                        <button type="button" class="close" data-dismiss="modal">&times;</button>

                                                                    </div>

                                                                    <div class="modal-body ">

                                                                        <div class="signature-1 mt-4 mb-4" v-for="attachments in drivers.uploads.attachmentUrls" v-bind:key="attachments">

                                                                            <a class="new-driver-registration-link">{{attachments}}</a>

                                                                            <a target="_blank" :href="`${API.baseURL}${attachments}`" class="btn btn-block btn-outline-secondary" style="color: #7c7c7c;border-color: #979797;font-size: 10px;font-weight: 500;text-transform: capitalize;padding: 10px 0px 10px 1px;width: 20%;margin-left: 2%;margin-right: 2%;"

                                                                                download>Download</a>

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                        <!-- End  -->

                                                        <a v-if="drivers.uploads.attachmentUrls==''">Certifications</a>

                                                    </h5>

                                                    <h5 v-else>

                                                        <a>Certifications</a>

                                                    </h5>

                                                    <span v-if="drivers.uploads" class="Certifications-number">

                                                        <p v-if="drivers.uploads.attachmentUrls!=''"> {{drivers.uploads.attachmentUrls.length}}</p>

                                                        <p v-else> {{drivers.uploads.attachmentUrls.length}}</p>

                                                    </span>

                                                    <span v-else class="Certifications-number">0</span>

                                                </div>

                                            </div>

                                            <div class="col-md-2">

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <!-- Deactivate Modal -->

                        <div class="modal" :id="`deactivateDriver${drivers._id}`">

                            <div class="modal-dialog">

                                <div class="modal-content">

                                    <!-- Modal Header -->

                                    <div class="modal-header">

                                        <h4 class="modal-title" style="font-size: 20px;">Deactivate Driver</h4>

                                        <button type="button" class="close mt-3" data-dismiss="modal">&times;</button>

                                    </div>

                                    <!-- Modal body -->

                                    <div class="modal-body pl-3">

                                        Are you sure you want to deactivate this driver?

                                    </div>

                                    <!-- Modal footer -->

                                    <div class="modal-footer">

                                        <button type="button" class="btn btn-success mr-3" :id="`${drivers._id}`" v-on:click='deactivateDriver(drivers._id)'>Yes</button>

                                        <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <!-- End Delete Modal -->

                    </div>

                    <!-- Pagination Bottom -->
                    <div class="row mt-12 mb-12" v-show="!busy">
                        <div class="col-md-4 right-align"></div>
                        <div class="col-md-4" style="display: inline-flex;">
                            <Pagination :show="mutDriverslist.length > 0" :page="pagination.curr" :total="pagination.total" @click="fetchDriverPage($event)"/>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                    <!-- /Pagination Bottom -->

                </div>

            </div>

        </div>

        <!-- TODO: make this a reusable component... 6/2/2022 -->

        <div class="modal" id="myModal">

            <div class="modal-dialog" style="max-width: 450px;">

                <div class="modal-content">

                    <!-- Modal Header -->

                    <div class="modal-header" style="padding-left: 2rem; padding-right: 2rem;">

                        <h4 class="modal-title" style="font-size: 24px; font-weight: 600;">Invite Driver</h4>

                        <button type="button" class="close" data-dismiss="modal" style="margin-top: 2px; margin-left: 10px;">×</button>

                    </div>

                    <!-- Modal body -->

                    <form @submit.prevent="inviteDriver">

                        <div class="modal-body">

                            <div class="form-group" style="padding-left: 2rem; padding-right: 2rem;">

                                <input type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                v-model="email"
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                style="padding: 3%; height: calc(3rem + 0px);"
                                autocomplete="off"
                                required>

                                <span>
                                    <small>
                                        <input type="checkbox" v-model="dontSendEmail" class="custom-switch"/>&nbsp;Check this box to prevent email notification.
                                    </small>
                                </span>

                            </div>

                            <p class="error-msg"> {{error}} </p>

                        </div>

                        <!-- Modal footer -->

                        <div class="modal-footer" style="margin-top: -4%;margin-bottom: 6%;padding-left: 2rem;padding-right: 2rem;">

                            <button
                            type="submit"
                            class="btn btn-block btn-info"
                            style="width: 100%; padding: 2%; font-size: 16px;"
                            :disabled="show && url">{{(show && url)? "Invite Sent!" : "Send"}}
                            </button>

                        </div>

                    </form>

                    <div class="" v-show="show">

                        <div class="modal-body" style="margin-top: -7%;padding-left: 2rem; padding-right: 2rem;">

                            <form>

                                <div class="form-group">

                                    <label for="magic-link" style="color: #000; font-size: 13px;">Web Magic Link</label>

                                    <input disabled type="text" v-model="url" id="magic-link" class="form-control" aria-describedby="emailHelp" placeholder="">

                                    <button @click.prevent="copylink(this.url)"
                                    type="submit"
                                    class="btn btn-primary btn-block contact-submit"
                                    style="width: 100%;padding: 2%;font-size: 12px;">
                                        <img src="@/assets/img/copy_document_paper_file_multimedia_icon.svg"
                                        alt=""
                                        style="margin-right: 2%;width: 5%;">

                                        Copy to Clipboard

                                    </button>

                                    <br/>

                                    <label for="magic-link" style="color: #000; font-size: 13px;">Mobile App Magic Link</label>

                                    <input disabled type="text" v-model="urlMobile" id="magic-link" class="form-control" aria-describedby="emailHelp" placeholder="">

                                    <button @click.prevent="copylink(this.urlMobile)"
                                    type="submit"
                                    class="btn btn-primary btn-block contact-submit"
                                    style="width: 100%;padding: 2%;font-size: 12px;">
                                        <img src="@/assets/img/copy_document_paper_file_multimedia_icon.svg"
                                        alt=""
                                        style="margin-right: 2%;width: 5%;">

                                        Copy to Clipboard

                                    </button>
                                </div>

                            </form>

                        </div>

                        <!-- Modal footer -->

                        <div class="modal-footer" style="margin-top: -4%;margin-bottom: 6%; padding-left: 2rem; padding-right: 2rem;">

                            <!-- nothing for now -->

                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination.vue'
import Busy from '@/components/Busy.vue'
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
import utils from '../utils';
import "@/assets/plugins/bootstrap/js/bootstrap.bundle.min.js"

export default {
    name: 'DriversList',
    data() {
        return {
            Driverslist: [],
            pagination: {
                curr: 1,
                total: 1,
                limit: 10,
            },
            email: '',
            show: false, // used to hide/show the invite modal
            url: '',
            urlMobile: '',
            error: '',
            dontSendEmail: false,
            filter: '',
            API: API,
            busy: true,
            utils: utils
        }
    },
    components: {
        Topnav,
        Sidebar,
        Busy,
        Pagination
    },
    computed: {
        mutDriverslist() {
            return this.Driverslist.filter(this.filterDriverData)
        }
    },
    async mounted() {
        await this.getNextDrivers()
    },
    methods: {
        resetInviteForm() {
            this.url = null;
            this.urlMobile = null;
            this.show = false;
            this.email = '';
        },

        fetchDriverPage(page) {
            this.pagination.curr = page
            this.getNextDrivers()
        },

        async getNextDrivers() {
            this.Driverslist = [] // clear

            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }

            this.loginToken = localStorage.getItem("user-info");

            this.busy = true

            try {
                const path = utils.pagination('/driver', this.pagination.curr, this.pagination.limit)
                let response = await API.get(path, {
                    headers: {
                        Authorization: `Bearer ${this.loginToken}`
                    }
                })

                if (response.status == 200) {
                    this.Driverslist = [...response.data.data]
                    this.pagination.total = response.data.pages

                    this.Driverslist.sort((a, b) => b.userId.is_registered - a.userId.is_registered)
                }

                this.busy = false
            }
            catch(error) {
                this.$toast.open({
                    message: utils.messageFromError(error),
                    type: 'error',
                    position: 'top'
                });

                this.busy = false
            }
        },

        async inviteDriver() {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
                'content-type': 'application/json',
            }
            await API.post('/MagicLink', {
                    recipientEmail: vm.email,
                    magicLinkType: 'driver',
                    Latitude: "30", // feed the API something for now
                    Longitude: "-78", // ^
                    sendEmailNotification: !vm.dontSendEmail
                }, {
                    headers: header
                })
                .then(function(response) {
                    //console.log(response);
                    if (response.status == 200) {
                        vm.show = true;
                        vm.generateMagicLink(response.data.data.magicToken);
                    }
                })

                .catch(function(error) {
                    vm.$toast.open({
                        message: error.response.data.error,
                        type: 'error',
                        position: 'top'
                    });
                });
        },
        generateMagicLink(magicToken) {
            this.url = `${window.origin}/#/magic-link?token=${magicToken}`
            this.urlMobile = `wit://app.witcourier.com/${magicToken}`
        },

        async copylink(url) {
            if(await utils.copyToClipboard(url)) {
                this.$toast.open({
                    message: 'Login link copied to clipboard!',
                    type: 'success',
                    position: 'bottom-left'
                });
            } else {
                this.$toast.open({
                    message: 'Browser could not copy to clipboard.',
                    type: 'info',
                    position: 'bottom-left'
                });
            }
        },

        async deactivateDriver(driver_id) {
            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");
            API.delete(`/driver/${driver_id}`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    },
                })
                .then(function(response) {
                    // console.log(response.status);
                    // console.log(response.data.data);
                    if (response.status == 200) {
                        vm.$toast.success('Driver deactivated successfully.', {
                            // override the global option
                            position: 'top'
                        })
                        location.reload();
                    }
                })
                .catch(function(error) {
                    // console.warn(error.response.data.status);
                    // handle error here
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();

                    } else {
                        vm.$toast.open({
                            message: error.response.data.error,
                            type: 'error',
                            position: 'top'
                        });

                    }
                });

        },

        filterDriverData(data) {
            if(this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim()+".*", 'i');
                for(const [key, value] of Object.entries(data)) {
                    if(key == "userId") { /* special case, filter user data too */
                        if(this.filterDriverData(value)) { return true; }
                        continue;
                    }

                    if(regex.test(value)) {
                        return true;
                    }
                }

                return false;
            }

            return true;
        }
    }
}
</script>

<style>
.action-menu-item {
    color: #000;
}
.action-menu-item:hover {
    background-color: rgba(202, 225, 255, 0.81);
}
.error-msg {
    color: red;
}

.dropleft:hover {
    cursor:pointer;
}

.sticky-toolbar {
    width: 100%;
    position: sticky;
    top: 10px;
    z-index: 1000;
}

div.search i {
    left: -30px;
    position: relative;
}
</style>
