<template>
    <Topnav />

    <Sidebar />

    <DynamicTable ref="selectCell" v-if="selectedCellTableData.data.length > 0"
                class="mouse-follow"
                :style="{ left: clickedMouse.x + 'px', top: clickedMouse.y + 'px' }"
                :header="selectedCellTableData.header"
                :data="selectedCellTableData.data"
                />

    <div class="content-wrapper" @mousemove="onMouseMove">

        <div class="content-header">

            <div class="container-fluid">

                <div class="row mt-4">
                    <div class="col-lg-2 col-2">
                        <label style="background: #f4f6f9; margin-right: 2%;">Select Table From Database:</label>

                        <select id="tableSelect" @change.prevent="selectTable" :disabled="isEditting || tableData?.busy">
                            <option v-show="!tableData.preventDefault">Select</option>
                            <option v-for="(table, index) in tables" :key="table" :value="index">{{table}}</option>
                        </select>
                    </div>

                    <div class="col-lg-10 col-10">
                        <progress-bar v-show="tableData.maxProgress > 0"
                        :bar-color="progressColor"
                        :val="tableData.progress"
                        :max="tableData.maxProgress"
                        :text="`(${tableData.progress}/${tableData.maxProgress}) ${progressText}`"
                        :opts="getFixOptions"
                        @cancel="clearProgressBar()"
                        @retry="clearProgressBar(); stageEdit()"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="form-item col-md-3">
                        <SpinButton v-show="!isEditting"
                        class="button btn-warning"
                        style="margin-right:1%;"
                        title="Return entries from the database"
                        :spin="tableData.busy"
                        @click="queryDatabase()">
                            Query Database
                        </SpinButton>

                        <SpinButton v-show="canRequestEdit"
                        class="button btn-info"
                        style="margin-right:1%;"
                        title="Start editting data"
                        :spin="tableData.busy"
                        @click="requestEdit()">
                            Request Edit
                        </SpinButton>

                        <SpinButton v-show="isEditting"
                        class="button btn-danger"
                        style="margin-right:1%;"
                        title="Cancel edits"
                        :spin="tableData.busy"
                        @click="cancelEdit()">
                            Cancel Edit
                        </SpinButton>

                        <SpinButton v-show="isEditting"
                        class="button btn-primary"
                        style="margin-right:1%;"
                        :title="canRequestCommit? 'Commit edits to the database' : 'Address problems first'"
                        :spin="tableData.busy"
                        :disabled="canRequestCommit == false"
                        @click="stageEdit()">
                            Commit Edit
                        </SpinButton>
                    </div>
                    <div class="form-item col-md-9">
                        <p class="table-error-message">{{tableData.errorMessage}}</p>
                    </div>

                    <!-- search bar -->
                    <div class="col-md-8 search" v-if="!isEditting">

                        <input type="text" placeholder="Search..." v-model="filter"/>

                        <i class="fa fa-search"></i>

                    </div>
                </div>

                <hr/>

                <Collapsible class="error-box" v-show="tableCellErrorsAsList.length > 0">
                    <template #title>
                        <span class="error-tag">⚠️ CLICK TO SEE PROBLEMS ({{tableCellErrorsAsList.length}})</span>
                    </template>

                    <p v-for="(key, i) in tableCellErrorsAsList" :key="key">
                        <span class="error-reset" title="Clear this problem" @click="handleClearErrorByKey(key)">↩️</span> {{i+1}}. <span class="error-line" @click="handleErrorLineClicked(errors[key].where.id)">{{errors[key].where.message}}</span>:&nbsp;<span>{{errors[key].info}}</span>
                    </p>
                </Collapsible>

                <DynamicTable ref="dynamicTable"
                :header="tableData.header"
                :sortableHeader="tableData.header"
                :data="tableData.data"
                :edit="isEditting"
                :perPage="45"
                :filter="filterData"
                :replace="replacementRules"
                :pageCount="tableData.pageCount"
                :selectedPage="tableData.pageIdx"
                :serverSidePagination="true"
                @jump="handlePageJump"
                @upload="commitEdit"
                @change="handleRowChange"
                @unstage="handleRowChange"
                @error="handleRowError"
                @click-cell="handleCellClick"
                @mouseleave="onMouseLeave"/>

            </div>

        </div>

    </div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import Sidebar from '@/components/Sidebar.vue'
import DynamicTable from '@/components/DynamicTable.vue';
import SpinButton from '@/components/SpinButton.vue';
import API from '../axios.config';
import utils from '../utils';
import Collapsible from '../components/Collapsible.vue';

export default {
    name: 'CMS',
    components: {
    Topnav,
    Sidebar,
    DynamicTable,
    SpinButton,
    ProgressBar,
    Collapsible
},
    data() {
        return {
            tables: [],
            allChargeTypesMapper: {}, // an associative array mapping id -> name
            allCustomersMapper: {}, // an associative array mapping id -> name
            allDepartmentsMapper: {}, // an associative array mapping id -> name
            allDriversMapper: {}, // an associative array mapping id -> name
            allCustomers: [], // list of fetched customers
            selectedTable: null, // id
            tableData: {
                data: [],
                header: [],
                errorMessage: '',
                preventDefault: false,
                busy: false,
                progress: 0, // for uploading / dropping records
                maxProgress: 0, // ^
                uploadError: null,
                pageCount: 1,
                pageIdx: 1,
            },
            selectedCellTableData: {
                data: [],
                header: []
            },
            progressText: '',
            isEditting: false,
            filter: '',
            errors: {}, // used as an alias table with ID string key
            mouse: {x: 0, y: 0},
            clickedMouse: {x: 0, y: 0},
        }
    },
    computed: {
        replacementRules() {
            return {
                'chargeType': this.allChargeTypesMapper,
                'customer': this.allCustomersMapper,
                'customerID': this.allCustomersMapper,
                'departmentID': this.allDepartmentsMapper,
                'department': this.allDepartmentsMapper,
                'driverId': this.allDriversMapper,
                'Driver': this.allDriversMapper,
            }
        },

        progressColor() {
            if(this.tableData.busy == false && this.progressText.length > 0) {
                return "#ff000f" ;
            }

            return "#00ff0f";
        },

        hasTable() {
            return !!this.selectedTable;
        },

        getFixOptions() {
            if(this.tableData.uploadError) {
                return [
                    /*{
                        text: 'Manual Fix', on: 'fix', title: 'Check the values or revert your changes.'
                    },*/
                    {
                        text: 'Try Again', on: 'retry', title: 'Useful for bad network conditions.'
                    },
                    'Cancel'
                ];
            }

            return [];
        },

        tableCellErrorsAsList() {
            return Object.keys(this.errors)
        },

        canRequestCommit() {
            return this.tableCellErrorsAsList.length == 0;
        },

        canRequestEdit() {
            return this.hasTable && this.tableData.data.length > 0 && !this.isEditting;
        },
    },

    async mounted() {
        this.tableData.busy = true;

        this.checkRole();
        this.tables = await this.fetchTableNames();
        await this.fetchChargeTypes();
        await this.fetchCustomers();
        await this.fetchDepartments();
        await this.fetchDrivers();

        // Note: For the customer 05/11/2023
        this.$toast.open({
            message: "CAUTION: Contact support before doing anything new!",
            type: 'warning',
            position: 'top',
            duration: 0, // manual dismiss
        });

        this.tableData.busy = false;
    },
    methods: {
        async checkRole() {
            const vm = this;
            let userId = localStorage.getItem("user_id");
            let users = localStorage.getItem("user-info")
            if (!users) {
                this.$router.push({ name: 'Login' })
            }
            let loginToken = localStorage.getItem("user-info")

            API.checkRole(userId, loginToken, ()=>{}, ()=>{
                vm.$router.push('Profile'); // redirect to the user's profile
            });
        },

        async fetchTableNames() {
            let loginToken = localStorage.getItem("user-info")

            try {
                let result = await API.get(`/schemas`, {
                    params: { },
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                return [...result.data.data].sort((a,b) => a.localeCompare(b));
            }
            catch(error) {
                if (error.status == 401) return null;

                if (error.status == "Expired") {
                    this.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    this.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    this.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }

            return [];
        },

        isFormReady() {
            this.tableData.errorMessage = '';

            if(!this.hasTable) {
                this.tableData.errorMessage = "Please select a TABLE to display";
            }

            return this.hasTable;
        },

        async fetchChargeTypes() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info")

            try {
                let response = await API.get(`/chargeType`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })

                if (response.status == 200) {
                    vm.allChargeTypesMapper = {}
                    response.data.data.forEach(el => {
                        vm.allChargeTypesMapper[el._id] = el.name
                    })
                }

            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }
        },

        async fetchCustomers() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info")

            try {
                let response = await API.get(`/customer`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })

                if (response.status == 200) {
                    vm.allCustomersMapper = {}
                    vm.allCustomers = [];
                    response.data.data.forEach(el => {
                        vm.allCustomersMapper[el._id] = el.name
                        vm.allCustomers.push(el);
                    })
                }
            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }
        },

        async fetchDepartments() {
            let failedCount = 0;
            const vm = this;
            let loginToken = localStorage.getItem("user-info")
            vm.allDepartmentsMapper = {}

            for(let i = 0; i < this.allCustomers.length; i++) {
                const customer = this.allCustomers[i];

                for(let j = 0; j < customer.departments.length; j++) {
                    const id = customer.departments[j]._id;
                    try {
                        let response = await API.get(`/department/${id}`, {
                            headers: {
                                Authorization: `Bearer ${loginToken}`
                            }
                        })

                        if (response.status == 200) {
                            const el = response.data.data;
                            vm.allDepartmentsMapper[el._id] = el.departmentName;
                        }
                    } catch(error) {
                        if (error.response && error.response.data.status == "Expired") {
                            vm.$toast.open({
                                message: 'Session expired. Please login again.',
                                type: 'error',
                                position: 'top'
                            });
                            vm.$router.push({ name: 'Login' })
                            localStorage.clear();
                        } else {
                            failedCount++;
                        }
                    }
                }
            }

            if(failedCount > 0) {
                vm.$toast.open({
                    message: utils.messageFromError(`${failedCount} departments failed to populate`),
                    type: 'error',
                    position: 'top'
                });
            }
        },

        async fetchDrivers() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info")

            try {
                let response = await API.get(`/driver`, {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                })

                if (response.status == 200) {
                    vm.allDriversMapper = {}
                    response.data.data.forEach(el => {
                        vm.allDriversMapper[el._id] = `${el.userId.first_name} ${el.userId.last_name}`;
                    })
                }
            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }
        },

        async apiGetTableData(tableName) {
            let loginToken = localStorage.getItem("user-info")

            try {
                let results = await API.get(utils.pagination(`/schemas/${tableName}`, this.tableData.pageIdx, 45), {
                    params: { },
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                this.filter = '';
                return {data: [...results.data.data], pageCount: results.data.pages};
            }
            catch(error) {
                if (error.status == 401) return null;

                if (error.status == "Expired") {
                    this.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    this.$router.push({ name: 'Login' })
                    localStorage.clear();
                } else {
                    this.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }

            return null;
        },

        async fetchTable() {
            if(!this.isFormReady()) return;

            let results = null;

            this.tableData.busy = true;

            // Reset the selected cell info
            this.selectedCellTableData = {
                header: [],
                data: []
            }

            results = await this.apiGetTableData(this.selectedTable);

            this.tableData.busy = false;

            const tdata = utils.makeTableFromResults(results.data)
            this.tableData.header = tdata.header
            this.tableData.data = tdata.data
            this.tableData.pageCount = results.pageCount
        },

        queryDatabase() {
            this.tableData.pageIdx = 1; // reset back to page one when querying a new table
            this.fetchTable(); // fetch the new table
        },

        selectTable(event) {
            let idx = event.target.value; // should be int index for this.tables
            this.selectedTable = this.tables[idx];
            this.tableData.preventDefault = true; // remove SELECT from drop-down
        },

        requestEdit() {
            if(this.canRequestEdit) this.isEditting = true;
        },

        stageEdit() {
            if(this.canRequestCommit == false) return;
            this.tableData.busy = true;
            this.$refs.dynamicTable.invokeUpload();
        },

        triggerUploadError(row_id, msg) {
            this.progressText = msg
            this.tableData.uploadError = row_id
            this.tableData.busy = false;
        },

        async updateDocument(doc) {
            try {
                let loginToken = localStorage.getItem("user-info")
                await API.put(`/schemas/${this.selectedTable}`, doc,
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                return true;
            }
            catch(error) {
                // TODO: change -1 to `row_id` of this record in the table so we know which record caused the problem
                this.triggerUploadError(-1, utils.messageFromError(error));
            }

            return false;
        },

        async deleteDocument(doc) {
            try {
                let loginToken = localStorage.getItem("user-info")
                await API.delete(`/schemas/${this.selectedTable}/${doc._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                return true;
            }
            catch(error) {
                // TODO: change -1 to `row_id` of this record in the table so we know which record caused the problem
                this.triggerUploadError(-1, utils.messageFromError(error));
            }

            return false;
        },

        async commitEdit(event) {
            function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }

            const totalUpdates = event.updates.length;
            const totalDeletes = event.deletes.length;

            if(totalUpdates + totalDeletes == 0) {
                this.$toast.open({
                    message: 'Nothing to commit to database',
                    type: 'info',
                    position: 'bottom-left'
                });

                this.tableData.busy = false;
                return;
            }

            this.tableData.progress = 0;
            this.tableData.maxProgress = totalUpdates + totalDeletes;
            for(const [_, doc] of event.updates.entries()) {
                this.progressText = `Updating record ${doc._id}`;
                // await sleep(1000) // for testing network requests
                if(!await this.updateDocument(doc)) {
                    return;
                }
                this.tableData.progress += 1;
            }

            // simulate a network error
            //this.progressText = "Error on last document"
            //this.tableData.uploadError = "fakeId"
            //this.tableData.busy = false;
            //return;

            for(const [_, doc] of event.deletes.entries()) {
                this.progressText = `Permanently eleting record ${doc._id}`;
                // await sleep(1000) // for testing network requests
                if(!await this.deleteDocument(doc)) {
                    return;
                }
                this.tableData.progress += 1;
            }

            // keep this in so the user can see completion
            await sleep(500)

            this.$toast.open({
                message: `Successfully changed ${this.tableData.progress} records`,
                type: 'success',
                position: 'bottom-left'
            });

            this.cancelEdit();
            this.clearProgressBar();
            await this.fetchTable();
            this.isEditting = false;
            this.tableData.busy = false;
        },

        clearProgressBar() {
            this.progressText = '';
            this.tableData.progress = this.tableData.maxProgress = 0;
            this.tableData.uploadError = null;
        },

        cancelEdit() {
             // restore original data we passed in
            this.$refs.dynamicTable.reset(this.tableData.data);
            this.isEditting = false;
            this.errors = {};
            this.filter = '';
            this.clearProgressBar();
        },

        handleRowChange(event) {
            const error_id = `${event.id}:${event.propertyName}`;
            delete this.errors[error_id];
        },

        handleRowError(event) {
            const error_id = `${event.id}:${event.propertyName}`;
            this.errors[error_id] = {
                where: {
                    id: event.id,
                    message: `On row #${event.id+1}, column "${event.propertyName}"`
                },
                info: `Invalid value "${event.value}". Expected ${event.expected}.`,
                cell: {reset_fn: event.reset_fn, rowId: event.id, propertyName: event.propertyName}
            }

            if(event.value == "" && event.expected == "number") {
                this.errors[error_id].info = `Field "${event.propertyName}" is a number and cannot take string/text values.`;
                return;
            }

            if(event.expected == "mongoid") {
                this.errors[error_id].info = `Field "${event.propertyName}" is a MongoID and must be 24 hex characters.`;
                return;
            }
        },

        handleCellClick(event) {
            // We only care about array element inspections
            if(event.type != "array") return

            this.selectedCellTableData = {
                header: ["value"],
                data: event.value.map((v) => { return {value: v}})
            }

            this.clickedMouse = {x: this.mouse.x, y: this.mouse.y }
        },

        handleErrorLineClicked(id) {
            this.$refs.dynamicTable.jumpToRow(id);
        },

        handleClearErrorByKey(error_id) {
            const cell = this.errors[error_id].cell
            cell.reset_fn()

            this.$refs.dynamicTable.handleCellRestore(cell.rowId, cell.propertyName)
            delete this.errors[error_id]
        },

        filterData(data) {
            if(this.filter.trim().length > 0) {
                const regex = new RegExp(this.filter.trim()+".*", 'i');
                for(const [_, value] of Object.entries(data)) {
                    if(regex.test(value)) {
                        return true;
                    }
                }

                return false;
            }

            return true;
        },

        onMouseMove(e) {
            this.mouse.x = e.pageX
            this.mouse.y = e.pageY
        },

        onMouseLeave() {
            // Reset to clear the widget
            this.selectedCellTableData = {
                header: [], data: []
            }
        },

        async handlePageJump(pageIdx) {
            this.tableData.pageIdx = pageIdx
            await this.fetchTable()
        }
    }
}
</script>

<style scoped>
select {
    height: 60%;
    margin-top: 2%;
}
.table-error-message {
    color: red;
}

.error-box {
    background-color: #606060;
    color: white;
    font-family: courier;
    font-size: small;
}

.error-box p span.error-line {
    text-decoration: underline dotted;
    cursor: pointer;
    color: #ffec9a;
}

.error-box p span.error-reset {
    cursor: pointer;
}

.error-box p span.error-line:hover {
    color: rgb(186, 186, 251);
}

.error-box .error-tag {
    color:white;
    font-family: sans-serif;
    font-size:initial;
}
div.search i {
    left: -30px;
    position: relative;
}

.mouse-follow {
    position: absolute;
    z-index: 1000;
    overflow:hidden;
}
</style>
