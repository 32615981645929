<template>
    <div>
        <Nav />

        <div class="login-box" style="min-height:100vh;">

            <div class="container-fluid">

                <div class="row">

                    <div class="col-md-4"></div>

                    <div class="col-md-4 mt-5 mb-5 contact-info-text">

                        <h6 class="timing-text mt-2">We are open 9am - 9pm CST on Monday-Friday</h6>

                        <p class="contact-subtext mt-2">{{ supportMsg }}</p>
                        <p>For questions about our services or other concerns, fill out the form.</p>

                        <form @submit.prevent="contactSupport">

                            <div class="form" style="margin-top: 2rem;">

                                <div class="form-item">

                                    <input type="text" id="name" v-model="name" autocomplete="off" required>

                                    <label for="name">Name</label>

                                </div>

                                <div class="form-item">

                                    <input type="email" id="email" v-model="email" autocomplete="off" required>

                                    <label for="email">Email</label>

                                </div>

                                <div class="form-item">

                                    <input type="text" id="phone" v-model="phone" autocomplete="off" required minlength="10"
                                        maxlength="10">

                                    <label for="contactPersonPhone">Phone Number</label>

                                </div>

                                <div class="form-item">

                                    <textarea id="comments" rows="4" cols="50" v-model="message" autocomplete="off"
                                        required></textarea>

                                    <label for="comments">Message</label>

                                </div>

                            </div>

                            <div class="row">

                                <div class="col-12 mt-4 mb-4">

                                    <button type="submit" class="btn btn-primary btn-block contact-submit"
                                        style="width: 30%">SUBMIT</button>

                                </div>

                            </div>

                        </form>

                    </div>

                    <div class="col-md-4"></div>

                </div>

            </div>

        </div>

        <Footer />
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import API from '../axios.config';
import utils from '../utils';

export default {
    name: 'CustomerSupport',
    components: {
        Nav,
        Footer
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            supportMsg: '',
            supportId: null,
        }
    },
    mounted() {
        this.getSupportInfo();
    },
    methods: {
        async contactSupport() {

            const vm = this;

            if (vm.supportId == null) {
                vm.$toast.open({
                    message: "We're sorry but we have no email support available at this time.",
                    type: 'error',
                    position: 'top'
                });
                return;
            }

            await API.post('sendsupportemail/' + vm.supportId, {
                name: vm.name,
                email: vm.email,
                phone: vm.phone,
                message: vm.message,
            },)
                .then(function (response) {
                    if (response.status == 200) {
                        vm.$toast.success(response.data.message, {
                            // override the global option
                            position: 'top'
                        })
                        location.reload();
                    }
                })
                .catch(function (error) {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                });

        },

        async getSupportInfo() {
            const vm = this;

            await API.get('supportInfo')
                .then(function (response) {
                    if (response.status == 200) {
                        console.log(response.data.data);
                        if (response.data.data.length == 0) {
                            vm.supportMsg = '';
                        } else {
                            var phn = utils.formatPhoneNumber(response.data.data[0].contactPersonPhone);

                            vm.supportMsg = 'For On-Call Support Dial ' + phn;
                            vm.supportId = response.data.data[0]._id;
                        }
                    }
                })
                .catch(function (error) {
                    // Keep public-facing screens quiet. Only show notification on submit.
                });

        },
    }
}
</script>