<template>
 <Topnav />
  <Sidebar />
  <div class="content-wrapper">
  <div class="content-header">
    <!-- Driver Profile -->
    <div class="container-fluid" :class="`${dshow}`" v-for="profile, in customerProfile" v-bind:key="profile._id" v-bind:id="profile._id">
      <div class="row mt-2 mb-4">
        <div class="col-md-10"></div>
        <div class="col-md-2">
            <div class="drivers-btn">
              <router-link :to="`/edit-driver-registration?id=${profile._id}`" class="btn btn-block btn-info" style="width: 50%;">Edit</router-link>
            </div>
        </div>
      </div>
      <div :class="`row ${dshow}`">
        <h2>User Account Info</h2>
        <div class="col-md-12">
          <div class="Customer-edit-form">
           <div class="driver-image" v-if="profile.userId">
                <img v-if="profile.userId.picture"  :src="`${API.baseURL}${profile.userId.picture}`" alt="" style="border-radius: 8px; width:150px; ">
                 <img v-else src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">
              </div>
              <div class="driver-image" v-else>
                <img  src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">
              </div>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="profile-data">
            <div class="row" style="border-bottom: 1px solid #ededed;">
              <div class="col">
                <div class="profiles-data">
                  <p>Name</p>
                  <h6>{{ profile.userId.first_name +" "+profile.userId.last_name }}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Date of Birth</p>                  
                  <h6>{{ profile.date_of_birth }}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Work Cell</p>
                  <h6>{{ profile.userId.phoneNo }}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>License</p>
                  <h6>{{ profile.current_license_number}}</h6>
                </div>
              </div>
               <div class="col">
                <div class="profiles-data">
                  <p>Home Address</p>
                  <h6> {{ profile.home_address}}</h6>
                </div>
              </div>
               <div class="col">
                <div class="profiles-data">
                  <p>Role</p>
                  <h6>{{ profile.userId.roles.join() }}</h6>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h6 class="driver-bank-info" style="margin-top: 2%;">Bank Information</h6>
              </div>
            </div>
             <div class="row" style="border-bottom: 1px solid #ededed;">
              <div class="col">
                <div class="profiles-data">
                  <p>Name</p>
                  <h6>{{ profile.bank_name}}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Route</p>
                  <h6>{{ profile.routing_number}}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Account</p>
                  <h6>{{ profile.account_number}}</h6>
                </div>
              </div>
               <div class="col">
                <div class="profiles-data">
                </div>
              </div>
            </div>
            <div class="row" v-if="profile.uploads">
              <div class="col-md-12" v-if="profile.uploads.attachmentUrls.length">
                <h6 class="driver-bank-info" style="margin-top: 2%;">Documents</h6>
                <div class="col-md-6" v-for="url in profile.uploads.attachmentUrls" :key="url">
                    <div class="document-link" >
                      <a class="new-driver-registration-link">{{url}}</a>
                      <a target="_blank" :href="`${API.baseURL}${url}`" class="btn btn-block btn-outline-secondary" style="color: #7c7c7c;border-color: #979797;font-size: 10px;font-weight: 500;text-transform: capitalize;padding: 10px 0px 10px 1px;width: 20%;margin-left: 2%;margin-right: 2%;" download>Download</a>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Customer Profile -->
    <div class="container-fluid" :class="`${cshow}`" v-for="profile in customerProfile" v-bind:key="profile._id" v-bind:id="profile._id">
      <div class="row mt-2 mb-4">
        <div class="col-md-10"></div>
        <div class="col-md-2">
            <div class="drivers-btn">
              <router-link :to="`/edit-customer-registration?id=${profile._id}`" class="btn btn-block btn-info" style="width: 50%;">Edit</router-link>
            </div>
        </div>
      </div>
      <div :class="`row ${cshow}`">
        <h2>User Account Info</h2>
        <div class="col-md-12">
          <div class="Customer-edit-form">
           <div class="driver-image" v-if="profile.userId.picture">
                <img :src="`${API.baseURL}${profile.userId.picture}`" alt="" style="border-radius: 8px; width:150px; ">
              </div>
              <div class="driver-image" v-else>
                <img  src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">
              </div>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="profile-data">
            <div class="row" style="border-bottom: 1px solid #ededed;">
              <div class="col">
                <div class="profiles-data">
                  <p>Name</p>
                  <h6>{{ profile.name }}</h6>
                </div>
              </div>

              <div class="col">
                <div class="profiles-data">
                  <p>Work Cell</p>
                  <h6>{{ profile.userId.phoneNo }}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Address</p>
                  <h6>{{ profile.address}}</h6>
                </div>
              </div>
               <div class="col">
                <div class="profiles-data">
                  <p>Role</p>
                  <h6>{{ profile.userId.roles.join() }}</h6>
                </div>
              </div>
              
            </div>
            <div v-if="profile.departments && profile.departments.length" class="row">
              <div class="col-md-12">
                <h2 style="margin-top: 2%;">Departments List</h2>
              </div>
            </div>
             <div class="row" style="border-bottom: 1px solid #ededed; margin-bottom: 30px;" v-for="depts in profile.departments" v-bind:key="depts._id">
              <div class="col">
                <div class="profiles-data">
                  <p>Name</p>
                  <h6>{{ depts.departmentName}}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Phone Number</p>
                  <h6>{{ depts.departmentPhoneNo}}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Email</p>
                  <h6>{{ depts.departmentEmail}}</h6>
                </div>
              </div>
              <div class="col">
                <div class="profiles-data">
                  <p>Address</p>
                  <h6>{{ depts.departmentAddress}}</h6>
                </div>
              </div>
              <!--<div class="col-md-2">
                <div class="profiles-data">
                </div>
              </div>-->
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- User/Admin profile -->
<div class="container-fluid"  :class="`${ashow}`" v-for="profile in profileData" v-bind:key="profile._id" v-bind:id="profile._id">
  <div class="row mt-2 mb-4">
        <div class="col-md-10"></div>
        <div class="col-md-2">
            <div class="drivers-btn">
              <router-link :to="`/edit-profile?id=${profile._id}`" class="btn btn-block btn-info" style="width: 50%;">Edit</router-link>
            </div>
        </div>
      </div>
      <div class="row">
        <h2>User Account Info</h2>
        <div class="col-md-12">
          <div class="Customer-edit-form">
            <div class="driver-image" v-if="true">
                <img :src="`${API.baseURL}${profile.picture}`" alt="" style="border-radius: 8px; width:150px; ">
              </div>
              <div class="driver-image" v-else>
                <img  src="../assets/img/profile.png" alt="" style="border-radius: 8px;width: 150px;">
              </div>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <div class="profile-data">
            <div class="row" style="border-bottom: 1px solid #ededed;">
              <div class="col">
                <div class="profiles-data">
                  <p>Name</p>
                  <h6>{{ profile.first_name +" "+ profile.last_name }}</h6>
                </div>
              </div>              
              <div class="col">
                <div class="profiles-data">
                  <p>Work Cell</p>
                  <h6>{{ profile.phoneNo }}</h6>
                </div>
              </div> 
              <div class="col">
                <div class="profiles-data">
                  <p>Email</p>
                  <h6>{{ profile.email }}</h6>
                </div>
              </div> 
              <div class="col">
                <div class="profiles-data">
                  <p>Roles</p>
                  <h6>{{ profile.roles.join() }}</h6>
                </div>
              </div> 

              <div class="col">
                <div class="profiles-data">
                  <router-link :to="`/driver-profile?id=${profile._id}`" class="btn btn-block btn-info">Manage Driver Profile</router-link>
                </div>
              </div>             
            </div>       
        </div>
      </div>
    </div>
</div>
<!-- End -->
</div>
</div> 

</template>
<script>
  import Topnav from '@/components/Topnav.vue'
  import Sidebar from '@/components/Sidebar.vue'
  import API from '../axios.config';
  import utils from '../utils'
  
  export default{
    name:'Profile',
    data(){
      return{
        profileData:[],
        customerProfile:[],
        dshow: 'd-show',
        cshow: 'd-show',
        ashow: 'd-show',
        bday:'',
        user_role:'',
        work_cell_number:'',
        email:'',
        API: API
      }
    },
    components:{
    	Topnav,
    	Sidebar

    },
    mounted(){
      const vm = this;
      let users = localStorage.getItem("user-info")      
      let roles = [...JSON.parse(localStorage.getItem("roles"))];

      let user_id=null;

      if(!users){
        this.$router.push({name:'Login'});
        return;
      }
    
      if(roles.includes("admin")){
          vm.loginToken = localStorage.getItem("user-info")
          user_id = localStorage.getItem("driver_id")
          vm.user_role="driver";

          this.ashow='d-show'
          this.dshow='d-none'
          this.cshow='d-none'
      }else if(roles.includes("driver")){
          vm.user_role="driver";
          user_id = localStorage.getItem("driver_id")
          vm.loginToken = localStorage.getItem("user-info")
          this.dshow='d-show'
          this.ashow='d-none'
          this.cshow='d-none'
      }
      else if(roles.includes("customer")){
          vm.user_role="customer";
          user_id = localStorage.getItem("customer_id")
          vm.loginToken = localStorage.getItem("user-info")
          this.cshow='d-show'
          this.ashow='d-none'
          this.dshow='d-none'
      }

      if(user_id == null) return;

      let header =  { 'Authorization': `Bearer ${vm.loginToken}` }

      API.get(`/${vm.user_role}/${user_id}`,
      {
        headers: header
      },
    )
    .then(function (response) { 
          if(response.status == 200){          
              if(roles.includes('admin')){
                let driver = response.data.data;
                let user = driver.userId;
                let profile = user;
                profile.uploads = driver.uploads;
                vm.profileData.push(profile);
              }else if(roles.includes("customer")  || roles.includes("driver")){  
                // TODO: why are there two different containers??
                vm.customerProfile.push(response.data.data);
              }            
          }
       })
       .catch(function (error) {       
            vm.$toast.open({
              message: utils.messageFromError(error),
              type: 'error',
              position: 'top'
          });
        });
    },

}
</script>

<style>
h2 {
    margin: auto;
    text-align: center;
    padding: 1%;
}
</style>