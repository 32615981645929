<template>
  <!--<router-view />-->
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.15s ease;
}

.scale-enter-from,
.scale-leave-to {
  filter: blur(0.05rem);
}
</style>