<template>
    <Nav />
    
    <div class="login-box" style="min-height:100vh;">
    
        <div class="container">
    
            <div class="row">
    
                <div class="col-md-4"></div>
    
                <div class="col-md-4">
    
                    <div class="card">
    
                        <p class="login-box-msg">OTP Verification</p>
    
                        <p class="otp-number">Enter the OTP sent to your registered mobile number</p>
    
                        <div class="card-body login-card-body">
    
                            <form @submit.prevent="verify">
    
                                <div class="row otp-box">
    
                                    <input type="text" 
                                    class="w-10 h-10 mx-2 text-center" 
                                    required id="key" 
                                    v-model="token" 
                                    minlength="6" 
                                    maxlength="6" 
                                    title="please enter 6 digit code" 
                                    style="width:95%; margin: 0 auto;"
                                    autocomplete="off">
    
                                </div>
      
                                    <div class="row">
    
                                      <div class="col-12">
                                          <button type="submit" class="btn btn-primary btn-block" v-if="!isVerifying">Continue</button>
                                          <button class="btn btn-primary btn-block wait" v-else><span class="spin"/></button>
                                      </div>
    
                                </div>
    
                            </form>
       
                            <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400;">

                              <span class="otp-link-text">Didn’t receive OTP?  </span>

                              <button v-on:click="resend_otp" type="button" class="fp btn" style="border:unset" :disabled="isDisabled">RESEND</button>

                              <span class="otp-link-text js-timeout">  01:20</span>
    
                            </p>
    
                            <div class="row">
    
                                <div class="col-md-3"></div>
    
                                <div class="col-md-6">
    
                                    <div class="customer_message_officer_service">
    
                                        <p class="mb-1 mt-4" style="text-align: center;font-size: 13px;font-weight: 400;">
    
                                        </p>
    
                                    </div>
    
                                </div>
        
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
        
            </div>
    
        </div>
    
    </div>
    <Footer />
</template>

<script>
import API from '../axios.config';
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import $ from 'jquery';
export default {
    name: 'OtpLogin',
    data() {
        return {
            token: '',
            loginToken: '',
            error: '',
            interval: '',
            isDisabled: true,
            isVerifying: false
        }

    },
    components: {
        Nav,
        Footer
    },
    methods: {
        async verify() {
            const vm = this;
            vm.isVerifying = true;
            vm.loginToken = localStorage.getItem("user-info");
            let username = localStorage.getItem("username");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
                'content-type': 'application/json',
            }

            await API.post('/verifyToken', {
                    token: this.token,
                    username: username
                }, {
                    headers: header
                })
                .then(function(response) {
                    if (response.status == 200) {
                        localStorage.setItem("otp_verify", "YES");
                        localStorage.setItem("user_id", response.data.data.userId);

                        let roles = JSON.stringify(response.data.data.roles);
                        localStorage.setItem("roles", roles);
                        
                        clearInterval(vm.interval);
                        if (roles.includes('admin') || roles.includes("driver")) {
                            if (roles.includes("driver")) {
                                localStorage.setItem("driver_id", response.data.data.driver._id)
                                localStorage.setItem("username", response.data.data.username)
                            }

                            vm.$router.push({ name: 'Dashboard' })
                        }
                        if (roles.includes("customer")) {
                            localStorage.setItem("customer_id", response.data.data.customer._id);
                            localStorage.setItem("username", response.data.data.username)

                            vm.$router.push({ name: 'Dashboard' })
                        }
                        if (roles.includes("department")) {
                            localStorage.setItem("customer_id", response.data.data.customer._id);
                            localStorage.setItem("department_id", response.data.data.department._id);
                            localStorage.setItem("username", response.data.data.department.departmentName + " Department User")
                            // redirect to add job form only
                            vm.$router.push(`add-job`)
                        }
                        if (roles.includes("subscriber")) {
                            localStorage.setItem("username", response.data.data.username)
                            let jobId = localStorage.getItem("past_job_id")
                            if (jobId) {
                                vm.$router.replace(`view-job?pastJobId=${jobId}`)
                            }
                        }

                        if (response.data.data.redirectTo) {
                            // view-job?pastJobId=6459165422f7fc92ccd742a2
                            vm.$router.push(response.data.data.redirectTo)
                        }
                    }
                    vm.isVerifying = false;
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: error.response? error.response.data.message : error,
                        type: 'error',
                        position: 'top'
                    });

                    vm.isVerifying = false;
                });
        },

        async countdown() {
            const vm = this;
            clearInterval(vm.interval);
            let interval = null;
            interval = setInterval(function() {
                let timer = $('.js-timeout').html();

                // TODO: why does this happen?
                if(typeof timer == "undefined") return;

                timer = timer.split(':');
                let minutes = timer[0];
                let seconds = timer[1];
                seconds -= 1;
                if (minutes < 0) return;
                else if (seconds < 0 && minutes != 0) {
                    minutes -= 1;
                    seconds = 59;
                } else if (seconds < 10 && length.seconds != 2) seconds = '0' + seconds;

                $('.js-timeout').html(minutes + ':' + seconds);

                if (minutes == 0 && seconds == 0) {
                    clearInterval(interval);
                    vm.isDisabled = false;
                }

            }, 1000);
            vm.interval = interval;
        },

        async resend_otp() {
            const vm = this;
            $('#pageloader').addClass('loading');
            $('.js-timeout').text("02:00");
            this.countdown();
            vm.isDisabled = true;

            this.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${this.loginToken}`,
                'content-type': 'application/json',
            }

            await API.get('/ott/resend', {
                    headers: header
                })
                .then(function(response) {
                    if (response.status == 200) {
                        console.warn(response);
                        vm.$toast.success('OTP has sent to your mobile number', {
                            // override the global option
                            position: 'top'
                        })
                        $('#pageloader').removeClass('loading');
                    }
                })
                .catch(function(error) {
                    vm.$toast.open({
                        message: error.response.data.message,
                        type: 'error',
                        position: 'top'
                    });
                });
        }
    },
    mounted() {
        let verified = localStorage.getItem("otp_verify")
        if (verified=='YES') {
            this.$router.push({ name: 'Dashboard' })
            return;
        }
        $('.js-timeout').text("02:00");
        this.countdown();
    }
}
</script>

<style scoped>
.error-msg {
    color: red;
}

.btn:disabled {
    cursor: not-allowed;
}

.wait {
  cursor:progress !important;
}

.spin {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 4px solid rgba(255, 255, 255, .3);
  border-radius: 100%;
  border-top-color: rgb(179, 190, 255);
  animation: spin 0.5s linear infinite;
  -webkit-animation: spin 0.5s linear infinite;
  margin: -10px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
  
  0%   {border-top-color: rgb(253, 147, 147); }
  50%  {border-top-color: rgb(157, 157, 255); }
  75%  {border-top-color: rgb(162, 255, 162); }
  100% {border-top-color: rgb(255, 255, 255); }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }

  0%   {border-top-color: rgb(253, 147, 147); }
  50%  {border-top-color: rgb(157, 157, 255); }
  75%  {border-top-color: rgb(162, 255, 162); }
  100% {border-top-color: rgb(255, 255, 255); }
}

</style>