<template>
    <Topnav />
    
    <Sidebar />

    <div class="content-wrapper">
        <div class="content-header">

            <SubscribersForm
            ref="subscribersManager"
            :role="role"
            :subscribers="subscribersList"
            :progressText="subscribersProcessText"
            :progress="subscribersProgress"
            :maxProgress="subscribersMaxProgress"
            @back="goBack"
            @save="saveSubscribers"
            @new="newSubscriber"
            @edit="updateSubscriber"/>

            <!-- Subscriber Modal -->
    
            <div class="modal" :style="subscriberModal.style">

                <div class="modal-dialog">

                    <div class="modal-content">

                        <!-- Modal Header -->

                        <div class="modal-header">

                            <h4 class="modal-title" style="font-size: 20px;">{{subscriberModal.editMode? 'Edit' : 'Add'}}&nbsp;Subscriber</h4>

                            <button type="button" class="close mt-3" @click="modalClose">&times;</button>

                        </div>

                        <!-- Modal body -->

                        <div class="modal-body pl-3" style="text-align: left;">
                            Email: <small class="error-msg">Required</small> <br/>
                            <input v-model="subscriberModal.subscriber.email" placeholder="e.g. subscriber@email.com" style="margin-bottom: 1%; width: 95%;"><br/>
                            
                            <br/>
                            Phone: <small class="error-msg" v-show="subscriberModal.subscriber.requires2FA">Required</small> <br/>
                            <input v-model="subscriberModal.subscriber.phoneNo" placeholder="123-456-7890" style="margin-bottom: 1%; width: 95%;"><br/>

                            <br/>
                            <input v-model="subscriberModal.subscriber.requires2FA" type="checkbox" id="modalCheckbox">
                            <label for="modalCheckbox">&nbsp;Is 2FA required?</label>

                        </div>

                        <!-- Modal footer -->

                        <div class="modal-footer">

                            <button v-if="subscriberModal.editMode" 
                            type="button" 
                            class="btn btn-warning"
                            :disabled="isSubscriberModalFieldInValid"
                            @click="modalUpdateSubscriber(subscriberModal.subscriber)">Update</button>

                            <button v-else
                            type="button" 
                            class="btn btn-warning"
                            :disabled="isSubscriberModalFieldInValid" 
                            @click="modalAddSubscriber(subscriberModal.subscriber)">Add</button>
                        </div>

                    </div>

                </div>

            </div>

            <div class="modal-backdrop show" :style="subscriberModal.style"></div>

            <!-- End Subscriber Modal -->

        </div>
    </div>
</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import SubscribersForm from '@/components/SubscribersForm.vue'
import API from '../axios.config'
import utils from '../utils'

export default {
    name: 'SubscribersList',
    data() {
        return {
            API: API,
            role: '',
            subscribersList: [],
            subscribersProgress: 0,
            subscribersMaxProgress: 0,
            subscribersProcessText: "",
            subscriberModal: {
                subscriber: {},
                style: { display: 'none' },
                editMode: false,
                ogSubscriber: null,
            },
            customerId: null,
            departmentId: null,
        }
    },
    components: {
        Topnav,
        Sidebar,
        SubscribersForm,
    },
    computed: {
        isSubscriberModalFieldInValid() {
            if(!this.subscriberModal.subscriber.email) return true

            if(
                this.subscriberModal.subscriber.requires2FA &&
                !this.subscriberModal.subscriber.phoneNo
            ) return true

            return false
        },
    },
    async mounted() {
        this.customerId = this.$route.query.customerId ?? localStorage.getItem("customer_id")
        this.departmentId = this.$route.query.departmentId ?? localStorage.getItem("department_id")

        let roles = [...JSON.parse(localStorage.getItem("roles"))]
        this.role = 
        roles.includes("department") ? "department" : 
        roles.includes("customer") ? "customer" :
        roles.includes("admin") ? "admin" : ""

        await this.fetchSubscribers()
    },
    methods: {
        async fetchSubscribers() {
            const vm = this;
            let loginToken = localStorage.getItem("user-info")
            let endpoint = null
            if (vm.departmentId) {
                endpoint = `/department/${vm.departmentId}`
            } else if (vm.customerId) {
                endpoint = `/customer/${vm.customerId}`
            }
            if (endpoint) {
                try {
                    let response = await API.get(endpoint, {
                        headers: {
                            Authorization: `Bearer ${loginToken}`
                        }
                    })

                    if (response.status == 200) {
                        vm.subscribersList = response.data.data.subscribers
                    }
                } catch(error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();
                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: 'error',
                            position: 'top'
                        });
                    }
                }
            }
        },
        addId(data) {
            const vm = this
            if (vm.departmentId) {
                data["departmentId"] = vm.departmentId
                data["customerId"] = vm.customerId
            } else if (vm.customerId) {
                data["customerId"] = vm.customerId
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        async saveSubscribers(result) {
            const droppedSubscribers = result.dropped;
            const updatedSubscribers = result.updated;
            const newSubscribers = result.created;
            const untouchedSubscribers = result.untouched;

            const vm = this;
            vm.loginToken = localStorage.getItem("user-info");

            let header = {
                'Authorization': `Bearer ${vm.loginToken}`,
            }
            
            // Prepare the progress bar and data
            this.subscribersProgress = 0;
            this.subscribersMaxProgress = droppedSubscribers.length + updatedSubscribers.length + newSubscribers.length;
            this.subscribersProcessText = "Preparing...";

            let newList = [];
            let failed2Drop = [];
            let stopped = null;

            droppedSubscribers.forEach(p => {
                failed2Drop.push(p);
            })

            untouchedSubscribers.forEach(p => {
                newList.push(p);
            })

            // First add the subscribers
            for(let i = 0; i < newSubscribers.length; i++) {
                const p = newSubscribers[i];
                vm.subscribersProcessText = `Adding ${p.email}`;

                try {
                    let copy = {};
                    vm.$refs.subscribersManager.copySubscriber(copy, p);
                    vm.addId(copy);
                    delete copy._id;

                    let response = await API.post(`/subscriber/`, copy, { headers: header });
                    newList.push(response.data.data);
                    vm.subscribersProgress += 1;

                    // create magic link
                    const data = {
                        magicLinkType: 'subscriber',
                        recipientEmail: copy.email,
                        subscriber:  response.data.data.subscriber._id,
                    }
                    await API.post(`/MagicLink`, data, { headers: header});
                } catch(err) {
                    vm.subscribersProcessText = utils.messageFromError(err);
                    stopped = p._id;
                    break;
                }
            }

            // Second update the subscribers
            for(let i = 0; i < updatedSubscribers.length && !stopped; i++) {
                const p = updatedSubscribers[i];
                vm.subscribersProcessText = `Updating ${p.email}`;
                vm.addId(p);
                try {
                    let response = await API.put(`/subscriber/`+p._id, p, { headers: header });
                    vm.subscribersProgress += 1;

                    // create magic link
                    const data = {
                        magicLinkType: 'subscriber',
                        recipientEmail: p.email,
                        subscriber: response.data.data.subscriber._id,
                    }
                    // TODO: should probably use RegenerateMagicLink API endpoint...
                    await API.post(`/MagicLink`, data, { headers: header});
                } catch(err) {
                    vm.subscribersProcessText = utils.messageFromError(err);
                    stopped = p._id;
                    break;
                }
            }

            // Third delete the dropped subscribers
            for(let i = 0; i < droppedSubscribers.length && !stopped; i++) {
                const p = droppedSubscribers[i];
                vm.subscribersProcessText = `Dropping ${p.email}`;
                vm.addId(p);
                // dummy subscribers being deleted are simply removed from the list
                // no remote commands required
                if(this.$refs.subscribersManager.subscriberIsLocal(p)) {
                    failed2Drop = failed2Drop.filter(fp => fp._id != p._id);
                    vm.subscribersProgress += 1;
                    continue;
                }

                try {
                    await API.delete(`/subscriber/`+p._id, { headers: header });
                    failed2Drop = failed2Drop.filter(fp => fp._id != p._id);
                    vm.subscribersProgress += 1;
                } catch(err) {
                    // unlike the others, don't stop! inform the user.
                    vm.$toast.open({
                        message: "Could not delete subscriber: " + utils.messageFromError(err),
                        type: 'error',
                        position: 'top'
                    });
                }
            }

            await vm.fetchSubscribers()

            // finished successfully, clear and reload
            // otherwise persist the changes and the error msg
            if(!stopped) {
                vm.subscribersProcessText = "";
                vm.$refs.subscribersManager.clear();
            } else {
                // allow fixing and rebase with new points
                vm.$refs.subscribersManager.allowFix(vm.subscribersList, stopped);
            }
        },
        newSubscriber() {
            console.log('new sub')
            this.subscriberModal.style = {
                display: "initial",
            }

            this.subscriberModal.subscriber = { 
                email: '',
                phoneNo: '',
                requires2FA: false,
            };

            this.subscriberModal.ogSubscriber = null;
            this.subscriberModal.editMode = false;
        },
        updateSubscriber(sub) {
            this.subscriberModal.style = {
                display: "initial",
            }

            const copy = {
                email: sub.email,
                phoneNo: sub.phoneNo,
                requires2FA: sub.requires2FA,
                _id: sub._id
            };

            this.subscriberModal.ogSubscriber = sub;
            this.subscriberModal.subscriber = copy;
            this.subscriberModal.editMode = true;
        },
        modalClose() {
            this.subscriberModal.style = {
                display: "none",
            }
        },
        modalUpdateSubscriber(data) {
            this.modalClose();
            this.$refs.subscribersManager.edit(data);
        },
        modalAddSubscriber(data) {
            this.modalClose();
            this.$refs.subscribersManager.add(data);
        },
    },
}
</script>

<style scoped>

</style>