<template>
  <div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mt-2">
          <h3> Loading....</h3>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import API from '../axios.config';

  export default {
    name: "MagicLink",
    // Verify MagicLink
    mounted() {
      const vm= this;
      API.get('/MagicLink', {
        params: {
          token:this.$route.query.token
        }
      }, {
        headers: {
          Authorization: `Bearer ${this.$route.query.token}`
        }
      }).then(({data}) => {
        const jobId = vm.$route.query.pastJobId

        if(data.data.driver != null) {
          // NOTE: first_name used to be how we checked if an account is registered
          if(!data.data.user.first_name || !data.data.user.is_registered) {
            vm.$router.replace(`new-driver-registration`)
          } else {
            vm.$router.replace(`dashboard`)
          }
          
          localStorage.setItem("driver_id",data.data.driver._id)
          localStorage.setItem("user_id",data.data.driver.userId)
          localStorage.setItem("roles",JSON.stringify(data.data.user.roles))
          localStorage.setItem("username",data.data.user.username)
          localStorage.setItem("loginToken",data.data.token)
          localStorage.setItem("user-info",data.data.token)
        } else if(data.data.customer != null) {
          localStorage.setItem("customer_id",data.data.customer._id)
          localStorage.setItem("user_id",data.data.customer.userId)
          localStorage.setItem("loginToken",data.data.token)
          localStorage.setItem("user-info",data.data.token)

          if(data.data.department != null) {
            // department role bound to a customer account
            localStorage.setItem("department_id",data.data.department._id)

            // department users are not real users but we can mimic the role data
            localStorage.setItem("roles",`["customer", "department"]`)
            localStorage.setItem("username", data.data.department.departmentName + " Department User")


            //console.log("should be going to add job");

            // redirect to add job form only
            vm.$router.replace(`add-job`)

          } else {
            // customer-only role
            localStorage.setItem("roles",JSON.stringify(data.data.user.roles))
            localStorage.setItem("username",data.data.user.username)

            // NOTE: first_name used to be how we checked if an account is registered
            if(!data.data.user.first_name || !data.data.user.is_registered) {
              vm.$router.replace(`new-customer-registration`)
            } else {
              vm.$router.replace(`dashboard`)
            }
          }
        } else if (data.data.subscriber != null && jobId) {
          localStorage.setItem("customer_id",data.data.subscriber.customerId)
          localStorage.setItem("department_id",data.data.subscriber.departmentId)
          localStorage.setItem("subscriber_id",data.data.subscriber._id)
          localStorage.setItem("roles",JSON.stringify(data.data.user.roles))
          localStorage.setItem("loginToken",data.data.token)
          localStorage.setItem("user-info",data.data.token)
          localStorage.setItem("username",data.data.user.username)

          if (!data.data.subscriber.requires2FA) { 
            localStorage.setItem("otp_verify", "YES")
            // redirect to view job form only
            vm.$router.replace(`view-job?pastJobId=${jobId}`)
          }
        } else {
          // OTP verification
          localStorage.setItem("otp_verify", "NO");
          localStorage.setItem("loginToken",data.data.token)
          localStorage.setItem('user-info', data.data.token)
          if (jobId) {
            localStorage.setItem("past_job_id", jobId)
          }
          
          vm.$router.replace('otp');
        }
      }).catch((e) => {
        vm.$router.replace('dashboard')
      });
    }
  }
</script>

<style scoped>

</style>