<template>
    <Topnav />
    
    <Sidebar />
    
    <div class="content-wrapper">
    
        <div class="content-header" style="padding: 0;">
    
            <div class="container-fluid" style="padding-left: 0;">
    
                <div class="row">

                    <div class="col-md-3 route-list-col">

                        <div class="add-route-btn col-md-6">
    
                            <button type="button"
                            :disabled="table.busy"
                            title="Prompts a form to create a new route"
                            class="btn btn-block btn-info" 
                            data-toggle="modal" 
                            ata-target="#routeModal"
                            @click.prevent="table.busy? 0 : handleCreateNewRouteClick()">
                            {{table.busy? "Please wait..." : "Add Route"}}
                            </button>
    
                        </div>

                        <div class="card-body scrolling-section">
                            <div class="row mb-12" v-if="table.busy && bootProgressText.length > 0">
                                <div class="col">
                                    <div class="row mb-12">
                                        <img src="@/assets/img/spinner.gif" style="width:40px;height:auto;"/>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row mb-12">
                                        {{bootProgressText}}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-12 jobs-address-assign" style="border-bottom: 1px solid #d8d8d861;width: 100%;" v-else-if="routeTemplates.length == 0">

                                <h4 style="text-align: center;width: 100%;"> Weekly Routes Is Empty </h4>

                            </div>

                            <div title="Click to view this route's details"
                            style="border-bottom: 1px solid #d8d8d861; width: 100%; margin-bottom: 10px;"
                            v-for="route in routeTemplates" :key="route"
                            :class="getRouteClass(route)"
                            @click="table.busy == false? handleRouteClick(route) : 0">
                                <div class="col-xl-10"><span style="width:100;">{{route.name}}</span></div>
                                <div v-if="table.busy==false" class="col-xl-2">
                                    <img src="@/assets/img/delete-icon.svg" 
                                    title="Delete route" 
                                    class="del-route"
                                    @click.stop="handleDeleteRouteClicked(route)"/>
                                </div>
                                <img v-else src="@/assets/img/spinner.gif" style="width:40px;height:auto;"/>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-9">

                        <GMapMap ref="gmap" :center="center" :zoom="zoom" map-type-id="terrain" class="gmap-override">
                            <GMapMarker v-for="(m, index) in markers" :key="index"
                            :title="m.address"
                            :position="m.position" 
                            :clickable="true" 
                            :draggable="false"
                            :animation="2"
                            @click="center = m.position" 
                            /> 
                            <GMapPolyline v-for="(m, index) in routePaths" :key="index"
                                :path="m.route"
                                :editable="false"
                                :options="{strokeColor: m.color, label:'dashed'}"
                                />
                        </GMapMap>

                        <div class="row">
                            <div class="col-md-6">
                                <SpinButton v-show="selectedRoute"
                                    class="button btn-primary" 
                                    style="margin-right:1%;"
                                    title="Save edits to weekly route" 
                                    :spin="table.busy"
                                    @click="handleSaveRouteClicked()">
                                        Save
                                </SpinButton>
                                <div v-show="showSaveReminder" class="save-reminder">
                                    Don't forget to save!
                                </div>
                            </div>
                            <div class="col-md-6">
                                <progress-bar v-show="table.maxProgress > 0"
                                :bar-color="progressColor"
                                :val="table.progress" 
                                :max="table.maxProgress" 
                                :text="`(${table.progress}/${table.maxProgress}) ${progressText}`"
                                :opts="getFixOptions"
                                @cancel="clearProgressBar()"
                                @retry="clearProgressBar(); submitAssignDriverRoute()"
                                />
                            </div>
                        </div>
                        <div class="row" v-if="selectedRoute">
                            <div class="col-md-4">
                                <b>Route</b>&nbsp;
                                <span style="margin:5px">{{selectedRoute.name}}</span>
                                <small class="link" title="Click to rename" @click="handleRenameRouteClicked(selectedRoute)">edit</small>
                                <hr/>
                                <div>
                                    <b>Charge Type</b>&nbsp;
                                    <select style="width:100%" v-model="selectedRoute.chargeType" @change="handleChargeTypeChanged">
                                        <option :value="null" disabled :selected="selectedRoute.chargeType==null">Please Select</option>
                                        <option v-for="c in allChargeTypes" :key="c._id" :value="c._id">{{c.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <b>Repeats</b>&nbsp;
                                Su&nbsp;<input type="checkbox" @click="setEditFlags" v-model="routeDayChecked[0]"/>&nbsp;
                                Mo&nbsp;<input type="checkbox" @click="setEditFlags" v-model="routeDayChecked[1]"/>&nbsp;
                                Tu&nbsp;<input type="checkbox" @click="setEditFlags" v-model="routeDayChecked[2]"/>&nbsp;
                                We&nbsp;<input type="checkbox" @click="setEditFlags" v-model="routeDayChecked[3]"/>&nbsp;
                                Th&nbsp;<input type="checkbox" @click="setEditFlags" v-model="routeDayChecked[4]"/>&nbsp;
                                Fr&nbsp;<input type="checkbox" @click="setEditFlags" v-model="routeDayChecked[5]"/>&nbsp;
                                Sa&nbsp;<input type="checkbox" @click="setEditFlags" v-model="routeDayChecked[6]"/>&nbsp;
                                <hr/>
                                <div>
                                    <b>Customer</b>&nbsp;
                                    <select style="width:100%" v-model="selectedRoute.customer" @change="handleCustomerChanged">
                                        <option :value="null" disabled :selected="selectedRoute.customer==null">Please Select</option>
                                        <option v-for="c in allCustomers" :key="c._id" :value="c._id">{{c.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                🔧 <a href="#" title="Click to access admin tools for this route" class="link"
                                    @click.prevent="handleAdminToolsClicked()">
                                    Show Admin Tools
                                    </a>
                                <hr/>
                                <b>Driver</b>&nbsp;
                                <small title="Remove driver" class="link" v-if="selectedRoute.driverId" @click="handleRemoveDriverClicked(selectedRoute)">Remove</small>
                                <small title="Assign a driver" class="link" v-else @click="handleAssignDriverClicked">Assign</small>
                                <div v-if="selectedRouteDriver">
                                    <img v-if="selectedRouteDriver.userId.picture" :src="`${API.baseURL}${selectedRouteDriver.userId.picture}`" alt="" style="width:30px;margin-top:13px;">
                                    <img v-else src="../assets/img/profile.png" alt="" style="width:30px;margin-top:13px;">
                                    {{ getDriverName(selectedRouteDriver) }}
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-8">
                                <b>Route</b>&nbsp;<span>None Selected</span>
                            </div>
                        </div>

                        <hr/>

                        <div class="row">
                            <div class="col-md-8">
                                <b>Tasks</b>
                                <img title="Add New Task" v-if="selectedRoute" 
                                src="../assets/img/Add-file-icon.svg" 
                                alt="" style="width: 5%;margin-right: 10px; cursor:pointer;"
                                @click="handleAddTaskClicked">
                            </div>
                        </div>

                        <DynamicTable ref="dynamicTable"
                        :header="table.header" 
                        :data="table.data" 
                        :perPage="8"
                        :actions="true"
                        :replace="computeRemappers"
                        @upload="commitEdits"
                        @edit="handleEditTaskClicked"/>
    
                    </div>

                </div>

            </div>

        </div>

        <!-- admin tools modal -->
        <div class="modal" :style="adminToolsModal.style">
    
            <div class="modal-dialog">

                <div class="modal-content">

                    <!-- Modal Header -->

                    <div class="modal-header">

                        <h4 class="modal-title" style="font-size: 20px;">🔧 Admin tools</h4>

                        <button type="button" class="close mt-3" @click="closeAdminToolsModal()">&times;</button>

                    </div>

                    <!-- Modal body -->

                    <div class="modal-body pl-3">

                        <div>
                            To create a job manually, click 
                            <SpinButton @click="handleGenerateNow()"
                            :spin="table.busy"
                            class="btn-danger">
                            Generate Now
                            </SpinButton>
                        </div>

                        <hr/>

                        <div v-if="generateStatus">
                            <span v-if="generateStatus.error" style="color:red">
                                {{generateStatus.error}}
                            </span>
                            <span v-else-if="generateStatus.jobId">
                                Job created with ID 
                                <a class="link" :href="`${config.baseURL}/#/view-job?jobId=${generateStatus.jobId}`">{{generateStatus.jobId}}</a>
                            </span>
                        </div>
                    </div>

                    <!-- Modal footer -->

                    <div class="modal-footer">

                        <button type="button" class="btn btn-info" @click="closeAdminToolsModal()">OK</button>

                    </div>

                </div>

            </div>

        </div>

        <!-- delete route modal -->
        <div class="modal" :style="deleteRouteModal.style" v-if="deleteRouteObj">
    
            <div class="modal-dialog">

                <div class="modal-content">

                    <!-- Modal Header -->

                    <div class="modal-header">

                        <h4 class="modal-title" style="font-size: 20px;">Delete {{deleteRouteObj.name}}</h4>

                        <button type="button" class="close mt-3" @click="closeDeleteRouteModal()">&times;</button>

                    </div>

                    <!-- Modal body -->

                    <div class="modal-body pl-3">

                        Are you sure you want to delete?

                    </div>

                    <!-- Modal footer -->

                    <div class="modal-footer">

                        <button type="button" class="btn btn-success mr-3" @click="deleteRoute(deleteRouteObj); closeDeleteRouteModal()">Yes</button>

                        <button type="button" class="btn btn-danger" @click="closeDeleteRouteModal()">No</button>

                    </div>

                </div>

            </div>

        </div>

        <!-- rename route modal -->
        <div class="modal" :style="renameRouteModal.style">
        
            <div class="modal-dialog" style="max-width: 450px;">
        
                <div class="modal-content">
    
                    <!-- Modal Header -->
        
                    <div class="modal-header" style="padding-left: 2rem; padding-right: 2rem;">
        
                        <h4 class="modal-title" style="font-size: 24px;font-weight: 600;">Rename Route</h4>
        
                        <button type="button"
                        class="close" data-dismiss="modal" style="margin-top: 2px; margin-left: 10px;"
                        @click="closeRenameRouteModal">
                        &times;
                        </button>
        
                    </div>
        
                    <!-- Modal body -->
        
                    <form @submit.prevent="renameRoute(selectedRoute, newRouteName)">
        
                        <div class="modal-body">
        
                            <div class="form-group" style="padding-left: 2rem; padding-right: 2rem;">
        
                                <input
                                class="form-control" 
                                style="padding: 3%;height: calc(3rem + 0px);"
                                autocomplete="off"
                                v-model="newRouteName"
                                required>
        
                            </div>
        
                        </div>
        
        
                        <!-- Modal footer -->
        
                        <div class="modal-footer" style="margin-top: -4%;margin-bottom: 6%;padding-left: 2rem;padding-right: 2rem;">
        
                            <button 
                            type="submit" 
                            class="btn btn-block btn-info" 
                            style="width: 100%; padding: 2%; font-size: 16px;">
                            Submit
                            </button>
                            
                        </div>
        
                    </form>
        
                </div>
        
            </div>
        
        </div>

        <!-- backdrop for any modal -->
        <div class="modal-backdrop show" :style="modalBackdrop.style"></div>

        <!-- assign driver form -->
        <div class="modal" :style="assignDriverModal.style">
    
            <div class="modal-dialog" style=" max-width: 900px;">

                <div class="modal-content">

                    <div class="modal-header">

                        <h4 class="modal-title" style="font-size: 24px;font-weight: 600;"></h4>

                        <button type="button" class="close" style="margin-top: 3px;margin-left: 3rem;" @click="closeAssignDriverModal">×</button>

                    </div>

                    <form @submit.prevent="submitAssignDriverRoute">

                        <div class="row modal-body mt-4 mb-2 assign-drivers-modal" style="padding-left: 2rem;padding-right: 2rem;height: 190px; margin: 0px;">

                            <div class="col-md-6 mt-4 mb-2" id="" v-if="allDrivers.length == 0">
                                No Drivers Found
                            </div>

                            <div class="col-md-6 mt-4 mb-2" id="" v-for="Driver in allDrivers" v-bind:key="Driver._id">

                                <div class="row signature">

                                    <div class="col-md-2" v-if="Driver.userId">

                                        <img v-if="Driver.userId.picture" :src="`${API.baseURL}${Driver.userId.picture}`" alt="" style="width:30px;margin-top:13px;">

                                        <img v-else src="../assets/img/profile.png" alt="" style="width:30px;margin-top:13px;">

                                    </div>

                                    <div v-else class="col-md-2">

                                        <img src="../assets/img/profile.png" alt="" style="width:30px;margin-top:13px;">

                                    </div>

                                    <div class="col-md-6">

                                        <h6 class="add-job-drives">{{getDriverName(Driver)}}</h6>

                                    </div>

                                    <div class="col-md-4">

                                        <input class="driverAssign" type="radio" :id="`Assign-${Driver._id}`"
                                        style="margin: 20px 10px 0px;" 
                                        :checked="assignDriver==Driver"
                                        v-on:click="stageAssignDriverRoute(Driver)">

                                        <label :for="`Assign-${Driver._id}`" style="cursor:pointer; margin: 15px 0px 0px;background-color: #fff; ">SELECT</label>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="col-md-12 footer-btns mt-4">

                            <div class="modal-footer" style="margin-top: -2%;margin-bottom: 4%; justify-content: center;">

                                <button type="submit" 
                                class="btn btn-block btn-info" 
                                style="width: 10%;font-size: 12px;padding: 10px;margin: 0;"
                                @click="acceptAssignDriverModal(selectedRoute)">
                                ASSIGN
                                </button>

                                <button type="button" 
                                class="btn btn-primary btn-block" 
                                style="width: 10%;font-size: 12px;margin-left: 1%;"
                                @click="closeAssignDriverModal">
                                CANCEL
                                </button>

                            </div>

                        </div>

                    </form>

                </div>

            </div>

        </div>

        <!-- create and edit task modal -->
        <div class="modal" :style="editTaskModal.style">
        
            <div class="modal-dialog" style="max-width: 900px;">
        
                <div class="modal-content">
    
                    <!-- Modal Header -->
        
                    <div class="modal-header" style="padding-left: 2rem; padding-right: 2rem;">
        
                        <h4 class="modal-title" style="font-size: 24px;font-weight: 600;">{{taskForm.isNew? "Add" : "Edit" }} Task</h4>
        
                        <button type="button"
                        class="close" data-dismiss="modal" style="margin-top: 2px; margin-left: 10px;"
                        @click="closeEditTaskModal">
                        &times;
                        </button>
        
                    </div>
        
                    <!-- Modal body -->
        
                    <form v-if="selectedRoute && selectedRoute.customer"
                    @submit.prevent="handleTaskSubmit">
        
                        <div class="modal-body">
                            
                            <div class="form-group" style="padding-left: 2rem; padding-right: 2rem;">
                                <h4>Bill To</h4>

                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Customer</label>

                                        <select class="form-control"
                                        v-model="selectedRoute.customer"
                                        style="padding: 3%;height: calc(3rem + 0px);"
                                        required>
                                            <option disabled :value="null" :selected="selectedRoute.customer==null">No Customer Selected</option>
                                            <option :value="selectedRoute.customer">
                                                {{ allCustomers.find(i => i._id == selectedRoute.customer).name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Department <small>(Optional)</small></label>
                                        <select class="form-control" 
                                        style="padding: 3%;height: calc(3rem + 0px);"
                                        required>
                                            <option 
                                            :value="null"
                                            @click="selectedDepartment = null">
                                                No Department
                                            </option>
                                            <option
                                            v-for="department in allCustomers.find(i => i._id == selectedRoute.customer).departments" :key="department._id"
                                            :value="department._id"
                                            :selected="selectedDepartment == department._id"
                                            @click="selectedDepartment = department._id">
                                                {{ department.departmentName }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" style="padding-left: 2rem; padding-right: 2rem;">
                                <div class="row">
                                    <div class="col-md-2">
                                        <h4>Task</h4>
                                    </div>
                                    <div class="col-md-4"></div>
                                    <div class="col-md-3">
                                        <label>Is Pickup</label>&nbsp;
                                        <input type="checkbox" v-model="taskForm.isPickup">
                                    </div>
                                    <div class="col-md-3">
                                        <label>Requires Proof</label>&nbsp;
                                        <input type="checkbox" v-model="taskForm.requiresPhotoSignature">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Facility Name <small>(Optional)</small></label>
                                        <input
                                        class="form-control"
                                        style="padding: 3%;height: calc(3rem + 0px);"
                                        autocomplete="off"
                                        v-model="taskForm.facility"
                                        @keyup="autocompleteNicknames">
                                        <ul v-show="autocomplete.nicknames" class="autocomplete-results">
                                            <li v-for="(obj, i) in autocomplete.nicknames" :key="i" 
                                            class="autocomplete-result"
                                            @click.prevent="handleFacilitySuggestionClicked(obj)">
                                                {{obj.nickname}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <label>Facility Address</label>
                                        <input
                                        class="form-control"
                                        style="padding: 3%;height: calc(3rem + 0px);"
                                        autocomplete="off"
                                        v-model="taskForm.address"
                                        required
                                        @keyup="autocompleteAddress">
                                        <ul v-show="autocomplete.addresses" class="autocomplete-results">
                                            <li v-for="(obj, i) in autocomplete.addresses" :key="i" 
                                            class="autocomplete-result"
                                            @click.prevent="handleAddressSuggestionClicked(obj)">
                                                {{obj.address}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-3">
                                        <label>Contact Name <small>(Optional)</small></label>
                                        <input
                                        class="form-control" 
                                        style="padding: 3%;height: calc(3rem + 0px);"
                                        autocomplete="off"
                                        v-model="taskForm.contact"
                                        >
                                    </div>
                                     <div class="col-md-3">
                                        <label>Contact Phone <small>(Optional)</small></label>
                                        <input
                                        type="tel"
                                        class="form-control" 
                                        style="padding: 3%;height: calc(3rem + 0px);"
                                        autocomplete="off"
                                        v-model="taskForm.phoneNumber"
                                        minlength="10" maxlength="10"
                                        @keypress="onlyNumber($event)"
                                        >
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Notes <small>(Optional)</small></label>
                                        <input
                                        class="form-control" 
                                        style="padding: 3%;height: calc(3rem + 0px);"
                                        autocomplete="off"
                                        v-model="taskForm.extraNotes"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <!-- Modal footer -->
        
                        <div class="modal-footer" style="margin-bottom: 6%;padding-left: 2rem;padding-right: 2rem;">
        
                            <button 
                            type="submit" 
                            class="btn btn-block btn-info" 
                            style="width: 100%; padding: 2%; font-size: 16px;">
                            Submit
                            </button>
                            
                        </div>
        
                    </form>
        
                </div>
        
            </div>
        
        </div>

    </div>

</template>

<script>
import Topnav from '@/components/Topnav.vue'
import Sidebar from '@/components/Sidebar.vue'
import API from '../axios.config';
import eznavigator from '../eznavigator';
import Collapsible from '../components/Collapsible.vue';
import ProgressBar from '@/components/ProgressBar.vue'
import DynamicTable from '../components/DynamicTable.vue';
import SpinButton from '../components/SpinButton.vue';
import utils from '../utils';
import config from '../../config';

const DAY_TABLE_SHORT = ['M','T','W','R','F','Sa','Su']
const DAY_TABLE_LONG  = ['Sunday', 
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

export default {
    name: 'WeeklyRouteMgmtm',
    data() {
        return {
            API: API,
            config: config,
            center: {lat: 0, lng: 0},
            zoom: 10,
            routeTemplates: [],
            markers: [],
            routePaths: [], // drawn paths for the route
            allDrivers: [],
            allCustomers: [],
            allChargeTypes: [],
            allCustomerIDMapper: {},
            allDepartmentsIDMapper: {},
            table: {
                header: [
                    {facility: 'Facility'},
                    {extraNotes: 'Notes'},
                    {customerID: "Customer"},
                    {department: "Department"},
                    {address: "Address"},
                    {contact: "Contact"},
                    {phoneNumber: "PhoneNumber", type: "string"},
                    {requiresPhotoSignature: "Require Proof?"},
                    {isPickup: 'Pickup?'},
                ],
                data: [],
                tasksCreated: [],
                busy: false,
                uploadError: false,
                errorMessage: '',
                progress: 0, // for uploading / dropping records
                maxProgress: 0, // ^
            },
            autocomplete: {
                facilities: [], // nickname suggestions
                addresses: [], // google road suggestions
                hasError: false // prevent spamming user
            },
            bootProgressText: '',
            progressText: '',
            generateStatus: {},
            taskForm: this.resetTaskForm(),
            isEditted: false,
            assignDriver: null,
            showSaveReminder: false,
            selectedRoute: null,
            selectedTask: null,
            selectedTaskIdx: -1, // Mav: Added this to try and solve mut data errors 
            selectedCustomer: null,
            selectedDepartment: null,
            deleteRouteObj: null,
            newRouteName: "",
            routeDayChecked: Array(7).fill(false),
            deleteRouteModal: { style: { display: 'none' } },
            renameRouteModal: { style: { display: 'none' } },
            assignDriverModal: { style: { display: 'none' } },
            editTaskModal: { style: { display: 'none' } },
            adminToolsModal: { style: { display: 'none' }},
            modalBackdrop: { style: { display: 'none' } }
        }
    },
    components: {
        Topnav,
        Sidebar,
        Collapsible,
        DynamicTable,
        SpinButton,
        ProgressBar
    },
    computed: {
        computeRemappers() {
            return {
                'customer': this.allCustomerIDMapper, 
                'customerID': this.allCustomerIDMapper,
                'department': this.allDepartmentsIDMapper,
            }
        },
        selectedRouteDriver() {
            if(this.selectedRoute && this.selectedRoute.driverId) {
                let result = this.allDrivers.filter(x=>x._id ==this.selectedRoute.driverId._id);
                if(result.length > 0) return result[0];
            }

            return null;
        },

        selectedRouteDriverFullName() {
            if(this.selectedRoute && this.selectedRoute.driverId) {
                return `${this.selectedRoute.driverId.userId.first_name} ${this.selectedRoute.driverId.userId.last_name}`;
            }

            return "";
        },

        progressColor() {
            if(this.table.busy == false && this.progressText.length > 0) {
                return "#ff000f" ;
            }

            return "#00ff0f";
        },

        getFixOptions() {
            if(this.table.uploadError) {
                return [
                    /*{
                        text: 'Manual Fix', on: 'fix', title: 'Check the values or revert your changes.'
                    },*/ 
                    {
                        text: 'Try Again', on: 'retry', title: 'Useful for bad network conditions.'
                    },
                    'Cancel'
                ];
            }

            return [];
        },
    },
    async mounted() {
        // load all dependencies
        this.table.busy = true;
        this.bootProgressText = "Discovering GPS"
        let latLon = await eznavigator.getLatLong();
        this.center.lat = latLon[0];
        this.center.lng = latLon[1];

        this.bootProgressText = "Reading permissions"
        this.loginToken = localStorage.getItem("user-info");

        this.bootProgressText = "Fetching charge type data"
        await this.fetchChargeTypes();

        this.bootProgressText = "Fetching all customers"
        await this.fetchCustomers();

        this.bootProgressText = "Linking departments"
        await this.fetchDepartments();

        this.bootProgressText = "Populating routes"
        await this.fetchWeeklyRoutes();

        this.bootProgressText = "Fetching drivers"
        await this.fetchDriverList();
        this.table.busy = false;

        this.bootProgressText = '';
    },
    methods: {
        //////////////////
        // util getters //
        //////////////////

        getDriverName(driver) {
            return `${driver.userId.first_name} ${driver.userId.last_name}`
        },

        getRouteClass(route) {
            let str = "row mb-12 container-fluid route-card jobs-address-assign";
            
            if(!this.selectedRoute) return str;

            // This is convoluted...
            // 1. Verify if this is a local (new) route. They have dummy IDs
            // 2. If it is, see if it is the same as the selected route
            // 3. Otherwise verify if the mongoid is valid and matches
            // 4. All else fails, just use the default class
            let isSelected = route.dummyID && route.dummyID == this.selectedRoute?.dummyID
            isSelected = isSelected || (this.selectedRoute?._id && route._id == this.selectedRoute._id)
            
            if(isSelected)
                return str + " route-card-selected"

            return str
        },

        //////////////////
        // API fetchers //
        //////////////////

        async fetchChargeTypes() {
            const vm = this;

            API.get(`/chargeType`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(function(response) {
                    if (response.status == 200) {
                        response.data.data.forEach(el => {
                          vm.allChargeTypes.push(el);
                        })
                    }
                })
                .catch(function(error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();
                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: 'error',
                            position: 'top'
                        });
                    }
                });
        },

        async fetchWeeklyRoutes() {
            const vm = this;
            vm.msg = "";

            try {
                let result = await API.get(`/weekly`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                });

                vm.routeTemplates = [...result.data.data];
            } catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    vm.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    vm.$router.push({ name: 'Login' })
                    localStorage.clear();

                } else {
                    vm.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            }
        },

        async fetchCustomers() {
            try {
                const loginToken = localStorage.getItem("user-info");

                let response = await API.get(`/customer`, {
                        headers: {
                            Authorization: `Bearer ${loginToken}`
                        }
                    })

                if (response.status == 200) {
                    let idMapper = {}
                    this.allCustomers = [...response.data.data]
                    this.allCustomers.forEach(el => {
                            idMapper[el._id] = el.name
                        })
                    this.allCustomerIDMapper = idMapper;
                }
            }
            catch(error) {
                if (error.response && error.response.data.status == "Expired") {
                    this.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    this.$router.push({ name: 'Login' })
                    localStorage.clear();

                } else {
                    this.$toast.open({
                        message: utils.messageFromError(error),
                        type: 'error',
                        position: 'top'
                    });
                }
            };
        },

        async fetchDepartments() {
            let failedCount = 0;
            const vm = this;
            let loginToken = localStorage.getItem("user-info")
            vm.allDepartmentsIDMapper = {}

            for(let i = 0; i < this.allCustomers.length; i++) {
                const customer = this.allCustomers[i];

                for(let j = 0; j < customer.departments.length; j++) {
                    const id = customer.departments[j]._id;
                    try {
                        let response = await API.get(`/department/${id}`, {
                            headers: {
                                Authorization: `Bearer ${loginToken}`
                            }
                        })

                        if (response.status == 200) {
                            const el = response.data.data;
                            vm.allDepartmentsIDMapper[el._id] = el.departmentName;
                        }
                    } catch(error) {
                        if (error.response && error.response.data.status == "Expired") {
                            vm.$toast.open({
                                message: 'Session expired. Please login again.',
                                type: 'error',
                                position: 'top'
                            });
                            vm.$router.push({ name: 'Login' })
                            localStorage.clear();
                        } else {
                            failedCount++;
                        }
                    }
                }
            }

            if(failedCount > 0) {
                vm.$toast.open({
                    message: utils.messageFromError(`${failedCount} departments failed to populate`),
                    type: 'error',
                    position: 'top'
                });
            }
        },

        ////////////////////
        // click handlers //
        ////////////////////

        async handleGenerateNow() {
            this.table.busy = true

            // generate
            this.generateStatus = {}

            try {
                let loginToken = localStorage.getItem("user-info");

                let header = {
                    'Authorization': `Bearer ${loginToken}`,
                }

                let response = await API.post(`/weekly/generate/${this.selectedRoute._id}`, 
                    {}, // empty body 
                    {
                        headers: header
                    }
                );

                if (response.status == 200) {
                    this.generateStatus.jobId = response.data.data._id
                } else {
                    this.generateStatus.error = "Feature not available"
                }
            }catch(err) {
                this.generateStatus.error = err.response.data.error
            }

            this.table.busy = false
        },

        handleCreateNewRouteClick() {
            if(this.canPreventUserFromLeaving()) 
                return;

            this.clearProgressBar();
            this.clearEditFlags();

            const list_len = this.routeTemplates.length;
            const nextID = list_len+1;
            const _dummyTask = () => { 
                return {
                    companyname: 'companyname',
                    phoneNumber: "123-345-5678",
                    countryCode: 'US',
                    customerID: "1234567890",
                    department: "0987654321",
                    address: "123 test st.",
                    contact: "Billy Burr",
                    requiresPhotoSignature: true,
                    extraNotes: 'this is a note',
                    facility: 'facility',
                    isPickup: Math.random() > 0.5,
                };
            }

            const _dummyDays = () => {
                let res = [];
                for(let i = 0; i < DAY_TABLE_SHORT.length; i++) {
                    if(Math.random() > 0.5) {
                        res.push(DAY_TABLE_SHORT[i])
                    }
                }

                return res;
            }

            const firstChargeType = this.allChargeTypes[0];
            this.routeTemplates.push({
                name: "New Route #" + nextID,
                dummyID: nextID,
                startTime: null,
                endTime: null,
                customer: null,
                department: null,
                days: [],
                tasks: [],
                driverId: null,
                chargeType: firstChargeType ? firstChargeType._id : null,
            })

            this.handleRouteClick(this.routeTemplates[list_len]);
            this.setEditFlags();
        },

        handleRouteClick(route) {
            if(this.canPreventUserFromLeaving()) 
                return;

            this.table.tasksCreated = []; // clear added tasks
            this.clearProgressBar();
            this.clearEditFlags();

            // TODO: fromDatabase(route) function or better data normalization
            this.selectedRoute = utils.safeStructuredClone(route);

            if(this.allChargeTypes.length > 0) {
                // this just gaurantees we have a charge type ID or use a default one (first)
                this.selectedRoute.chargeType = this.selectedRoute.chargeType?._id ?? this.allChargeTypes[0]._id
            } 

            this.updateRouteTaskData();
            this.setCheckedDays(route);
        },

        handleDeleteRouteClicked(route) {
            let style = {
                display: "initial",
            }

            this.deleteRouteObj = route;
            this.deleteRouteModal.style = style;
            this.modalBackdrop.style = style;
        },

        handleAdminToolsClicked() {
            let style = {
                display: "initial",
            }

            // clears
            this.generateStatus = {}

            this.modalBackdrop.style = style
            this.adminToolsModal.style = style
        },

        handleRemoveDriverClicked(route) {
            route.driverId = null
            this.clearEditFlags()
        },

        handleAssignDriverClicked() {
            let style = {
                display: "initial",
            }

            this.assignDriverModal.style = style;
            this.modalBackdrop.style = style;

            this.fetchDriverList();
        },

        handleRenameRouteClicked(route) {
            let style = {
                display: "initial",
            }

            this.newRouteName = route.name;
            this.renameRouteModal.style = style;
            this.modalBackdrop.style = style;
        },

        handleEditTaskClicked(task, idx, update_fn) {
            this.selectedTask = this.taskForm = this.resetTaskForm(task);

            // TODO: better data normalization
            this.selectedDepartment = this.selectedTask.department;

            this.selectedTaskIdx = idx;
            this.taskForm.update_fn = update_fn;

            let style = {
                display: "initial",
            }

            this.editTaskModal.style = style;
            this.modalBackdrop.style = style;
        },

        handleAddTaskClicked() {
            this.taskForm = this.resetTaskForm();

            if(!this.validateRouteFieldsForTasks()) return;
            
            let style = {
                display: "initial",
            }

            this.editTaskModal.style = style;
            this.modalBackdrop.style = style;
        },

        closeRenameRouteModal() {
            let style = {
                display: "none"
            }

            this.renameRouteModal.style = style;
            this.modalBackdrop.style = style;
        },

        closeAssignDriverModal() {
            let style = {
                display: "none"
            }
            
            this.assignDriverModal.style = style;
            this.modalBackdrop.style = style;
        },

        acceptAssignDriverModal(route) {
            if(!route) return;

            this.closeAssignDriverModal();
            route.driverId = this.assignDriver;
            this.assignDriver = null; // reset
            this.clearEditFlags();
        },

        async handleSaveRouteClicked() {
            if(!this.validateRouteFieldsForSaving()) return

            this.clearProgressBar()
            this.clearEditFlags()

            // prevent using the UI
            this.table.busy = true

            this.selectedRoute.days = []
            for(let i = 0; i < this.routeDayChecked.length; i++) {
                if(!this.routeDayChecked[i]) continue
                this.selectedRoute.days.push(DAY_TABLE_LONG[i])
            }

            // This calls `this.commitEdits()` later with the doc info
            this.$refs.dynamicTable.invokeUpload()
        },

        handleFacilitySuggestionClicked(suggestion) {
            this.taskForm.address = `${suggestion.address}`
            this.taskForm.facility = suggestion.nickname
            this.autocomplete.nicknames = []
        },

        handleAddressSuggestionClicked(suggestion) {
            this.taskForm.address = `${suggestion.address}`

            if(suggestion.nickname) {
                this.taskForm.facility = suggestion.nickname
            }

            this.autocomplete.addresses = []
        },

        closeAdminToolsModal() {
            let style = {
                display: "none"
            }

            this.adminToolsModal.style = style;
            this.modalBackdrop.style = style;
        },

        closeDeleteRouteModal() {
            let style = {
                display: "none"
            }

            this.deleteRouteModal.style = style;
            this.modalBackdrop.style = style;
        },

        closeEditTaskModal() {
            let style = {
                display: "none"
            }

            this.editTaskModal.style = style;
            this.modalBackdrop.style = style;

            // reset the following to initital states
            this.selectedTask = null;
            this.selectedDepartment = null;
            this.autocomplete.nicknames = [];
            this.autocomplete.addresses = [];
            this.autocomplete.hasError = false;
        },

        handleTaskSubmit() {
            if(!this.validateTaskFieldsForSaving()) return; 
            if(this.taskForm.isNew) {
                this.addNewTask(this.taskForm);
            } else {
                this.updateTask(this.taskForm);
            }
        },


        handleCustomerChanged() {
            // selectedRoute.tasks data
            for(let i = 0; i < this.selectedRoute.tasks.length; i++) {
                let task = this.selectedRoute.tasks[i];
                task.customerID = this.selectedRoute.customer;
                task.department = null;
                this.$refs.dynamicTable.forceEdit(i, task);
            }

            // table data
            // NOTE: is this necessary? Why do we use selectedRouted.tasks then?
            for(let i = 0; i < this.table.data.length; i++) {
                let task = this.table.data[i];
                task.customerID = this.selectedRoute.customer;
                task.department = null;
                this.$refs.dynamicTable.forceEdit(i, task);
            }

            this.isEditted = true
        },

        handleChargeTypeChanged() {
            this.isEditted = true
        },

        /////////////////////
        // private methods //
        /////////////////////

        async commitNewRoute(data) {
            if(!this.selectedRoute) return;

            try {
                let loginToken = localStorage.getItem("user-info");

                let header = {
                    'Authorization': `Bearer ${loginToken}`,
                }

                // + 1 for uploading the route record itself
                this.table.maxProgress = data.tasks.length + 1;
                this.table.progress = 0;
                
                for(let i = 0; i < data.tasks.length; i++) {
                    let task = data.tasks[i]
                    this.progressText = `Creating task #${i+1} for ${task.address}`;
                    let response = await API.post('/Task', 
                        task, 
                        {
                            headers: header
                        }
                    );

                    if (response.status == 200) {
                        data.tasks[i] = response.data.data.task;
                    }
                    this.table.progress++;
                }

                this.progressText = `Creating route ${this.selectedRoute.name}`;

                let response = await API.post('/weekly', 
                    data, 
                    {
                        headers: header
                    }
                );

                this.table.progress++; // route endpoint is included in the batch

                if (response.status == 200) {
                    // `this.selectedRoute` is a pointer to an item in the array
                    this.selectedRoute = response.data.data;
                    await utils.sleep(500);
                }
            } catch(error) {
                this.setErrorMessage(utils.messageFromError(error));
            }
        },

        fromTaskForm(taskForm) {
            let t = {};
            t.countryCode = 'US';
            t.customerID = taskForm.customerID;
            t.department = taskForm.department;
            t.address = taskForm.address;
            t.phoneNumber = taskForm.phoneNumber;
            t.contact = taskForm.contact;
            t.requiresPhotoSignature = taskForm.requiresPhotoSignature;
            t.facility = taskForm.facility;
            t.isPickup = taskForm.isPickup;
            t.extraNotes = taskForm.extraNotes;
            t.chargeType = taskForm.chargeType;
            t.addressLocation = taskForm.addressLocation ?? null;
            return t;
        },

        addNewTask(taskForm) {
            if(!this.selectedRoute || !this.selectedRoute.customer) return;

            // adopt the route's customer, chargeType, and the selected department
            taskForm.customerID = this.selectedRoute.customer;
            taskForm.chargeType = this.selectedRoute.chargeType;
            taskForm.department = this.selectedDepartment;

            // reformat the task to be API compatible
            let newTask = this.fromTaskForm(taskForm);

            // add the task to the route and update the table
            this.selectedRoute.tasks.push(newTask)
            this.table.tasksCreated.push(newTask) // we need to create these later
            this.updateRouteTaskData();
            this.closeEditTaskModal();
        },

        updateTask(form) {
            if(!this.selectedRoute || !this.selectedTask) return;
            this.taskForm.update_fn(this.fromTaskForm(form));
            this.closeEditTaskModal();
        },

        updateRouteTaskData() {
            if(!this.selectedRoute) return

            this.routePaths = [] // erase the polyline paths

            this.table.data = utils.safeStructuredClone(this.selectedRoute.tasks);

            let latBounds = null
            let lonBounds = null
            this.markers = []

            for(let i = 0; i < this.selectedRoute.tasks.length; i++) {
                let t = this.selectedRoute.tasks[i];

                const addressLocation = t.addressLocation;
                if(addressLocation == null || !(addressLocation.Latitude && addressLocation.Longitude)) 
                continue;
                                
                if(addressLocation.Latitude.length == 0 || addressLocation.Longitude.length == 0) 
                continue;

                let lat = parseFloat(addressLocation.Latitude);
                let long = parseFloat(addressLocation.Longitude);

                if(isNaN(lat) || isNaN(long) || (lat == 0 && long == 0)) 
                continue;

                this.markers.push({
                    address: t.address,
                    position: {
                        lat: lat,
                        lng: long
                    }
                })

                // draw the route
                let color = '#FF0055'

                let idx = this.allChargeTypes.findIndex((value) => value._id == t.chargeType)
                if(idx > -1) {
                    const taskName = this.allChargeTypes[idx].name
                    if(utils.isNameSTATLike(taskName)) {
                        color = '#FFFF00'
                    }
                }

                const vm = this;
                let callback = function(route) {
                    console.log("got route")
                    console.log(route)

                    vm.routePaths.push({
                        route: route,
                        color: color
                    })
                }

                // create a path from each pair of points
                if(this.markers.length > 1) {
                    const destLatLon = this.markers[this.markers.length-2].position
                    const startLatLon = this.markers[this.markers.length-1].position
                    console.log("calling google roads...")
                    utils.googleRoads(window, startLatLon, destLatLon, callback)
                }

                if(!latBounds) {
                    latBounds = {
                        min: lat, max: lat
                    }
                } else {
                    latBounds.min = Math.min(latBounds.min, lat);
                    latBounds.max = Math.max(latBounds.max, lat);
                }

                if(!lonBounds) {
                    lonBounds = {
                        min: long, max: long
                    }
                } else {
                    lonBounds.min = Math.min(lonBounds.min, long);
                    lonBounds.max = Math.max(lonBounds.max, long);
                }
            }
                            
            if(latBounds && lonBounds) {
                // re-calculate center of all drivers
                this.center.lat = (latBounds.min + latBounds.max)/2.0;
                this.center.lng = (lonBounds.min + lonBounds.max)/2.0;
            }
        },

        async deleteRoute(route) {
            let routeId = route._id;
            this.table.busy = true;
            if(routeId) {
                try {
                    let loginToken = localStorage.getItem("user-info")
                
                    this.table.progress = 0;
                    this.table.maxProgress = route.tasks.length + 1;
                    this.progressText = `Deleting route ${route.name}`;
                    await API.delete(`/weekly/${routeId}`, 
                        {
                            headers: {
                                Authorization: `Bearer ${loginToken}`
                            }
                        }
                    );
                    this.table.progress++;

                    for(let i = 0; i < route.tasks.length; i++) {
                        let taskId = route.tasks[i]._id;
                        await API.delete(`/tasks/${taskId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${loginToken}`
                            }
                        });
                        this.table.progress++;
                    }
                } catch(error) {
                    this.setErrorMessage(utils.messageFromError(error));
                    return;
                }

                // keep this in so the user can see completion
                await utils.sleep(500)

                this.$toast.open({
                    message: `Successfully deleted route`,
                    type: 'success',
                    position: 'bottom-left'
                });
            }

            this.deleteRouteObj = null;
            this.routeTemplates = this.routeTemplates.filter(x=> x != route);

            // clear weekly route selection
            if(route == this.selectedRoute) {
                this.selectedRoute = null;
                this.clearEditFlags();
                this.clearProgressBar();
            }

            this.table.busy = false;
        },

        renameRoute(route, name) {
            route.name = name;
            this.closeRenameRouteModal();
            this.setEditFlags();
        },

        resetTaskForm(task) {
            this.selectedTask = null;
            this.selectedTaskIdx = -1;
            this.selectedCustomer = null;
            this.selectedDepartment = null;

            if(!task) {
                const form = {
                    isNew: true,
                    customerID: null,
                    department: null,
                    phoneNumber: '',
                    contact: '',
                    requiresPhotoSignature: false,
                    facility: '',
                    isPickup: false,
                    extraNotes: '',
                    address: '',
                    addressLocation: null,
                    update_fn: null,
                }

                return form;
            }

            let addressLocation = null
            if(task.addressLocation) {
                addressLocation = {Latitude: task.addressLocation.Latitude, Longitude: task.addressLocation.Longitude}
            }

            const form = {
                isNew: false,
                customerID: task.customerID,
                department: task.department,
                phoneNumber: task.phoneNumber ?? '',
                contact: task.contact,
                requiresPhotoSignature: task.requiresPhotoSignature,
                facility: task.facility,
                isPickup: task.isPickup,
                extraNotes: task.extraNotes,
                address: task.address,
                addressLocation: addressLocation,
                update_fn: null
            }

            return form
        },

        canPreventUserFromLeaving() {
              if(this.isEditted && !this.showSaveReminder) {
                this.showSaveReminder = true;
                return true;
            }

            return false;
        },

        setEditFlags() {
            this.isEditted = true;
            this.showSaveReminder = false;
        },

        clearEditFlags() {
            this.isEditted = false;
            this.showSaveReminder = false;
        },

        resetCheckedDays() {
            this.routeDayChecked = Array(7).fill(false);
        },

        setCheckedDays(route) {
            this.resetCheckedDays();
            for(let i = 0; i < route.days.length; i++) {
                const d = route.days[i];
                this.routeDayChecked[DAY_TABLE_LONG.indexOf(d)] = true;
            }
        },

        submitAssignDriverRoute() {
            this.setEditFlags();
            this.closeAssignDriverModal();
        },
    
        stageAssignDriverRoute(driver) {
            // We need to check and unselect because we are using radio inputs
            if(this.assignDriver == driver) {
                this.assignDriver = null;
                return;
            }
            this.assignDriver = driver;
        },

        clearProgressBar() {
            this.progressText = '';
            this.table.progress = this.table.maxProgress = 0;
            this.table.uploadError = null;
        },

        async fetchDriverList() {
            const vm = this;
            vm.msg = "";
            vm.loginToken = localStorage.getItem("user-info");

            await API.get(`/driver`, {
                    headers: {
                        Authorization: `Bearer ${vm.loginToken}`
                    }
                })
                .then(async function(response) {
                    if (response.status == 200) {
                        vm.allDrivers = response.data.data;
                    }
                })
                .catch(function(error) {
                    if (error.response && error.response.data.status == "Expired") {
                        vm.$toast.open({
                            message: 'Session expired. Please login again.',
                            type: 'error',
                            position: 'top'
                        });
                        vm.$router.push({ name: 'Login' })
                        localStorage.clear();

                    } else {
                        vm.$toast.open({
                            message: utils.messageFromError(error),
                            type: 'error',
                            position: 'top'
                        });
                    }
                });
        },

        flattenNicknames(address_suggestions) {
            let result = [];

            address_suggestions.forEach(suggested => {
                suggested.nicknames.forEach(nick => {
                    result.push({
                        address: suggested.address,
                        phone: suggested.phone,
                        facility: suggested.facility,
                        nickname: nick
                    });
                })
            });

            return result;
        },

        flattenStreets(matches, google_suggestions) {
            let result = []

            matches.forEach(m => {
                result.push({
                    address: m.address
                })
            })

            google_suggestions.forEach(suggested => {
                result.push({
                    address: suggested.description,
                });
            });

            return result;
        },

        async autocompleteAddress($event) {
            this.autocomplete.nicknames = []
            this.autocomplete.addresses = []

            if($event.target.value.length == 0) return;

            try {
                let loginToken = localStorage.getItem("user-info");

                let header = {
                    'Authorization': `Bearer ${loginToken}`,
                }

                let geolocation = await eznavigator.getLatLong();

                let response = await API.post('/customer-address-autocomplete', {
                        address: $event.target.value,
                        location: geolocation,
                        customerId: this.selectedRoute?.customer ?? null,
                    }, 
                    {
                        headers: header
                    }
                );

                if (response.status == 200) {
                    const address_suggestions = response.data.data.address_suggestions;
                    const google_results = response.data.data.google_results;
                    this.autocomplete.addresses = this.flattenStreets(address_suggestions, google_results);
                    this.autocomplete.hasError = false;
                }
            } catch(error) {
                if(this.autocomplete.hasError) return; 

                if (error.response && error.response.data.status == "Expired") {
                    this.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    this.$router.push({ name: 'Login' })
                    localStorage.clear();

                } else {
                    this.$toast.open({
                        message: "Autocomplete failed. Please enter manually.",
                        type: 'error',
                        position: 'top'
                    });
                }

                this.autocomplete.hasError = true;
            }
        },

        async autocompleteNicknames($event) {
            this.autocomplete.nicknames = []
            this.autocomplete.addresses = []

            if($event.target.value.length == 0) return;

            try {
                let loginToken = localStorage.getItem("user-info");

                let header = {
                    'Authorization': `Bearer ${loginToken}`,
                }

                let geolocation = await eznavigator.getLatLong();

                let response = await API.post('/customer-address-autocomplete', {
                        address: $event.target.value,
                        location: geolocation,
                        customerId: this.selectedRoute?.customer ?? null,
                    }, 
                    {
                        headers: header
                    }
                );

                if (response.status == 200) {
                    this.autocomplete.nicknames = this.flattenNicknames(response.data.data.address_suggestions);
                    this.autocomplete.hasError = false;
                }
            }
            catch(error) {
                if(this.autocomplete.hasError) return;

                if (error.response && error.response.data.status == "Expired") {
                    this.$toast.open({
                        message: 'Session expired. Please login again.',
                        type: 'error',
                        position: 'top'
                    });
                    this.$router.push({ name: 'Login' })
                    localStorage.clear();

                } else {
                    this.$toast.open({
                        message: "Autocomplete failed. Please enter manually.",
                        type: 'error',
                        position: 'top'
                    });

                }

                this.autocomplete.hasError = true;
            }
        },

        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },

        setErrorMessage(msg) {
            this.table.hasError = true;
            this.table.busy = false; // NOTE: assuming
            this.progressText = `Error: ${msg}`;
        },

        validateRouteFieldsForSaving() {
            if(!this.selectedRoute) {
                this.$toast.open({
                        message: 'No route selected',
                        type: 'error',
                        position: 'top'
                    });
                return false;
            }

            if(this.selectedRoute.customer == null) {
                this.$toast.open({
                        message: 'Please select a customer before saving',
                        type: 'error',
                        position: 'top'
                    });
                return false;
            }

            if(this.selectedRoute.chargeType == null) {
                this.$toast.open({
                        message: 'Please select a charge type before saving',
                        type: 'error',
                        position: 'top'
                    });
                return false;
            }

            if(this.selectedRoute.tasks.length == 0) {
                this.$toast.open({
                        message: 'Please create a task before saving',
                        type: 'error',
                        position: 'top'
                    });
                return false;
            }

            return true;
        },

        validateRouteFieldsForTasks() {
            if(!this.selectedRoute) {
                this.$toast.open({
                        message: 'No route selected',
                        type: 'error',
                        position: 'top'
                    });
                return false;
            }

            if(this.selectedRoute.customer == null) {
                this.$toast.open({
                        message: 'Please select a customer',
                        type: 'error',
                        position: 'top'
                    });
                return false;
            }

            return true;
        },

        validateTaskFieldsForSaving() {
            if(!this.taskForm.address || this.taskForm.address.trim().length == 0) {
                this.$toast.open({
                        message: 'Task can be saved with an address',
                        type: 'error',
                        position: 'top'
                    });
                return false;
            }

            return true;
        },

        ////////////////////
        // Dynamic Table  //
        ////////////////////
        async createTaskDocument(doc) {
            try {
                let loginToken = localStorage.getItem("user-info")
                let result = await API.post(`/Task/`, doc,
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                const idx = this.selectedRoute.tasks.findIndex(t => t == doc)

                if(idx > -1) {
                    this.selectedRoute.tasks[idx] = result.data.data.task;
                }

                return true;
            }
            catch(error) {
                this.setErrorMessage(utils.messageFromError(error));
            }

            return false;
        },

        async updateTaskDocument(doc) {
            try {
                let loginToken = localStorage.getItem("user-info")
                await API.put(`/Task/${doc._id}`, doc,
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                return true;
            }
            catch(error) {
                this.setErrorMessage(utils.messageFromError(error));
            }

            return false;
        },

        async deleteTaskDocument(doc) {
            try {
                let loginToken = localStorage.getItem("user-info")

                await API.delete(`/Task/${doc._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${loginToken}`
                    }
                });

                return true;
            }
            catch(error) {
                this.setErrorMessage(utils.messageFromError(error));
            }

            return false;
        },

        async commitEdits(event) {
            function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }

            // remove edits and deletes from taskCreated events
            // TODO: is there a better system to track this? imo the table should track adds too
            let newUpdates = [];
            for(let i = 0; i < event.updates.length; i++) {
                const t = event.updates[i];
                if(t._id) {
                    newUpdates.push(t);
                }
            }

            let newDeletes = [];
            for(let i = 0; i < event.deletes.length; i++) {
                const t = event.deletes[i];
                if(t._id) {
                    newDeletes.push(t);
                }
            }

            event.updates = newUpdates;
            event.deletes = newDeletes;

            const totalCreates = this.table.tasksCreated.length;
            const totalUpdates = event.updates.length; 
            const totalDeletes = event.deletes.length;

            this.table.progress = 0;
            this.table.maxProgress = totalCreates + totalUpdates + totalDeletes + 1;

            if(this.table.maxProgress == 0) {
                this.$toast.open({
                    message: 'No changes to commit to database',
                    type: 'info',
                    position: 'bottom-left'
                });

                this.table.busy = false;
                return;
            }

            for(const [_, doc] of this.table.tasksCreated.entries()) {
                this.progressText = `Creating task for ${doc.address}`;
                // await sleep(1000) // for testing network requests
                if(!await this.createTaskDocument(doc)) {
                    return;
                }
                this.table.progress += 1;
            }

            for(const [_, doc] of event.updates.entries()) {
                this.progressText = `Updating task ${doc._id}`;
                // await sleep(1000) // for testing network requests
                if(!await this.updateTaskDocument(doc)) {
                    return;
                }
                this.table.progress += 1;
            }

            // simulate a network error
            //this.progressText = "Error on last document"
            //this.table.uploadError = "fakeId"
            //this.table.busy = false;
            //return;

            for(const [_, doc] of event.deletes.entries()) {
                this.progressText = `Deleting task ${doc._id}`;
                // await sleep(1000) // for testing network requests
                if(!await this.deleteTaskDocument(doc)) {
                    return;
                }
                this.table.progress += 1;
            }

            let routeId = this.selectedRoute._id;

            try {
                let loginToken = localStorage.getItem("user-info")

                // upload and update endpoints only accept the `id` for the driver field, not the entire object
                let routeUpdate = utils.safeStructuredClone(this.selectedRoute);
                routeUpdate.driverId = routeUpdate.driverId?._id ?? null;

                // upload and update endpoints only accept the `id`s for the task array, not the entire object
                let tasks = []
                for(let i = 0; i < routeUpdate.tasks.length; i++) {
                    const id = routeUpdate.tasks[i]._id ?? routeUpdate.tasks[i];

                    if(id == null && id.length == 0) continue;

                    tasks.push(id);
                }

                routeUpdate.tasks = tasks;

                if(routeUpdate._id) {
                    this.progressText = `Updating route ${routeUpdate.name}`;

                    await API.put(`/weekly/${routeId}`, 
                        routeUpdate, 
                        {
                            headers: {
                                Authorization: `Bearer ${loginToken}`
                            }
                        }
                    );
                } else {
                    this.progressText = `Creating route ${routeUpdate.name}`;
                    let result = await API.post('/weekly', 
                        routeUpdate, 
                        {
                            headers: {
                                Authorization: `Bearer ${loginToken}`
                            }
                        }
                    );

                    routeId = result.data.data._id
                }

                this.table.progress++; // route endpoint is included in the batch
            } catch(error) {
                this.setErrorMessage(utils.messageFromError(error));
                return;
            }

            // keep this in so the user can see completion
            await sleep(500)

            this.$toast.open({
                message: `Successfully changed ${this.table.progress} records`,
                type: 'success',
                position: 'bottom-left'
            });

            await this.fetchWeeklyRoutes();
            const prevRoute = this.routeTemplates.find(route => route._id == routeId);
            this.handleRouteClick(prevRoute);
            this.table.busy = false;
        },
    }
}
</script>

<style scoped>
.link {
    cursor: pointer;
    text-decoration: underline;
}
.link:hover {
    text-decoration: none;
}
.del-route {
    margin-left: auto; 
    margin-right: 0;
    cursor:pointer;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid rgba(111, 111, 111, 0.38);
    box-shadow: 0px 1px 0px 1px rgba(111, 111, 111, 0.38);
    border-radius: 8px;
    padding: 10%;
    background-color: #f7f7f7;
}

.del-route:hover {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    box-shadow: 0px -1px 0px 1px rgba(254, 160, 160, 0.38);
    filter: invert(100%) sepia(0%) saturate(32%) hue-rotate(88deg) brightness(108%) contrast(106%);
}
.gmap-override {
    height: 40vh !important;
    margin-bottom: 1%;
}
.add-route-btn {
    margin-left: 20px;
}
.route-list-col {
    padding-top: 15px;
}
.route-card {
    cursor: pointer;
    background-color: #f7f7f7;
    box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.1);
    color: #555;
}
.route-card:hover {
    box-shadow: 0px 1px 0px 1px rgba(0,0,0,0.2);
    background-color: white;
    color: #212529;
}
.route-card-selected {
    box-shadow: 0px 0px 2px 2px #6eb0f7 !important;
    background: white;
}
.save-reminder {
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  display: inline-block; /* needed for this page */
  position: initial; /* needed for this page */
  z-index: 1;
  top: 0px;
  right: 105%;

  /* Start the shake animation and make the animation last for 1s seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
.autocomplete {
  position: relative;
  display: inline-block;
}
.autocomplete-result {
  padding: 10px;
  cursor: pointer;
  background-color: #fff; 
  border-bottom: 1px solid #d4d4d4; 
}
.autocomplete-result:hover {
  background-color: #e9e9e9;
}
.autocomplete-results {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  list-style-type: none;
  padding-left: 0px;
  margin-left: 15px;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
</style>
