<template>
    <div class="self" v-if="busy">
        <img src="@/assets/img/spinner.gif" class="busy"/>
        {{text}}
    </div>
</template>

<script>

export default {
	name:'Busy',
    props: {
        busy: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            default: ''
        }
    },
	data(){
        return {
        }  
    },
    mounted(){
    }
}
</script>

<style scoped>
.busy {
    object-fit: contain;
    width: 40px;
    height: auto;
}

.self {
    padding: 10px;
}
</style>
